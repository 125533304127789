import React, { useState, useEffect } from 'react';
import AdminHeader from '../../Header/AdminHeader';
import AdminFooter from '../../Footer/AdminFooter';
import AdminLeftMenu from '../../Navbar/AdminLeftMenu';
import 'suneditor/dist/css/suneditor.min.css';
import { useParams } from 'react-router-dom';
import SunEditor, { buttonList } from "suneditor-react";
import AWS from "aws-sdk";
import { userloginDetails } from '../../Utils/AdminAuth';
import { HOST_URL } from '../../Dashboard/api/Api';

const AddBlog = () => {

    var params = useParams()
    params = params.id;
    const lStorage = userloginDetails();
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const isAdmin = lStorage.user.isAdmin;
    const [title, setTitle] = useState('');
    const [meta, setMeta] = useState('');
    const [postdetails, setPostdetails] = useState('');
    const [pageTitle, setPageTitle] = useState('');
    const [catname, setCatname] = useState('');
    const [imageFile, setimageFile] = useState('');
    const [tag, setTag] = useState('');
    const [content, setContent] = useState('');
    const [url, setUrl] = useState('');
    const [image, setImage] = useState('');
    const [error, seterror] = useState({
        errarStatus: false,
        errorMessage: '',
        errorBg: ''
    });

    const addBlogPost = () => {
        const data = new FormData();
        data.append('file_name', imageFile)
        data.append('user_id', userId)
        data.append('user_type', isAdmin)
        data.append('token', userToken)
        data.append('title', title)
        data.append('page_title', pageTitle)
        data.append('url', url)
        data.append('meta', meta)
        data.append('method', 'create')
        data.append('post_details', postdetails)
        data.append('cat_name', catname)
        data.append('tag', tag)
        fetch(`${HOST_URL}/blog.php`, {
            method: "POST",
            body: data
        }).then((result) => {
            result.json().then((resp) => {
                console.log(resp);
                if (resp.success === true) {
                    document.querySelector('body').scrollTo(0, 0);
                    seterror({
                        errarStatus: true,
                        errorMessage: resp.message,
                        errorBg: 'alert alert-success'
                    })
                    setTimeout(function () { window.location = "/admin/blog-list"; }, 1000);
                } else {
                    seterror({
                        errarStatus: true,
                        errorMessage: resp.message,
                        errorBg: 'alert alert-danger'
                    })
                }
            })
        })
    }

    useEffect(() => {
        if (params) {
            FatchFaqTemplate();
        } else {
        }
    }, []);

    const FatchFaqTemplate = () => {

        fetch(`${HOST_URL}/blog.php?user_id=${userId}&id=${params}&user_type=${isAdmin}&method=retrieveid&token=${userToken}`).then((response) => {
            response.json().then((result) => {
                console.log('result', result)
                if (result.success === true) {
                    setTitle(result.data[0].title)
                    setMeta(result.data[0].meta)
                    setContent(result.data[0].post_details)
                    setTag(result.data[0].tag)
                    setUrl(result.data[0].url)
                    setPageTitle(result.data[0].page_title)
                    setImage(result.data[0].image_name)
                    setCatname(result.data[0].cat_name)
                } else {
                    seterror({
                        errarStatus: true,
                        errorMessage: result.message,
                        errorBg: 'alert alert-danger'
                    })
                }
            })
        })
    }

    const handleChange = (content) => {
        setPostdetails(content);
    }

    const updateBlog = () => {

        const data = new FormData();
        data.append('file_name', imageFile)
        data.append('user_id', userId)
        data.append('user_type', isAdmin)
        data.append('token', userToken)
        data.append('title', title)
        data.append('url', url)
        data.append('page_title', pageTitle)
        data.append('id', params)
        data.append('meta', meta)
        data.append('method', 'create')
        data.append('post_details', postdetails)
        data.append('cat_name', catname)
        data.append('tag', tag)
        data.append('method', 'update')
        console.log(data)
        fetch(`${HOST_URL}/blog.php`, {
            method: "POST",
            body: data
        }).then((result) => {
            result.json().then((resp) => {
                console.log(resp)
                document.querySelector('body').scrollTo(0, 0);
                if (resp.success === true) {
                    seterror({
                        errarStatus: true,
                        errorMessage: resp.message,
                        errorBg: 'alert alert-success'
                    })
                    setTimeout(function () { window.location = "/admin/edit-blog/" + params; }, 1000);
                } else {
                    seterror({
                        errarStatus: true,
                        errorMessage: resp.message,
                        errorBg: 'alert alert-danger'
                    })
                }
            })
        })
    }

    const titleHandler = (e) => {
        var spacesString = title;
        var newSrt = spacesString.replace(/[^a-zA-Z ]/g, "")
        var removedSpacesText = newSrt.split(" ").join("-").toLowerCase();
        setUrl(removedSpacesText);

    }

    const handleImageUploadBefore = async (files, info, uploadHandler) => {
        let response = {}
        const selectedFile = files[0];

        if (!selectedFile) {
            console.error('Please select a file');
            return;
        }

        // Configure AWS
        AWS.config.update({
            accessKeyId: process.env.AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.AWS_SECRATE_KEY_id,
            region: 'ap-south-1',
        });

        const s3 = new AWS.S3();
        const bucketName = 'authkey-blog-images';
        const fileName = selectedFile.name;

        // Upload file to S3
        const params = {
            Bucket: bucketName,
            Key: `${Date.now()}.${fileName}`,
            Body: selectedFile,
        };

        try {
            const data = await s3.upload(params).promise();
            console.log('data.Location', data.Location);
            console.log('File uploaded successfully. URL:', data.Location);
            response = {
                result: [
                    {
                        url: data.Location,
                        name: selectedFile.name,
                        size: selectedFile.size
                    }
                ]
            }
            uploadHandler(response)
            console.log('response', response);

        } catch (error) {
            console.error('Error uploading file to S3:', error);
        }


    }

    return (
        <div className="wrapper">
            <AdminLeftMenu />
            <div className="main">
                <AdminHeader />
                <main className="content">
                    <div className="container-fluid p-0">
                        <div className="row mb-2 mb-xl-3">
                            <div className="col-auto d-none d-sm-block">
                                {params ? <h3><strong>Edit Post : </strong> <span className='text-danger'> {title}  </span></h3> : <h3><strong>Add New </strong> Blog Post  </h3>}
                            </div>
                        </div>

                        {
                            error.errarStatus ?
                                <div className={error.errorBg} role="alert">
                                    <strong>Alert!</strong> {error.errorMessage}
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                :
                                null
                        }

                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form>
                                            <div className="row">
                                                <div className="mb-3 col-md-12">
                                                    <label className="form-label" > Blog Title </label>
                                                    <input type="text" defaultValue={title} onKeyUp={titleHandler} onChange={(e) => setTitle(e.target.value)} className="form-control" placeholder="Enter Page Tiitle" />
                                                </div>
                                                <div className="mb-3 col-md-12">
                                                    <label className="form-label" htmlFor="inputEmail4">Blog Canonical URL </label>
                                                    <input type="text" defaultValue={url} onChange={(e) => setUrl(e.target.value)} className="form-control" placeholder="Page Slag URL" />
                                                </div>

                                                <div className="mb-3 col-md-12">
                                                    <label className="form-label" >Blog Meta Description</label>
                                                    <textarea rows="5" defaultValue={meta} onChange={(e) => setMeta(e.target.value)} className="form-control" placeholder="Enter Meta Discription max 150 character "></textarea>
                                                </div>

                                                <div className="mb-3 col-md-12">
                                                    <label className="form-label" > Blog Page Title </label>
                                                    <input type="text" defaultValue={pageTitle} onChange={(e) => setPageTitle(e.target.value)} className="form-control" placeholder="Enter Blog Page Title" />
                                                </div>

                                                {params ?

                                                    <>
                                                        <div className="mb-3 col-md-8">
                                                            <label className="form-label" >Blog Image</label>
                                                            <input type="file" onChange={(e) => setimageFile(e.target.files[0])} className="form-control" />
                                                        </div>

                                                        <div className="mb-3 col-md-4">
                                                            <label className="form-label" >Blog Image</label>
                                                            <img className="card-img" src={'https://authkey.io/blogimages/' + image} alt="Bologna" />
                                                        </div>
                                                    </>

                                                    :

                                                    <div className="mb-3 col-md-12">
                                                        <label className="form-label" >Blog Image</label>
                                                        <input type="file" onChange={(e) => setimageFile(e.target.files[0])} className="form-control" />
                                                    </div>


                                                }




                                                <div className="mb-3 col-md-12">
                                                    <label className="form-label" >Blog Post Details</label>
                                                    <SunEditor setContents={content} onImageUploadBefore={handleImageUploadBefore} onChange={handleChange} height="300" setOptions={{
                                                        buttonList: buttonList.complex
                                                    }}
                                                        placeholder="Please type here..." />
                                                </div>

                                                <div className="mb-3 col-md-12">
                                                    <label className="form-label" > Blog Category Name </label>
                                                    <input type="text" defaultValue={catname} onChange={(e) => setCatname(e.target.value)} className="form-control" placeholder="Enter Category Name" />
                                                </div>

                                                <div className="mb-3 col-md-12">
                                                    <label className="form-label" > Blog Tags</label>
                                                    <input type="text" defaultValue={tag} onChange={(e) => setTag(e.target.value)} className="form-control" placeholder="Enter Tags Name" />
                                                </div>

                                            </div>

                                            {params ?
                                                <div className="float-right">
                                                    <input onClick={updateBlog} type="button" value="Update Post" className="btn btn-sm  btn-primary" />
                                                </div>
                                                :
                                                <div className="float-right">
                                                    <input onClick={addBlogPost} type="button" value="Publish Post" className="btn btn-sm  btn-primary" />
                                                </div>
                                            }





                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>
                <AdminFooter />
            </div>
        </div>
    );
}

export default AddBlog;