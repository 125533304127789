import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimesCircle, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from 'react-data-table-component';
import { customTableStylesForSummery } from '../Utils/customTableStyles';
import AdminHeader from '../Header/AdminHeader';
import AdminLeftMenu from '../Navbar/AdminLeftMenu';
import { userloginDetails } from '../Utils/AdminAuth';
import { HOST_URL } from '../Dashboard/api/Api';

class PartnerDashboard extends Component {


	constructor() {
		const lStorage = userloginDetails();
		const userId = lStorage.user.userId;
		const userToken = lStorage.token;
		const isAdmin = lStorage.user.isAdmin;

		super();
		this.state = {
			user_id: userId,
			token: userToken,
			userType: isAdmin,
			allUserList: null,
			filterData: null,
			emailcount: '',
			smscount: '',
			voicecount: '',
			inActive: '',
			dateFrom: '',
			dateTo: '',
			filterText: '',
			resetPaginationToggle: false,
			userCurrentStatus: '',
			successMessage: false,
			errorMessage: '',
			bgColor: '',
			crossIcons: false,
		}
	}

	componentDidMount() {
		this.countAll();
	}

	countAll() {
		fetch(`${HOST_URL}/user_list_categorywise.php?user_id=${this.state.user_id}&method=usingservice&token=${this.state.token}&type=${this.state.userType}`).then((response) => {
			response.json().then((result) => {
				// console.log(result)
				if (result.success === true) {
					this.setState({
						filterData: result.data,
						allUserList: result.data
					})
					if (this.state.filterData) {
						this.countData();
					}
				} else {
					this.setState({
						successMessage: true,
						bgColor: "alert alert-danger alert-dismissible",
						errorMessage: result.message
					})
				}

			})
		})
	}

	dateFrom = (date) => {
		let dated = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + ("0" + date.getDate()).slice(-2);
		this.setState({
			dateFrom: dated,
			SelecteddateFrom: date
		});
	};

	dateTo = (date) => {
		let dated = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + ("0" + date.getDate()).slice(-2);
		this.setState({
			dateTo: dated,
			SelecteddateTo: date
		});
	};

	countData = (e) => {
		var smsCount = this.state.allUserList.filter(function (element) { return element.sms === 'YES' })
		var EmailCount = this.state.allUserList.filter(function (element) { return element.email === 'YES' })
		var VoiceCount = this.state.allUserList.filter(function (element) { return element.voice === 'YES' })
		var InCative = this.state.allUserList.filter(function (element) { return element.voice === 'NO' && element.sms === 'NO' && element.email === 'NO' } || [])
		if (e === 'sms') {
			this.setState({
				filterData: smsCount
			})
		} else if (e === 'voice') {
			this.setState({
				filterData: VoiceCount
			})
		}
		else if (e === 'email') {
			this.setState({
				filterData: EmailCount
			})
		} else if (e === 'inactive') {
			this.setState({
				filterData: InCative
			})
		}
		this.setState({
			smscount: smsCount,
			emailcount: EmailCount,
			voicecount: VoiceCount,
			inActive: InCative
		})

	}

	filterdataFuncation = (e) => {
		let searchTeram = e.target.value.toLowerCase();

		var newArray = this.state.filterData.filter(function (el) {
			return el.username.includes === searchTeram;
		});

		this.setState({
			filterData: newArray,
			crossIcons: true
		})
	}

	resetvalue = () => {
		this.setState({
			filterData: this.state.allUserList, crossIcons: false
		})
	}

	countDay = (e) => {
		const userDate = new Date(e);
		const TodateDate = new Date();
		const diffTime = Math.abs(TodateDate - userDate);
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		return (
			diffDays
		)
	}

	loginToUserAccount(emailaddress, password) {
		var url = document.location.href;
		const singleTemplateData = ({ username: emailaddress, password: password, admintype: this.state.userType });
		console.log(singleTemplateData)
		fetch(`${HOST_URL}/login.php`, {
			method: "post",
			headers: {
				'content-Type': 'application/json'
			},
			body: JSON.stringify(singleTemplateData)
		}).then((result) => {
			result.json().then((result) => {
				if (result.success === true) {
					result['url'] = url;
					localStorage.setItem('login', JSON.stringify(result));
					this.props.history.push('/dashboard');
				} else {
					this.setState({
						successMessage: true,
						bgColor: "alert alert-danger alert-dismissible",
						errorMessage: result.message
					})
				}
			})
		})
	}


	userDisabled(isactive, selectedUserId) {
		document.querySelector('body').scrollTo(0, 0);
		fetch(`${HOST_URL}/admin_user_list.php?user_id=${this.state.user_id}&method=enabledisable&current_status=${isactive}&ret_user_id=${selectedUserId}&token=${this.state.token}`).then((response) => {
			response.json().then((result) => {
				if (result.success === true) {
					this.setState({
						successMessage: true,
						successBg: "alert alert-success alert-dismissible",
						errorMessage: result.message
					})
					window.location.reload();
				} else {
					this.setState({
						successMessage: true,
						successBg: "alert alert-danger alert-dismissible",
						errorMessage: result.message
					})
				}
			})
		})
	}

	render() {
		//console.log(this.state)
		const columns = [
			{
				name: 'Login',
				cell: (row) => <p> {row.status === 1 ? <FontAwesomeIcon onClick={() => this.loginToUserAccount(row.username, row.password)} role='button' color="green" icon={faSignInAlt} /> : <FontAwesomeIcon role='button' color="red" icon={faSignInAlt} />}  </p>,
				width: '70px',
			},
			{
				name: 'Partner Email ID / Username',
				cell: (row) => <p className='text-left'>{row.username} <br /> <span className='text-info'> {row.user_id} </span> </p>,
				width: '300px',
				sortable: true,

			},
            {
				name: 'Clients',
				cell: (row) => <span>100</span>,
				sortable: true,

			},
            {
				name: 'Reseller',
                cell: (row) => <span>2</span>,
				sortable: true,

			},
            {
				name: 'Today Active Users',
                cell: (row) => <span>30</span>,
				width: '180px',
				sortable: true,

			},
			{
				name: 'WhatsApp Enabled',
                cell: (e) => <strong>{e.sms === 'YES' ? <span className='text-primary'>Yes</span> : <span className='text-danger'>NO</span>}  </strong>,
				selector: row => row.created,
				sortable: true,
				width: '200px',

			},
			{
				name: 'RCS Enabled',
                cell: (e) => <strong>{e.sms === 'YES' ? <span className='text-primary'>Yes</span> : <span className='text-danger'>NO</span>}  </strong>,
				selector: row => row.parent,
				sortable: true,
				width: '150px',

			},
			{
				name: 'Last Recharge',
				cell: (row) => <span>{Math.trunc(row.balance)} </span>,
				selector: row => row.balance,
				sortable: true,
				width: '150px',

			},
			{
				name: 'Today Recharge',
				cell: (row) => <span className='text-primary'>{this.countDay(row.created)} </span>,
				selector: row => row.sms,
				width: '150px',
				sortable: true,
			},
			{
				name: 'Today Volume',
				cell: (row) => <span className='text-primary'>{this.countDay(row.created)} </span>,
				selector: row => row.voice,
				sortable: true,
				width: '150px',
			},
			// {
			// 	name: 'Email',
			// 	cell: (e) => <strong>{e.email === 'YES' ? <span className='text-primary'>Yes</span> : <span className='text-danger'>NO</span>}  </strong>,
			// 	selector: row => row.email,
			// 	sortable: true,
			// 	width: '100px',
			// },

		];

		return (
			<div className="wrapper">
				<AdminLeftMenu />
				<div className="main">
					<AdminHeader />

					<main className="content">
						<div className="container-fluid p-0">
							<div className="row mb-3 mb-xl-3">
								<div className="col-auto d-none d-sm-block">
									<h3 className="mt-2"><strong>Partner Dashboard</strong></h3>
								</div>
								

								<div className="col-auto ml-auto d-flex text-right mt-n1">

									<DatePicker className="form-control btn-block"
										dateFormat="yyyy/MM/dd"
										//value={this.state.dateFrom}
										onChange={this.dateFrom}
										selected={this.state.SelecteddateFrom}
										placeholderText='From Date'
									/>

									<DatePicker className="form-control btn-block"
										dateFormat="yyyy/MM/dd"
										//value={this.state.dateTo}
										onChange={this.dateTo}
										selected={this.state.SelecteddateTo}
										placeholderText='To Date'
									/>

									<button onClick={() => this.countAll()} style={{  borderRadius: '20%',padding: '10px 15px' }} className="btn btn-sm btn-primary ml-2"><FontAwesomeIcon icon={faSearch} /> </button>


								</div>


							</div>

							<div className="row">
								<div className="col-xl-12  mt-3 col-xxl-5 d-flex">
									<div className="w-100">
										<div className="row">
											<div className="col-sm-3">
												<div className="card">
													<div className="card-body">
														<h5 className="card-title mb-4">Total Partners</h5>
														<h2 className="mt-1 mb-3">{this.state.smscount.length}</h2>

													</div>
													{this.state.smscount.length ?
														<button onClick={() => this.countData('sms')} className='btn border-0 btn-primary btn-block btn-sm'>View Users List</button>
														:
														null
													}

												</div>
											</div>
											<div className="col-sm-3">
												<div className="card">
													<div className="card-body">
														<h5 className="card-title mb-4">Total Accounts</h5>
														<h2 className="mt-1 text-warning mb-3">{this.state.voicecount.length}</h2>
													</div>
													{this.state.voicecount.length ?

														<button onClick={() => this.countData('voice')} className='btn border-0 btn-warning btn-block btn-sm'>View Users List</button>
														:
														null
													}

												</div>
											</div>
											<div className="col-sm-3">
												<div className="card">
													<div className="card-body">
														<h5 className="card-title mb-4">WhatsApp Enabled</h5>
														<h2 className="mt-1 text-info mb-3">{this.state.emailcount.length}</h2>
													</div>
													{this.state.emailcount.length ? <button onClick={() => this.countData('email')} className='btn border-0 btn-info btn-block btn-sm'>View Users List</button> : null}

												</div>
											</div>
											<div className="col-sm-3">
												<div className="card">
													<div className="card-body">
														<h5 className="card-title mb-4">RCS Enabled</h5>
														<h2 className="mt-1 text-danger mb-3">{this.state.inActive.length}</h2>
													</div>
													
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>


							<div className="row">
								<div className="col-12 col-lg-12 col-xxl-12 d-flex">
									<div className="card pb-5 flex-fill">

										

										<div className="">


											<DataTable
												columns={columns}
												data={this.state.filterData || 0}
												pagination={this.state.allUserList ? true : false}
												customStyles={customTableStylesForSummery}
												highlightOnHover
												paginationRowsPerPageOptions={[10, 25, 50, 100]}
												noDataComponent={'Loading.......'}
											/>

										</div>
									</div>
								</div>
							</div>

						</div>
					</main>
				</div>
			</div>

		);
	}
}

export default PartnerDashboard; 