import React, { Component } from 'react';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../DashboardLeftMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import ReactTelInput from 'react-telephone-input';
import flags from '../../Dashboard/assets/img/flags.png'
import 'react-telephone-input/css/default.css'
import VoiceSampleCode from './VoiceSampleCode';
import { HOST_URL } from '../api/Api';
class SendVoice extends Component {


    constructor() {
        const lStorage = JSON.parse(localStorage.getItem("login"));
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;
        super();
        this.state = {
            UserDetails: [],
            ListodSernderID: '',
            user_id: userId,
            token: userToken,
            authkey: '',
            countryCode: '91',
            senderid: 'ACCOTP',
            mobile: '',
            message: 'This is test voice call from authkey account',
            successMessage: false,
            errorMessage: '',
            bgColor: '',
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.getUserDetails();
        this.GetListOfSenderId();
    }

    getUserDetails() {
        fetch(`${HOST_URL}/profile.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                //  console.log(result)
                if (result.success === true) {
                    this.setState({ UserDetails: result.data[0], authkey: result.data[0].authkey, mobile: result.data[0].mobile});
                }
            })
        })
    }


    GetListOfSenderId() {
        fetch(`${HOST_URL}/senderid_request.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                //   console.log(result)
                if (result.success === true) {
                    this.setState({ ListodSernderID: result });
                }

            })
        })
    }


    sendVoice() {
        fetch(`https://api.authkey.io/request?authkey=${this.state.authkey}&mobile=${this.state.mobile}&country_code=${this.state.countryCode}&voice=${this.state.message}`).then((response) => {
            response.json().then((result) => {
                document.querySelector('body').scrollTo(0, 0);
                this.setState({ successMessage: true });
                this.setState({ bgColor: "alert alert-success alert-dismissible" });
                this.setState({ errorMessage: JSON.stringify(result) });
            })
        })
    }

    handleInputChange(telNumber) {
        this.setState({ countryCode: telNumber.substring(1) })
        // console.log('input changed. number: ', telNumber);
    }


    render() {
        return (
            <div className="wrapper">
                <DashboardLeftMenu />
                <div className="main">
                    <DashboardHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className=" row mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong> Get Started With </strong> Voice </h3>
                                </div>

                            </div>
                            <div className="">

                            {
                                    this.state.successMessage ?
                                        <div className={this.state.bgColor} role="alert">
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                            {this.state.errorMessage}
                                        </div>
                                        :
                                        null
                                }
 
                                <div className="card">
                                    <div className="card-body">
                                        <h3>Send Voice Test Message</h3>
                                     
                                        <div className="form-group mt-5 row">
                                            <label for="colFormLabel" className="col-sm-2 col-form-label">Send Voice to </label>
                                            <div className="col-sm-4">
                                                <div className="col-auto">
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="">
                                                                <ReactTelInput
                                                                    defaultCountry="in"
                                                                    flagsImagePath={flags}
                                                                    onChange={this.handleInputChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <input type="text" defaultValue={this.state.UserDetails.mobile}
                                                            onChange={(event) => this.setState({ mobile: event.target.value })}
                                                            className="form-control" id="inlineFormInputGroup" placeholder="+912384930480" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="form-group row">
                                            <label for="colFormLabel" className="col-sm-2 col-form-label">AuthKey </label>
                                            <div className="col-sm-6">
                                                <div className="col-auto">
                                                    <div className="input-group mb-2">
                                                        <input type="text" value={this.state.UserDetails.authkey}
                                                            onChange={(event) => this.setState({ authkey: event.target.value })}
                                                            className="form-control" id="inlineFormInputGroup" placeholder="ksdfkjsfhe89324926734343424342423" />
                                                        <div className="input-group-text">
                                                            <FontAwesomeIcon icon={faCopy} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>


                                        <div className="form-group row">
                                            <label for="colFormLabel" className="col-sm-2 col-form-label"> Message </label>
                                            <div className="col-sm-6">
                                                <div className="col-auto">
                                                    <div className="input-group mb-2">
                                                        <input type="text" className="form-control"
                                                            onChange={(event) => this.setState({ message: event.target.value })}
                                                            id="inlineFormInputGroup" defaultValue="This is test voice call from authkey account" placeholder="This is test voice call from authkey account" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <button type="button" onClick={()=> this.sendVoice()} className="btn mt-3 btn-sm button-cus"> Test Message</button>
                                        <h3 className="mt-5 mb-3">Sample Code</h3>

                                        <VoiceSampleCode />
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>

                    <DashboardFooter />
                </div>
            </div>
        );
    }
}

export default SendVoice;