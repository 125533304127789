import Axios from "axios";
import React, { useState } from "react";

const DocTest = () => {
  const [file, setFile] = useState(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const handleUpload = async () => {
    if (!file) {
      console.error("No file selected");
      return;
    }

    const form = new FormData(); // Create a new FormData instance
    form.append("file", file); // Append the file to the form

    try {
      const { data } = await Axios.post(
        "http://localhost:8000/api/doc_test",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data", 
          },
          onUploadProgress: (progressEvent) => {
            if (progressEvent.lengthComputable) {
              const percent = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              );
              setUploadPercentage(percent);
            }
          },
        }
      );
      console.log(data); 
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <>
      <div>DocTest</div>
      <input
        type="file"
        onChange={(e) => setFile(e.target.files[0])} 
      />
      <button onClick={handleUpload}>Submit</button>
      <p>progress {uploadPercentage}</p>
    </>
  );
};

export default DocTest;
