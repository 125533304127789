
import React, { useEffect } from 'react';
import AdvanceDataTable from "../../UI/Table/AdvanceDataTable";
import AdminLayout from '../../UI/Layout/AdminLayout';
import { useState } from 'react';
import { userloginDetails } from '../../Utils/AdminAuth';
import api from '../../../axios/Axios'
import axios from 'axios';
import Spining from '../../UI/Spining/Spining';
import Select from 'react-select';
import { HOST_URL, NODE_HOST_URL } from '../../Dashboard/api/Api';
import { ADMIN_RCS_TEMPLATE_LIST_CONST} from '../../UI/Table/ColumnConst';
const AdminRcsTemplateList = () => {
  // const lStorage = userloginDetails();
  // const userId = lStorage.user.userId;
  // const userToken = lStorage.token;
  // const adminType = lStorage.user.isAdmin;
  const [userId, setUserId] = useState("");
  const [token, setToken] = useState("");
  const [adminType, setAdminType] = useState("");
  const [error, setError] = useState({
    bgColor: "alert alert-success alert-dismissible",
    errorMessage: "",
    successMessage: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [rcsTemplateList, setRcsTemplateList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterTemplateList, setFilterTemplateList] = useState([]);
  const [authenticationButton, setAuthenticationButton] = useState("");
  const [route, setRoute] = useState("");

  const [allUsers, setAllUsers] = useState([]);
  const [selecteduser, setSelectedusers] = useState(null);
  const [status, setStaus] = useState("");
  const customStyles = {
    control: (provided) => ({
      ...provided,
      cursor: "pointer",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (style) => ({
      ...style,
      fontSize: "12px",
    }),
  };

  useEffect(() => {
    const lStorage = JSON.parse(localStorage.getItem("admin"));
    const user_id = lStorage?.user?.userId;
    const token = lStorage?.token;
    const adminType = lStorage?.user?.isAdmin;
    setUserId(user_id);
    setToken(token);
    setAdminType(adminType);

  }, [])

  useEffect(() => {
    if (userId && token && adminType) {
      fetchUsersList();

    }
  }, [userId, token, adminType])

  const handleClick = () => {
    getRcsTemplateList();
  }

  const getRcsTemplateList = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.post(`${NODE_HOST_URL}/admin/rcs`, {
        token: token,
        user_id: userId,
        method: "retrieve_temp",
        search_user_id: selecteduser.value,
        status: status
      });

      if (data.success === true) {
        setRcsTemplateList(data.data)
      }else{
        setRcsTemplateList([])
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false)
    }
  };

  const fetchUsersList = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get(
        `${HOST_URL}/admin_user_list.php?user_id=${userId}&method=retrieve_all_user&type=${adminType}&token=${token}`
      );

      if (data.success) {
        // console.log(result.data)
        const formattedUsers = data.data.map((user) => ({
          value: user.id,
          label: user.email,
        }));

        setAllUsers([{ value: "0", label: "All Users" }, ...formattedUsers]);
      }
    } catch (error) {
      console.log(error.message);
      setError({
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: error.message,
        successMessage: true,
      });
    }

    setIsLoading(false);
  };

  const searchHandler = (e) => {
    let searchValue = e.target.value.toString();
    setSearchQuery(searchValue)
  }

  const reset = (e) => {
    this.setState({
      search: "",
    });
  }



  return (
    <AdminLayout>

      <div className="container-fluid p-0">

        <h6>Rcs Template List</h6>

        <div className=" d-flex flex-column align-items-center justify-content-center mb-4">

          <Select
            onChange={(selectedOption) =>
              setSelectedusers(selectedOption)
            }
            options={allUsers} 
            value={selecteduser}
            isSearchable={true}
            placeholder="Select Users"
            isClearable={true}
            styles={customStyles}
            isLoading={isLoading}
          />

          <select name="" id="" className='form-control mb-2' value={status} onChange={(e) => setStaus(e.target.value)}>
            <option value=""  disabled >Select Template Status</option>
            {selecteduser?.value !== "0" && <option value="all">All</option>}
            <option value="0">Pending</option>
            <option value="1">Approved</option>
            <option value="2">Draft</option>
            <option value="5">In Review</option>
            <option value="3">Rejected</option>
            <option value="4">Deleted</option>
          </select>
          <button onClick={handleClick} className='btn btn-primary w-100'>Search</button>

        </div>

        <AdvanceDataTable
          tableName={"Template List"}
          tableCell={ADMIN_RCS_TEMPLATE_LIST_CONST}
          tableData={rcsTemplateList}
          isLoading={isLoading}
        />
      </div>

    </AdminLayout>

  )
}

export default AdminRcsTemplateList;