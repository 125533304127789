import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarCheck, faInfoCircle, faMobile, faBan, faDownload, faSearch } from '@fortawesome/free-solid-svg-icons'
import EmptyImg from "../../Dashboard/assets/img/create-project.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { HOST_URL, NODE_HOST_URL } from '../api/Api';
import Axios from '../../../axios/Axios';


class FlashCallDetailReport extends Component {

    constructor(props) {

        const lStorage = JSON.parse(localStorage.getItem("login"));
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;
        super(props);
        this.state = {
            user_id: userId,
            token: userToken,
            voiceDetailsReportList: '',
            responceMessage: '',
            status: '',
            senderid: '',
            mobile: '',
            email: '',
            channel: this.props.match.params.channel,
            dateFrom: this.props.match.params.dateFrom,
            dateTo: this.props.match.params.dateTo,
            SelecteddateFrom: '',
            SelecteddateTo: '',
            successMessage: false,
            errorMessage: '',
            bgColor: '',
            flashCallDetail: [],
        }
    }

    componentDidMount() {
        this.detailsReport();
    }

    // currentDate(currentDate) {
    //     const year = currentDate.getFullYear();
    //     const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    //     const day = String(currentDate.getDate()).padStart(2, "0");
    //     const formattedDate = `${year}-${month}-${day}`;
    //     return formattedDate;
    //   }

    detailsReport = async () => {
        //console.log('channel',this.state.channel)
        let paramData = {
            user_id: this.state.user_id,
            token: this.state.token,
            method: 'voice_api_detail',
            fromdate: this.state.dateFrom,
            todate: this.state.dateTo
        }
        try {
            let { data } = await Axios.post(`${NODE_HOST_URL}/flashcall_voice`, paramData)
            if (data) {
                console.log(data)
                this.setState({ flashCallDetail: data.detail })
            }
        } catch (error) {
            console.log(error)
        }
    }

    FilderDetailsWithChannel() {
        this.setState({ voiceDetailsReportList: '' });
        fetch(`${HOST_URL}/user_report.php?user_id=${this.state.user_id}&method=details&channel=voice&token=${this.state.token}&date_from=${this.state.dateFrom}&date_to=${this.state.dateTo}&mobile=${this.state.mobile}&status=${this.state.status}`).then((response) => {
            response.json().then((result) => {
                console.log(result)
                if (result.success === true) {
                    if (this.state.channel === 'voice') {
                        this.setState({ voiceDetailsReportList: result.message });
                    }
                } else {
                    this.setState({ successMessage: true, errorMessage: result.message, bgColor: "alert alert-danger alert-dismissible" })
                }
            })
        })

    }

    dataDownload() {


        var date1 = new Date(this.state.dateFrom);
        var date2 = new Date(this.state.dateTo);
        var Difference_In_Time = date2.getTime() - date1.getTime();
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        //console.log('Difference_In_Days', Math.trunc(Difference_In_Days))

        if (Math.trunc(Difference_In_Days) <= 15) {

            window.location.href = `${HOST_URL}/user_report.php?user_id=${this.state.user_id}&method=download&channel=voice&token=${this.state.token}&date_from=${this.state.dateFrom}&date_to=${this.state.dateTo}`;

        } else {

            document.querySelector('body').scrollTo(0, 0);
            this.setState({ successMessage: true, bgColor: "alert alert-danger alert-dismissible", errorMessage: `Please go to Download Center to Download 15 day's after data.` });
        }


    }

    dateFrom = (date) => {
        let dated = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, "0") + '-' + date.getDate().toString().padStart(2, "0");
        this.setState({
            dateFrom: dated,
            SelecteddateFrom: date
        });
    };

    dateTo = (date) => {
        let dated = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, "0") + '-' + date.getDate().toString().padStart(2, "0");
        this.setState({
            dateTo: dated,
            SelecteddateTo: date
        });
    };

    setStatus() {
        if (this.state.channel === 'voice') {
            return (
                <>
                    <option value=""> All </option>
                    <option value="success"> Success </option>
                    <option value="failed"> Failed </option>
                    <option value="submitted"> Submitted </option>
                </>
            )
        } else {
            return (
                <>
                    <option value=""> No Channel Selected </option>
                </>
            )
        }
    }

    render() {
        return (
            <div className="wrapper">
                <DashboardLeftMenu />
                <div className="main">
                    <DashboardHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className=" mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong>Flash Call Reprot </strong></h3>
                                </div>
                            </div>
                            <div className="">
                                <div className="card">
                                    <div className="">
                                        <h5 className="card-title  mt-5">
                                            <div className="col-auto ml-auto text-right mt-n1">
                                                <nav className="text-center" aria-label="breadcrumb">
                                                    <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
                                                        <Link to="/dashboard/flash-call"><li className="breadcrumb-item"> Today's Summary  </li> </Link>
                                                        <Link to={'/dashboard/flash-call-detail-reports/' + 'voice/' + this.state.dateFrom + '/' + this.state.dateTo}> <li className="breadcrumb-item active">Details </li> </Link>


                                                    </ol>
                                                </nav>
                                            </div>
                                        </h5>
                                    </div>

                                    <div className="card-body">
                                        {
                                            this.state.successMessage ?
                                                <div className={this.state.bgColor} role="alert">
                                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                        <span aria-hidden="true">×</span>
                                                    </button>
                                                    <div className="">
                                                        {this.state.errorMessage} <Link to="/dashboard/download-centre">Download Centre</Link>
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }
                                        <div className="filter-option">
                                            <h6 className="card-subtitle text-muted">Filter </h6>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="input-group mb-2 mr-sm-2">
                                                        {/* <div className="input-group-text"><FontAwesomeIcon icon={faCalendarCheck} /></div> */}
                                                        <DatePicker className="form-control btn-block"
                                                            value={this.state.dateFrom}
                                                            dateFormat="yyyy/MM/dd"
                                                            onChange={this.dateFrom}
                                                            selected={this.state.SelecteddateFrom}
                                                            placeholderText='From Date'
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-group mb-2 mr-sm-2">
                                                        {/* <div className="input-group-text"><FontAwesomeIcon icon={faCalendarCheck} /></div> */}

                                                        <DatePicker className="form-control btn-block"
                                                            dateFormat="yyyy/MM/dd"
                                                            value={this.state.dateTo}
                                                            onChange={this.dateTo}
                                                            selected={this.state.SelecteddateTo}
                                                            placeholderText='Date To'
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-md-4'>
                                                    <div className="input-group mb-2 mr-sm-2">
                                                        <div className="input-group-text"><FontAwesomeIcon icon={faBan} /></div>
                                                        <select onChange={(event) => this.setState({ status: event.target.value })} className="form-control">
                                                            <option value=""> Select Status </option>
                                                            {
                                                                this.setStatus()
                                                            }
                                                        </select>
                                                    </div>
                                                </div>



                                            </div>

                                            {/* <div className="row">
                        <div className="col-md-12">
                            <div className="input-group mb-2 mr-sm-2">
                                <div className="input-group-text"><FontAwesomeIcon icon={faMobile}/></div>
                                {this.state.channel==='email'?
                                <input  onChange={(event) => this.setState({ email:event.target.value })} type="text"  placeholder="Enter Email" className="form-control" />
                                :
                                <input  onChange={(event) => this.setState({ mobile:event.target.value })} type="text"  placeholder="Enter Mobile NO" className="form-control" />
                                }
                            </div>
                        </div>

                    

                        </div> */}

                                            <div className="row mt-3">
                                                <div className="col-md-6"></div>
                                                {/* <div className="col-md-3">
                        <button type="button" onClick={()=> this.dataDownload()} className="btn-success btn-sm btn-block ml-auto"><FontAwesomeIcon icon={faDownload}/> Download Reports </button>
                    </div> */}
                                                <div className="col-md-3">
                                                    <button type="button" onClick={() => this.detailsReport()} className="btn-primary btn-block btn-sm ml-auto"> <FontAwesomeIcon icon={faSearch} /> Filter Search </button>
                                                </div>
                                            </div>
                                        </div>


                                        <hr />


                                        <div className="row">
                                            <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                                <div style={{ overflow: 'scroll', height: 400 + 'px' }} className=" flex-fill">
                                                    <div className="">
                                                        {this.state.channel === 'voice' && this.state.flashCallDetail.length > 0 ?
                                                            <table className="table table-striped table-sm my-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Mobile</th>
                                                                        <th>Country Code</th>
                                                                        <th>Status</th>
                                                                        <th>Cost</th>
                                                                        <th>Duration</th>
                                                                        <th>Start Time </th>
                                                                        <th>End Time </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        this.state.flashCallDetail.map((item, i) =>
                                                                            <tr>

                                                                                <td>{item.mobile}</td>
                                                                                <td>{item.country_code}</td>
                                                                                <td>
                                                                                    {item.status}
                                                                                </td>
                                                                             

                                                                                <td>{item.voice_cost || 0}</td>
                                                                                

                                                                                {/* <td>
                                                                                    <span className="badge bg-info" data-tip data-event='click focus' data-for={"registerTip" + i} style={{ cursor: 'pointer', fontSize: 15, }} > <small style={{ fontSize: 10 + 'px' }}>Message Show / Hide </small> <FontAwesomeIcon icon={faInfoCircle} color='yellow' size={5} /></span>
                                                                                    <ReactTooltip globalEventOff='click'
                                                                                        className="tooltip"
                                                                                        html={true}
                                                                                        clickable={true}
                                                                                        onClick={() => { ReactTooltip.show(this.fooRef) }}
                                                                                        id={"registerTip" + i}
                                                                                        place="bottom" effect="solid">
                                                                                        {item.message}
                                                                                    </ReactTooltip>
                                                                                </td> */}

                                                                                <td>{item.call_duration}</td>
                                                                                {/* <td>{item.keypress === '' || !item.keypress ? 'NA' : <span className="badge bg-info">{item.keypress}</span>}</td> */}
                                                                                <td>{item.call_start_time}</td>
                                                                                <td>{item.call_end_time}</td>

                                                                            </tr>
                                                                        )
                                                                    }
                                                                </tbody>
                                                            </table>
                                                            :
                                                            <div className="text-center">
                                                                <div className="card-body">
                                                                    <img alt="Empty Data" className="project-empty-img" src={EmptyImg} />
                                                                    <small>{this.state.responceMessage} in <strong> {this.state.channel}  </strong> Between {this.state.dateFrom} to {this.state.dateTo} </small>
                                                                </div>
                                                            </div>

                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <DashboardFooter />
                </div>
            </div>
        );
    }
}

export default FlashCallDetailReport;