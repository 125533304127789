import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import FacebookLogin from "react-facebook-login";
import logo from "../Dashboard/assets/img/logo.png";
import LoginSignupMessage from "./siteComponents/LoginSignupMessage";
import { BASE_URL, HOST_URL } from "../Dashboard/api/Api";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      isfacebooklogin: false,
      show: false,
      loginMessage: "",
      bgColor: "",
      url: false,
      tfa: false,
    };
    this.keyPressed = this.keyPressed.bind(this);
    this.login = this.login.bind(this);
  }

  keyPressed(event) {
    if (event.key === "Enter") {
      this.login();
    }
  }

  componentDidMount() {
    localStorage.clear();
    const hostUrl = window.location.host;
    if (hostUrl !== BASE_URL || "localhost:3000") {
      this.setState({ url: true });
    } else {
      this.setState({ url: false });
    }
    var url = document.location.href;
    var mainURL = "console.authkey.io";
    var localHost = "localhost:3000";
    if (url.indexOf(localHost) !== -1 || url.indexOf(mainURL) !== -1) {
      console.log("URL test passed");
    } else {
      window.location = "/my-account";
    }
  }

  login() {
    this.setState({ loginMessage: false });
    var url = window.location.host;
    fetch(`${HOST_URL}/login.php`, {
      method: "post",
      headers: {
        "content-Type": "application/json",
      },
      body: JSON.stringify(this.state),
    }).then((result) => {
      result.json().then((resp) => {
        if (resp.success === true) {
          this.setState({
            show: true,
            loginMessage: "Success",
            bgColor: "alert alert-success",
          });
          if (resp.user.userId === 8407) {
            localStorage.setItem("stanmaxuser", JSON.stringify(resp));

            setTimeout(function () {
              window.location = "/stanmax/whatsappconv";
            }, 1000);
            return;
          }
          if (resp.user.isAdmin === "client") {
            resp["url"] = url;
            localStorage.setItem("login", JSON.stringify(resp));
            localStorage.setItem("admin", JSON.stringify({ success: false }));
            setTimeout(function () {
              window.location = "/dashboard";
            }, 1000);
          } else if (resp.user.isAdmin === "admin") {
            localStorage.setItem("admin", JSON.stringify(resp));
            setTimeout(function () {
              window.location = "/admin";
            }, 1000);
          } else if (resp.user.isAdmin === "emp") {
            localStorage.setItem("emp", JSON.stringify(resp));
            setTimeout(function () {
              window.location = "/admin";
            }, 1000);
          } else if (resp.user.isAdmin === "reseller") {
            localStorage.setItem("reseller", JSON.stringify(resp));
            setTimeout(function () {
              window.location = "/admin";
            }, 1000);
          }
        } else {
          this.setState({
            show: true,
            loginMessage: resp.message,
            bgColor: "alert alert-danger",
          });
        }
      });
    });
  }

  responseFacebook = (response) => {
    const loginWithFacebook = {
      email: response.email,
      name: response.name,
      api_response: response,
    };
    var url = document.location.href;
    fetch(`${HOST_URL}/facebook_login.php`, {
      method: "post",
      headers: {
        "content-Type": "application/json",
      },
      body: JSON.stringify(loginWithFacebook),
    }).then((result) => {
      result.json().then((resp) => {
        if (resp.success === true && resp.account === "old") {
          this.setState({
            show: true,
            bgColor: "alert alert-success alert-dismissible",
            loginMessage: "success",
          });
          resp["url"] = url;
          localStorage.setItem("login", JSON.stringify(resp));
          localStorage.setItem("admin", JSON.stringify({ success: false }));
          setTimeout(function () {
            window.location = "/dashboard";
          });
        } else if (resp.success === true && resp.account === "new") {
          resp["url"] = url;
          this.setState({
            show: true,
            bgColor: "alert alert-success alert-dismissible",
            loginMessage: "success",
          });
          localStorage.setItem("login", JSON.stringify(resp));
          localStorage.setItem("admin", JSON.stringify({ success: false }));
          setTimeout(function () {
            window.location = "/signup-success";
          });
        } else {
          this.setState({
            show: true,
            bgColor: "alert alert-danger alert-dismissible",
            loginMessage: resp.message,
          });
        }
      });
    });
  };

  render() {
    document.querySelector("body").scrollTo(0, 0);
    return (
      <div>
        <div>
          {" "}
          <Helmet>
            <title>
              Login page for SMS Email Voice Using one API - AuthKey.io
              |Powerful API built For Sending OTPs & Trans Traffic
            </title>
            <meta
              name="description"
              content="Send SMS Voice or Email parallelly or use other channels as Fallback | High Volume APIs Built for Sending huge volume with Parallel Sending and Fallback option."
            />
            <link rel="canonical" href="https://authkey.io/login" />
            <style type="text/css">{`
                        .deals-and-offers {
                            display:none !important;
                        }
                        @media only screen and (max-width: 600px) {
                            .mobile-img {
                            display: none;
                            }
                        }
                    `}</style>
          </Helmet>
          <section style={{ background: "#fff" }} className="loginbg">
            <div className=" container-fluid d-flex flex-column">
              <div className="row justify-content-center heightVh min-vh-100">
                <LoginSignupMessage />
                <div className="col-lg-6 col-xl-6 py-md-0">
                  <div className="login-new-box heightVh">
                    {this.state.tfa === false ? (
                      <form className="form-row col-sm-10 pt-4 col-md-6 col-lg-10 pl-md-5 pr-sm-0">
                        {this.state.show ? (
                          <div className="form-group col-md-12">
                            <div className={this.state.bgColor}>
                              {this.state.loginMessage}
                            </div>
                          </div>
                        ) : null}
                        <div className="mb-0 col-md-12 text-left login-box">
                          <Link to="/">
                            <img
                              className="img-fluid mb-3"
                              src={logo}
                              style={{ width: "50%" }}
                              alt=""
                            />
                          </Link>
                          <h3 className="">Login </h3>
                        </div>
                        <div className="form-group col-md-12">
                          <label className="form-control-label">
                            Email address
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              required
                              name="username"
                              onChange={(event) =>
                                this.setState({ username: event.target.value })
                              }
                              className="form-control logininput"
                              placeholder="name@example.com"
                            />
                          </div>
                        </div>
                        <div className="form-group col-md-12 mb-0">
                          <label className="form-control-label">
                            Enter Password
                          </label>
                          <div className="input-group ">
                            <input
                              type="password"
                              onKeyPress={this.keyPressed}
                              autoComplete="no"
                              required
                              name="password"
                              onChange={(event) =>
                                this.setState({ password: event.target.value })
                              }
                              className="form-control logininput"
                              placeholder="Password"
                            />
                          </div>
                          <div className="row">
                            <div className="mt-4 col-md-12 text-left">
                              <div className="d-flex justify-content-between">
                                <p className="small">
                                  <Link to="/signup">
                                    Don't have an Account ?
                                  </Link>
                                </p>
                                <p className="Forgot">
                                  <Link to="forgot-password">
                                    Forgot Password ?
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-2 d-flex w-100 justify-content-between">
                          <button
                            type="button"
                            onClick={() => {
                              this.login();
                            }}
                            className="home-button mx-0 btn-signup"
                            style={{ width: "48%" }}
                          >
                            Log in
                          </button>

                          <Link to="/signup" style={{ width: "48%" }}>
                            <button
                              type="button"
                              style={{ background: "#fff" }}
                              className=" home-button mx-0 w-100"
                            >
                              Create Account
                            </button>
                          </Link>
                        </div>

                        <div className="form-group mt-5 position-relative col-md-12 mb-4">
                          <hr className="mt-0 mb-0" />
                          <p className="m-auto login-with right-50 d-block  position-absolute ">
                            Sign in with
                          </p>
                        </div>
                        <div className="m-auto">
                          <FacebookLogin
                            className="facebook"
                            appId="4884679621657801"
                            textButton="Login With Facebook"
                            autoLoad={false}
                            fields="name,email"
                            callback={this.responseFacebook}
                          />
                        </div>

                        <div
                          style={{
                            textAlign: "center",
                            width: "100%",
                            marginTop: "20px",
                          }}
                        >
                          <small style={{ fontSize: "11px" }}>
                            {`© 2020-${new Date().getFullYear()} AuthKey.io Datagen Internet Services Pvt. Ltd. | All rights reserved`}
                          </small>
                        </div>
                      </form>
                    ) : (
                      <>
                        
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>{" "}
        </div>
      </div>
    );
  }
}
export default Login;
