import React, { useState } from "react";
import * as XLSX from "xlsx";

const UploadExcelFile = (props) => {
    const [excelFile, setExcelFile] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const {setUploadProgress} =props;
    const CHUNK_SIZE = 1024 * 1024; // 1 MB chunk size

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const fileSizeLimit = 50 * 1024 * 1024; // 50 MB in bytes

        if (file) {
            const fileExtension = file.name.split(".").pop().toLowerCase();

            if (fileExtension !== "xls" && fileExtension !== "xlsx") {
                setErrorMessage("Unsupported file format. Please upload an Excel file (.xls or .xlsx).");
                setExcelFile(false);
                return;
            }
            if (file.size > fileSizeLimit) {
                setErrorMessage("File size exceeds 50 MB limit. Please upload a smaller file.");
                setExcelFile(false);
                return;
            }


            setErrorMessage(""); // Clear previous errors
            props.setLoading(true);

            readFileInChunks(file);
        }
    };

    const readFileInChunks = (file) => {
        let offset = 0;
        const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
        const reader = new FileReader();
        const chunks = [];

        reader.onload = (e) => {
            chunks.push(e.target.result); // Store the current chunk
            offset += CHUNK_SIZE;

            // Update progress
            const progress = Math.min(Math.round((offset / file.size) * 100), 100);
            setUploadProgress(progress);

            if (offset < file.size) {
                readNextChunk(); // Read the next chunk
            } else {
                // All chunks read
                const binaryStr = chunks.join(""); // Combine chunks
                const workbook = XLSX.read(binaryStr, { type: "binary" });
                const sheetName = workbook.SheetNames[0]; // Read the first sheet
                const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]); // Convert to JSON

                sendData(file, sheetData);
                setExcelFile(true); // Show success message
                props.setLoading(false);
                setUploadProgress(0); // Reset progress
            }
        };

        reader.onerror = () => {
            setErrorMessage("An error occurred while reading the file.");
            props.setLoading(false);
            setUploadProgress(0); // Reset progress
        };

        const readNextChunk = () => {
            const blob = file.slice(offset, offset + CHUNK_SIZE);
            reader.readAsBinaryString(blob); // Read the next chunk
        };

        readNextChunk(); // Start reading the first chunk
    };

    const sendData = (file, data) => {
        props.sendCsvData(file, data); // Send parsed data to the parent component
    };

    return (
        <>
            <div className="input-group mb-2 mt-4 mr-sm-2">
                <div className="input-group-prepend">
                    <div className="input-group-text">Upload Excel File</div>
                </div>
                <input
                    type="file"
                    name="file"
                    accept=".xls, .xlsx"
                    onChange={handleFileChange}
                    className="form-control"
                />
            </div>

            {errorMessage && (
                <div className="alert alert-danger">{errorMessage}</div>
            )}

            {excelFile && !errorMessage && (
                <div className="alert alert-success">File Uploaded Successfully</div>
            )}
        </>
    );
};

export default UploadExcelFile;
