import React, { useEffect, useState } from 'react';
import AdminHeader from '../Header/AdminHeader';
import AdminFooter from '../Footer/AdminFooter';
import AdminLeftMenu from '../Navbar/AdminLeftMenu';
import { Link, useParams } from 'react-router-dom';
import { userloginDetails } from '../Utils/AdminAuth';
import { HOST_URL } from '../Dashboard/api/Api';
import { server } from '../../server';

const AgentSetting = () => {
    const lStorage = userloginDetails();
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const type = lStorage.user.isAdmin;
    const { id, userType } = useParams();

    const [email, setEmail] = useState('');
    const [error, setError] = useState({
        errorStatus: false,
        bgColor: '',
        errorMessage: ''
    });
    const [agentPrice, setAgentPrice] = useState('')
    const [selectAgent, setSelectAgent] = useState('')
    const [balance,setBalance] = useState(false)
    const [agentDetails,setAgentDetails] = useState([])

    useEffect(() => {
        getUserDetails();
        getDataList();
    }, []);

    const getUserDetails = async () => {
        const response = await fetch(`${HOST_URL}/admin_user_list.php?user_id=${userId}&method=retrieve&type=userdetails&ret_user_id=${id}&token=${userToken}`);
        const result = await response.json();
        if (result.success) {
            setEmail(result.data[0].email);
        } else {
            setError({
                errorStatus: true,
                bgColor: 'alert alert-danger alert-dismissible',
                errorMessage: result.message
            });
        }
    };

    const getDataList = async() =>{
        let response = await fetch (`${server}/api/admin_user_list.php?user_id=${userId}&method=retrieve&type=userdetails&ret_user_id=${id}&token=${userToken}`);
        let result = await response.json();
        if(result.success){
            console.log(result)
            const data = result.data[0];
            setSelectAgent(data.allow_agent || '')
            setAgentPrice(data.peragent_cost || '')
            setBalance(data.balance_deduct_wallet === 1)
        }
    }

    const Submit = async () =>{
        let data = {
            user_id: userId,
            method: "update_agent_setting",
            type: type,
            ret_user_id: id,
            token: userToken,
            allow_agent: selectAgent,
            per_agent_price: agentPrice,
            balance_deduct_wallet: balance ? 1 : 0
        }
        let response = await fetch(`${server}/api/admin_user_list.php`,{
            method: "post",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(data),
        })
        let result = await response.json();
        if(result.success){
            console.log(result)
            setTimeout(()=>{
                window.location.reload();
            },200)
        }
    }

    return (
        <div>
            <div className="wrapper">
                <AdminLeftMenu />
                <div className="main">
                    <AdminHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3>User Details <strong className='text-info'>{email}</strong></h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">
                                            <nav className="text-center" aria-label="breadcrumb">
                                                <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
                                                    <Link to={`/admin/user-details/${id}/${userType}`}><li className="breadcrumb-item">User Details</li></Link>
                                                    <Link to={`/admin/user-transaction-details/${id}/${userType}`}><li className="breadcrumb-item">Transaction Details</li></Link>
                                                    <Link to={`/admin/user-price-details/${id}/${userType}`}><li className="breadcrumb-item">Add Price</li></Link>
                                                    <Link to={`/admin/user-add-price/${id}/${userType}`}><li className="breadcrumb-item">Add Credit</li></Link>
                                                    <Link to={`/admin/user-entity-pass/${id}/${userType}`}><li className="breadcrumb-item">Entity By Pass</li></Link>
                                                    <Link to={`/admin/change-currency/${id}/${userType}`}><li className="breadcrumb-item">Change Currency</li></Link>
                                                    <Link to={`/admin/bulk-sms-route/${id}/${userType}`}><li className="breadcrumb-item">Bulk Sms Route Setting</li></Link>
                                                    <Link to={`/admin/agent-setting/${id}/${userType}`}><li className="breadcrumb-item active">Agent Setting</li></Link>
                                                </ol>
                                            </nav>
                                        </h5>
                                    </div>
                                    <div className="card-body">
                                        {error.errorStatus && (
                                            <div className={error.bgColor} role="alert">
                                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                                <div>{error.errorMessage}</div>
                                            </div>
                                        )}
                                        <div className="filter-option">
                                            <div className="row mt-3">
                                                <div className='col-12'>
                                                    <div className='row'>
                                                        <div className='col-6'>
                                                            <label>Per Agent Price</label>
                                                            <input type='text' name='agentPrice' className='form-control' placeholder='Enter Price' value={agentPrice} onChange={(e) => setAgentPrice(e.target.value)} />
                                                        </div>
                                                        <div className='col-6'>
                                                            <label>Allow Agent</label>
                                                            <select
                                                                value={selectAgent}
                                                                onChange={(e) => setSelectAgent(e.target.value)}
                                                                className="form-control"
                                                            >
                                                                <option value={""}>Select</option>
                                                                {Array.from({ length: 50 }, (_, i) => (
                                                                    <option key={i + 1} value={i + 1}>
                                                                        {i + 1}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='row mt-3'>
                                                        <div className='col-6'>
                                                        <input
                                                            type="checkbox"
                                                            checked={balance}
                                                            onChange={()=>setBalance(!balance)} 
                                                        />
                                                        <span style={{ fontSize: "13px" }} className="ml-2">Balance Deducted from Wallet</span>
                                                    </div>                                          
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <button type="button" style={{ float: "right" }} onClick={Submit} className="btn btn-primary mt-3">Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <AdminFooter />
                </div>
            </div>
        </div>
    );
};

export default AgentSetting;