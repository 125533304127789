import React, { useEffect, useState } from "react";
import DashboardLayout from "../../UI/Layout/DashboardLayout";
import Title from "../../UI/Text/Title";
import { useParams } from "react-router-dom";
import Axios from "../../../axios/Axios";
import { NODE_HOST_URL } from "../api/Api";
import Spining from "../../UI/Spining/Spining";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MdCancel } from "react-icons/md";
import {
  faCalendarAlt,
  faFilter,
  faTimesCircle,
  faSearch,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Collapsible from "react-collapsible";

const ContactListDtl = () => {
  const { id, list_type, mob, ref } = useParams();

  const lStorage = JSON.parse(localStorage.getItem("login"));
  const user_id = lStorage.user.userId;
  const token = lStorage.token;
  const [bllingData, setBillingData] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fromdate, setFromdate] = useState(new Date());
  const [todate, setTodate] = useState(new Date());
  const [error, serError] = useState({
    status: false,
    bgColor: "",
    message: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [posbill, setPosbill] = useState([]);
  const [posbillitem, setPosbillitem] = useState([]);
  const [poscustomer, setPoscustomer] = useState([]);

  const [activeTab, setActiveTab] = useState(1);
  const [customerDtl, setCustomerDtl] = useState({});

  const getButtonClass = (tabId) => {
    return activeTab === tabId ? "btn btn-dark" : "btn btn-secondary";
  };

  function formatDateInIST(date) {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const d = new Date(date);
    const formattedDate = d.toLocaleDateString("en-IN", {
      ...options,
      timeZone: "Asia/Kolkata",
    });
    const [day, month, year] = formattedDate.split('/');
    return `${year}-${month}-${day}`;
}

  useEffect(() => {
    getContactDetails(true);
  }, [mob, ref]);
  const getContactDetails = async (flag) => {
    setLoading(true);
    const paramData = {
      token,
      user_id,
      method: "list_data_details",
      mobile: mob,
      ref_id: ref,

      from_date: formatDateInIST(fromdate),
      to_date: formatDateInIST(todate),
      posbill,
      posbillitem,
      poscustomer,
    };

    try {
      const { data } = await Axios.post(
        `${NODE_HOST_URL}/contact_list`,
        paramData
      );

      if (data.success) {
        setBillingData(data.data);
        const items = data.data;
        items.sort((a, b) => new Date(b.created) - new Date(a.created));
        setCustomerDtl(items[0]);
      } else {
        serError({
          status: true,
          bgColor: "alert alert-danger alert-dismissible",
          message: data.message,
        });
        console.log("Error: ", data.message || "No success message");
      }
    } catch (error) {
      console.log("Error:", error);
      serError({
        status: true,
        bgColor: "alert alert-danger alert-dismissible",
        message: error.message,
      });
    }
    setLoading(false);
  };

  const fromdateHandler = (data) => {
    setFromdate(data);
  };

  const todateHandler = (data) => {
    setTodate(data);
  };

  const handleCheckboxChange = (e, type) => {
    const dataname = e.target.name;
    if (type === "posbill") {
      if (e.target.checked === true) {
        setPosbill((preState) => [...preState, dataname]);
      } else {
        setPosbill((preState) => preState.filter((data) => data !== dataname));
      }
    }
    if (type === "posbillitem") {
      if (e.target.checked === true) {
        setPosbillitem((preState) => [...preState, dataname]);
      } else {
        setPosbillitem((preState) =>
          preState.filter((data) => data !== dataname)
        );
      }
    }
    if (type === "poscustomer") {
      if (e.target.checked === true) {
        setPoscustomer((preState) => [...preState, dataname]);
      } else {
        setPoscustomer((preState) =>
          preState.filter((data) => data !== dataname)
        );
      }
    }
  };
  const handleSearch = () => {
    getContactDetails();
    setShowModal(false);
  };
  return (
    <>
      <DashboardLayout>
        {loading && <Spining />}

        <main className="content">
          <div className="container-fluid p-0">
            <Title
              isButton={true}
              btnName={`Back`}
              actionURL={`/dashboard/view-contact-list/${id}/${list_type}`}
            >
              Billing details of mobile :{mob}
            </Title>
            {error.status && (
              <div className={error.bgColor} role="alert">
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <div className="">{error.message}</div>
              </div>
            )}
            <div className="card flex-fill">
              <div className="row p-1 align-items-center">
                <div className=" col-md-4 col-sm-12">
                  <div className="input-group my-2 mr-sm-2 table4">
                    <div className="input-group-text">
                      <FontAwesomeIcon icon={faCalendarAlt} />
                    </div>
                    <div>
                      <ReactDatePicker
                        className="form-control btn-block"
                        dateFormat="yyyy-MM-dd"
                        value={fromdate}
                        onChange={fromdateHandler}
                        selected={fromdate}
                        placeholderText="From Date"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="input-group my-2 mr-sm-2 table5">
                    <div className="input-group-text">
                      <FontAwesomeIcon icon={faCalendarAlt} />
                    </div>
                    <div>
                      <ReactDatePicker
                        className="form-control btn-block"
                        dateFormat="yyyy-MM-dd"
                        value={todate}
                        onChange={todateHandler}
                        selected={todate}
                        placeholderText="Date To"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-2 col-12 d-flex justify-content-end">
                  <FontAwesomeIcon
                    onClick={() => setShowModal(true)}
                    style={{ cursor: "pointer" }}
                    icon={faFilter}
                  />
                </div>
                <div className="col-md-2 col-12 d-flex justify-content-end">
                  <button
                    className="btn btn-success"
                    onClick={() => handleSearch()}
                    style={{ height: "50px" }}
                  >
                    {" "}
                    Search
                  </button>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="d-flex flex-wrap justify-content-center align-items-center">
                <button
                  className={`${getButtonClass(1)} text1`}
                  onClick={() => setActiveTab(1)}
                >
                  POS Bill
                </button>
                <button
                  className={`${getButtonClass(2)} text1`}
                  onClick={() => setActiveTab(2)}
                >
                  POS Bill Items
                </button>
                <button
                  className={`${getButtonClass(3)} text1`}
                  onClick={() => setActiveTab(3)}
                >
                  POS Customer
                </button>
              </div>
              <div className="card">
                {activeTab === 1 && (
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped">
                      {bllingData?.map((data, i) => (
                        <>
                          <Collapsible
                            trigger={`Bill id : ${data.billingDetails.POSBILL[0].BillId} | Bill No : ${data.billingDetails.POSBILL[0].BillNo}`}
                          >
                            <tbody key={i}>
                              {Object.entries(
                                data.billingDetails.POSBILL[0]
                              ).map(([key, value]) => (
                                <tr key={key}>
                                  <td>{key}</td>
                                  <td>{value}</td>
                                </tr>
                              ))}
                            </tbody>{" "}
                          </Collapsible>
                        </>
                      ))}
                    </table>
                  </div>
                )}

                {activeTab === 2 && (
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped">
                      {bllingData?.map((data, i) => (
                        <>
                          <Collapsible
                            trigger={`POSBillItemId : ${data.billingDetails.POSBillItems[0].POSBillItemId} | ItemId : ${data.billingDetails.POSBillItems[0].ItemId}`}
                          >
                            <tbody key={i}>
                              {Object.entries(
                                data.billingDetails.POSBillItems[0]
                              ).map(([key, value]) => (
                                <tr key={key}>
                                  <td>{key}</td>
                                  <td>{value}</td>
                                </tr>
                              ))}
                            </tbody>{" "}
                          </Collapsible>
                        </>
                      ))}
                    </table>
                  </div>
                )}

                {activeTab === 3 && (
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped">
                      <>
                        <tbody>
                          {Object.entries(
                            customerDtl.billingDetails.POSCustomer[0]
                          ).map(([key, value]) => (
                            <tr key={key}>
                              <td>{key}</td>
                              <td>{value}</td>
                            </tr>
                          ))}
                        </tbody>{" "}
                      </>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>

        {showModal && (
          <div
            style={{
              zIndex: "1000",
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              backdropFilter: "blur(5px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "90%",
                height: "80%",
                backgroundColor: "white",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="row">
                      {/* Title Section */}
                      <div className="col-6">
                        <div className="card-header d-flex justify-content-between align-items-center">
                          <h5
                            className="card-title mb-0"
                            style={{ fontSize: "15px" }}
                          >
                            {/* <strong>Subject:</strong> {"title"} */}
                            <strong>
                              Filter billing details of mobile :{mob}
                            </strong>
                          </h5>
                        </div>
                      </div>
                      <div className="col-6 d-flex justify-content-end align-items-center">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => handleSearch()}
                          className="nav-icon"
                        >
                          <div className="position-relative">
                            <FontAwesomeIcon
                              color="red"
                              icon={faTimesCircle}
                              className="text-dark"
                            />
                          </div>
                        </span>
                      </div>
                      {/* Search Section */}
                    </div>

                    <hr className="m-0" />

                    {/* Message Section */}
                    <div className="card-body">
                      <div className="">
                        <div className="inbox_msg">
                          <div className="message-container table-responsive">
                            <div
                              style={{
                                maxHeight: "22rem",
                                overflowY: "scroll",
                              }}
                            >
                              {/* Message List will go here */}

                              <div>
                                <h4>POS Bill</h4>

                                {bllingData[0].billingDetails.POSBILL.sort(
                                  (a, b) =>
                                    new Date(b.createdAt) -
                                    new Date(a.createdAt)
                                ).map((customer, idx) => {
                                  const keys = Object.keys(customer);

                                  return (
                                    <div
                                      key={idx}
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      {keys.map((key, keyIdx) => (
                                        <div
                                          key={keyIdx}
                                          style={{
                                            marginRight: "10px",
                                            flexGrow: 1,
                                          }}
                                        >
                                          <label className="cursor-pointer">
                                            <input
                                              type="checkbox"
                                              style={{ marginRight: "2px" }}
                                              value={customer[key]}
                                              name={key}
                                              checked={
                                                posbill.includes(key)
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) =>
                                                handleCheckboxChange(
                                                  e,
                                                  "posbill"
                                                )
                                              }
                                              className="cursor-pointer"
                                            />
                                            {key}
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  );
                                })}
                              </div>

                              <div>
                                <h4>POS Bill Items</h4>
                                {bllingData[0].billingDetails.POSBillItems.map(
                                  (customer, idx) => {
                                    const keys = Object.keys(customer);

                                    return (
                                      <div
                                        key={idx}
                                        style={{
                                          display: "flex",
                                          flexWrap: "wrap",
                                        }}
                                      >
                                        {keys.map((key, keyIdx) => (
                                          <div
                                            key={keyIdx}
                                            style={{
                                              marginRight: "10px",
                                              flexGrow: 1,
                                            }}
                                          >
                                            <label className="cursor-pointer">
                                              <input
                                                type="checkbox"
                                                style={{ marginRight: "2px" }}
                                                value={customer[key]}
                                                className="cursor-pointer"
                                                name={key}
                                                checked={
                                                  posbillitem.includes(key)
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) =>
                                                  handleCheckboxChange(
                                                    e,
                                                    "posbillitem"
                                                  )
                                                }
                                              />
                                              {key}
                                            </label>
                                          </div>
                                        ))}
                                      </div>
                                    );
                                  }
                                )}
                              </div>

                              <div>
                                <h4>POS Customer</h4>
                                {bllingData[0].billingDetails.POSCustomer.map(
                                  (customer, idx) => {
                                    const keys = Object.keys(customer);

                                    return (
                                      <div
                                        key={idx}
                                        style={{
                                          display: "flex",
                                          flexWrap: "wrap",
                                        }}
                                      >
                                        {keys.map((key, keyIdx) => (
                                          <div
                                            key={keyIdx}
                                            style={{
                                              marginRight: "10px",
                                              flexGrow: 1,
                                            }}
                                          >
                                            <label className="cursor-pointer">
                                              <input
                                                type="checkbox"
                                                style={{ marginRight: "2px" }}
                                                className="cursor-pointer"
                                                value={customer[key]}
                                                name={key}
                                                checked={
                                                  poscustomer.includes(key)
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) =>
                                                  handleCheckboxChange(
                                                    e,
                                                    "poscustomer"
                                                  )
                                                }
                                              />
                                              {key}
                                            </label>
                                          </div>
                                        ))}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* File Upload and Button Section */}
                      <div className="filter-option">
                        <div className="row">
                          <div
                            className="col-12 d-flex justify-content-end w-100"
                            style={{ marginTop: "10px" }}
                          >
                            <button
                              type="button"
                              onClick={() => handleSearch()}
                              className="btn btn-success ml-auto"
                            >
                              Search
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </DashboardLayout>
    </>
  );
};

export default ContactListDtl;
