import React, { useEffect, useState } from 'react';
import AdminLeftMenu from '../Navbar/AdminLeftMenu';
import AdminHeader from '../Header/AdminHeader';
import AdminFooter from '../Footer/AdminFooter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faSearch } from '@fortawesome/free-solid-svg-icons';
import { HOST_URL } from '../Dashboard/api/Api';
import Select from "react-select";

const ResellerSetting = () => {
  const lStorage = JSON.parse(localStorage.getItem("admin"));
  const userId = lStorage.user.userId;
  const userToken = lStorage.token;
  const type = lStorage.user.isAdmin;

  const [resellerData, setResellerData] = useState({ label: "Select Reseller", value: "0" });
  const [parentData, setParentData] = useState({ label: "Select Parent", value: "" });
  const [MainData, setMainData] = useState([]);
  const [searchMessage, setSearchMessage] = useState('');
  const [resellerListData, setResellerListData] = useState([]);
  const [customId, setCustomId] = useState('');
  const [email, setEmail] = useState('');
  const [error, seterror] = useState({
    errorStatus: false,
    errorMessage: "",
    bgcolor: "",
  });
  const [openResellerData, setOpenResellerData] = useState(false)

  useEffect(() => {
    SelectData();
    ResellerList();
  }, []);

  console.log(resellerListData)

  const SelectData = async () => {
    try {
      let response = await fetch(`${HOST_URL}/employee_user_list.php?user_id=${userId}&method=retrieve&type=admin&token=${userToken}`);
      let result = await response.json();
      if (result.success) {
        setMainData(result.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  console.log(MainData)

  const onResellerChange = (selectedOption) => {
    setResellerData(selectedOption);
  }

  const onParentChange = (selectedOption) => {
    setParentData(selectedOption);
  }

  const ResellerData = async () => {
    let data = {
      user_id: userId,
      token: userToken,
      reseller_parent_id: parentData.value,
      reseller_id: resellerData.value,
      type: type,
      method: "update_reseller_parent"
    };
    try {
      let response = await fetch(`${HOST_URL}/employee_user_list.php`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      let result = await response.json();
      if (result.success) {
        seterror({
          errorStatus: true,
          errorMessage: result.message,
          bgcolor: "alert alert-success",
        });
        setTimeout(() => {
          seterror({ errorStatus: false });
          setParentData('');
          setResellerData('');
          ResellerList();
        }, 2000);
      } else {
        seterror({
          errorStatus: true,
          errorMessage: result.message,
          bgcolor: "alert alert-danger",
        });
      }
    } catch (error) {
      seterror({
        errorStatus: true,
        errorMessage: error.toString(),
        bgcolor: "alert alert-danger",
      });
    }
  };

  const ResellerList = async () => {
    let data = {
      user_id: userId,
      token: userToken,
      type: type,
      method: "reseller_list_parent"
    };
    try {
      let response = await fetch(`${HOST_URL}/employee_user_list.php`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      let result = await response.json();
      if (result.success) {
        setResellerListData(result.data);
      } else {
        seterror({
          errorStatus: true,
          errorMessage: result.message,
          bgcolor: "alert alert-danger",
        });
      }
    } catch (error) {
      seterror({
        errorStatus: true,
        errorMessage: error.toString(),
        bgcolor: "alert alert-danger",
      });
    }
  };

  console.log(resellerListData)

  const toggleUpdate = (reseller_id, parent_id, email, parent_email) => {
    setResellerData({ label: email, value: reseller_id });
    setParentData({ label: parent_email, value: parent_id });
    setCustomId(reseller_id);
  };

  console.log(resellerData, parentData)

  const filteredResellerOptions = MainData.filter(x => x.user_type === "reseller");

  return (
    <div>
      <div className="wrapper">
        <AdminLeftMenu />
        <div className="main">
          <AdminHeader />
          <main className="content">
            <div className="container-fluid p-0">
              <div className="row mb-2 mb-xl-3">
                {/* Optional header content */}
              </div>

              <div className="row">
                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                  <div className="card flex-fill">
                    <div className="card-body">
                      <div className="filter-option">
                        <div className='row'>
                          <div className='col-4'>
                            <label>Reseller</label>
                            <Select
                              onChange={onResellerChange}
                              options={
                                customId === resellerData.value
                                  ? [{ label: resellerData.label, value: resellerData.value }]
                                  : filteredResellerOptions
                                    .map(option => {
                                      const isOptionInList = resellerListData.some(x => x.reseller_id === option.id);
                                      return !isOptionInList ? { label: option.username, value: option.id } : null;
                                    })
                                    .filter(option => option !== null) 
                              }
                              value={resellerData}
                              isSearchable={true}
                              placeholder="Select Reseller"
                            />
                          </div>
                          <div className='col-4'>
                            <label>Parent</label>
                            <Select
                              onChange={onParentChange}
                              options={MainData.map((x) => ({ label: x.username, value: x.id }))}
                              value={parentData}
                              isSearchable={true}
                              placeholder="Select Parent"
                            />
                          </div>
                        </div>
                        <div className='row mt-2'>
                          <div className='col-6'>
                            {error.errorStatus ? (
                              <div className={error.bgcolor} role="alert">
                                <strong>Alert!</strong> {error.errorMessage}
                              </div>
                            ) : null}
                          </div>
                          <div className='col-6'>
                            <button type="button" onClick={() => ResellerData()} className="btn btn-info float-right">Update</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                  <div className="card flex-fill">
                    <div className="card-header d-flex align-items-center border-bottom">
                      <FontAwesomeIcon icon={faPaperPlane} className="mr-2" style={{ fontSize: "1.5rem" }} />
                      <h3 className="card-title mb-0 font-weight-bold">List of Reseller and its parent</h3>
                    </div>

                    <div className="card-body">
                      <div className="filter-option">
                        <div className="row">
                          <div className='col-6'></div>
                          <div className='col-6'>
                            <div className="input-group">
                              <div className="input-group-prepend float-right">
                                <span className="input-group-text"><FontAwesomeIcon icon={faSearch} /></span>
                              </div>
                              <input
                                type="text"
                                id="searchInput"
                                name="searchdata"
                                value={searchMessage}
                                onChange={(e) => setSearchMessage(e.target.value)}
                                className="form-control"
                                placeholder="Search"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="container mt-4 resellersetting">
                          <table className="table table-bordered resellertable ">
                            <thead className="sticky-top">
                              <tr>
                                <th>SNo.</th>
                                <th>Reseller</th>
                                <th>Parent</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {resellerListData.reverse().filter((x) => {
                                return searchMessage.toLowerCase() === '' ? x : x.email.toLowerCase().includes(searchMessage) ||
                                  x.fullname.toLowerCase().includes(searchMessage) ||
                                  x.parent_email.toLowerCase().includes(searchMessage) ||
                                  x.parent_name.toLowerCase().includes(searchMessage);
                              }).map((item, index) => (
                                <tr key={item.reseller_id}>
                                  <td>{index + 1}</td>
                                  <td>{`${item.email} (${item.fullname})`}</td>
                                  <td>{`${item.parent_email} (${item.parent_name})`}</td>
                                  <td>
                                    <span onClick={() => toggleUpdate(item.reseller_id, item.parent_id, item.email, item.parent_email)} className="badge bg-success">
                                      Edit
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </main>
          <AdminFooter />
        </div>
      </div>
    </div>
  );
};

export default ResellerSetting;
