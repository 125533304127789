import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignJustify,
  faUser,
  faUsers,
  faChartBar,
  faCreditCard,
  faBroadcastTower,
  faAtom,
  faChartLine,
  faImage,
  faBan,
  faTicketAlt,
  faPhotoVideo,
  faInbox,
  faBlog,
  faStarOfLife,
  faCog,
  faSms,
  faMoneyBillAlt,
  faEnvelope,
  faSignInAlt,
  faTimes,
  faSlidersH
} from "@fortawesome/free-solid-svg-icons";
import logoUrl from "../Dashboard/assets/img/logo.png";
import AdminLogo from "../Dashboard/assets/img/admin.png";
import { checkUserUrl } from "../Utils/Utils";
import whstappLogo from "../Dashboard/assets/img/whatsapp.png";
import { userloginDetails } from "../Utils/AdminAuth";
import RcsBlackImage from "../Dashboard/assets/img/rcsBlackImage-removebg-preview.png";

const DashboardLeftMenu = () => {
  const [menuId, setMenuId] = useState("sidebar");
  const [pName, setPName] = useState("");
  const [empMenu, setempMenu] = useState(true);
  const [settings, setSettings] = useState(null);

  const lStorage = userloginDetails();
  const isAdmin = lStorage.user.isAdmin;
  const url = document.location.href;

  useEffect(() => {
    const storedSettings = localStorage.getItem('siteSettings');

    if (storedSettings) {
      const parsedSettings = JSON.parse(storedSettings);
      setSettings(parsedSettings);
    }
  }, []);



  const checkActive = (match, location) => {
    if (isAdmin === "emp" || isAdmin === "reseller") {
      setempMenu(false);
    } else {
      setempMenu(true);
    }

    //some additional logic to verify you are in the home URI
    if (!location) return false;
    const { pathname } = location;
    // console.log(pathname);
    setPName(pathname);
  };

  const menuHideShow = () => {
    if (menuId === "sidebar") {
      setMenuId("sidebar collapsed");
    } else {
      setMenuId("sidebar");
    }
  };

  return (
    <nav id="sidebar" className={menuId}>

      <div
        className="sidebar-content js-simplebar"
        style={{
          background: "#f8f8f8",
          // Full height of the viewport
          position: "sticky", // Fix sidebar in place
          top: 0, // Stick the sidebar to the top
          left: 0,
          height: "100vh", // Full height of the viewport
          margin: "0px",

          // Stick the sidebar to the left
          // Prevent the sidebar itself from scrolling
          zIndex: 1000,
          // Ensure it's on top of other elements
        }}

      >
        <div
          style={{
            position: "sticky", // Keep the logo and dashboard fixed at the top of the sidebar
            top: 0,
            zIndex: 1000,

            background: "#f8f8f8", // Same background as the sidebar
            // Optional: Add space below the fixed section
          }}
        >
          <NavLink className="sidebar-brand border-right" to="/admin">
            {checkUserUrl(url) === true ? (
              <img className="Logo" alt="logo" src={logoUrl} />
            ) : settings?.dashboard_logo ? (
              <img className="Logo1" alt="logo" src={settings?.dashboard_logo} />
            ) : (
              <img className="Logo" alt="logo" src={AdminLogo} />
            )}
          </NavLink>


          <span onClick={menuHideShow} className="sidebar-toggle d-flex">
            <i className="hamburger align-self-center"></i>
          </span>

          <ul className="sidebar-nav mt-4">
            <li
              className={
                pName === "/admin" ? "sidebar-item active" : "sidebar-item"
              }
            >
              <NavLink
                className="sidebar-link"
                isActive={checkActive}
                to="/admin"
              >
                <FontAwesomeIcon icon={faAlignJustify} />{" "}
                <span className="align-middle">
                  {" "}
                  {isAdmin === "emp" ? "Employee" : ""}{" "}
                  {isAdmin === "admin" ? "admin" : ""}
                  {isAdmin === "reseller" ? "Reseller" : ""} Dashboard
                </span>
              </NavLink>
            </li>
          </ul>
        </div>

        <div
          className="sidebar1"
          style={{
            position: "sticky",
            overflowY: "auto", // Make this section scrollable
            flexGrow: 1, // Allow the user management section to take the remaining height
            height: "100vh",
            // Ensure the scrollable content doesn't exceed the sidebar height (adjust padding and logo height)
          }}
        >
          <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
            <li
              className={
                pName === "/admin/user-management" ||
                  pName === "/admin/add-new-client" ||
                  pName === "/admin/assign-plan" ||
                  pName === "/admin/all-user-management"
                  ? "sidebar-item active"
                  : "sidebar-item"
              }
            >
              <a
                href="#userManagement"
                data-target="#userManagement"
                data-toggle="collapse"
                className="sidebar-link collapsed"
              >
                <FontAwesomeIcon icon={faUser} />{" "}
                <span className="align-middle">User Management </span>
              </a>
              <ul
                id="userManagement"
                className={
                  pName === "/admin/user-management" ||
                    pName === "/admin/add-new-client" ||
                    pName === "/admin/assign-plan" ||
                    pName === "/admin/all-user-management"
                    ? "sidebar-dropdown list-unstyled collapse show"
                    : "sidebar-dropdown list-unstyled collapse"
                }
                data-parent="#sidebar"
              >
                <li
                  className={
                    pName === "/admin/user-management"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <Link className="sidebar-link" to="/admin/user-management">
                    {" "}
                    Users List{" "}
                  </Link>
                </li>
                {isAdmin === "admin" && (
                <li
                  className={
                    pName === "/admin/add-new-client"
                      ? "sidebar-item active"
                      : "sidebar-item "
                  }
                >
                  <Link className="sidebar-link" to="/admin/add-new-client">
                    {" "}
                    <span className="align-middle">Add New Client</span>
                  </Link>
                </li>
  )}

                {isAdmin === "admin" && (
                  <li
                    className={
                      pName === "/admin/assign-plan"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link className="sidebar-link" to="/admin/assign-plan">
                      {" "}
                      <span className="align-middle"> Assign Plan </span>
                    </Link>
                  </li>
                )}

                <li
                  className={
                    pName === "/admin/employee-management"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <NavLink
                    className="sidebar-link"
                    isActive={checkActive}
                    to="/admin/employee-management"
                  >{" "}
                    {/* <FontAwesomeIcon icon={faUsers} />{" "} */}
                    <span className="align-middle">{isAdmin === "reseller" ? "Reseller" : "Emp/Reseller"}</span>
                  </NavLink>
                </li>


                {/* <li
                className={
                  pName === "/admin/all-user-management"
                    ? "sidebar-item active"
                    : "sidebar-item"
                }
              >
                <Link className="sidebar-link" to="/admin/all-user-management">
                  All Users list
                </Link>
              </li> */}
              </ul>
            </li>


            {isAdmin === "admin" ? (
              <>
                <li
                  className={
                    pName === "/admin/analytics"
                      ? "sidebar-item active"
                      : "sidebar-item "
                  }
                >
                  <NavLink
                    className="sidebar-link"
                    isActive={checkActive}
                    to="/admin/analytics"
                  >
                    <FontAwesomeIcon icon={faChartBar} />{" "}
                    <span className="align-middle">User Analytics</span>
                  </NavLink>
                </li>
              </>
            ) : null}

            {isAdmin === "admin" ? (
              <>
                <li
                  className={
                    pName === "/admin/partner-dashboard"
                      ? "sidebar-item active"
                      : "sidebar-item "
                  }
                >
                  <NavLink
                    className="sidebar-link"
                    isActive={checkActive}
                    to="/admin/partner-dashboard"
                  >
                    <FontAwesomeIcon icon={faChartLine} />{" "}
                    <span className="align-middle">Partner Dashboard</span>
                  </NavLink>
                </li>
              </>
            ) : null}

            {/* {isAdmin === "admin" ? (
              <>
                <li
                  className={
                    pName === "/dashboard/whatsapp-optin-otpout"
                      ? "sidebar-item active"
                      : "sidebar-item "
                  }
                >
                  <NavLink
                    className="sidebar-link"
                    isActive={checkActive}
                    to="/dashboard/whatsapp-gallery"
                  >
                   <FontAwesomeIcon icon={faImage} />
                   {" "}
                    <span className="align-middle">Media Gallery</span>
                  </NavLink>
                </li>
              </>
            ) : null} */}


            {isAdmin === "admin" ? (
              <>
                <li
                  className={
                    pName === "/admin/whatsapp-account-list" ||
                      pName === "/admin/campaign-report" ||
                      pName === "/admin/whatsapp-summary" ||
                      pName === "/admin/whatsapp-api" ||
                      pName === "/admin/whatsapp-billing-summary" ||
                      pName === "/admin/queue-whatsapp-status" ||
                      pName === "/admin/review-template" ||
                      pName === "/admin/whatsapp-setup/details/:id" ||
                      pName === "/admin/pinnacle-embeded-signup" ||
                      pName ===
                      "/admin/pinnacle-embeded-signup/register/:id/:wabaid/:pnid" ||
                      pName === "/admin/pinnacle-embeded-token" ||
                      pName === "/admin/queue-whatsapp-status"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <a
                    href="#whatsappManagement"
                    data-target="#whatsappManagement"
                    data-toggle="collapse"
                    className="sidebar-link collapsed"
                  >
                    <img
                      className="dashboardIcons"
                      alt="icons"
                      src={whstappLogo}
                    />{" "}
                    <span className="align-middle">WhatsApp</span>
                  </a>
                  <ul
                    id="whatsappManagement"
                    className={
                      pName === "/admin/whatsapp-account-list" ||
                        pName === "/admin/campaign-report" ||
                        pName === "/admin/whatsapp-summary" ||
                        pName === "/admin/whatsapp-api" ||
                        pName === "/admin/whatsapp-billing-summary" ||
                        pName === "/admin/queue-whatsapp-status" ||
                        pName === "/admin/queue-whatsapp-status" ||
                        pName === "/admin/review-template"
                        ? "sidebar-dropdown list-unstyled collapse show"
                        : "sidebar-dropdown list-unstyled collapse"
                    }
                    data-parent="#sidebar"
                  >
                     <li
                      className={
                        pName === "/admin/whatsapp-default-embeded-signup"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link
                        className="sidebar-link"
                        to="/admin/whatsapp-default-embeded-signup"
                      >
                        {" "}
                        Default Embeded Signup{" "}
                      </Link>
                    </li>
                    
                    <li
                      className={
                        pName === "/admin/whatsapp-account-list"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link
                        className="sidebar-link"
                        to="/admin/whatsapp-account-list"
                      >
                        {" "}
                        Manage Users{" "}
                      </Link>
                    </li>

                    <li
                      className={
                        pName === "/admin/campaign-report"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link
                        className="sidebar-link"
                        to="/admin/campaign-report"
                      >
                        {" "}
                        Campaign Report{" "}
                      </Link>
                    </li>

                    {/* <li
                    className={
                      pName === "/admin/whatsapp-summary"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link className="sidebar-link" to="/admin/whatsapp-summary">
                      {" "}
                      All Users Summary{" "}
                    </Link>
                  </li> */}
                    <li
                      className={
                        pName === "/admin/whatsapp-api"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link className="sidebar-link" to="/admin/whatsapp-api">
                        {" "}
                        All Users API{" "}
                      </Link>
                    </li>
                    <li
                      className={
                        pName === "/admin/whatsapp-billing-summary"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link
                        className="sidebar-link"
                        to="/admin/whatsapp-billing-summary"
                      >
                        {" "}
                        Billing Summary
                      </Link>
                    </li>
                    <li
                      className={
                        pName === "/admin/review-template"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link className="sidebar-link" to="/admin/review-template">
                        {" "}
                        Review Template
                      </Link>
                    </li>
                    <li
                      className={
                        pName === "/admin/queue-whatsapp-status"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link
                        className="sidebar-link"
                        to="/admin/queue-whatsapp-status"
                      >
                        {" "}
                        Queue Status
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                  className={
                    pName === "/admin/blacklist-new" ||
                      pName === "/admin/sms-campaign-report" ||
                      pName === "/admin/entity-management"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <a
                    href="#sms"
                    data-target="#sms"
                    data-toggle="collapse"
                    className="sidebar-link collapsed"
                  >
                    <FontAwesomeIcon icon={faSms} />{" "}
                    <span className="align-middle"> SMS </span>
                  </a>
                  <ul
                    id="sms"
                    className={
                      pName === "/admin/blacklist-new" ||
                        pName === "/admin/sms-campaign-report" ||
                        pName === "/admin/entity-management"
                        ? "sidebar-dropdown list-unstyled collapse show"
                        : "sidebar-dropdown list-unstyled collapse"
                    }
                    data-parent="#sidebar"
                  >
                    <li
                      className={
                        pName === "/admin/blacklist-new"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link className="sidebar-link" to="/admin/blacklist-new">
                        BlackList
                      </Link>
                    </li>
                    <li
                      className={
                        pName === "/admin/sms-campaign-report"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link
                        className="sidebar-link"
                        to="/admin/sms-campaign-report"
                      >
                        Campaign Report
                      </Link>
                    </li>
                    <li
                      className={
                        pName === "/admin/entity-management"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link
                        className="sidebar-link"
                        to="/admin/entity-management"
                      >
                        {" "}
                        Entity Management{" "}
                      </Link>
                    </li>
                  </ul>
                </li>



                <li
                  className={
                    pName === "/admin/rcs/rcs-setting-add" ||
                      pName === "/admin/rcs/rcs-template-list" ||
                      pName === "/admin/rcs/rcs-setting-list" ||
                      pName === "/admin/rcs/campaign-report"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <a
                    href="#rcs"
                    data-target="#rcs"
                    data-toggle="collapse"
                    className="sidebar-link collapsed"
                  >
                    <img alt="Rcs Image" className="text-primary mr-2 rcs-logo-image" style={{ fontSize: '2em', color: "black" }} src={RcsBlackImage} />{" "}
                    <span className="align-middle"> RCS </span>
                  </a>
                  <ul
                    id="rcs"
                    className={
                      pName === "/admin/rcs/rcs-setting-add" ||
                        pName === "/admin/rcs/rcs-template-list" ||
                        pName === "/admin/rcs/rcs-setting-list" ||
                        pName === "/admin/rcs/campaign-report"
                        ? "sidebar-dropdown list-unstyled collapse show"
                        : "sidebar-dropdown list-unstyled collapse"
                    }
                    data-parent="#sidebar"
                  >
                    <li
                      className={
                        pName === "/admin/rcs/rcs-setting-list" ||
                          pName === "/admin/rcs/rcs-setting-add"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link className="sidebar-link" to="/admin/rcs/rcs-setting-list">
                        Bot List
                      </Link>
                    </li>
                    <li
                      className={

                        pName === "/admin/rcs/rcs-template-list"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link className="sidebar-link" to="/admin/rcs/rcs-template-list">
                        RCS Template
                      </Link>
                    </li>
                    <li
                      className={

                        pName === "/admin/rcs/campaign-report"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link className="sidebar-link" to="/admin/rcs/campaign-report">
                        Campaign Report
                      </Link>
                    </li>


                  </ul>
                </li>

                <li
                  className={
                    pName === "/admin/embeded-signup" ||
                      pName === "/admin/whatsapp-setup" ||
                      pName === "/admin/whatsapp-setup/details/:id" ||
                      pName === "/admin/pinnacle-embeded-signup" ||
                      pName ===
                      "/admin/pinnacle-embeded-signup/register/:id/:wabaid/:pnid" ||
                      pName === "/admin/pinnacle-embeded-token" ||
                      pName === "/admin/karix-embeded-signup"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <a
                    href="#embededsignup"
                    data-target="#embededsignup"
                    data-toggle="collapse"
                    className="sidebar-link collapsed"
                  >
                    <FontAwesomeIcon icon={faSignInAlt} />{" "}
                    <span className="align-middle"> Embeded Signup </span>
                  </a>
                  <ul
                    id="embededsignup"
                    className={
                      pName === "/admin/embeded-signup" ||
                        pName === "/admin/whatsapp-setup" ||
                        pName === "/admin/whatsapp-setup/details/:id" ||
                        pName === "/admin/pinnacle-embeded-signup" ||
                        pName ===
                        "/admin/pinnacle-embeded-signup/register/:id/:wabaid/:pnid" ||
                        pName === "/admin/pinnacle-embeded-token" ||
                        pName === "/admin/karix-embeded-signup"
                        ? "sidebar-dropdown list-unstyled collapse show"
                        : "sidebar-dropdown list-unstyled collapse"
                    }
                    data-parent="#sidebar"
                  >
                    <li
                      className={
                        pName === "/admin/embeded-signup"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link className="sidebar-link" to="/admin/embeded-signup">
                        {" "}
                        Embeded Signup
                      </Link>
                    </li>
                    <li
                      className={
                        pName === "/admin/whatsapp-setup" ||
                          pName === "/admin/whatsapp-setup/details/:id"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link className="sidebar-link" to="/admin/whatsapp-setup">
                        {" "}
                        Authkey Embeded Signup
                      </Link>
                    </li>
                    <li
                      className={
                        pName === "/admin/pinnacle-embeded-signup" ||
                          pName ===
                          "/admin/pinnacle-embeded-signup/register/:id/:wabaid/:pnid"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link
                        className="sidebar-link"
                        to="/admin/pinnacle-embeded-signup"
                      >
                        {" "}
                        Pinnacle Embeded Signup
                      </Link>
                    </li>
                    <li
                      className={
                        pName === "/admin/pinnacle-embeded-token"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link
                        className="sidebar-link"
                        to="/admin/pinnacle-embeded-token"
                      >
                        {" "}
                        Pinnacle Embeded Token
                      </Link>
                    </li>
                    <li
                      className={
                        pName === "/admin/karix-embeded-signup"
                          // pName ===
                          // "/admin/pinnacle-embeded-signup/register/:id/:wabaid/:pnid"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link
                        className="sidebar-link"
                        to="/admin/karix-embeded-signup"
                      >
                        {" "}
                        Karix Embeded Signup
                      </Link>
                    </li>


                  </ul>
                </li>






                {isAdmin === "admin" ? (
                  <>
                    <li
                      className={
                        pName === "/admin/billing"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <NavLink
                        className="sidebar-link"
                        isActive={checkActive}
                        to="/admin/billing"
                      >
                        <FontAwesomeIcon icon={faMoneyBillAlt} />{" "}
                        <span className="align-middle">Billing</span>
                      </NavLink>
                    </li>
                  </>
                ) : null}

                {isAdmin === "admin" ? (
                  <>
                    <li
                      className={
                        pName === "/admin/vendor-summary"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <NavLink
                        className="sidebar-link"
                        isActive={checkActive}
                        to="/admin/vendor-summary"
                      >
                        <FontAwesomeIcon icon={faMoneyBillAlt} />{" "}
                        <span className="align-middle">Vendor Summary</span>
                      </NavLink>
                    </li>
                  </>
                ) : null}

                <li
                  className={
                    pName === "/admin/affiliate-management" ||
                      pName === "/admin/affilite-commision-hostory"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <a
                    href="#affiliateManagement"
                    data-target="#affiliateManagement"
                    data-toggle="collapse"
                    className="sidebar-link collapsed"
                  >
                    <FontAwesomeIcon icon={faStarOfLife} />{" "}
                    <span className="align-middle">Affiliate Management</span>
                  </a>
                  <ul
                    id="affiliateManagement"
                    className={
                      pName === "/admin/affiliate-management" ||
                        pName === "/admin/affilite-commision-hostory"
                        ? "sidebar-dropdown list-unstyled collapse show"
                        : "sidebar-dropdown list-unstyled collapse"
                    }
                    data-parent="#sidebar"
                  >
                    <li
                      className={
                        pName === "/admin/affiliate-management"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link
                        className="sidebar-link"
                        to="/admin/affiliate-management"
                      >
                        {" "}
                        Affiliate Users{" "}
                      </Link>
                    </li>

                    <li
                      className={
                        pName === "/admin/affilite-commision-hostory"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link
                        className="sidebar-link"
                        to="/admin/affilite-commision-hostory"
                      >
                        Affiliate Commision List{" "}
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : null}

            {/* 					
					<li  className={pName==='/admin/traffic-management'?'sidebar-item active':'sidebar-item '}>
						<NavLink className="sidebar-link " isActive={checkActive} to="/admin/traffic-management">
						<FontAwesomeIcon icon={faGlobe} />  <span className="align-middle">Traffic Management</span>
			            </NavLink>
					</li> */}

            {empMenu ? (
              <>
                <li
                  className={
                    pName === "/admin/employee-management"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <NavLink
                    className="sidebar-link"
                    isActive={checkActive}
                    to="/admin/employee-management"
                  >
                    <FontAwesomeIcon icon={faUsers} />{" "}
                    <span className="align-middle">Employee Management</span>
                  </NavLink>
                </li>

                <li
                  className={
                    pName === "/admin/change-user-route" ||
                      pName === "/admin/route-management" ||
                      pName === "/admin/shift-router"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <a
                    href="#Route"
                    data-target="#Route"
                    data-toggle="collapse"
                    className="sidebar-link collapsed"
                  >
                    <FontAwesomeIcon icon={faBroadcastTower} />{" "}
                    <span className="align-middle"> Route Management </span>
                  </a>
                  <ul
                    id="Route"
                    className={
                      pName === "/admin/change-user-route" ||
                        pName === "/admin/route-management" ||
                        pName === "/admin/shift-router" ||
                        pName === "/admin/lcr-route-setting"
                        ? "sidebar-dropdown list-unstyled collapse show"
                        : "sidebar-dropdown list-unstyled collapse"
                    }
                    data-parent="#sidebar"
                  >
                    <li
                      className={
                        pName === "/admin/route-management"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link
                        className="sidebar-link"
                        to="/admin/route-management"
                      >
                        Route List
                      </Link>
                    </li>

                    <li
                      className={
                        pName === "/admin/shift-router"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link className="sidebar-link" to="/admin/shift-router">
                        Shift Router
                      </Link>
                    </li>

                    <li
                      className={
                        pName === "/admin/change-user-route"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link
                        className="sidebar-link"
                        to="/admin/change-user-route"
                      >
                        Change Route
                      </Link>
                    </li>
                    <li
                      className={
                        pName === "/admin/lcr-route-setting"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link
                        className="sidebar-link"
                        to="/admin/lcr-route-setting"
                      >
                        LCR Route Setting
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                  className={
                    pName === "/admin/rich-media"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <NavLink
                    className="sidebar-link"
                    isActive={checkActive}
                    to="/admin/rich-media"
                  >
                    <FontAwesomeIcon icon={faPhotoVideo} />{" "}
                    <span className="align-middle"> Rich Media Request </span>
                  </NavLink>
                </li>

                <li
                  className={
                    pName === "/admin/login-history" ||
                      pName === "/admin/signup-history" ||
                      pName === "/admin/entity-history" ||
                      pName === "/admin/voice-template-history" ||
                      pName === "/admin/sernder-history" ||
                      pName === "/admin/aws-setup-history" ||
                      pName === "/admin/api-request" ||
                      pName === "/admin/email-template-history" ||
                      pName === "/admin/sms-template-history"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <a
                    href="#activity"
                    data-target="#activity"
                    data-toggle="collapse"
                    className="sidebar-link collapsed"
                  >
                    <FontAwesomeIcon icon={faAtom} />{" "}
                    <span className="align-middle"> User Activity </span>
                  </a>
                  <ul
                    id="activity"
                    className={
                      pName === "/admin/login-history" ||
                        pName === "/admin/signup-history" ||
                        pName === "/admin/entity-history" ||
                        pName === "/admin/email-template-history" ||
                        pName === "/admin/sms-template-history" ||
                        pName === "/admin/aws-setup-history" ||
                        pName === "/admin/voice-template-history" ||
                        pName === "/admin/api-request" ||
                        pName === "/admin/sernder-history" ||
                        pName === "/admin/invalid-api-request"
                        ? "sidebar-dropdown list-unstyled collapse show"
                        : "sidebar-dropdown list-unstyled collapse"
                    }
                    data-parent="#sidebar"
                  >
                    <li
                      className={
                        pName === "/admin/login-history"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link className="sidebar-link" to="/admin/login-history">
                        Login History
                      </Link>
                    </li>
                    <li
                      className={
                        pName === "/admin/signup-history"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link className="sidebar-link" to="/admin/signup-history">
                        Signup History
                      </Link>
                    </li>
                    <li
                      className={
                        pName === "/admin/entity-history"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link className="sidebar-link" to="/admin/entity-history">
                        Entity History
                      </Link>
                    </li>
                    <li
                      className={
                        pName === "/admin/sernder-history"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link
                        className="sidebar-link"
                        to="/admin/sernder-history"
                      >
                        Sender History
                      </Link>
                    </li>

                    <li
                      className={
                        pName === "/admin/sms-template-history"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link
                        className="sidebar-link"
                        to="/admin/sms-template-history"
                      >
                        SMS Template History
                      </Link>
                    </li>

                    <li
                      className={
                        pName === "/admin/voice-template-history"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link
                        className="sidebar-link"
                        to="/admin/voice-template-history"
                      >
                        Voice Template History
                      </Link>
                    </li>

                    <li
                      className={
                        pName === "/admin/email-template-history"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link
                        className="sidebar-link"
                        to="/admin/email-template-history"
                      >
                        Email Template History
                      </Link>
                    </li>

                    <li
                      className={
                        pName === "/admin/aws-setup-history"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link
                        className="sidebar-link"
                        to="/admin/aws-setup-history"
                      >
                        {" "}
                        AWS Setup History{" "}
                      </Link>
                    </li>

                    <li
                      className={
                        pName === "/admin/api-request"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link className="sidebar-link" to="/admin/api-request">
                        {" "}
                        API Request{" "}
                      </Link>
                    </li>

                    <li
                      className={
                        pName === "/admin/invalid-api-request"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link
                        className="sidebar-link"
                        to="/admin/invalid-api-request"
                      >
                        {" "}
                        Invalid API Request{" "}
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : null}

            <li
              className={
                pName === "/admin/transaction-history"
                  ? "sidebar-item active"
                  : "sidebar-item"
              }
            >
              <NavLink
                className="sidebar-link"
                isActive={checkActive}
                to="/admin/transaction-history"
              >
                <FontAwesomeIcon icon={faCreditCard} />{" "}
                <span className="align-middle"> Transaction History </span>
              </NavLink>
            </li>


            <li
              className={
                pName === "/dashboard/my-profile" ||
                  pName === "/dashboard/change-password"
                  ? "sidebar-item active"
                  : "sidebar-item"
              }
            >
              <a
                href="#ui"
                data-target="#ui"
                data-toggle="collapse"
                className="sidebar-link collapsed"
              >
                <FontAwesomeIcon icon={faUser} />{" "}
                <span className="align-middle">My Account</span>
              </a>
              <ul
                id="ui"
                className={
                  pName === "/admin/my-profile" ||
                    pName === "/admin/change-password"
                    ? "sidebar-dropdown list-unstyled collapse show"
                    : "sidebar-dropdown list-unstyled collapse"
                }
                data-parent="#sidebar"
              >
                <li
                  className={
                    pName === "/admin/my-profile"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <Link className="sidebar-link" to="/admin/my-profile">
                    Profile
                  </Link>
                </li>

                <li
                  className={
                    pName === "/admin/change-password"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <Link className="sidebar-link" to="/admin/change-password">
                    Change Password
                  </Link>
                </li>
              </ul>
            </li>

            {isAdmin === "admin" ? (
              <li
                className={
                  pName === "/admin/spam-word" || pName === "/admin/spam-report"
                    ? "sidebar-item active"
                    : "sidebar-item"
                }
              >
                <a
                  href="#spam"
                  data-target="#spam"
                  data-toggle="collapse"
                  className="sidebar-link collapsed"
                >
                  <FontAwesomeIcon icon={faBan} />{" "}
                  <span className="align-middle"> Spam Management </span>
                </a>
                <ul
                  id="spam"
                  className={
                    pName === "/admin/spam-word" ||
                      pName === "/admin/blacklist" ||
                      pName === "/admin/spam-report"
                      ? "sidebar-dropdown list-unstyled collapse show"
                      : "sidebar-dropdown list-unstyled collapse"
                  }
                  data-parent="#sidebar"
                >
                  <li
                    className={
                      pName === "/admin/spam-report"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link className="sidebar-link" to="/admin/spam-report">
                      Spam Report
                    </Link>
                  </li>
                  <li
                    className={
                      pName === "/admin/spam-word"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link className="sidebar-link" to="/admin/spam-word">
                      Spam Word
                    </Link>
                  </li>
                  <li
                    className={
                      pName === "/admin/blacklist"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link className="sidebar-link" to="/admin/blacklist">
                      Black List
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null}

            {isAdmin === "admin" ? (
              <li
                className={
                  pName === "/admin/reseller-setting"
                    ? "sidebar-item active"
                    : "sidebar-item"
                }
              >
                <a
                  href="#setting"
                  data-target="#setting"
                  data-toggle="collapse"
                  className="sidebar-link collapsed"
                >
                  <FontAwesomeIcon icon={faCog} />{" "}
                  <span className="align-middle"> Settings </span>
                </a>
                <ul
                  id="setting"
                  className={
                    pName === "/admin/reseller-setting"
                      ? "sidebar-dropdown list-unstyled collapse show"
                      : "sidebar-dropdown list-unstyled collapse"
                  }
                  data-parent="#sidebar"
                >
                  <li
                    className={
                      pName === "/admin/reseller-setting"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link className="sidebar-link" to="/admin/reseller-setting">
                      Reseller Setting
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null}

            {isAdmin === "reseller" &&
              <li
                className={
                  pName === "/admin/reseller-site-settings"
                    ? "sidebar-item active"
                    : "sidebar-item"
                }
              >
                <NavLink
                  className="sidebar-link"
                  isActive={checkActive}
                  to="/admin/reseller-site-settings"
                >
                  <FontAwesomeIcon icon={faCog} />{" "}
                  <span className="align-middle">  Reseller Site Setting </span>
                </NavLink>
              </li>}


            <li
              className={
                pName === "/admin/support"
                  ? "sidebar-item active"
                  : "sidebar-item"
              }
            >
              <NavLink
                className="sidebar-link"
                isActive={checkActive}
                to="/admin/support"
              >
                <FontAwesomeIcon icon={faTicketAlt} />{" "}
                <span className="align-middle"> Support Ticket </span>
              </NavLink>
            </li>






            <hr className="mb-0" />
            {(isAdmin === "admin" || isAdmin === "emp") && (
              <>
                <li
                  className={
                    pName === "/admin/faq-list"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <NavLink
                    className="sidebar-link"
                    isActive={checkActive}
                    to="/admin/faq-list"
                  >
                    <FontAwesomeIcon icon={faAlignJustify} />{" "}
                    <span className="align-middle"> Add FAQ </span>
                  </NavLink>
                </li>

                <li
                  className={
                    pName === "/admin/blog-list"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <NavLink
                    className="sidebar-link"
                    isActive={checkActive}
                    to="/admin/blog-list"
                  >
                    <FontAwesomeIcon icon={faBlog} />{" "}
                    <span className="align-middle"> Blog </span>
                  </NavLink>
                </li>
              </>
            )}
            {isAdmin === "admin" ? (
              <>
                <li
                  className={
                    pName === "/admin/feedback"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <NavLink
                    className="sidebar-link"
                    isActive={checkActive}
                    to="/admin/feedback"
                  >
                    <FontAwesomeIcon icon={faInbox} />{" "}
                    <span className="align-middle"> Feedback </span>
                  </NavLink>
                </li>

                <li
                  className={
                    pName === "/admin/contact-us-enquiry"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <NavLink
                    className="sidebar-link"
                    isActive={checkActive}
                    to="/admin/contact-us-enquiry"
                  >
                    <FontAwesomeIcon icon={faInbox} />{" "}
                    <span className="align-middle"> Contact us </span>
                  </NavLink>
                </li>
              </>
            ) : null}
          </ul>
        </div>
      </div>

    </nav>
  );
};

export default DashboardLeftMenu;