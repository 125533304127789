import React, { useEffect, useState } from "react";
import { WhstappGalleryList } from "../../api/Api";

const SetMediaDynamic = (props) => {
  // console.log("props", props);

  const [media, setMedia] = useState();
  const [medialist, setMediaList] = useState();

  useEffect(() => {
    // setMedia(
    //   Object.keys(props.headertext)[0]
    //     ? props.headertext[Object.keys(props.headertext)[0]]
    //     : null
    // );
    let mtype=props.headertext.TemplateType;
    setMedia(props.headertext[mtype]);
  //  console.log(props.headertext[mtype]);
  }, [props.headertext]);

  useEffect(() => {
    WhstappGalleryList().then((res) => {
      setMediaList(res.data.data);
    });
  }, []);

  useEffect(() => {
    mediaUrlHandler();
  }, [media]);

  const mediaUrlHandler = () => {
    props.setMediaURL(media);
  };

  return (
    <>
      {props.headertext && props.headertext.hasOwnProperty("TemplateType") && (
        <div className="bg-dark">
          <div className="input-group mb-2 mr-sm-2">
            <div className="input-group-text">
              {Object.keys(props.headertext)[0]} URL
            </div>
            <input
              className="form-control"
              type="text"
              value={media}
              onChange={(e) => {
                mediaUrlHandler();
                setMedia(e.target.value);
              }}
            />
          </div>
          <div className="d-flex justify-content-between">
            <span
              data-toggle="modal"
              data-target="#galleryPopup"
              className="badge badge-info"
            >
              Choose Form Media Gallery
            </span>
            <a target="_blank" href={media}>
              <span className="badge badge-success">Preview</span>
            </a>
          </div>
        </div>
      )}

      <div
        className="modal fade"
        id="galleryPopup"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="galleryPopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="galleryPopupLabel">
                Choose Media
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {medialist && (
                <div className="input-group drop-down-icons">
                  <select
                    onChange={(e) => setMedia(e.target.value)}
                    className="form-control"
                  >
                    <option value="">Select Media</option>
                    {medialist.map((item,index) => (
                      <option key={index} value={item.doc_url}>{item.doc_name}</option>
                    ))}
                  </select>
                </div>
              )}

              <a className="mt-3 d-block" target="_blank" href={media}>
                <span className="badge badge-success">Preview</span>
              </a>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                data-dismiss="modal"
                className="btn btn-primary"
              >
                Select
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetMediaDynamic;