import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import { HOST_URL } from '../api/Api';

class CreateNewTickets extends Component {

constructor () {

const lStorage = JSON.parse(localStorage.getItem("login"));
const userId= lStorage.user.userId;
const userToken= lStorage.token;

super();
    this.state = {
        user_id:userId,
        token:userToken,
        email:'',
        subject:'',
        department:'',
        messageBody:'',
        parent_id:'',
        errorMessage:'',
        successMessage:false,
        bgColor:''
    }
}


componentDidMount() {
    this.getUserDetails();
}

getUserDetails(){

    fetch(`${HOST_URL}/profile.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response) => {
        response.json().then((result) => {
             // console.log(result)
            if (result.success === true) {
                this.setState({email:result.data[0].username,parent_id:result.data[0].parent,ticketUserId:result.data[0].id})
            }
        })
    })
}



CreateNewTicket(){

    document.querySelector('body').scrollTo(0, 0);

  fetch(`${HOST_URL}/support.php?user_id=${this.state.user_id}&token=${this.state.token}&email=${this.state.email}&subject=${this.state.subject}&method=create&department=${this.state.department}&message=${this.state.messageBody}&type=client&parent_id=${this.state.parent_id}`).then((response)=> {
    response.json().then((result)=>{
        // console.log(result)
        if(result.success===true){
            this.setState({successMessage:true});	
            this.setState({bgColor:"alert alert-success alert-dismissible"});	
            this.setState({errorMessage:result.message});	
            setTimeout(function(){window.location = "/dashboard/support";}, 2000);
            
        }else{
            this.setState({successMessage:true});	
            this.setState({bgColor:"alert alert-danger alert-dismissible"});	
            this.setState({errorMessage:result.message});	

        }
       
    })
})


}



render() {
    return (
<div className="wrapper">
    <DashboardLeftMenu />
        <div className="main">
            <DashboardHeader />
            <main className="content">
                <div className="container-fluid p-0">
                    <div className="row mb-2 mb-xl-3">
                        <div className="col-auto d-none d-sm-block">
                            <h3><strong>  Support   </strong> Tickets </h3>
                        </div>
                    </div>


                       {
                        this.state.successMessage? 
                        <div className={this.state.bgColor} role="alert">
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">×</span>
                        </button>
                        <div className="">
                            {this.state.errorMessage}
                        </div>
                        </div>
                        :
                        null
                        }


                    <div className="row">
                    
                    <div className="col-md-8">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title mb-0">Create New Ticket</h5>
                        </div>
                        <div className="card-body">
                        <p>If you can't find a solution to your problems in our <Link to='/'>Help Center </Link>, Then you can submit a ticket by selecting the appropriate department below.</p>


                            <form id="newtiecktid">
                                <div className="row">
                                    <div className="mb-3 col-md-12">
                                        <input type="text" defaultValue={this.state.email} readOnly placeholder="Email Address" onChange={(event) => this.setState({ subject: event.target.value })} className="form-control" id="inputPasswordNew2"/>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="mb-3 col-md-12">
                                    <label>Subject</label>
                                        <input type="text" onChange={(event) => this.setState({ subject: event.target.value })}   placeholder="" className="form-control" id="inputPasswordNew"/>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="mb-3 col-md-12">
                                    <label>Department</label>
                                        <select onChange={(event) => this.setState({ department: event.target.value })} className="form-control">
                                            <option value="t">Select Department </option>
                                            <option value="Technical Support">Technical Support </option>
                                            <option value="Billing Support">Billing Support </option>
                                            <option value="Payment Support">Payment Support </option>
                                            <option value="Channel  Support ">Channel  Support </option>
                                            <option value="Template  Support ">Template  Support </option>
                                            <option value="Sales  Support ">Sales  Support </option>
                                        </select>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="mb-3 col-md-12">
                                    <label>Your Message</label>
                                       <textarea rows="5"  onChange={(event) => this.setState({ messageBody: event.target.value })} className="form-control"></textarea>
                                    </div>
                                </div>


                                {/* <div className="row">
                                    <div className="mb-3 col-md-12">
                                    <label>Attachments</label>
                                      <input className="form-control" type="file"/>
                                      <small>Allowed File Extensions: .jpg, .gif, .jpeg, .png, .pdf
                                    </small>
                                    </div>
                                  
                                </div> */}


                                <button type="button" onClick={()=> this.CreateNewTicket()} className="btn btn-sm float-right btn-primary">Submit</button>
                            </form>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-4">
                    <div className="card">
									<p className="tooglebox">
										<Link className="" data-toggle="collapse" to="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
										Help Center
								</Link>
									</p>
									<div className="">
										<div className="collapse show" id="collapseExample">
											<div className="links-set">
												<ol className="mt-3">
												<li><Link to="/dashboard/create-sender-id">Setup / Create New Sender ID</Link></li>

												<li><Link to="/dashboard/add-entity-id"> Setup / Add Entity New ID</Link></li>

												<li><a href="https://authkey.io/sms-api-docs" target="_blank">How to Create SMS Template</a></li>
												
												</ol>
											</div>
										</div>
									</div>
								</div>
                    </div>

                   
                </div>
            </div>
        </main>
        <DashboardFooter />
    </div>
</div>
        );
    }
}

export default CreateNewTickets;