import React, { useEffect, useState } from 'react'
import DashboardFooter from '../../Footer/DashboardFooter'
import DashboardLeftMenu from '../DashboardLeftMenu'
import DashboardHeader from '../../Header/DashboardHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobile, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { HOST_URL_DB2 } from '../api/Api'

const Optout = () => {

    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;

    const [numberCount, setNumberCount] = useState('')
    const [searchedNumber, setSearchedNumber] = useState([])
    const [number,setNumber] = useState('')
    const [mobile, setMobile] = useState('')
    const [deletePopup,setDeletePopup] = useState(false)
    const [id,setId] = useState('') 
    const[numberArray,setNumberArray] = useState([]);
    const [error, setError] = useState({
        errorStatus: false,
        bgColor: "",
        errorMessage: ""
    })
    const [disable , setDisable] = useState(false)

    useEffect(() => {
        GetNumberCount();
    }, [])

    const GetNumberCount = async () => {
        let data = {
            token: userToken,
            user_id: userId,
            method: "retrieve_count",
        }
        try {
            let response = await fetch(`${HOST_URL_DB2}/optout_sms.php`, {
                method: "post",
                headers: {
                    "content-Type": "application/json",
                },
                body: JSON.stringify(data),
            })
            let result = await response.json();
            if (result.success) {
                // console.log(result.data)
                setNumberCount(result.data)
            }
            else {
                setError({
                    errorStatus: true,
                    bgColor: "alert alert-danger",
                    errorMessage: result.message
                })
            }
        } catch (error) {
            setError({
                errorStatus: true,
                bgColor: "alert alert-danger",
                errorMessage: error
            })
        }
    }

    const SearchNumber = async () => {
        let data = {
            token: userToken,
            user_id: userId,
            method: "search",
            mobile: mobile
        }
        try {
            let response = await fetch(`${HOST_URL_DB2}/optout_sms.php`, {
                method: "post",
                headers: {
                    "content-Type": "application/json",
                },
                body: JSON.stringify(data),
            })
            let result = await response.json();
            setDisable(true)
            if (result.success) {
                // console.log(result.data)
                setSearchedNumber(result.data)
                setDisable(false)
            }
            else {
                setError({
                    errorStatus: true,
                    bgColor: "alert alert-danger",
                    errorMessage: result.message
                })
                setDisable(false)
            }
        } catch (error) {
            setError({
                errorStatus: true,
                bgColor: "alert alert-danger",
                errorMessage: error
            })
            setDisable(false)
        }
    }

    const toggleDelete = (id) =>{
       setDeletePopup(true)
       setId(id)
    }

    const onDelete = async() =>{
        let data = {
            token: userToken,
            user_id: userId,
            method: "delete",
            mobile: mobile,
            id: id
        }
        try {
            let response = await fetch(`${HOST_URL_DB2}/optout_sms.php`, {
                method: "post",
                headers: {
                    "content-Type": "application/json",
                },
                body: JSON.stringify(data),
            })
            let result = await response.json();
            if (result.success) {
                setError({
                    errorStatus: true,
                    bgColor: "alert alert-success",
                    errorMessage: result.message
                })
                setDeletePopup(false);
                setTimeout(()=>{
                    window.location.reload();
                },2000);              
            }
            else {
                setError({
                    errorStatus: true,
                    bgColor: "alert alert-danger",
                    errorMessage: result.message
                })
            }
        } catch (error) {
            setError({
                errorStatus: true,
                bgColor: "alert alert-danger",
                errorMessage: error
            })
        }
    }

    const AddNumber = async() =>{
        let data = {
            token: userToken,
            user_id: userId,
            method: "add",
            optout: number
        }
        console.log(data)
        try {
            let response = await fetch(`${HOST_URL_DB2}/optout_sms.php`, {
                method: "post",
                headers: {
                    "content-Type": "application/json",
                },
                body: JSON.stringify(data),
            })
            let result = await response.json();
            setDisable(true)
            if (result.success) {
                setError({
                    errorStatus: true,
                    bgColor: "alert alert-danger",
                    errorMessage: result.message
                })
                setNumber('')
                setDisable(false)
            }
            else {
                setError({
                    errorStatus: true,
                    bgColor: "alert alert-danger",
                    errorMessage: result.message
                })
                setDisable(false)
            }
        } catch (error) {
            setError({
                errorStatus: true,
                bgColor: "alert alert-danger",
                errorMessage: error
            })
            setDisable(false)
        }
    }

    const onChange = (e) => {
        setNumber(e.target.value)
        newArray(e.target.value)
    }

    const newArray = (data) => {
        const numbers = data.split('\n' || ',').map(num => num.trim());
        setNumberArray(numbers)
    }

    return (
        <div>
            <div className="wrapper">
                <DashboardLeftMenu />
                <div className="main">
                    <DashboardHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                    <div className="card flex-fill">
                                        <div className="card-header d-flex align-items-center">
                                            <h3 className="card-title mb-0 font-weight-bold">Optout</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                    <div className="card flex-fill">
                                        <div className="card-header d-flex align-items-center border-bottom">
                                            <FontAwesomeIcon icon={faMobile} className="mr-2" style={{ fontSize: "1.5rem" }} />
                                            <h3 className="card-title mb-0 font-weight-bold">Enter Your Number for BlackList</h3>
                                        </div>

                                        <div className="card-body">
                                            <div className="filter-option">
                                            {error.errorStatus && (
                                            <div className={error.bgColor} role="alert">
                                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                                <div>{error.errorMessage}</div>
                                            </div>
                                        )}
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <textarea type="text" className="form-control" name="number" value={number} onChange={onChange} />
                                                    </div>
                                                </div>
                                                <div className='row mt-2'>
                                                    <div className='col-6'>
                                                        <button onClick={AddNumber} style={{ float: "right", fontSize: "12px", padding: "10px 10px 10px 10px" }} disabled={disable ? true : false} type="button" className="btn btn-primary">Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                    <div className="card flex-fill">
                                        <div className="card-header d-flex align-items-center border-bottom">
                                            {/* <FontAwesomeIcon icon={faMobile} className="mr-2" style={{ fontSize: "1.5rem" }} /> */}
                                            <h3 className="card-title mb-0 font-weight-bold">BlackList Files</h3>
                                        </div>

                                        <div className="card-body">
                                            <div className="filter-option">
                                                <div className='row'>
                                                    <div className='col-12 col-md-6 mb-3'>
                                                        <div className="mb-3 form-password-toggle">
                                                            <div className="input-group input-group-merge">
                                                                <input
                                                                    type="text"
                                                                    id="password"
                                                                    className="form-control"
                                                                    name="password"
                                                                    aria-describedby="password"
                                                                    value={`${numberCount} Numbers are BlackList`}
                                                                    onChange={"onChange"}
                                                                />
                                                                <a
                                                                    type="button"
                                                                    className="btn btn-primary"
                                                                    style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, height: "45px" }}
                                                                    href={`${HOST_URL_DB2}/optout_sms.php?user_id=${userId}&method=download&token=${userToken}`}
                                                                >
                                                                    Download
                                                                </a>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className='col-12 col-md-6 mb-3'>
                                                        <div className="mb-3 form-password-toggle">
                                                            <div className="input-group input-group-merge">
                                                                <input
                                                                    type="text"
                                                                    id="mobile"
                                                                    className="form-control"
                                                                    name="mobile"
                                                                    placeholder="Enter Mobile Number"
                                                                    aria-describedby="mobile"
                                                                    value={mobile}
                                                                    onChange={(e) => setMobile(e.target.value)}
                                                                />
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary"
                                                                    disabled = {disable ? true : false}
                                                                    style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, height: "45px" }}
                                                                    onClick={() => SearchNumber()}
                                                                >
                                                                    Search
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {searchedNumber.length > 0 ? <div className="container mt-4">
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Number</th>
                                                                <th>IP</th>
                                                                <th>Created Date</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {searchedNumber.map((item, index) => (
                                                                <tr key={item.id}>
                                                                    <td>{item.number}</td>
                                                                    <td>{item.ip}</td>
                                                                    <td>{item.created_date}</td>
                                                                    <td>
                                                                        <span onClick={()=>toggleDelete(item.id)} className="badge bg-danger">
                                                                            Delete
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div> : null}
                                            </div>
                                        </div>

                                        {deletePopup && <div className="modal fade show" tabIndex="-1" style={{ display: 'block' }} aria-labelledby="popupLabel" aria-hidden="true">
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="popupLabel">Delete: {"title"}</h5>
                                                        {/* <button type="button" className="btn-close" onClick={() => setDeletePopup(false)} aria-label="Close">x</button> */}
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <h5>Are You Sure You Want to Delete</h5>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <button type="button" onClick={onDelete} className="btn btn-danger me-2 float-right">Delete</button>
                                                                <button type="button" onClick={() => setDeletePopup(false)} className="btn btn-secondary float-right mr-2">Close</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <DashboardFooter />
                </div>
            </div>
        </div>
    )
}

export default Optout