import React, { Component } from "react";
import { CAMPAIGN_CONTACT_LIST_API } from "../../../constants/ServiceConstants";
import api from "../../../axios/Axios";
import DashboardLayout from "../../UI/Layout/DashboardLayout";
import Title from "../../UI/Text/Title";
import AdvanceDataTable from "../../UI/Table/AdvanceDataTable";
import {
  CAMPAIGN_CONTACT_LIST,
  CAMPAIGN_FILTER_CONTACT_LIST,
  FACEBOOK_LEADS,
} from "../../UI/Table/ColumnConst";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Alert from "../../UI/Alert/Alert";
import { NODE_HOST_URL } from "../api/Api";
import FacebookAuth from "./FacebookAuth";
import Axios from "../../../axios/Axios";
import axios from "axios";
class FacebookLeads extends Component {
  constructor() {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const user_id = lStorage.user.userId;
    const token = lStorage.token;
    super();

    this.state = {
      userId: user_id,
      token: token,
      ContactListData: [],
      setIsLoading: true,
      searchDataTable: false,
      filterContact: "",
      isError: false,
      messageType: "",
      errorMessage: "",
      responseToken: "",
      faceboolLeadsData: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.getWhatsaAppNumberList();
  }

  setToken = (value) => {
    this.setState({ responseToken: value });
    this.getFacebookLeads(value);
  };

  getFacebookLeads = async (token) => {
    try {
      let { data } = await axios.get(
        `https://graph.facebook.com/v12.0/me/accounts?access_token=${token}`
      );
      let filterData = data.data.filter((x) => x.name === "Authkey");
      this.getFacebookLeads2step(
        filterData[0]?.id,
        filterData[0]?.access_token
      );
    } catch (error) {
      console.log(error);
    }
  };

  getFacebookLeads2step = async (id, access_token) => {
    try {
      let { data } = await axios.get(
        `https://graph.facebook.com/v12.0/${id}/leadgen_forms?access_token=${access_token}`
      );
      this.getFacebookLeads3step(data.data[0].id, access_token);
    } catch (error) {
      console.log(error);
    }
  };

  getFacebookLeads3step = async (id, access_token) => {
    this.setState({ isLoading: true });
    try {
      let { data } = await axios.get(
        `https://graph.facebook.com/v12.0/${id}/leads?access_token=${access_token}`
      );
      this.setState({ faceboolLeadsData: data.data });
    } catch (error) {
      console.log(error);
    }
    this.setState({ isLoading: false });
  };

  getWhatsaAppNumberList = async () => {
    try {
      const { data } = await api.post(`${NODE_HOST_URL}/retrieveLeads`, {
        method: "retrieve_leads",
      });
      if (data.success === true) {
        this.setState({ ContactListData: data.data, setIsLoading: false });
      } else {
        this.setState({
          errorMessage: data.message,
          isError: true,
          messageType: "alert-danger",
          setIsLoading: false,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  filterData = async () => {
    this.setState({ isError: false });
    try {
      const { data } = await api.post(CAMPAIGN_CONTACT_LIST_API, {
        token: this.state.token,
        user_id: this.state.userId,
        method: "filter",
        mobile: this.state.filterContact,
      });

      if (data.success === true) {
        this.setState({
          ContactListData: data.data,
          setIsLoading: false,
          searchDataTable: true,
        });
      } else {
        this.setState({
          errorMessage: data.message,
          isError: true,
          messageType: "alert-danger",
          setIsLoading: false,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  resetFilter = () => {
    this.getWhatsaAppNumberList();
    this.setState({
      searchDataTable: false,
      filterContact: "",
    });
  };

  render() {
    return (
      <DashboardLayout>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="d-flex justify-content-between align-items-center w-100 mb-3">
              <h6>Facebook Leads</h6>
              <FacebookAuth setToken={this.setToken} />
            </div>

            {this.state.isError && (
              <Alert type={this.state.messageType}>
                {this.state.errorMessage}
              </Alert>
            )}

            <div className="row">
              <div className="">
                <div className="">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-header">
                          <h5 className="card-title mb-0">
                            Rcs Template List{" "}
                          </h5>
                        </div>
                        <hr className="m-0" />
                        <div className="">
                          {this.state.faceboolLeadsData.length > 0 ? (
                            <div className="table-responsive">
                              <table className="table table-hover project my-0">
                                <thead>
                                  <tr>
                                    <th>Created Date</th>
                                    <th>Questions</th>
                                    <th>Answers</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.faceboolLeadsData.map(
                                    (x, index) => {
                                      let fieldData = x.field_data;
                                      return (
                                        <tr key={x.created_time}>
                                          <td style={{ color: "black" }}>
                                            {(() => {
                                              let date = new Date(
                                                x.created_time
                                              );
                                              let formattedDate = `${date.getFullYear()}/${(
                                                date.getMonth() + 1
                                              )
                                                .toString()
                                                .padStart(2, "0")}/${date
                                                .getDate()
                                                .toString()
                                                .padStart(2, "0")}`;
                                              let formattedTime = `${date
                                                .getHours()
                                                .toString()
                                                .padStart(2, "0")}:${date
                                                .getMinutes()
                                                .toString()
                                                .padStart(2, "0")}:${date
                                                .getSeconds()
                                                .toString()
                                                .padStart(2, "0")}`;
                                              return `${formattedDate} ${formattedTime}`;
                                            })()}
                                          </td>
                                          <td
                                            style={{ color: "black" }}
                                            dangerouslySetInnerHTML={{
                                              __html: fieldData
                                                .map((f) => f.name)
                                                .join("<br/>"),
                                            }}
                                          ></td>
                                          <td
                                            style={{ color: "black" }}
                                            dangerouslySetInnerHTML={{
                                              __html: fieldData
                                                .map((f) => f.values)
                                                .join("<br/>"),
                                            }}
                                          ></td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <div className="text-center">
                              <div className="card-body">
                                <p>No Record Found....</p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </DashboardLayout>
    );
  }
}

export default FacebookLeads;
