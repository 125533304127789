import React from "react";
import SuggestionsButtonUi from "./SuggestionsButton";
import { charaterCount, getMediaType, insertVariable } from "../../../Utils/Utils";

const StandaloneCardForm = ({ formData, setFormData }) => {

    const handleCardChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            standaloneCard: {
                ...prevData.standaloneCard,
                [name]: value,
            },
        }));
    };

    const mediaHandler = (e) => {
        let url = e.target.value;
        let type = getMediaType(e.target.value);
        setFormData((prevData) => ({
            ...prevData, standaloneCard: {
                ...prevData.standaloneCard,
                media: {
                    file_type: type,
                    url: url
                }
            }
        }))
    }

    return (
        <div className="suggested-button">
            <div className="form-row">
                <div className="form-group drop-down-icons  col-md-6">
                    <select
                        className="form-control"
                        name="card_oriention"
                        value={formData.standaloneCard.card_oriention}
                        onChange={handleCardChange}
                    >
                        <option value="">Select Card Oriention</option>
                        <option value="horizontal">Horizontal</option>
                        <option value="vertical">Vertical</option>
                    </select>
                </div>

                {formData.standaloneCard.card_oriention === 'horizontal' ?
                    <div className="form-group drop-down-icons  col-md-6">
                        <select
                            className="form-control"
                            name="media_height"
                            value={formData.standaloneCard.media_height}
                            onChange={handleCardChange}
                        >
                            <option value="">Select Card Alignment</option>
                            <option value="left">Left</option>
                            <option value="right">Right</option>
                        </select>
                    </div>
                    :
                    <div className="form-group drop-down-icons  col-md-6">
                        <select
                            className="form-control"
                            name="card_width"
                            value={formData.standaloneCard.card_width}
                            onChange={handleCardChange}
                        >
                            <option value="">Select Media Height</option>
                            <option value="Sort Height">Short Height</option>
                            <option value="Medium Height">Medium Height</option>
                        </select>
                    </div>
                }

            </div>
            <div className="form-row">
                <div className="form-group drop-down-icons  col-md-12">
                    <select
                        className="form-control"
                        name="file_type"
                        disabled
                        value={formData.standaloneCard.file_type}
                        onChange={handleCardChange}
                    >
                        <option value="Public">Public Image / Video  </option>
                        <option value="Upload">Upload Image / Video </option>
                    </select>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-12">
                    <input
                        type="text"
                        className="form-control"
                        name="media"
                        value={formData.standaloneCard.media.url}
                        onChange={(e) => mediaHandler(e)}
                        placeholder="Enter Public Image/Video URL"
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-12">
                    <input
                        type="text"
                        className="form-control"
                        name="card_title"
                        id="card_title"
                        value={formData.standaloneCard.card_title}
                        onChange={handleCardChange}
                        placeholder="Enter Card Title"
                    />
                    <div className="d-flex mt-2 justify-content-between">
                        {charaterCount(formData.standaloneCard?.card_title)}
                        {!formData.standaloneCard.card_title.includes('[title1]') &&
                            <span className="badge badge-primary" onClick={() => insertVariable(document.getElementById('card_title'), formData, setFormData, 'title')}>Add Varibale</span>
                        }
                    </div>
                </div>
                <div className="form-group col-md-12">
                    <textarea
                        className="form-control"
                        name="card_description"
                        id="card_description"
                        value={formData.standaloneCard.card_description}
                        onChange={handleCardChange}
                        placeholder="Enter Card Description"
                    />
                    <div className="d-flex mt-2 justify-content-between">
                        {charaterCount(formData.standaloneCard?.card_description)}
                        <span className="badge badge-primary" onClick={() => insertVariable(document.getElementById('card_description'), formData, setFormData,)}>Add Varibale</span>
                    </div>
                </div>
            </div>
            <SuggestionsButtonUi formData={formData} setFormData={setFormData} />
        </div>
    );
};

export default StandaloneCardForm;
