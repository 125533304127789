import React, { useEffect, useState } from "react";
import Select, {components } from "react-select";
// import makeAnimated from "react-select/animated";
import axios from "axios";
import { HOST_URL } from "../../api/Api";

function CarouselButton(props) {
  const lStorage = JSON.parse(localStorage.getItem("login"));
  const userId = lStorage.user.userId;
  const userToken = lStorage.token;
  let paramid = props.paramid;
  // const animatedComponents = makeAnimated();
  const [defaultButtnData, setdefaultButtnData] = useState();
  const [callToAction, setCallToAction] = useState(false);
  const [specialUser, setSpecialUser] = useState("");
  const [urlAction, setUrlAction] = useState(false);
  const [urlAction2, setUrlAction2] = useState(false);
  const [quickReply, setQuickReply] = useState(false);
  const [buttonType, setButtonType] = useState("Select button type");
  const [isChecked, setisChecked] = useState(false);
  const [quickRepCount, setQuickRepCount] = useState(1);
  const [selectedOption, setSelectedOption] = useState([""]);

  // console.log(props);

  useEffect(() => {
    if (paramid !== undefined) {
      setisChecked(true);
      setdefaultButtnData(props.defaultButtnData);
      if (props.defaultButtnData.length > 0) {
        if (props.defaultButtnData[0].actionType === "calltoaction") {
          setButtonType("call to action");
          props.defaultButtnData.map((item) => {
            let key = Object.keys(item);
            if (key.includes("urltext")) {
              setUrlAction(item);
            }
            if (key.includes("urltext2")) {
              setUrlAction2(item);
            } else if (key.includes("phone")) {
              setCallToAction(item);
            }
          });
        }
        if (props.defaultButtnData[0].actionType === "quickReply") {
          setButtonType("quick reply");
          
          const convertObjectToArray = (obj) => {
            return Object.keys(obj).map(key => ({ [key]: obj[key] }));
        };
        
        const transformedArray = convertObjectToArray(props.defaultButtnData[0]);
        const removeActionType = (arr) => {
          return arr.filter(item => !Object.keys(item).includes("actionType"));
      };
      
      const filteredArray = removeActionType(transformedArray);
        setQuickReply(props.defaultButtnData[0]);
        setQuickRepCount(filteredArray.length);
        }
      }
    }
  }, [props.defaultButtnData, paramid]);

  useEffect(() => {
    props.defaultButtnData.forEach((item) => {
      const keyys = Object.keys(item);
      if (keyys.includes("phone")) {
        const newObj = { value: "callphone", label: "Call Phone number" };
        setSelectedOption((prevDefaultopt) => [...prevDefaultopt, newObj]);
      }
      if (keyys.includes("urltext")) {
        const newObj = { value: "visitwebsite1", label: "Visit website 1" };
        setSelectedOption((prevDefaultopt) => [...prevDefaultopt, newObj]);
      }
      if (keyys.includes("urltext2")) {
        const newObj = { value: "visitwebsite2", label: "Visit website 2" };
        setSelectedOption((prevDefaultopt) => [...prevDefaultopt, newObj]);
      }
    });
  }, [props.defaultButtnData]);

  useEffect(() => {
    axios
      .get(
        `${HOST_URL}/get_user_route.php?user_id=${userId}&method=retrieve&token=${userToken}`
      )
      .then((res) => {
        if (res.data.success === true) {
          setSpecialUser(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  });

  const buttonHandler = (e) => {
    setButtonType(e.target.value);
    props.sendButtonData(false);
    props.sendURLButton(false);
    setCallToAction(false);
    setUrlAction(false);
    setUrlAction2(false);
    setQuickReply(false);
    setButtonValue();
  };

  const buttonHnadlar = (event) => {
    event.target.checked ? setisChecked(true) : setisChecked(false);
  };

  const setButtonValue = () => {
    props.sendButtonData([callToAction]);
    props.sendURLButton([urlAction]);
    props.sendURLButton2([urlAction2]);
    props.sendQuickButton([quickReply]);
    props.onButtonTypeChange(buttonType);
  };

  useEffect(() => {
    setButtonValue();
  }, [callToAction, urlAction, urlAction2, quickReply, buttonType]);

  const changeHandler = (e) => {
    setCallToAction({
      ...callToAction,
      [e.target.name]: e.target.value,
      actionType: "calltoaction",
    });
  };

  const urlHandler = (e) => {
    setUrlAction({
      ...urlAction,
      [e.target.name]: e.target.value,
      actionType: "calltoaction",
    });
  };

  const urlHandler2 = (e) => {
    setUrlAction2({
      ...urlAction2,
      [e.target.name]: e.target.value,
      actionType: "calltoaction",
    });
  };
  


  const quickLinkhandler = (e) => {
    setQuickReply({
      ...quickReply,
      [e.target.name]: e.target.value,
      actionType: "quickReply",
    });
  };

  const addQuickRepInput = (e) => {
    if (quickRepCount < 3) {
      setQuickRepCount(quickRepCount + 1);
    }
  };

  const handleSelect = (selectedValue) => {
    setSelectedOption(selectedValue);
    if (selectedValue.length === 0) {
      setCallToAction(false);
      setUrlAction(false);
      setUrlAction2(false);
      return;
    }

    let stringArray = JSON.stringify(selectedValue);

    let phoneTextIndex = stringArray.indexOf("callphone");
    let urlTextIndex = stringArray.indexOf("visitwebsite1");
    let urlTextIndex2 = stringArray.indexOf("visitwebsite2");

    if (phoneTextIndex === -1) {
      setCallToAction(false);
    }

    if (urlTextIndex === -1) {
      setUrlAction(false);
    }
    if (urlTextIndex2 === -1) {
      setUrlAction2(false);
    }
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      cursor: "pointer",
    }),
  };
  
  return (
    <>
      <div className="whatsapp-options">
        <label>
          <h4>
            Button<span className="text-muted">(optional)</span>
          </h4>
          <p>
            Create buttons that let customers respond to your message or take
            action.
          </p>
        </label>
        {props.cards > 1 ? null :
        <label className="switch">
          <input
            type="checkbox"
            defaultChecked={isChecked === true ? "checked" : null}
            onChange={buttonHnadlar}
          />
          <span className="slider round"></span>
        </label>}
      </div>
      {isChecked && (
        <>
          {props.cards > 1 ? null : 
          <div className="input-group drop-down-icons">
            <select
              disabled={paramid}
              onChange={buttonHandler}
              value={buttonType}
              className="form-control cursor-pointer"
            >
              <option value={"Select button type"}>Select button type</option>
              <option value={"call to action"}>Call To Action</option>
              <option value={"quick reply"}>Quick Reply</option>
            </select>
          </div>}

          {buttonType === "call to action" ? (

             <div className="call-action-area">
              {props.cards > 1 ? null : 
              <Select
                options={
                  specialUser === 26
                    ? [
                        { value: "callphone", label: "Call Phone number" },
                        { value: "visitwebsite1", label: "Visit website 1" },
                      ]
                    : [
                        { value: "callphone", label: "Call Phone number" },
                        { value: "visitwebsite1", label: "Visit website 1" },
                        { value: "visitwebsite2", label: "Visit website 2" },
                      ]
                }
                closeMenuOnSelect={false}
                value={selectedOption}
                hideSelectedOptions={false}
                // components={animatedComponents}
                isMulti
                components={{
                  Option,
                }}
                styles={customStyles}
                classNamePrefix="select"
                placeholder="Select type of action"
                onChange={handleSelect}
              />}
              {selectedOption.map((option) => {
                if (option.value === "callphone") {
                  return (
                    <>
                      <div className="choose-options">
                        <span className="">
                          <span className="p-2"> Call phone number </span>
                        </span>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <input
                            type="text"
                            className="form-control"
                            id="inputFirstName"
                            name="text"
                            value={callToAction.text}
                            disabled = {props.cards > 1 ? true : false}
                            onChange={changeHandler}
                            placeholder="Button Text"
                            maxLength={20}
                          />
                        </div>
                        <div className="col-md-4">
                          <input
                            type="text"
                            className="form-control"
                            name="countryCode"
                            value={callToAction.countryCode}
                            disabled = {props.cards > 1 ? true : false}
                            id="inputFirstName"
                            onChange={changeHandler}
                            placeholder="country code"
                          />
                        </div>
                        <div className="col-md-4">
                          <input
                            type="text"
                            className="form-control"
                            name="phone"
                            value={callToAction.phone}
                            disabled = {props.cards > 1 ? true : false}
                            id="inputFirstName"
                            autoComplete="off"
                            onChange={changeHandler}
                            placeholder="Mobile Number"
                            maxLength={15}
                          />
                        </div>
                      </div>
                    </>
                  );
                }
              })}

              {selectedOption.map((option) => {
                if (option.value === "visitwebsite1") {
                  return (
                    <>
                      <div className="choose-options">
                        <span className="">
                          <span className="p-2"> Visit website 1 </span>
                        </span>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <input
                            type="text"
                            className="form-control"
                            name="urltext"
                            value={urlAction.urltext}
                            onChange={urlHandler}
                            id="inputFirstName"
                            disabled = {props.cards > 1 ? true : false}
                            placeholder="Button Text"
                            maxLength={20}
                          />
                        </div>
                        <div className="col-md-4 drop-down-icons">
                          <select
                            value={urlAction.urlType}
                            onChange={urlHandler}
                            name="urlType"
                            className="form-control"
                            disabled = {props.cards > 1 ? true : false}
                          >
                            <option>URL Type</option>
                            <option>Static</option>
                            <option>Dynamic</option>
                          </select>
                        </div>
                        <div className="col-md-4">
                          <input
                            type="text"
                            onChange={urlHandler}
                            name="url"
                            value={urlAction.url}
                            disabled = {props.cards > 1 ? true : false}
                            className="form-control"
                            id="inputFirstName"
                            placeholder="www.example.com"
                          />
                        </div>
                      </div>
                    {urlAction.urlType==="Dynamic" && <div className="input-group mt-1 mr-sm-1">
                        <div className="input-group-text">{`{{1}}`}</div>
                        <input
                          type="text"
                          value={urlHandler2.sampleurl}
                          name="sampleurl"
                          required
                          onChange={urlHandler}
                          disabled = {props.cards > 1 ? true : false}
                          placeholder={`Sample Data of {{1}}`}
                          className="form-control"
                        />
                      </div>}
                    </>
                  );
                }

                if (option.value === "visitwebsite2") {
                  return (
                    <>
                      <div className="choose-options">
                        <span className="">
                          <span className="p-2"> Visit website 2 </span>
                        </span>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <input
                            type="text"
                            className="form-control"
                            name="urltext2"
                            value={urlAction2.urltext2}
                            onChange={urlHandler2}
                            disabled = {props.cards > 1 ? true : false}
                            id="inputFirstName"
                            placeholder="Button Text"
                            maxLength={20}
                          />
                        </div>
                        <div className="col-md-4 drop-down-icons">
                          <select
                            value={urlAction2.urlType}
                            onChange={urlHandler2}
                            name="urlType"
                            className="form-control"
                            disabled = {props.cards > 1 ? true : false}
                          >
                            <option>URL Type</option>
                            <option>Static</option>
                            <option>Dynamic</option>
                          </select>
                        </div>
                        <div className="col-md-4">
                          <input
                            type="text"
                            onChange={urlHandler2}
                            disabled = {props.cards > 1 ? true : false}
                            name="url"
                            value={urlAction2.url}
                            className="form-control"
                            id="inputFirstName"
                            placeholder="www.example.com"
                          />
                        </div>
                      </div>
                      {urlAction2.urlType==="Dynamic" && <div className="input-group mt-1 mr-sm-1">
                        <div className="input-group-text">{`{{1}}`}</div>
                        <input
                          type="text"
                          name="sampleurl"
                          value={urlHandler2.sampleurl}
                          required
                          onChange={urlHandler2}
                          disabled = {props.cards > 1 ? true : false}
                          placeholder={`Sample Data of {{1}}`}
                          className="form-control"
                        />
                      </div>}
                    </>
                  );
                }
              })}
            </div>
          ) : null}

          {buttonType === "quick reply" && (
            <div>
              <div className="call-action-area">
                {Array.from({ length: quickRepCount }, (_, index) => (
                  <>
                    <div
                      className="input-group mb-1 "
                      id={`button${index + 1}`}
                      key={index}
                    >
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                          {" "}
                          Button {index + 1}
                        </span>
                      </div>
                      <input
                        type="text"
                        onChange={quickLinkhandler}
                        name={`button${index + 1}`}
                        value={quickReply[`button${index + 1}`]}
                        maxLength={20}
                        disabled = {props.cards > 1 ? true : false}
                        className="form-control"
                        placeholder="Username"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                    {/* <div className="float-right mt-2" >Character:0/20
</div> */}
                    {/* <button onClick={delInput(index)}>delete</button> */}
                  </>
                ))}
                {props.cards > 1 ? null : 
                <button
                  className="btn btn-sm btn-primary mb-2 mt-4 float-right"
                  onClick={addQuickRepInput}
                >
                  Add Button
                </button>}
                {/* <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      {" "}
                      Button 2
                    </span>
                  </div>
                  <input
                    type="text"
                    onChange={quickLinkhandler}

                    name="button2"
                    defaultValue={quickReply.button2}
                    className="form-control"
                    placeholder="Username"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      {" "}
                      Button 3
                    </span>
                  </div>
                  <input
                    type="text"
                    onChange={quickLinkhandler}
                    name="button3"
                    defaultValue={quickReply.button3}
                    className="form-control"
                    placeholder="Username"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div> */}
              </div>
            </div>
          )}
          {/* <div className="text-right mt-2">
            <button className="btn btn-sm btn-success" onClick={setButtonValue}>Save</button>
          </div> */}
        </>
      )}
    </>
  );
}

export default CarouselButton;