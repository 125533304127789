import React, { useEffect, useState } from "react";
import DashboardLeftMenu from "../DashboardLeftMenu";
import DashboardHeader from "../../Header/DashboardHeader";
import DashboardFooter from "../../Footer/DashboardFooter";
import { userId, userToken } from "../../Utils/userAuth";
import Axios from "../../../axios/Axios";
import { NODE_HOST_URL } from "../api/Api";
import Spining from "../../UI/Spining/Spining";

const TwoFactorAuth = () => {
  const [status, setStatus] = useState(false);
  const [authType, setAuthType] = useState("");
  const [pin, setPin] = useState("");
  const [reEnterPin, setReEnterPin] = useState("");
  const [error, setError] = useState({
    success: false,
    bgColor: "",
    errorMessage: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    RetrieveData();
  }, []);

  const handleStatus = (e) => {
    const value = e.target.checked;
    setStatus(value ? true : false);
  };

  const RetrieveData = async () => {
    setLoading(true);
    const paramData = {
      user_id: userId,
      token: userToken,
      method: "retrieve_2fa_setting",
    };
    try {
      const { data } = await Axios.post(
        `${NODE_HOST_URL}/user_setting`,
        paramData
      );
      if (data.success) {
        const value = Number(data.data.status) === 1 ? true : false;
        setStatus(value);
        setAuthType(data.data.auth_type);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const SaveData = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (pin === reEnterPin) {
      const paramSaveData = {
        method: "2fa_setting",
        token: userToken,
        user_id: userId,
        status: status ? "1" : "0",
        auth_type: authType,
        pin,
      };
      try {
        const { data } = await Axios.post(
          `${NODE_HOST_URL}/user_setting`,
          paramSaveData
        );
        if (data.success) {
          setError({
            success: true,
            bgColor: "alert alert-success",
            errorMessage: data.message,
          });
          setTimeout(() => {
            setError({ success: false });
          }, 3000);
        } else {
          setError({
            success: true,
            bgColor: "alert alert-danger",
            errorMessage: data.message,
          });
        }
      } catch (error) {
        setError({
          success: true,
          bgColor: "alert alert-danger",
          errorMessage: error.message,
        });
      }
    } else {
      setError({
        success: true,
        bgColor: "alert alert-danger",
        errorMessage: "Pin are not equal to ReEnter Pin",
      });
    }
    setLoading(false);
  };

  return (
    <div className="wrapper">
      <DashboardLeftMenu />
      <div className="main">
        <DashboardHeader />
        {loading && <Spining />}
        <main className="content">
          <div className="container-fluid p-0">
            <div className="row mb-2 mb-xl-3">
              <div className="col-auto d-none d-sm-block">
                <h3>Two Factor Authentication</h3>
              </div>
            </div>
            <div className="row">
              {error.success ? (
                <div className={error.bgColor} role="alert">
                  <div className="">{error.errorMessage}</div>

                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
              ) : null}

              <div className="card">
                <div className="card-header">
                  <h5 className="card-title mb-0">Two Factor Authentication</h5>
                </div>
                <div className="card-body">
                  <div className="d-flex flex-wrap ">
                    <div className="col-12 col-md-auto">
                      <label className="">Status</label>
                    </div>
                    <div className="col-12 col-md-auto">
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={status}
                          onChange={handleStatus}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                  {status && (
                    <form onSubmit={SaveData}>
                      <div className="row">
                        <div className="d-flex flex-wrap mb-3">
                          <div className="col-6 col-md-6">
                            <label>
                              Select Authentication Type
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              value={authType}
                              onChange={(e) => setAuthType(e.target.value)}
                              required
                            >
                              <option value="">
                                Select Authentication Type
                              </option>
                              <option value="pin">Pin</option>
                              <option value="otp">OTP</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      {authType === "pin" && (
                        <div>
                          <div className="row">
                            <div className="d-flex flex-wrap ">
                              <div className="col-6 col-md-6">
                                <label>
                                  Pin
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <div className="mb-3">
                                  <input
                                    type="password"
                                    value={pin}
                                    onChange={(e) => setPin(e.target.value)}
                                    placeholder="Enter Pin"
                                    className="form-control"
                                    id="inputPasswordNew"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="d-flex flex-wrap ">
                              <div className="col-6 col-md-6">
                                <label>
                                  Re-enter Enter Pin
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <div className="mb-3">
                                  <input
                                    type="password"
                                    value={reEnterPin}
                                    onChange={(e) =>
                                      setReEnterPin(e.target.value)
                                    }
                                    placeholder="Re-enter Enter Pin"
                                    className="form-control"
                                    id="inputPasswordNew2"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <button type="submit" className="btn btn-sm btn-success">
                        Save changes
                      </button>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
        <DashboardFooter />
      </div>
    </div>
  );
};

export default TwoFactorAuth;
