import React from 'react';
import styles from './rcsPreview.module.css';
import { whatsapp_brand } from '../../../../images/WhatsAppImages';
import { MdVerifiedUser, MdOutlineEmojiEmotions } from "react-icons/md";
import { IoSearch } from "react-icons/io5";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CiCirclePlus } from "react-icons/ci";
import { FaImages, FaMicrophone } from "react-icons/fa";
import { formatDateToCustomFormat } from '../../../Utils/Utils';
import { Carousel } from 'react-responsive-carousel';
import { CiGlobe } from "react-icons/ci";
import { FaPhoneAlt } from "react-icons/fa";
const CustomPrevArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    style={{
      position: 'absolute',
      top: '50%',
      left: '-1%',
      transform: 'translateY(-50%)',
      backgroundColor: 'transparent',
      border: 'none',
      color: 'black',
      fontSize: '30px',
      zIndex: "1"
    }}
  >
    &#10094; {/* Unicode for left arrow */}
  </button>
);

const CustomNextArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    style={{
      position: 'absolute',
      top: '50%',
      right: '-1%',
      transform: 'translateY(-50%)',
      backgroundColor: 'transparent',
      border: 'none',
      color: 'black',
      fontSize: '30px',
      zIndex: "1"
    }}
  >
    &#10095; {/* Unicode for right arrow */}
  </button>
);

const RcsPreview = ({ temp_type, rcsContent, previews, suggestionButtons, cardDataArray, cardDynamicDataArray }) => {

  const renderButtons = (buttons) => {
    return buttons.map((button, index) => (
      <div
        key={index}
        className={`${styles.button} ${styles.suggestionButton}`}
      >
        {button.type_of_action === "reply" && (
          <span>{button.suggestion_text}</span>
        )}
        {button.type_of_action === "url_action" && (
          <a target='_blank' href={`${button.url}`} >
            <span style={{ color: "blue" }} className='d-flex justify-content-center align-items-center'> <CiGlobe />
              {button.suggestion_text}
            </span></a>
        )}
        {button.type_of_action === "dialer_action" && (
            <a href={`tel:${button.phone_number}`}>
            <span style={{ color: "blue" }} className='d-flex justify-content-center align-items-center'><FaPhoneAlt />{button.phone_number}</span>
            </a>             

        )}
      </div>
    ));
  };


  const renderRichCards = (cardDataArray) => {
    if (!Array.isArray(cardDataArray) || cardDataArray.length === 0) return null;
    return (
      <div className={styles.richCardContainer}>
        {cardDataArray.map((cardData, index) => {
          const { media, card_title, card_description, } = cardData;
          const { url, file_type } = media;
          return (
            <div
              key={index}
              className={styles.richCard}
            >
              {url && (
                <div className={styles.media}>
                  {file_type === "image" ? (
                    <img src={url} alt={`Card Media ${index}`} />
                  ) : (
                    <video src={url} controls />
                  )}
                </div>
              )}
              <div className={styles.cardContent}>
                <h5 style={{ fontSize: ".8rem", fontFamily: "sans-serif" }}>{card_title || "No Title"}</h5>
                <span style={{ fontSize: ".7rem" }}>{card_description || "No Description"}</span>
              </div>
              {cardData.temp_suggestion && cardData.temp_suggestion.length > 0 &&
                <div className={styles.suggestions}>
                  {renderButtons(cardData.temp_suggestion)}
                </div>
              }
            </div>
          );
        })}
      </div>
    );
  };

  const safeCardDataArray = Array.isArray(cardDataArray) ? cardDataArray : [];
  const safeCardDynamicDataArray = Array.isArray(cardDynamicDataArray) ? cardDynamicDataArray : [];

  const renderCarousel = (cardDataArray) => (
    <Carousel
      autoPlay={false}
      infiniteLoop={true}
      showThumbs={false}
      showArrows={true}
      showStatus={false}
      interval={5000}
    >
      {cardDataArray.map((cardData, index) => {
        const { media, card_title, card_description } = cardData;
        const { url, file_type } = media;
        return (
          <div key={index} className={styles.richCard} style={{ backgroundColor: "#e3f0e7", borderRadius: "4px", padding: ".2rem", marginBottom: "1.5rem" }}>
            {url && (
              <div className={styles.media}>
                {file_type === "image" ? (
                  <img src={url} alt={`Card Media ${index}`} />
                ) : (
                  <video src={url} controls />
                )}
              </div>
            )}
            <div className={styles.cardContent}>
              <h4 style={{ fontSize: ".8rem", fontFamily: "sans-serif" }}>{card_title || "No Title"}</h4>
              <p style={{ fontSize: ".7rem" }}>{card_description || "No Description"}</p>
            </div>
            {cardData.temp_suggestion && cardData.temp_suggestion.length > 0 &&
              <div className={styles.suggestions}>
                {renderButtons(cardData.temp_suggestion)}
              </div>
            }
          </div>
        );
      })}
    </Carousel>
  );
  if (previews && previews.length > 0) {

    return (
      <Carousel
        autoPlay={false}
        infiniteLoop={true}
        showThumbs={false}   // Hide thumbnail images
        showArrows={true}    // Enable arrows
        showStatus={false}   // Hide status indicators
        interval={5000}      // Set the autoplay interval in milliseconds
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && <CustomPrevArrow onClick={onClickHandler} />
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && <CustomNextArrow onClick={onClickHandler} />
        }
      >

        {previews.map((preview, index) => (
          <div key={index} className={`${styles.container}`}>
            <div className={styles.deviceFrame}>
              <div className={styles.rcsHeader}>
                <div className='d-flex justify-content-center align-items-center'>
                  <img src={whatsapp_brand} alt="" className={styles.brandImage} />
                  <span className='ml-2'>RCS</span>
                </div>
                <div className='d-flex justify-content-evenly align-items-center w-25'>
                  <MdVerifiedUser />
                  <IoSearch />
                  <BsThreeDotsVertical />
                </div>
              </div>
              <div className={styles.description}>
                <img src={whatsapp_brand} alt="logo" className={styles.detailLogo} />
                <div className="description w-75 text-center">Welcome! We're here to help. How can we assist you today?</div>
              </div>

              <div className={styles.deviceContent}>
                <div className="d-flex justify-content-center w-100" style={{ fontSize: '.6rem' }}>
                  {formatDateToCustomFormat(new Date())}
                </div>
                <div className={`${styles.message} ${styles.user}`}>
                  {temp_type === "text" && <div className='d-flex flex-column align-items-end justify-content-center w-75' style={{ backgroundColor: "#e3f0e7", borderRadius: "4px" }}>
                    <div className={styles.bubble}>{preview}</div>
                    <div className={styles.suggestions}>
                      {renderButtons(preview.suggestionButtons || [])}
                    </div>
                  </div>}
                  {temp_type === "richcardstandalone" && (renderRichCards(preview) || renderRichCards(cardDataArray))}
                  {temp_type === "richcardcarousel" &&
                    <div style={{ width: '80%', margin: '0 auto' }}>
                      {renderCarousel(preview) || renderCarousel(cardDataArray)}
                    </div>
                  }
                </div>
              </div>
              <div className={styles.footer}>
                <div className={styles.addOptions}>
                  <CiCirclePlus />
                  <FaImages />
                </div>
                <div className={styles.input}>
                  <FaMicrophone className='mr-2' />
                  <MdOutlineEmojiEmotions />
                </div>
              </div>
            </div>
          </div>

        ))}
      </Carousel>

    );

  }
  return (
    <div className={`${styles.container}`}>
      <div className={styles.deviceFrame}>
        <div className={styles.rcsHeader}>
          <div className='d-flex justify-content-center align-items-center '>
            <img src={whatsapp_brand} alt="" className={styles.brandImage} />
            <span className='ml-2'>RCS</span>
          </div>
          <div className='d-flex justify-content-evenly align-items-center w-25'>
            <MdVerifiedUser />
            <IoSearch />
            <BsThreeDotsVertical />
          </div>
        </div>
        <div className={styles.description}>
          <img src={whatsapp_brand} alt="logo" className={styles.detailLogo} />
          <div className="description w-75 text-center">Welcome! We're here to help. How can we assist you today?</div>
        </div>

        <div className={styles.deviceContent}>
          <div className="d-flex justify-content-center w-100" style={{ fontSize: ".6rem" }}>{formatDateToCustomFormat(new Date())}</div>
          <div className={`${styles.message} ${styles.user}`}>
            {temp_type === "text" && <div className='d-flex flex-column align-items-end justify-content-center w-75' style={{ backgroundColor: "#e3f0e7", borderRadius: "4px" }}>
              <div className={styles.bubble}>{rcsContent}</div>
              <div className={styles.suggestions}>
                {renderButtons(suggestionButtons || [])}
              </div>
            </div>}
            {temp_type === "richcardstandalone" && (safeCardDynamicDataArray[0]?.card_title ? renderRichCards(safeCardDynamicDataArray) : renderRichCards(safeCardDataArray))}
            {temp_type === "richcardcarousel" &&
              <div style={{ width: '80%', margin: '0 auto' }}>
                {(safeCardDynamicDataArray[0]?.card_title ? renderCarousel(safeCardDynamicDataArray) : renderCarousel(safeCardDataArray))}
              </div>
            }
          </div>

        </div>
        <div className={styles.footer}>
          <div className={styles.addOptions}>
            <CiCirclePlus />
            <FaImages />

          </div>
          <div className={styles.input}>
            <FaMicrophone className='mr-2' />
            <MdOutlineEmojiEmotions />
          </div>
        </div>

      </div>
    </div>
  );
};

export default RcsPreview;
