import React, { Component } from "react";
import AdminLayout from "../../UI/Layout/AdminLayout";
import Title from "../../UI/Text/Title";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";

import Axios from "../../../axios/Axios";
import { dateFormate } from "../../Utils/Utils";
import Spining from "../../UI/Spining/Spining";
import AdvanceWhatsappTable from "../../UI/Table/AdvanceWhatsappTable";
import { ADMIN_WHATSAPP_API_LIST } from "../../UI/Table/ColumnConst";
import { userloginDetails } from "../../Utils/AdminAuth";
import { HOST_URL } from "../../Dashboard/api/Api";

class WhatsappSummaryApi extends Component {
  constructor() {
    const lStorage = userloginDetails();
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const adminType = lStorage.user.isAdmin;
    var today = new Date(),
      date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        ("0" + today.getDate()).slice(-2);
    super();

    this.state = {
      user_id: userId,
      token: userToken,
      adminType: adminType,
      dateFrom: date,
      dateTo: date,
      summaryData: [],
      serchData: "",
      userCurrentStatus: "",
      setIsLoading: false,
      ErrorResponse: "",
    };
    this.dateFrom = this.dateFrom.bind(this);
    this.dateTo = this.dateTo.bind(this);
  }

  searchSummary() {
    this.setState({
      setIsLoading: true,
      summaryData: [],
    });
    const method = [
      {
        name: "Whatsapp Api",
        method: "summary_admin",
      },
    ];

    method.forEach((item) => {
      let data = {
        user_id: this.state.user_id,
        method: item.method,
        token: this.state.token,
        date_from: this.state.dateFrom,
        date_to: this.state.dateTo,
      };
      console.log("data", data);
      Axios.post(`${HOST_URL}/whatsapp_report.php`, data).then(
        (res) => {
          // this.setState({ summaryData: res.data.message, setIsLoading: false });
          this.setState({
            summaryData: [
              ...this.state.summaryData,
              Object.assign(res.data.message, { name: item.name }),
            ],
            setIsLoading: false,
          });
        }
      );
    });
  }

  dateFrom(date) {
    let selectedDate = dateFormate(date);
    this.setState({
      dateFrom: selectedDate,
    });
  }

  dateTo(date) {
    let selectedDate = dateFormate(date);
    this.setState({
      dateTo: selectedDate,
    });
  }

  render() {
    console.log("summaryData", this.state.summaryData);
    return (
      <AdminLayout>
        <Title isButton={false}>WhatsApp API </Title>
        <div className="row">
          <div className="">
            <div className="card">
              {this.state.successMessage ? (
                <div className={this.state.bgColor} role="alert">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div className="">{this.state.errorMessage}</div>
                </div>
              ) : null}

              {this.state.setIsLoading && <Spining />}

              <div className="card-body">
                <div className="filter-option">
                  <h6 className="card-subtitle text-muted">
                    Select WhatsApp API Data Range{" "}
                  </h6>
                  <div className="row">
                    <div className="col-md-5">
                      <div className="input-group mb-2 mr-sm-2">
                        <div className="input-group-text">
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </div>
                        <DatePicker
                          className="form-control btn-block"
                          dateFormat="yyyy/MM/dd"
                          value={this.state.dateFrom}
                          onChange={this.dateFrom}
                          selected={this.state.SelecteddateFrom}
                          placeholderText="From Date"
                        />
                      </div>
                      
                    </div>
                    <div className="col-md-5">
                      <div className="input-group mb-2 mr-sm-2">
                        <div className="input-group-text">
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </div>
                        <DatePicker
                          className="form-control btn-block"
                          dateFormat="yyyy/MM/dd"
                          value={this.state.dateTo}
                          onChange={this.dateTo}
                          selected={this.state.SelecteddateTo}
                          placeholderText="Date To"
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="input-group btn-block mb-2 mr-sm-2">
                        <button
                          onClick={() => this.searchSummary()}
                          className="btn mt-1 btn-blue btn-sm  btn-block"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {this.state.summaryData ? (
                  <div className="summary-data">
                    <div className="row">
                      {
                        <div className="col-md-12">
                          <AdvanceWhatsappTable
                            tableName={"Conversation Summary"}
                            filter={true}
                            tableCell={ADMIN_WHATSAPP_API_LIST}
                            tableData={this.state.summaryData[0]}
                            isLoading={this.state.setIsLoading}
                            isError={this.state.ErrorResponse}
                          />
                        </div>
                      }
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    );
  }
}
export default WhatsappSummaryApi;
