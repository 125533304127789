import React from "react";
import "../../../../style/components/whatsAppPreview.css";
import { bg_whatsapp } from "../../../../images/WhatsAppImages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft, faForward, faBackward, faGlobe, faPhone, faReply } from "@fortawesome/free-solid-svg-icons";

function PreviewRcsTemplate({ formData, setFormData }) {

    const next = () => {
        setFormData((prevData) => ({
            ...prevData, activeTabIndex: formData.activeTabIndex === formData.carousels.length - 1 ? 0 : formData.activeTabIndex + 1
        }))
    }

    const preView = () => {
        setFormData((prevData) => ({
            ...prevData, activeTabIndex: formData.activeTabIndex === 0 ? formData.carousels.length - 1 : formData.activeTabIndex - 1
        }))
    }

    return (
        <div className="card ">
            <div className="card-body">
                <div className="whatsapp">
                    <ul>
                        <li><strong> Template name: </strong> {formData.templateName}</li>
                        <li><strong> Sender ID : </strong> {formData.senderId} </li>
                        <li> <strong> Bot Message Type: </strong> {formData.botMessageType} </li>
                        <li><strong> Type: </strong> {formData.templateType} </li>
                    </ul>
                    <div
                        style={{ backgroundImage: `url(${bg_whatsapp})` }}
                        className="whatsapp-review whatsapp-bg"
                    >
                        <div>
                            <div className="text-section">
                                <div className="whats-app-header">
                                    <div className="mt-2 d-flex">
                                        <FontAwesomeIcon color="#fff" size="10" icon={faArrowAltCircleLeft} />   <p>RCS</p>
                                    </div>
                                </div>

                                {formData.templateType === 'text' &&
                                    <div className="body-message">
                                        <p className="">
                                            {formData.content}
                                        </p>
                                    </div>
                                }

                                {formData.templateType === 'richcardcarousel' &&
                                    <div className="body-message d-flex align-items-center justify-content-between">
                                        <div className="preView">
                                            <FontAwesomeIcon onClick={preView} icon={faBackward} />
                                        </div>
                                        {
                                            formData.carousels.map((item, i) => {
                                                return (
                                                    <div key={i} className={`body-message ${formData.activeTabIndex === i ? null : "hide-current"}`} >
                                                        {item?.media?.file_type === "image" ?
                                                            <img className="img-fluid" src={item.media.url} />
                                                            : item.media?.file_type === "video" ? <video width="100%" controls>
                                                                <source src={item.media.url} type="video/mp4" />
                                                            </video>
                                                                :
                                                                null}
                                                        <h6 className="mt-2 rcs-title-preview">
                                                            {item.card_title}
                                                        </h6>
                                                        <small>{item.card_description}</small>

                                                        <>
                                                            {item.temp_suggestion.map((item, index) => (
                                                                item.type_of_action === 'reply' ?
                                                                    <div key={index} className="button-text border">
                                                                        <a href={`tel:+${item.countryCode}${item.phone}`}>
                                                                            <p>
                                                                                <FontAwesomeIcon icon={faReply} /> {item.suggestion_postback}
                                                                            </p>
                                                                        </a>
                                                                    </div>
                                                                    :
                                                                    item.type_of_action === 'url_action' ?
                                                                        <div key={index} className="button-text border">
                                                                            <a href={`tel:+${item.countryCode}${item.phone}`}>
                                                                                <p>
                                                                                    <FontAwesomeIcon icon={faGlobe} /> {item.url}
                                                                                </p>
                                                                            </a>
                                                                        </div>
                                                                        :
                                                                        item.type_of_action === 'dialer_action' &&
                                                                        <div key={index} className="button-text border">
                                                                            <a href={`tel:+${item.countryCode}${item.phone}`}>
                                                                                <p>
                                                                                    <FontAwesomeIcon icon={faPhone} /> {item.phone_number}
                                                                                </p>
                                                                            </a>
                                                                        </div>
                                                            ))}
                                                        </>

                                                    </div>
                                                )
                                            })
                                        }

                                        <div className="preView">
                                            <FontAwesomeIcon onClick={next} icon={faForward} />
                                        </div>
                                    </div>
                                }

                                {formData.templateType === 'richcardstandalone' &&
                                    <div className="body-message">
                                        {formData?.standaloneCard.media.file_type === "image" ?
                                            <img className="img-fluid" src={formData?.standaloneCard.media.url} />
                                            : formData?.standaloneCard.media.file_type === "video" ?
                                                <video width="100%" controls>
                                                    <source src={formData?.standaloneCard.media.url} type="video/mp4" />
                                                </video>
                                                :
                                                null
                                        }

                                        <h6 className="mt-2 rcs-title-preview">
                                            {formData?.standaloneCard.card_title}
                                        </h6>
                                        <small>{formData?.standaloneCard.card_description}</small>

                                        <>
                                            {formData?.standaloneCard?.temp_suggestion.map((item, index) => (
                                                item.type_of_action === 'reply' ?
                                                    <div key={index} className="button-text border">
                                                        <a href={`tel:+${item.countryCode}${item.phone}`}>
                                                            <p>
                                                                <FontAwesomeIcon icon={faReply} /> {item.suggestion_postback}
                                                            </p>
                                                        </a>
                                                    </div>
                                                    :
                                                    item.type_of_action === 'url_action' ?
                                                        <div key={index} className="button-text border">
                                                            <a href={`tel:+${item.countryCode}${item.phone}`}>
                                                                <p>
                                                                    <FontAwesomeIcon icon={faGlobe} /> {item.url}
                                                                </p>
                                                            </a>
                                                        </div>
                                                        :
                                                        item.type_of_action === 'dialer_action' &&
                                                        <div key={index} className="button-text border">
                                                            <a href={`tel:+${item.countryCode}${item.phone}`}>
                                                                <p>
                                                                    <FontAwesomeIcon icon={faPhone} /> {item.phone_number}
                                                                </p>
                                                            </a>
                                                        </div>
                                            ))}
                                        </>
                                    </div>
                                }

                                {formData.suggestionsButton && (
                                    <>
                                        {formData.suggestionsButton.map((item, index) => (
                                            item.type_of_action === 'reply' ?
                                                <div key={index} className="button-text">
                                                    <a href={`tel:+${item.countryCode}${item.phone}`}>
                                                        <p>
                                                            <FontAwesomeIcon icon={faReply} /> {item.suggestion_postback}
                                                        </p>
                                                    </a>
                                                </div>
                                                :
                                                item.type_of_action === 'url_action' ?
                                                    <div key={index} className="button-text">
                                                        <a href={`tel:+${item.countryCode}${item.phone}`}>
                                                            <p>
                                                                <FontAwesomeIcon icon={faGlobe} /> {item.url}
                                                            </p>
                                                        </a>
                                                    </div>
                                                    :
                                                    item.type_of_action === 'dialer_action' &&
                                                    <div key={index} className="button-text">
                                                        <a href={`tel:+${item.countryCode}${item.phone}`}>
                                                            <p>
                                                                <FontAwesomeIcon icon={faPhone} /> {item.phone_number}
                                                            </p>
                                                        </a>
                                                    </div>
                                        ))}
                                    </>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PreviewRcsTemplate;
