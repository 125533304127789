import React, { useEffect, useState } from "react";
import AdvanceDataTable from "../../UI/Table/AdvanceDataTable";
import { ADMIN_PINNACLE_TOKEN, ADMIN_WHATSAPP_SETUP } from "../../UI/Table/ColumnConst";
import AdminLayout from "../../UI/Layout/AdminLayout";
import Title from "../../UI/Text/Title";
import Spining from "../../UI/Spining/Spining";
import { userloginDetails } from "../../Utils/AdminAuth";
import Axios from "axios";
import { HOST_URL, NODE_HOST_URL } from "../../Dashboard/api/Api";

const PinnacleEmbededToken = () => {
  const lStorage = userloginDetails();
  const userId = lStorage.user.userId;
  const userToken = lStorage.token;
  const adminType = lStorage.user.isAdmin;
  const [alert, setAlert] = useState({
    status: false,
    bgColor: "",
    errorMessage: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [resData, setResData] = useState([]);
  useEffect(()=>{
    handleSearch();
  },[])
  
  const handleSearch = async() => {
    setIsLoading(true);
 
    try {
      const dataforsearch = {
        user_id: userId,
        token: userToken,
        method: "retrieve_token_pinnacle",
       
      };
      const res = await Axios.post(`${HOST_URL}/whatsapp_setup.php`, dataforsearch);
      console.log(res.data.data)
      if(res.data.success===true){       
        if(res.data.data.length>0){
        setResData(res.data.data)
      }else{
        setResData([])
      }
      }else{
        setAlert({
            status: true,
            bgColor: "danger",
            errorMessage: res.data.message,
          });
        }
      
      
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };
  
  
  return (
    <div>
      <AdminLayout>
        <Title isButton={false}> Pinnacle Embeded Token </Title>

        <div className="row">
          <div className="">
            <div className="card">
              {alert.status === true ? (
                <div className={alert.bgColor} role="alert">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div className="">{alert.errorMessage}</div>
                </div>
              ) : null}

              {isLoading && <Spining />}

              <div className="card-body">
                <div className="card">
                  <AdvanceDataTable
                    tableName={""}
                    tableCell={ADMIN_PINNACLE_TOKEN }
                    tableData={resData}
                    isLoading={isLoading}
                    isError={alert.errorMessage}
                    filterstatus={true}
                    pagination={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    </div>
  );
};

export default PinnacleEmbededToken;
