import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import AdminHeader from '../Header/AdminHeader';
import AdminFooter from '../Footer/AdminFooter';
import AdminLeftMenu from '../Navbar/AdminLeftMenu';
import EmptyImg from "../Dashboard/assets/img/create-project.png";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { userloginDetails } from '../Utils/AdminAuth';
import { HOST_URL, NODE_HOST_URL } from '../Dashboard/api/Api';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from '../../axios/Axios';
import dayjs from "dayjs";
import Select from "react-select";
import Spining from "../UI/Spining/Spining";

const ResellerPriceDetails = (props) => {
    const lStorage = userloginDetails();
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const empType = lStorage.user.isAdmin;
    const { id, userType } = useParams();
    const param = useParams();

    const [email, setEmail] = useState('');
    const [rcsPrice, setRcsPrice] = useState(false)
    const [openOtherPrice, setopenOtherPrice] = useState(true)
    const [rcsPriceList, setRcsPriceList] = useState([])
    const [selectUserPriceDetails, setSelectedUSerPriceDetails] = useState([])
    const [_id, setID] = useState('')
    const [rcsID, setRcsID] = useState('')
    const [isChecked, setisChecked] = useState(false);
    const [isOtherPriceChecked, setIsOtherPriceChecked] = useState(false)
    const [price_update, setPriceUpdate] = useState('');
    const [voiceCost, setVoiceCost] = useState('');
    const [voicePulse, setVoicePulse] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [voicecountryCode, setVoiceCountryCode] = useState('');
    const [smscountryCode, setSmsCountryCode] = useState('')
    const [smsCost, setSmsCost] = useState('');
    const [wp_conv_cost, setWp_Conv_Cost] = useState('');
    const [wp_sending_cost, setWpSendingCost] = useState('');
    const [utility, setUtility] = useState('');
    const [authentication, setAuthentication] = useState('');
    const [rcscountryCode, setRcsCountryCode] = useState('');
    const [botid, setBotId] = useState('');
    const [margin, setMargin] = useState('');
    const [basicrcscost, setBasicRcsCost] = useState('');
    const [a2p_single_rcs_cost, seta2pSingleRcsCost] = useState('');
    const [a2p_conversation_cost, seta2pConversationCost] = useState('');
    const [p2a_conversation_cost, setP2aConversationCost] = useState('');
    const [status, setStatus] = useState(1);
    const [frozen, setFrozen] = useState(0);
    const [OtherPriceconfirmCode, setOtherPriceconfirmCode] = useState('')
    const [error, setError] = useState({
        errorStatus: false,
        bgColor: '',
        errorMessage: ''
    });
    const [userBotID, setUserBotID] = useState([])
    const [emailCost, setEmailCost] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [otherPriceShowModal, setOtherPriceShowModal] = useState(false);
    const [confirmCode, setConfirmCode] = useState('');
    const [bulkMargin, setBulkMargin] = useState('');
    const [OtherPricebulkMargin, setOtherPricebulkMargin] = useState('')
    const [channel, setChannel] = useState('whatsapp');
    const [rcsSearch, setRcsSearch] = useState('')
    const [entryType, setEntryType] = useState('single');
    const [popupError, setPopupError] = useState({
        popupStatus: false,
        popupColor: "",
        popupMessage: "",
    })
    const [countryList, setCountryList] = useState([]);
    const [searchList, setSearchList] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(50);
    const [whatsappStatus, setWhatsappStatus] = useState('');
    const [whatsappFrozen, setWhatsappFrozen] = useState('');
    const [smsStatus, setSmsStatus] = useState(1);
    const [smsFrozen, setSmsFrozen] = useState(0);
    const [voiceStatus, setVoiceStatus] = useState('');
    const [voiceFrozen, setVoiceFrozen] = useState('');
    const [loading, setLoading] = useState(false)
    const [marginType, setMarginType] = useState("")

    const customStyles = {
        control: (provided) => ({
            ...provided,
            cursor: "pointer",
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        option: (style) => ({
            ...style,
            fontSize: "12px",
        }),
    };

    useEffect(() => {
        getUserDetails();
        getBotid();
        getDetailList();
        getRcsData();
        CountryList();
    }, []);

    const handleEntryChange = (e) => {
        const { value } = e.target;
        if (entryType !== value) {
            setEntryType(value); // Update the entry type
        } else {
            setEntryType(''); // Deselect if already selected
        }
    };

    const getDetailList = async () => {
        try {
            let { data } = await Axios.post(`${HOST_URL}/admin_user_list.php?user_id=${userId}&method=retrieve&type=pricelist&ret_user_id=${id}&token=${userToken}`)
            if (data.success) {
                setSelectedUSerPriceDetails(data.data)
                setEmailCost(data.email_cost)
                // console.log(data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getUserDetails = async () => {
        try {
            const response = await fetch(
                `${HOST_URL}/admin_user_list.php?user_id=${userId}&method=retrieve&type=userdetails&ret_user_id=${id}&token=${userToken}`
            );

            const result = await response.json();

            if (result.success === true) {
                setEmail(result.data[0].email);
            } else {
                setError({
                    errorStatus: true,
                    bgColor: 'lert alert-danger alert-dismissible',
                    errorMessage: result.message
                });
            }
        } catch (error) {
            console.error('Error fetching user details:', error);
            setError({
                errorStatus: true,
                bgColor: 'lert alert-danger alert-dismissible',
                errorMessage: `An error occurred while fetching user details.`
            });
        }
    };

    const ChangeRcsPrice = () => {
        setRcsPrice(true);
        setopenOtherPrice(false)
    }

    const OtherPrice = () => {
        setRcsPrice(false);
        setopenOtherPrice(!openOtherPrice);
    }

    const getBotid = async () => {
        const senderParamData = {
            user_id: userId,
            token: userToken,
            method: "userwise_bot",
            client_id: id
        }
        try {
            let { data } = await Axios.post(`${NODE_HOST_URL}/admin/rcs`, senderParamData);
            if (data.success) {
                setUserBotID(data.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const AddWhatsappPrice = async () => {
        setLoading(true)
        document.querySelector("body").scrollTo(0, 0);
        let paramData = {
            user_id: userId,
            token: userToken,
            retr_user_id: id,
            country_code: countryCode.value,
            wp_sending_cost: wp_sending_cost,
            wp_conv_cost: wp_conv_cost,
            wp_auth_cost: authentication,
            wp_util_cost: utility,
            method: "add_whatsapp_price",
            is_active: smsStatus === 1 ? true : false,
            is_frozen: smsFrozen === 1 ? true : false
        }
        // console.log(paramData)
        try {
            let { data } = await Axios.post(`${NODE_HOST_URL}/user`, paramData)
            if (data.success) {
                setError({
                    errorStatus: true,
                    bgColor: 'alert alert-success alert-dismissible',
                    errorMessage: data.message
                });
                setCountryCode('');
                setWpSendingCost('')
                setWp_Conv_Cost('');
                setAuthentication('');
                setUtility('');
                setTimeout(() => {
                    setError({ errorStatus: false })
                }, 3000);
                getDetailList();
            }
            else {
                setError({
                    errorStatus: true,
                    bgColor: 'alert alert-danger alert-dismissible',
                    errorMessage: data.message
                });
                setCountryCode('');
                setWpSendingCost('')
                setWp_Conv_Cost('');
                setAuthentication('');
                setUtility('');
            }
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    const AddSmsPrice = async () => {
        setLoading(true)
        document.querySelector("body").scrollTo(0, 0);
        let paramData = {
            user_id: userId,
            token: userToken,
            retr_user_id: id,
            country_code: smscountryCode.value,
            sms_cost: smsCost,
            method: "add_sms_price",
            is_active: smsStatus === 1 ? true : false,
            is_frozen: smsFrozen === 1 ? true : false
        }
        // console.log(paramData)
        try {
            let { data } = await Axios.post(`${NODE_HOST_URL}/user`, paramData)
            if (data.success) {
                setError({
                    errorStatus: true,
                    bgColor: 'alert alert-success alert-dismissible',
                    errorMessage: data.message
                });
                setSmsCountryCode('');
                setSmsCost('')
                setID('');
                setTimeout(() => {
                    setError({ errorStatus: false })
                }, 3000);
                getDetailList();
            }
            else {
                setError({
                    errorStatus: true,
                    bgColor: 'alert alert-danger alert-dismissible',
                    errorMessage: data.message
                });
                setSmsCountryCode('');
                setSmsCost('')
            }
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }
    // console.log(smsFrozen)

    const AddVoicePrice = async () => {
        setLoading(true)
        document.querySelector("body").scrollTo(0, 0);
        let paramData = {
            user_id: userId,
            token: userToken,
            retr_user_id: id,
            country_code: voicecountryCode.value,
            voice_cost: voiceCost,
            voice_pulse: voicePulse,
            method: "add_voice_price",
            is_active: smsStatus === 1 ? true : false,
            is_frozen: smsFrozen === 1 ? true : false
        }
        // console.log(paramData)
        try {
            let { data } = await Axios.post(`${NODE_HOST_URL}/user`, paramData)
            if (data.success) {
                setError({
                    errorStatus: true,
                    bgColor: 'alert alert-success alert-dismissible',
                    errorMessage: data.message
                });
                setVoiceCountryCode('');
                setVoiceCost('');
                setVoicePulse('');
                setTimeout(() => {
                    setError({ errorStatus: false })
                }, 3000);
                getDetailList();
            }
            else {
                setError({
                    errorStatus: true,
                    bgColor: 'alert alert-danger alert-dismissible',
                    errorMessage: data.message
                });
                setVoiceCountryCode('');
                setVoiceCost('');
                setVoicePulse('');
            }
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }
    const updatePrice = (
        id,
        countrycode,
        smscost,
        voiceCost,
        voicepulse,
        wp_conv_cost,
        wp_sending_cost,
        wp_auth_cost,
        wp_util_cost,
        status,
        frozen
    ) => {
        document.querySelector("body").scrollTo(0, 0);

        let data = countryList.find(x => x.value == countrycode);

        setCountryCode(data);
        setSmsCountryCode(data);
        setVoiceCountryCode(data);
        setSmsCost(smscost);
        setVoiceCost(voiceCost);
        setVoicePulse(voicepulse);
        setWp_Conv_Cost(wp_conv_cost);
        setWpSendingCost(wp_sending_cost);
        setAuthentication(wp_auth_cost);
        setUtility(wp_util_cost);
        setID(id);
        setSmsStatus(status);
        setSmsFrozen(frozen);
    }

    const isValid = () => {
        if (rcscountryCode === undefined || rcscountryCode === '') {
            setError({
                errorStatus: true,
                bgColor: 'alert alert-danger alert-dismissible',
                errorMessage: "Please Enter Country Code"
            });
            return false
        }
        if (this.state.botid === undefined || this.state.botid === '') {
            setError({
                errorStatus: true,
                bgColor: 'alert alert-danger alert-dismissible',
                errorMessage: "Please Enter Bot Id"
            });
            return false
        }
        if (this.state.margin === undefined || this.state.margin === '') {
            setError({
                errorStatus: true,
                bgColor: 'alert alert-danger alert-dismissible',
                errorMessage: "Please Enter Margin"
            });
            return false
        }
        // if(this.state.id){
        //   if(this.state.status === undefined || this.state.status === ''){
        //     this.setState({
        //       successMessage: true,
        //       bgColor: "alert alert-danger alert-dismissible",
        //       errorMessage: "Please Select Status",
        //     });
        //     return false
        //   }
        //   if(this.state.frozen === undefined || this.state.frozen === ''){
        //     this.setState({
        //       successMessage: true,
        //       bgColor: "alert alert-danger alert-dismissible",
        //       errorMessage: "Please Select Frozen",
        //     });
        //     return false
        //   }
        //   return fasle;
        // }
        return true;
    }

    const addRcsPrice = async () => {
        setLoading(true)
        const paramData = {
            user_id: userId,
            retr_user_id: id,
            token: userToken,
            method: "add_single_country_pricercs",
            country_code: rcscountryCode.value,
            bot_id: botid || '67890',
            basic_sms_cost: basicrcscost,
            p2a_conv_cost: p2a_conversation_cost,
            a2p_conv_cost: a2p_conversation_cost,
            a2p_single_sms_cost: a2p_single_rcs_cost,
            is_frozen: frozen === 1 ? true : false,
            is_active: status === 1 ? true : false
        }
        // console.log(paramData)
        try {
            let { data } = await Axios.post(`${NODE_HOST_URL}/user`, paramData);

            if (data.success === true) {
                setError({
                    errorStatus: true,
                    bgColor: 'alert alert-success alert-dismissible',
                    errorMessage: data.message
                });
                setTimeout(() => {
                    setError({ errorStatus: false });
                    // window.location.reload();
                }, 3000);
                getRcsData();
                setRcsCountryCode("");
                setBotId("");
                setMargin("");
                setRcsID("");
                setBasicRcsCost('');
                seta2pConversationCost('');
                setP2aConversationCost('');
                seta2pSingleRcsCost('')
            }
            else {
                setError({
                    errorStatus: true,
                    bgColor: 'alert alert-danger alert-dismissible',
                    errorMessage: data.message
                });
                getRcsData();
                setRcsCountryCode("");
                setBotId("");
                setMargin("");
                setRcsID("");
                setBasicRcsCost('');
                seta2pConversationCost('');
                setP2aConversationCost('');
                seta2pSingleRcsCost('')
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                setError({
                    errorStatus: true,
                    bgColor: 'alert alert-danger alert-dismissible',
                    errorMessage: error.response.data.message
                });
            } else {
                setError({
                    errorStatus: true,
                    bgColor: 'alert alert-danger alert-dismissible',
                    errorMessage: 'An unexpected error occurred. Please try again later.'
                });
            }

            setTimeout(() => {
                setError({ errorStatus: false });
            }, 4000);
        }
        setLoading(false)
    }


    const getRcsData = async () => {
        const retrieveParam = {
            user_id: userId,
            userId: id,
            token: userToken,
            method: "retrieve_price"
        }
        try {
            let { data } = await Axios.post(`${NODE_HOST_URL}/admin/rcs_setting`, retrieveParam);
            if (data.success) {
                // console.log(data)
                setRcsPriceList(data.data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getRcsPrice = (id, countrycode, botid, rcscost, a2pcost, a2pconvcost, p2acost) => {
        let rcsCountryCodeData = countryList.find(x => x.value == countrycode)
        setRcsID(id);
        setRcsCountryCode(rcsCountryCodeData);
        setBotId(botid);
        setBasicRcsCost(rcscost);
        seta2pSingleRcsCost(a2pcost);
        seta2pConversationCost(a2pconvcost);
        setP2aConversationCost(p2acost)
    }

    const openPopup = () => {
        if(marginType === undefined || marginType === ''){
            setError({
                errorStatus: true,
                bgColor: 'alert alert-danger alert-dismissible',
                errorMessage: "Please Select Margin Type",
            });
            return false
        }
        if(bulkMargin === undefined || bulkMargin === ''){
            setError({
                errorStatus: true,
                bgColor: 'alert alert-danger alert-dismissible',
                errorMessage: "Please Enter Margin",
            });
            return false
        }
        else{
            setShowModal(true);
        }
    }

    const BulkMarginHnadler = (e) => {
        setBulkMargin(e.target.value);
        setError({ errorStatus: false })
    }

    const MarginTypeHandler = (e) =>{
        setMarginType(e.target.value)
        setError({errorStatus: false})
    }

    const handleConfirmationCode = (e) => {
        setConfirmCode(e.target.value);
        setPopupError({ popupStatus: false })
    }

    const handleOtherPriceConfirmationCode = (e) => {
        setOtherPriceconfirmCode(e.target.value);
        setPopupError({ popupStatus: false })
    }

    const confirmationCode = async () => {
        setLoading(true)
        if (confirmCode === '123456') {
            // let paramData = {
            //     user_id: userId,
            //     token: userToken,
            //     bot_id: botid ? botid : "0000",
            //     // country_code:91,
            //     retr_user_id: id,
            //     margin: parseFloat(bulkMargin),
            //     method: channel === "whatsapp" ? "whatsapp_bulk" : channel === "sms" ? "sms_bulk" : channel === "voice" ? "voice_bulk" : channel === "rcs" ? "add_all_country_pricercs" : null,
            //     parent_type: empType,
            //     client_type: userType
            // }

            let smsParamData = {
                user_id: userId,
                token: userToken,
                method: "add_bulk_sms_price",
                retr_user_id: id,
                sms_cost_margin: bulkMargin,
                margin_type: marginType
            }

            let voiceParamData = {
                user_id: userId,
                token: userToken,
                method: "add_bulk_voice_price",
                retr_user_id: id,
                voice_cost_margin: bulkMargin,
                margin_type: marginType
            }

            let whatsappParamData = {
                user_id: userId,
                token: userToken,
                method: "add_bulk_whatsapp_price",
                retr_user_id: id,
                wp_sending_cost_margin: bulkMargin,
                wp_conv_cost_margin: bulkMargin,
                wp_auth_cost_margin: bulkMargin,
                wp_util_cost_margin: bulkMargin,
                margin_type: marginType
            }

            let rcsParamData = {
                user_id: userId,
                retr_user_id: id,
                token: userToken,
                method: "add_bulk_rcs_price",
                bot_id: botid || '67890',
                basic_sms_cost_margin: bulkMargin,
                p2a_conv_cost_margin: bulkMargin,
                a2p_conv_cost_margin: bulkMargin,
                a2p_single_sms_cost_margin: bulkMargin,
                margin_type: marginType
            }
            try {
                let { data } = await Axios.post(`${NODE_HOST_URL}/user`, channel === 'whatsapp' ? whatsappParamData : channel === 'sms' ? smsParamData : channel === 'voice' ? voiceParamData : channel === 'rcs' ? rcsParamData : null);
                if (data.success) {
                    setError({
                        errorStatus: true,
                        bgColor: "alert alert-success",
                        errorMessage: data.message,
                    });
                    setShowModal(false);
                    setConfirmCode('');
                    setBulkMargin('')
                    setTimeout(() => {
                        setError({ errorStatus: false })
                    }, 3000);
                    getDetailList();
                }
                else {
                    setPopupError({
                        popupStatus: true,
                        popupColor: "alert alert-danger",
                        popupMessage: data.message,
                    });
                }
            } catch (error) {
                console.log(error)
                if (error.response && error.response.data && error.response.data.message) {
                    setPopupError({
                        popupStatus: true,
                        popupColor: 'alert alert-danger alert-dismissible',
                        popupMessage: error.response.data.message
                    });
                } else {
                    setPopupError({
                        popupStatus: true,
                        popupColor: 'alert alert-danger alert-dismissible',
                        popupMessage: 'An unexpected error occurred. Please try again later.'
                    });
                }
            }
        } else {
            setPopupError({
                popupStatus: true,
                popupColor: "alert alert-danger",
                popupMessage: "Confirmation Code is not Correct",
            });
        }
        setLoading(false)
    }

    const OtherPriceconfirmationCode = () => {
        if (OtherPriceconfirmCode === '123456') {
            setOtherPriceShowModal(false);
            setOtherPriceconfirmCode('');
        } else {
            setPopupError({
                popupStatus: true,
                popupColor: "alert alert-danger",
                popupMessage: "Confirmation Code is not Correct",
            });
        }
    }

    const CountryList = async () => {
        try {
            let { data } = await Axios.post(`${HOST_URL}/country_master.php`);
            if (data.success) {
                let result = data.data
                const formattedCountry = result.map(user => ({
                    value: user.country_code,
                    label: user.country_name,
                }));
                setCountryList(formattedCountry);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleChannel = (e) => {
        setChannel(e.target.value);
        setError({ errorStatus: false })
        setCountryCode("")
        setSmsCountryCode("")
        setVoiceCountryCode("")
        setRcsCountryCode("")
        setID('')
        setEntryType('single')
    }

    const handleWhatsappCountryCode = (selectedOption) => {
        setCountryCode(selectedOption)
    }

    const handleSmsCountryCode = (selectedOption) => {
        setSmsCountryCode(selectedOption)
    }

    const handleVoiceCountryCode = (selectedOption) => {
        setVoiceCountryCode(selectedOption)
    }

    const handleRcsCountryCode = (selectedOption) => {
        setRcsCountryCode(selectedOption)
    }

    const handlePagination = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = selectUserPriceDetails?.slice(indexOfFirstRecord, indexOfLastRecord);
    const totalPages = Math.ceil(selectUserPriceDetails?.length / recordsPerPage);

    return (
        <div>
            <div className="wrapper">
                <AdminLeftMenu />
                <div className="main">
                    <AdminHeader />
                    {loading && <Spining />}
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3>User Details <strong className='text-info'> {email} </strong>  </h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="card">

                                    <div className="card-header">
                                        <h5 className="card-title mb-0">
                                            <div className="col-auto ml-auto text-right mt-n1">
                                                <nav className="text-center" aria-label="breadcrumb">
                                                    <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
                                                        <Link to={'/admin/edit-employee/' + id + `/` + userType}><li className="breadcrumb-item ">Update Details </li> </Link>
                                                        <Link to={'/admin/reseller-transaction-details/' + id + `/` + userType}>  <li className="breadcrumb-item "> Transaction Details </li></Link>

                                                        <Link to={'/admin/reseller-price-details/' + id + `/` + userType}>
                                                            <li className="breadcrumb-item active"> Add Price </li></Link>
                                                        {empType === "admin" && <Link to={'/admin/reseller-add-price/' + id + `/` + userType}>  <li className="breadcrumb-item"> Add Credit </li></Link>}
                                                        <Link to={'/admin/reseller-billing-mode/' + id + `/` + userType}>  <li className="breadcrumb-item"> Billing Mode </li></Link>
                                                    </ol>
                                                </nav>
                                            </div>
                                        </h5>
                                    </div>

                                    <div className="card-body">
                                        {error.errorStatus && (
                                            <div className={error.bgColor} role="alert">
                                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                                <div>{error.errorMessage}</div>
                                            </div>
                                        )}


                                        <div className="filter-option">
                                            {/* <h6 className="card-subtitle text-muted">Add New Price</h6> */}
                                            <div className="card">
                                                <div className="row m-3">
                                                    <div className='col-5'>
                                                        <select
                                                            className='form-control'
                                                            value={channel}
                                                            onChange={handleChannel}
                                                        >
                                                            <option value="">Select Channel</option>
                                                            <option value="whatsapp">Whatsapp</option>
                                                            <option value="sms">SMS</option>
                                                            <option value="voice">Voice</option>
                                                            <option value="rcs">RCS</option>
                                                        </select>
                                                    </div>

                                                    {channel !== "" &&
                                                        <div className='col-6'>
                                                            <div className="d-flex align-items-center">
                                                                <div className="form-check mr-3">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id="single-entry"
                                                                        checked={entryType === 'single'}
                                                                        onChange={handleEntryChange}
                                                                        value="single"
                                                                    />
                                                                    <label className="form-check-label" htmlFor="single-entry">
                                                                        Single Entry
                                                                    </label>
                                                                </div>
                                                                {channel === 'voice' ? null :
                                                                    <div className="form-check ml-3">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            id="bulk-entry"
                                                                            checked={entryType === 'bulk'}
                                                                            onChange={handleEntryChange}
                                                                            value="bulk"
                                                                        />
                                                                        <label className="form-check-label" htmlFor="bulk-entry">
                                                                            Bulk Entry
                                                                        </label>
                                                                    </div>}
                                                            </div>
                                                        </div>}
                                                </div>

                                                <div className="card-body">
                                                    {channel !== "" && entryType === 'bulk' ?
                                                        <>
                                                            <div className="row">
                                                                <div className='col-4'>
                                                                    <select value={marginType} className='form-control' onChange={MarginTypeHandler} >
                                                                        <option value=''>Select Margin Type</option>
                                                                        <option value="percentage">Percentage</option>
                                                                        <option value="currency">Currency</option>
                                                                    </select>
                                                                </div>
                                                                <div className='col-4'>
                                                                    <input type='text' disabled={marginType ? false : true} className='form-control' value={bulkMargin} onChange={BulkMarginHnadler} placeholder={marginType === "percentage" ? 'Enter Margin Percentage' : marginType === "currency" ? 'Enter Margin Currency' : 'Enter Margin'} />
                                                                </div>
                                                                <div className="col-4">
                                                                    <button
                                                                        onClick={openPopup}
                                                                        className="btn btn-secondary"
                                                                        style={{ fontSize: "12px", padding: "10px 10px 10px 10px" }}
                                                                    >
                                                                        <span className="mr-2"><FontAwesomeIcon icon={faPlus} /></span>
                                                                        Add Price
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <p className='mt-3' style={{ fontSize: "13px", color:"red" }}>*<b>NOTE:</b> Add <b>Margin</b> according to your <b>Currency</b></p>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : null}
                                                    {channel !== "" && entryType === 'single' ?
                                                        <>
                                                            {channel === "whatsapp" &&
                                                                <>
                                                                    <div className='row'>
                                                                        <div className="col-md-4">
                                                                            <label className="form-label">County Code</label>
                                                                            <div className="input-group drop-down-icons mb-2 mr-sm-2 table2">
                                                                                <div className="input-group mb-2 mr-sm-2">
                                                                                    {/* <div className="input-group-text">User </div> */}
                                                                                    <Select
                                                                                        onChange={handleWhatsappCountryCode}
                                                                                        options={countryList}
                                                                                        // options={countryList
                                                                                        //     .filter(x => !selectUserPriceDetails.some(detail => detail.country_code === x.value)) // Exclude countries that match the selectedUserPriceDetails
                                                                                        //     .map(x => ({ value: x.value, label: x.label })) // Ensure correct formatting for the Select component
                                                                                        // }
                                                                                        value={countryCode}
                                                                                        isSearchable={true}
                                                                                        placeholder="Select Country"
                                                                                        isClearable={true}
                                                                                        styles={customStyles}
                                                                                    />

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-md-3">
                                                                            <label className="form-label"> conversation cost</label>
                                                                            <input
                                                                                type="text"
                                                                                value={wp_conv_cost}
                                                                                onChange={(event) =>
                                                                                    setWp_Conv_Cost(event.target.value)
                                                                                }
                                                                                placeholder="Rs."
                                                                                className="form-control"
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label className="form-label"> Marketing Cost</label>
                                                                            <input
                                                                                type="text"
                                                                                value={wp_sending_cost}
                                                                                onChange={(event) =>
                                                                                    setWpSendingCost(event.target.value)
                                                                                }
                                                                                placeholder="Rs."
                                                                                className="form-control"
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label className="form-label"> Utility Cost</label>
                                                                            <input
                                                                                type="text"
                                                                                value={utility}
                                                                                onChange={(event) =>
                                                                                    setUtility(event.target.value)
                                                                                }
                                                                                placeholder="Rs."
                                                                                className="form-control"
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <label className="form-label"> Authentication Cost</label>
                                                                            <input
                                                                                type="text"
                                                                                value={authentication}
                                                                                onChange={(event) =>
                                                                                    setAuthentication(event.target.value)
                                                                                }
                                                                                placeholder="Rs."
                                                                                className="form-control"
                                                                            />
                                                                        </div>

                                                                        {_id &&
                                                                            <>
                                                                                <div className="col-md-3">
                                                                                    <label className="form-label">Status</label>
                                                                                    <select className="form-control" disabled={true} value={smsStatus} onChange={(e) => setSmsStatus(e.target.value)}>
                                                                                        <option value="1">Active</option>
                                                                                        <option value="0">Inactive</option>
                                                                                    </select>
                                                                                </div>

                                                                                <div className="col-md-3">
                                                                                    <label className="form-label">Frozen</label>
                                                                                    <select className="form-control" disabled={true} value={smsFrozen} onChange={(e) => setSmsFrozen(e.target.value)}>
                                                                                        <option value="1">Frozen</option>
                                                                                        <option value="0">non-Forzen</option>
                                                                                    </select>
                                                                                </div>
                                                                            </>}

                                                                        <div className='row'>
                                                                            <div className='col-md-6'>
                                                                                <p className='mt-3 ml-3' style={{ fontSize: "13px" , color:"red"}}>*<b>NOTE:</b> Add <b>Price</b> according to your <b>Currency</b></p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-12 text-right">
                                                                            {!_id ? (
                                                                                <div style={{ marginTop: 40 }} className="input-group text-right">
                                                                                    <input
                                                                                        type="button"
                                                                                        onClick={() => AddWhatsappPrice()}
                                                                                        value="Add"
                                                                                        className="btn btn-sm btn-primary text-center"
                                                                                    />
                                                                                </div>
                                                                            ) : (
                                                                                <div style={{ marginTop: 40 }} className="input-group">
                                                                                    <input
                                                                                        type="button"
                                                                                        onClick={() => AddWhatsappPrice()}
                                                                                        value="Update"
                                                                                        className="btn btn-sm btn-primary text-center"
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </>}
                                                            {channel === "sms" &&
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <label className="form-label">County Code</label>
                                                                            <div className="input-group drop-down-icons mb-2 mr-sm-2 table2">
                                                                                <div className="input-group mb-2 mr-sm-2">
                                                                                    {/* <div className="input-group-text">User </div> */}
                                                                                    <Select
                                                                                        onChange={handleSmsCountryCode}
                                                                                        options={countryList}
                                                                                        value={smscountryCode}
                                                                                        isSearchable={true}
                                                                                        placeholder="Select Country"
                                                                                        isClearable={true}
                                                                                        styles={customStyles}
                                                                                    // isDisabled={this.state.updateId ? true : false}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label className="form-label"> Cost</label>
                                                                            <input
                                                                                type="text"
                                                                                value={smsCost}
                                                                                onChange={(event) =>
                                                                                    setSmsCost(event.target.value)
                                                                                }
                                                                                placeholder="Rs."
                                                                                className="form-control"
                                                                            />
                                                                        </div>

                                                                        {_id &&
                                                                            <div className="col-md-4">
                                                                                <label className="form-label">Status</label>
                                                                                <select className="form-control" value={smsStatus} onChange={(e) => setSmsStatus(e.target.value)}>
                                                                                    <option value="1">Active</option>
                                                                                    <option value="0">Inactive</option>
                                                                                </select>
                                                                            </div>}
                                                                    </div>
                                                                    {_id &&
                                                                        <div className='row'>
                                                                            <div className="col-md-4">
                                                                                <label className="form-label">Frozen</label>
                                                                                <select className="form-control" value={smsFrozen} onChange={(e) => setSmsFrozen(e.target.value)}>
                                                                                    <option value="1">Frozen</option>
                                                                                    <option value="0">non-Forzen</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>}

                                                                    <div className='row'>
                                                                        <div className='col-md-6'>
                                                                            <p className='mt-2 ml-2' style={{ fontSize: "13px", color:"red" }}>*<b>NOTE:</b> Add <b>Price</b> according to your <b>Currency</b></p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-12 text-right">
                                                                        {!_id ? (
                                                                            <div style={{ marginTop: 40 }} className="input-group text-right">
                                                                                <input
                                                                                    type="button"
                                                                                    onClick={() => AddSmsPrice()}
                                                                                    value="Add"
                                                                                    className="btn btn-sm btn-primary text-center"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            <div style={{ marginTop: 40 }} className="input-group">
                                                                                <input
                                                                                    type="button"
                                                                                    onClick={() => AddSmsPrice()}
                                                                                    value="Update"
                                                                                    className="btn btn-sm btn-primary text-center"
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div> </>}
                                                            {channel === "voice" &&
                                                                <>
                                                                    <div className='row'>
                                                                        <div className="col-md-4">
                                                                            <label className="form-label">County Code</label>
                                                                            <div className="input-group drop-down-icons mb-2 mr-sm-2 table2">
                                                                                <div className="input-group mb-2 mr-sm-2">
                                                                                    {/* <div className="input-group-text">User </div> */}
                                                                                    <Select
                                                                                        onChange={handleVoiceCountryCode}
                                                                                        options={countryList.filter(x => x.value === '91')}
                                                                                        value={voicecountryCode}
                                                                                        isSearchable={true}
                                                                                        placeholder="Select Country"
                                                                                        isClearable={true}
                                                                                        styles={customStyles}
                                                                                    // isDisabled={this.state.updateId ? true : false}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label className="form-label">Cost</label>
                                                                            <input
                                                                                type="text"
                                                                                value={voiceCost}
                                                                                onChange={(event) =>
                                                                                    setVoiceCost(event.target.value)
                                                                                }
                                                                                placeholder="Rs."
                                                                                className="form-control"
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label className="form-label"> Pulse</label>
                                                                            <select
                                                                                value={voicePulse}
                                                                                onChange={(event) =>
                                                                                    setVoicePulse(event.target.value)
                                                                                }
                                                                                className="form-control"
                                                                            >
                                                                                <option value=""> Select </option>
                                                                                <option value="15">15</option>
                                                                                <option value="30">30</option>
                                                                                {/* <option value="60">60</option> */}
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    {_id &&
                                                                        <div className='row'>
                                                                            <div className="col-md-4">
                                                                                <label className="form-label">Status</label>
                                                                                <select className="form-control" disabled={true} value={smsStatus} onChange={(e) => setSmsStatus(e.target.value)}>
                                                                                    <option value="1">Active</option>
                                                                                    <option value="0">Inactive</option>
                                                                                </select>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label className="form-label">Frozen</label>
                                                                                <select className="form-control" disabled={true} value={smsFrozen} onChange={(e) => setSmsFrozen(e.target.value)}>
                                                                                    <option value="1">Frozen</option>
                                                                                    <option value="0">non-Forzen</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                    <div className='row'>
                                                                        <div className='col-md-6'>
                                                                            <p className='mt-2 ml-2' style={{ fontSize: "13px" , color:"red"}}>*<b>NOTE:</b> Add <b>Price</b> according to your <b>Currency</b></p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-12 text-right">
                                                                        {!_id ? (
                                                                            <div style={{ marginTop: 40 }} className="input-group text-right">
                                                                                <input
                                                                                    type="button"
                                                                                    onClick={() => AddVoicePrice()}
                                                                                    value="Add"
                                                                                    className="btn btn-sm btn-primary text-center"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            <div style={{ marginTop: 40 }} className="input-group">
                                                                                <input
                                                                                    type="button"
                                                                                    onClick={() => AddVoicePrice()}
                                                                                    value="Update"
                                                                                    className="btn btn-sm btn-primary text-center"
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </>}
                                                            {channel === "rcs" &&
                                                                <div className="row">
                                                                    <div className="col-md-4">
                                                                        <label className="form-label">County Code</label>
                                                                        <div className="input-group drop-down-icons mb-2 mr-sm-2 table2">
                                                                            <div className="input-group mb-2 mr-sm-2">
                                                                                {/* <div className="input-group-text">User </div> */}
                                                                                <Select
                                                                                    onChange={handleRcsCountryCode}
                                                                                    options={countryList.filter(x => x.value === '91')}
                                                                                    value={rcscountryCode}
                                                                                    isSearchable={true}
                                                                                    placeholder="Select Country"
                                                                                    isClearable={true}
                                                                                    styles={customStyles}
                                                                                // isDisabled={this.state.updateId ? true : false}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-4">
                                                                        <label className="form-label">Bot Id</label>
                                                                        <select value={botid} className="form-control" onChange={(e) => setBotId(e.target.value)}>
                                                                            <option value={""}>select Bot ID</option>
                                                                            {userBotID?.map((x) => (
                                                                                <option value={x.bot_id}>{x.bot_id}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>

                                                                    {/* {!rcsID && <div className="col-md-4">
                                                                     <label className="form-label">Margin</label>
                                                                     <input
                                                                         type="text"
                                                                         value={margin}
                                                                         onChange={(event) =>
                                                                             setMargin(event.target.value)
                                                                         }
                                                                         placeholder="Rs."
                                                                         className="form-control"
                                                                     />
                                                                 </div>} */}

                                                                    <div className="col-md-4">
                                                                        <label className="form-label">Basic Cost</label>
                                                                        <input
                                                                            type="text"
                                                                            value={basicrcscost}
                                                                            onChange={(event) =>
                                                                                setBasicRcsCost(event.target.value)
                                                                            }
                                                                            placeholder="Rs."
                                                                            className="form-control"
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <label className="form-label">Rich Media Cost</label>
                                                                        <input
                                                                            type="text"
                                                                            value={a2p_single_rcs_cost}
                                                                            onChange={(event) =>
                                                                                seta2pSingleRcsCost(event.target.value)
                                                                            }
                                                                            placeholder="Rs."
                                                                            className="form-control"
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <label className="form-label">A2P Conversation Cost</label>
                                                                        <input
                                                                            type="text"
                                                                            value={a2p_conversation_cost}
                                                                            onChange={(event) =>
                                                                                seta2pConversationCost(event.target.value)
                                                                            }
                                                                            placeholder="Rs."
                                                                            className="form-control"
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <label className="form-label">P2A Conversation Cost</label>
                                                                        <input
                                                                            type="text"
                                                                            value={p2a_conversation_cost}
                                                                            onChange={(event) =>
                                                                                setP2aConversationCost(event.target.value)
                                                                            }
                                                                            placeholder="Rs."
                                                                            className="form-control"
                                                                        />
                                                                    </div>

                                                                    {rcsID &&
                                                                        <div className="col-md-4">
                                                                            <label className="form-label">Status</label>
                                                                            <select className="form-control" value={status} onChange={(e) => setStatus(e.target.value)}>
                                                                                <option value="1">Active</option>
                                                                                <option value="0">Inactive</option>
                                                                            </select>
                                                                        </div>}

                                                                    {rcsID &&
                                                                        <div className="col-md-4">
                                                                            <label className="form-label">Frozen</label>
                                                                            <select className="form-control" value={frozen} onChange={(e) => setFrozen(e.target.value)}>
                                                                                <option value="1">Frozen</option>
                                                                                <option value="0">non-Forzen</option>
                                                                            </select>
                                                                        </div>}

                                                                    <div className='row'>
                                                                        <div className='col-md-6'>
                                                                            <p className='mt-3 ml-3' style={{ fontSize: "13px", color:"red" }}>*<b>NOTE:</b> Add <b>Price</b> according to your <b>Currency</b></p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-12 text-right">
                                                                        {rcsID ? (
                                                                            <div style={{ marginTop: 40 }} className="input-group">
                                                                                <input
                                                                                    type="button"
                                                                                    onClick={() => addRcsPrice()}
                                                                                    value="Update"
                                                                                    className="btn btn-sm btn-primary text-center"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            <div style={{ marginTop: 40 }} className="input-group text-right">
                                                                                <input
                                                                                    type="button"
                                                                                    onClick={() => addRcsPrice()}
                                                                                    value="Add"
                                                                                    className="btn btn-sm btn-primary text-center"
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>}
                                                        </> : null}
                                                </div>
                                            </div>
                                        </div>

                                        {channel !== 'rcs' &&
                                            <>
                                                {channel && selectUserPriceDetails?.length > 0 ? <div className='row'>
                                                    <div className='col-6'>
                                                        <h3 style={{ cursor: "pointer", fontSize: "15px" }} className="card-title m-3"><b>{channel === 'whatsapp' ? <span>Whatsapp</span> : channel === 'sms' ? <span>SMS</span> : channel === 'voice' ? <span>Voice</span> : null}</b></h3>
                                                    </div>
                                                    <div className='col-6'>
                                                        <input
                                                            type="text"
                                                            id="searchInput"
                                                            name="searchList"
                                                            value={searchList}
                                                            onChange={(e) => setSearchList(e.target.value)}
                                                            className="form-control mb-2"
                                                            placeholder="Enter to Search"
                                                        />
                                                    </div>
                                                </div> : null}

                                                <div className="row">
                                                    <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                                        <div className="card table-responsive flex-fill">
                                                            {channel && selectUserPriceDetails?.length > 0 ? (
                                                                <>
                                                                    <table className="table table-hover my-0">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Country Code</th>
                                                                                <th className="">Currency</th>
                                                                                {channel === 'sms' && <th className="">SMS Cost</th>}
                                                                                {channel === 'voice' && <th className="">Voice / Pulse Cost</th>}
                                                                                {channel === 'whatsapp' && (
                                                                                    <>
                                                                                        <th className="">WP Conv Cost</th>
                                                                                        <th className="">WP Sending Cost</th>
                                                                                        <th className="">Utility</th>
                                                                                        <th className="">Authentication</th>
                                                                                    </>
                                                                                )}
                                                                                <th className="">Date / Time</th>
                                                                                <th className="">Edit</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {currentRecords.filter((x) => {
                                                                                return searchList.toLowerCase() === '' ? x : x.country_name.toLowerCase().includes(searchList);
                                                                            }).map((item, i) => {
                                                                                // Conditionally check if the current item is valid based on the selected channel
                                                                                if (
                                                                                    (channel === "whatsapp" && (item.wp_conv_cost === 0 || item.wp_conv_cost === null)) ||
                                                                                    (channel === "sms" && (item.sms_cost === 0 || item.sms_cost === null)) ||
                                                                                    (channel === "voice" && (item.voice_cost === 0 || item.voice_cost === null))
                                                                                ) {
                                                                                    return null; // Skip this row if it's not valid for the selected channel
                                                                                }

                                                                                return (
                                                                                    <tr key={i}>
                                                                                        <td>{item.country_name} ({item.country_code})</td>
                                                                                        <td>{item.currency_name}</td>
                                                                                        {channel === "sms" && <td>{item.sms_cost}</td>}
                                                                                        {channel === "voice" && (
                                                                                            <td>
                                                                                                {item.voice_cost} / {item.voice_pulse}
                                                                                            </td>
                                                                                        )}
                                                                                        {channel === "whatsapp" && (
                                                                                            <>
                                                                                                <td>{item.wp_conv_cost}</td>
                                                                                                <td>{item.wp_sending_cost}</td>
                                                                                                <td>{item.wp_util_cost}</td>
                                                                                                <td>{item.wp_auth_cost}</td>
                                                                                            </>
                                                                                        )}
                                                                                        <td>{item.modified}</td>
                                                                                        <td>
                                                                                            <span
                                                                                                onClick={() =>
                                                                                                    updatePrice(
                                                                                                        item.id,
                                                                                                        item.country_code,
                                                                                                        item.sms_cost,
                                                                                                        item.voice_cost,
                                                                                                        item.voice_pulse,
                                                                                                        item.wp_conv_cost,
                                                                                                        item.wp_sending_cost,
                                                                                                        item.wp_auth_cost,
                                                                                                        item.wp_util_cost,
                                                                                                        item.is_active,
                                                                                                        item.is_frozen
                                                                                                    )
                                                                                                }
                                                                                                className="badge bg-success"
                                                                                            >
                                                                                                <FontAwesomeIcon icon={faEdit} /> Edit
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                    <ul className="pagination mt-3">
                                                                        {Array.from({ length: totalPages }).map((_, index) => (
                                                                            <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                                                                <button className="page-link" onClick={() => handlePagination(index + 1)}>
                                                                                    {index + 1}
                                                                                </button>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </>
                                                            ) : (
                                                                <div className="text-center">
                                                                    <div className="card-body">
                                                                        <img alt="Empty Data" className="project-empty-img" src={EmptyImg} />
                                                                        <p>No Any User Price Found</p>
                                                                    </div>
                                                                </div>
                                                            )}

                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        {channel === "rcs" &&
                                            <>
                                                {channel && rcsPriceList?.length > 0 ? <div className='row'>
                                                    <div className='col-6'>
                                                        <h3 style={{ cursor: "pointer", fontSize: "15px" }} className="card-title m-3"><b>{channel === 'rcs' ? <span>RCS</span> : null}</b></h3>
                                                    </div>
                                                    {/* <div className='col-6'>
                                                        <input
                                                            type="text"
                                                            id="searchInput"
                                                            name="rcsSearch"
                                                            value={rcsSearch}
                                                            onChange={(e) => setRcsSearch(e.target.value)}
                                                            className="form-control mb-2"
                                                            placeholder="Enter to Search"
                                                        />
                                                    </div> */}
                                                </div> : null}

                                                <div className="row">
                                                    <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                                        <div className="card table-responsive flex-fill">
                                                            {rcsPriceList.length > 0 ? (
                                                                <table className="table table-hover my-0">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Country Code</th>
                                                                            <th className="">Bot ID</th>
                                                                            <th className="">Rcs Cost</th>
                                                                            <th className="">A2P Sinagle Rcs Cost</th>
                                                                            <th className=""> A2P Conversation Cost </th>
                                                                            <th className=""> P2A Conversation Cost </th>
                                                                            <th className=""> Date/Time </th>
                                                                            <th className=""> Edit </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {rcsPriceList.map((item, i) => (
                                                                            <tr key={i}>
                                                                                <td>
                                                                                    {item.country_code}
                                                                                </td>
                                                                                <td>{item.bot_id}</td>
                                                                                <td>{item.basic_sms_cost}</td>
                                                                                <td>{item.a2p_single_sms_cost}</td>
                                                                                <td>{item.a2p_conv_cost}</td>
                                                                                <td>{item.p2a_conv_cost}</td>
                                                                                <td>{dayjs(item.created_date).format("DD/MM/YYYY h:mm A")}</td>
                                                                                <td>
                                                                                    <span
                                                                                        onClick={() =>
                                                                                            getRcsPrice(
                                                                                                item._id,
                                                                                                item.country_code,
                                                                                                item.bot_id,
                                                                                                item.basic_sms_cost,
                                                                                                item.a2p_single_sms_cost,
                                                                                                item.a2p_conv_cost,
                                                                                                item.p2a_conv_cost
                                                                                            )
                                                                                        }
                                                                                        className="badge bg-success"
                                                                                    >
                                                                                        <FontAwesomeIcon icon={faEdit} /> Edit
                                                                                    </span>
                                                                                </td>


                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            ) : (
                                                                <div className="text-center">
                                                                    <div className="card-body">
                                                                        <img alt="Empty Data" className="project-empty-img" src={EmptyImg} />
                                                                        <p>No Any User Price Found</p>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>}
                                    </div>
                                    {showModal && (
                                        <div className="modal fade show d-block" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="exampleModalLabel">Confirmation Code</h5>
                                                    </div>
                                                    <div className="modal-body">
                                                        {popupError.popupStatus ? (
                                                            <div className={popupError.popupColor} role="alert">
                                                                <button
                                                                    type="button"
                                                                    className="close"
                                                                    data-dismiss="alert"
                                                                    aria-label="Close"
                                                                >
                                                                    <span aria-hidden="true">×</span>
                                                                </button>
                                                                <div className="">{popupError.popupMessage}</div>
                                                            </div>
                                                        ) : null}
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <label>Enter the Code</label>
                                                                <input type="number" value={confirmCode} onChange={handleConfirmationCode} placeholder="Please Enter the Code" className="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
                                                            Close
                                                        </button>
                                                        <button type="button" onClick={confirmationCode} className="btn btn-info">
                                                            Confirm
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {otherPriceShowModal && (
                                        <div className="modal fade show d-block" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="exampleModalLabel">Confirmation Code</h5>
                                                    </div>
                                                    <div className="modal-body">
                                                        {popupError.popupStatus ? (
                                                            <div className={popupError.popupColor} role="alert">
                                                                <button
                                                                    type="button"
                                                                    className="close"
                                                                    data-dismiss="alert"
                                                                    aria-label="Close"
                                                                >
                                                                    <span aria-hidden="true">×</span>
                                                                </button>
                                                                <div className="">{popupError.popupMessage}</div>
                                                            </div>
                                                        ) : null}
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <label>Enter the Code</label>
                                                                <input type="number" value={OtherPriceconfirmCode} onChange={handleOtherPriceConfirmationCode} placeholder="Please Enter the Code" className="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" onClick={() => setOtherPriceShowModal(false)}>
                                                            Close
                                                        </button>
                                                        <button type="button" onClick={OtherPriceconfirmationCode} className="btn btn-info">
                                                            Confirm
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </main>
                    <AdminFooter />
                </div>
            </div>
        </div>
    )
}

export default ResellerPriceDetails
