import React, { Component } from 'react';
import AdminHeader from '../Header/AdminHeader';
import AdminFooter from '../Footer/AdminFooter';
import AdminLeftMenu from '../Navbar/AdminLeftMenu';
import EmptyImg from "../Dashboard/assets/img/create-project.png";
import { userloginDetails } from '../Utils/AdminAuth';
import { HOST_URL } from '../Dashboard/api/Api';
class FeedBackMessage extends Component {

    constructor() {

        const lStorage = userloginDetails();
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;
        super();

        this.state = {
            user_id: userId,
            token: userToken,
            feedbackList: '',
            successMessage: false,
            errorMessage: '',
            bgColor: "alert alert-success alert-dismissible",
        }

    }

    componentDidMount() {
        this.onserch();
    }

    onserch() {
        fetch(`${HOST_URL}/user_suggestion.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                console.log('result', result)
                if (result.success === true) {
                    this.setState({ feedbackList: result.data });
                } else {
                    this.setState({
                        successMessage: true,
                        successBg: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }

    render() {
        // console.log(this.state.feedbackList);
        return (
            <div className="wrapper">
                <AdminLeftMenu />
                <div className="main">
                    <AdminHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong> Feedback </strong> Message </h3>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                    <div className="card table-content-show flex-fill">

                                        <div className="card-header">

                                            <h5 className="card-title mb-0"> List </h5>
                                        </div>

                                        <div className="card-body">

                                            {
                                                this.state.successMessage ?
                                                    <div className={this.state.bgColor} role="alert">
                                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                            <span aria-hidden="true">×</span>
                                                        </button>
                                                        <div className="">
                                                            {this.state.errorMessage}
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }

                                            {this.state.feedbackList ?

                                                <div style={{ height: 400 + 'px' }} className="table-responsive">
                                                    <table className="table table-striped table-xs table-sm my-0">
                                                        <thead className="sticky-top">
                                                            <tr>
                                                                <th style={{ 'width': 30 + '%' }}>User ID </th>
                                                                <th style={{ 'width': 50 + '%' }}>Feedback Message </th>
                                                                <th style={{ 'width': 20 + '%' }}>Created Date Time </th>


                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                this.state.feedbackList.map((item, i) =>

                                                                    <tr key={i}>


                                                                        <td>{item.username} (<strong>{item.user_id}</strong>)</td>
                                                                        <td>{item.message}</td>
                                                                        <td>{item.created}</td>

                                                                    </tr>

                                                                )
                                                            }


                                                        </tbody>
                                                    </table>
                                                </div>
                                                :
                                                <div className="text-center">
                                                    <div className="card-body">
                                                        <img alt="Empty Data" className="project-empty-img" src={EmptyImg} />
                                                        <p>No Any Login History </p>

                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <AdminFooter />
                </div>
            </div>

        );
    }
}
export default FeedBackMessage;