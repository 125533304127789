import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import AdminHeader from '../Header/AdminHeader';
import AdminFooter from '../Footer/AdminFooter';
import AdminLeftMenu from '../Navbar/AdminLeftMenu';
import EmptyImg from "../Dashboard/assets/img/create-project.png";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { userloginDetails } from '../Utils/AdminAuth';
import { HOST_URL, NODE_HOST_URL } from '../Dashboard/api/Api';
import Axios from '../../axios/Axios';
import Spining from "../UI/Spining/Spining";

const ResellerAddDetails = (props) => {
    const lStorage = userloginDetails();
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const empType = lStorage.user.isAdmin;

    const { id, userType } = useParams();
    const param = useParams();
    const [email, setEmail] = useState('');
    const [currentBalance, setCurrentBalance] = useState('');
    const [currency, setCurrency] = useState('');
    const [creditType, setCreditType] = useState('')
    const [creditAmount, setCreditAmount] = useState('')
    const [remark, setRemark] = useState('');
    const [disableButton, setDisableButton] = useState(false);
    const [error, setError] = useState({
        errorStatus: false,
        bgColor: '',
        errorMessage: ''
    });
    const [loading,setLoading] = useState(false)

    useEffect(() => {
        getUserDetails();
    })

    const getUserDetails = async () => {
        try {
            const response = await fetch(
                `${HOST_URL}/admin_user_list.php?user_id=${userId}&method=retrieve&type=userdetails&ret_user_id=${id}&token=${userToken}`
            );

            const result = await response.json();

            if (result.success === true) {
                setEmail(result.data[0].email);
                setCurrentBalance(result.data[0].currency);
                setCurrency(result.data[0].country_currency);
            } else {
                setError({
                    errorStatus: true,
                    bgColor: 'alert alert-danger alert-dismissible',
                    errorMessage: result.message
                })
            }
        } catch (error) {
            console.error('Error fetching user details:', error);
            setError({
                errorStatus: true,
                bgColor: 'alert alert-danger alert-dismissible',
                errorMessage: 'An error occurred while fetching user details.'
            })

        }
    };

    const AddPrice = async () => {
        setLoading(true)
        const paramData = {
            user_id: userId,
            token: userToken,
            method: "recharge",
            recever_id: id,
            amount: creditAmount,
            des: remark
        }
        try {
           let {data} = await Axios.post(`${NODE_HOST_URL}/user`,paramData);
           if(data.success){
            setLoading(false)
            setError({
                errorStatus: true,
                bgColor: 'alert alert-success alert-dismissible',
                errorMessage: 'Balance Added Successfully.'
            });
            setCreditAmount('')
            setRemark('')
            setTimeout(()=>{
                setError({errorStatus: false})
            },3000);
           }
           else{
            setLoading(false)
           }
        } catch (error) {
            console.log(error)
        }
    }

    console.log(currentBalance);
    return (
        <div>
            <div className="wrapper">
                <AdminLeftMenu />
                <div className="main">
                    <AdminHeader />
                    {loading && <Spining />}
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3>User Details <strong className='text-info'> {email} </strong>  </h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="card">

                                    <div className="card-header">
                                        <h5 className="card-title mb-0">
                                            <div className="col-auto ml-auto text-right mt-n1">
                                                <nav className="text-center" aria-label="breadcrumb">
                                                    <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
                                                        <Link to={'/admin/edit-employee/' + id + `/` + userType}><li className="breadcrumb-item ">Update Details </li> </Link>
                                                        <Link to={'/admin/reseller-transaction-details/' + id + `/` + userType}>  <li className="breadcrumb-item "> Transaction Details </li></Link>

                                                        <Link to={'/admin/reseller-price-details/' + id + `/` + userType}>
                                                            <li className="breadcrumb-item"> Add Price </li></Link>
                                                        <Link to={'/admin/reseller-add-price/' + id + `/` + userType}>  <li className="breadcrumb-item active"> Add Credit </li></Link>
                                                        <Link to={'/admin/reseller-billing-mode/' + id + `/` + userType}>  <li className="breadcrumb-item"> Billing Mode </li></Link>
                                                    </ol>
                                                </nav>
                                            </div>
                                        </h5>
                                    </div>

                                    <div className="card-body">
                                        {error.errorStatus && (
                                            <div className={error.bgColor} role="alert">
                                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                                <div>{error.errorMessage}</div>
                                            </div>
                                        )}

                                        <div className="filter-option">
                                            <div className="row">
                                                <div className="col-2">
                                                    <h6 className="card-subtitle text-muted">
                                                        Add More Credit{" "}
                                                    </h6>
                                                </div>
                                                <div className="col-6">
                                                    <span className="float-left"><b>Current Balance:</b>
                                                        {currency} {" "} {currentBalance}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-8 text-right">
                                                    <div className="row">
                                                        {/* <div className="input-group  drop-down-icons mb-2  col-md-6">
                                                            <select
                                                                onChange={(event) =>
                                                                    setCreditType(
                                                                        event.target.value,
                                                                    )
                                                                }
                                                                value={creditType}
                                                                className="form-control"
                                                            >
                                                                <option value="">Select Credit type</option>
                                                                <option value="Demo Credit">
                                                                    Demo Credit
                                                                </option>
                                                                <option value="paid">Paid Credit</option>
                                                            </select>
                                                        </div> */}

                                                        <div className="input-group mb-2  col-md-6">
                                                            <input
                                                                onChange={(event) =>
                                                                    setCreditAmount(
                                                                        event.target.value,
                                                                    )
                                                                }
                                                                value={creditAmount}
                                                                type="text"
                                                                placeholder="Enter Amount"
                                                                className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <textarea
                                                        placeholder="Description"
                                                        onChange={(event) =>
                                                            setRemark(event.target.value)
                                                        }
                                                        value={remark}
                                                        className="form-control mb-2"
                                                    ></textarea>
                                                </div>
                                                <div className="col-md-4  text-right">
                                                    <div className="input-group mb-2 mr-sm-2">
                                                        <input
                                                            type="button"
                                                            onClick={() => AddPrice()}
                                                            disabled={disableButton ? true : false}
                                                            value="Add Credit"
                                                            className="btn btn-block btn-primary"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <AdminFooter />
                </div>
            </div>
        </div>
    )
}

export default ResellerAddDetails