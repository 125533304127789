import React, { useState } from "react";
import "../../../style/components/whatsAppPreview.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faGlobe, faCopy, faFill } from "@fortawesome/free-solid-svg-icons";
import { bg_whatsapp, whatsapp_brand } from "../../../images/WhatsAppImages";

function WhatsAppPreviewAuthentication(props) {
    const [uppserText ,setUppperText] = useState(`{{1}} is your verification code.`)
  let previewData = props.previewData;
  const companyname = localStorage.getItem("wp-cmpy-name");
  var truncatedcompanyname;
  if(companyname){
  if(companyname.length <= 15){
     truncatedcompanyname = companyname
  }else{
     truncatedcompanyname = companyname.substring(0, 15) + '...';
  }
}
  const companyimg = localStorage.getItem("wp-cmpy-img");

  const displayWhatsAppPreview = (TemplateType, headerText) => {
    for (let key in headerText) {
      if (key === "text") {
        return <strong> {previewData.headerText.receiveHeadePrev} </strong>;
      }
      if (key === "image") {
        return <img className="img-fluid" src={previewData.headerText.image} />;
      }
      if (key === "video") {
        return (
          <video width="100%" controls>
            <source src={previewData.headerText.video} type="video/mp4" />
          </video>
        );
      }
      if (key === "document") {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={previewData.headerText.document}
          >
            <div className="doc-type">Document Media</div>
          </a>
        );
      }
    }
  };

  const quickReply = () => {
    return previewData.quickButton.map((item) => {
      let key = Object.keys(item);
      const index = key.indexOf("actionType");
      if (index > -1) {
        let data = key.splice(index, 1);
      }

      return key.map((keyitem) => (
        <div className="button-text">
          <p className="mb-1">{item[keyitem]}</p>
        </div>
      ));
    });
  };

  console.log(previewData);

  return (
    <div className="whatsapp">
      {previewData && (
        <>
          <ul>
            <li>
              {" "}
              <strong> Template name: </strong> {previewData.tampleName}{" "}
            </li>
            <li>
              <strong> Language: </strong> {previewData.language.label}{" "}
            </li>
            <li>
              {" "}
              <strong> Category: </strong>
              Authentication{" "}
            </li>
            <li>
              {" "}
              <strong> Type: </strong> {previewData.selectedOption}{" "}
            </li>
          </ul>
          <div
            style={{ backgroundImage: `url(${bg_whatsapp})` }}
            className="whatsapp-review whatsapp-bg"
          >
            <div>
              <div className="text-section">
                <div className="whats-app-header">
                  <div className="">
                    {" "}
                    <img src={companyimg ? companyimg : whatsapp_brand} />{" "}
                  </div>
                  <div className="">
                    {" "}
                    <p>{truncatedcompanyname} </p>{" "}
                  </div>
                </div>

                {/* <div className="body-message">
                  {displayWhatsAppPreview(
                    previewData.headerText.TemplateType,
                    previewData.headerText
                  )}
                  <img src={previewData.headerText.tempImage} className="img-fluid"/>
                  <p>
                    {" "}
                    {previewData.preBodyMessage
                      ? previewData.preBodyMessage
                      : previewData.bodyMessage}
                  </p>

                  <small>{previewData.footerText}</small>
                </div> */}

                <div className="body-message">
                <span style={{fontSize:"10px", fontWeight:"bold"}}>{previewData.headerText}</span>
                    <span style={{fontSize:"10px", fontWeight:"bold"}}>                       
                    {previewData.securityRecommendation ?                    
                       <span>{previewData.securityText}</span>
                     : null}
                     </span><br/>

                     <span style={{fontSize:"10px"}}>
                        {previewData.expiryTime ? `This code expires in ${previewData.selectTime ? previewData.selectTime : 5} minutes.` : null}
                     </span>

            {/* {displayWhatsAppPreview(
                previewdata.headerText.TemplateType,
                previewdata.headerText
              )} */}
              {/* {carouselContent ? (
                Object.keys(carouselContent).map((x, i) => {
                  let index = i + 1
                  return(
                  <p key={index}>
                    { index === previewdata.selectedCard ? carouselContent[x] : null}
                  </p>
                )})
              ) : null
             } */}

              <small>{previewData.footerText}</small>
              {/* {previewData.buttonType === 'choose button' ? null : ( */}
                <>
                  <hr />

                    <div className="button-text">
                      <a>
                        <p>
                        <FontAwesomeIcon style={{color:'2a9df4'}} icon={faCopy} />
                          <span style={{color:'#2a9df4'}} className="ml-2">{previewData.buttonCopyCode}</span>
                        </p>
                        {previewData.selectedOption === 'one_tap' || previewData.selectedOption === 'zero_tap' ? 
                         <p className="mt-3">
                         <FontAwesomeIcon style={{color:'2a9df4'}} icon={faFill} />
                           <span style={{color:'#2a9df4'}} className="ml-2">{previewData.autofill}</span>
                         </p> : null}                     
                      </a>
                    </div>
                 
                  </>
             
              
            </div>

                {/* {previewData.Callbutton[0] && (
                  <>
                    {previewData.Callbutton.map((item, index) => (
                      <div className="button-text">
                        <a href={`tel:+${item.countryCode}${item.phone}`}>
                          {" "}
                          <p key={index}>
                            {" "}
                            <FontAwesomeIcon icon={faPhone} /> {item.text}{" "}
                          </p>{" "}
                        </a>
                      </div>
                    ))}
                  </>
                )}

                {previewData.Urlbutton[0] && (
                  <>
                    {previewData.Urlbutton.map((item, index) => (
                      <div className="button-text">
                        <a href={`${item.url}`}>
                          {" "}
                          <p key={index}>
                            {" "}
                            <FontAwesomeIcon icon={faGlobe} /> {item.urltext}{" "}
                          </p>{" "}
                        </a>
                      </div>
                    ))}
                  </>
                )}

                {previewData.Urlbutton2[0] && (
                  <>
                    {previewData.Urlbutton2.map((item, index) => (
                      <div className="button-text">
                        <a href={`${item.url}`}>
                          {" "}
                          <p key={index}>
                            {" "}
                            <FontAwesomeIcon icon={faGlobe} /> {item.urltext2}{" "}
                          </p>{" "}
                        </a>
                      </div>
                    ))}
                  </>
                )} */}

                {previewData.quickButton[0] && <>{quickReply()}</>}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default WhatsAppPreviewAuthentication;
