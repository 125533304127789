import React from 'react';
export const checkUserUrl = (url) => {
  if (url) {
    var mainURL = "console.authkey.io";
    const testURL= "staging.authkey.io";
    var localHost = "localhost:3000";
    if (url.indexOf(localHost) !== -1 || url.indexOf(mainURL) !== -1 || url.indexOf(testURL) !== -1) {
      return true;
    } else {
      return false;
    }
  }
};

export const dateFormate = (date) => {
  return (
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1) +
    "-" +
    ("0" + date.getDate()).slice(-2)
  );
};

export const formatDateToCustomFormat=(date)=> {
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  // Determine the suffix for the day (st, nd, rd, th)
  const daySuffix = (day) => {
    if (day > 3 && day < 21) return 'th'; // special case for 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  return `${month} ${day}${daySuffix(day)}, ${year}`;
}

export const charaterCount = (text) => {

  if (text === undefined || text === null) {
    return false
  } else {
    var newstr = text.replace(/ /g, " ");
    var patt = new RegExp("[^\x00-\x7F]");
    var res = patt.test(newstr);
    var charLength = text.replace(/{|}|\[|\]|~|\^|\||\\/g, "  ");
    var outputString = parseInt(charLength.length);
    let obj = {
      total: "",
      credit: "",
    };
    if (res) {
      if (outputString < 70) {
        obj["total"] = outputString;
        obj["credit"] = 1;
      } else if (outputString / 70 == 1) {
        obj["total"] = outputString;
        obj["credit"] = Math.round(outputString / 70);
      } else {
        obj["total"] = outputString;
        obj["credit"] = Math.ceil(outputString / 67);
      }
    } else {
      if (outputString < 160) {
        obj["total"] = outputString;
        obj["credit"] = 1;
      } else if (outputString / 160 == 1) {
        obj["total"] = outputString;
        obj["credit"] = Math.round(outputString / 160);
      } else {
        obj["total"] = outputString;
        obj["credit"] = Math.ceil(outputString / 153);
      }
    }

    return (
      <p className="sms-count mb-0">
        <small className='mr-2'>
          Character Used <strong id="totalWords">{obj.total}</strong>
        </small>
        <small> Credits <strong> {obj.credit}</strong>
        </small>
      </p>
    );
  }
};



const textareaCounters = {};

export const insertVariable = (textarea, formData, setFormData, type = 'body', activeIndex, carousels, setCarousels) => {
  // Get or initialize the counter for the current textarea
  const textareaId = textarea.id || "default";  // Fallback to 'default' if no id is provided
  if (!textareaCounters[textareaId]) {
    textareaCounters[textareaId] = 1;  // Initialize the counter for this textarea
  }

  // Get the current cursor position and textarea value
  const cursorPosition = textarea.selectionStart;
  const text = textarea.value;

  // Create the variable name dynamically based on the counter for the current textarea
  var variable = "";
  if (type === "body") {
    variable = `[var${textareaCounters[textareaId]}]`;
  } else {
    variable = `[title${textareaCounters[textareaId]}]`;
  }
  //const variable = `[var${textareaCounters[textareaId]}]`;

  // Increment the counter for the next variable
  textareaCounters[textareaId]++;

  // Insert the variable at the cursor position
  const updatedText = text.slice(0, cursorPosition) + variable + text.slice(cursorPosition);
  textarea.value = updatedText;

  if (formData.templateType === 'text') {
    // Update the state using setFormData
    setFormData((prevData) => ({
      ...prevData,
      content: updatedText,
    }));
  }

  console.log('updatedText', updatedText)

  if (formData.templateType === 'richcardstandalone') {
    if (type === 'title') {
      // Update the standaloneCard title for 'richcardstandalone'
      setFormData((prevData) => ({
        ...prevData,
        standaloneCard: {
          ...prevData.standaloneCard,
          card_title: updatedText,
        },
      }));
    }

    if (type === 'body') {
      // Update the standaloneCard description for 'richcardstandalone'
      setFormData((prevData) => ({
        ...prevData,
        standaloneCard: {
          ...prevData.standaloneCard,
          card_description: updatedText,
        },
      }));
    }
  }

  if (formData.templateType === 'richcardcarousel') {
    // For 'richcardcarousel', we update the specific carousel item based on activeIndex
    const updatedCarousels = [...carousels];

    if (type === 'title') {
      // Update the carousel title for 'richcardcarousel'
      updatedCarousels[activeIndex] = {
        ...updatedCarousels[activeIndex],
        card_title: updatedText,
      };
    }

    if (type === 'body') {
      // Update the carousel description for 'richcardcarousel'
      updatedCarousels[activeIndex] = {
        ...updatedCarousels[activeIndex],
        card_description: updatedText,
      };

    }

    setCarousels(updatedCarousels);

    // Update the carousels state
    setFormData((prevData) => ({
      ...prevData,
      carousels: updatedCarousels,
    }));

  }


  // Move the cursor position after the inserted variable
  textarea.selectionStart = cursorPosition + variable.length;
  textarea.selectionEnd = cursorPosition + variable.length;
  textarea.focus();
};

export const getMediaType = (url) => {
  const imageRegex = /\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i;
  const videoRegex = /\.(mp4|avi|mov|mkv|flv|webm|wmv)$/i;

  if (imageRegex.test(url)) {
    return 'image';
  } else if (videoRegex.test(url)) {
    return 'video';
  } else {
    return 'unknown'; // In case the URL is neither an image nor a video
  }
}