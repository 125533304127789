import React, { useEffect, useState } from "react";
import AdvanceDataTable from "../../UI/Table/AdvanceDataTable";
import AdminLayout from "../../UI/Layout/AdminLayout";
import Spining from "../../UI/Spining/Spining";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { ADMIN_RCS_SETTING_LIST } from "../../UI/Table/ColumnConst";
import Axios from "../../../axios/Axios";
import { NODE_HOST_URL } from "../../Dashboard/api/Api";
import { userloginDetails } from "../../Utils/AdminAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSearch, faTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
// import EmptyImg from ".../Dashboard/assets/img/create-project.png";  

const RcsSettingList = () => {
    const lStorage = userloginDetails();
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState([]);
    const [searchData, setSearchData] = useState('')
    const [openDeletePopup, setOpenDeletePopup] = useState(false)
    const [user_Id, setUserId] = useState('');
    const [username, setUsername] = useState('');
    useEffect(() => {
        fetchRcsSettingList();
    }, []);

    const fetchRcsSettingList = async () => {
        setLoading(true);
        try {
            const dataForList = {
                user_id: userId,
                token: userToken,
                method: "retrieve_setting",
            };
            const { data } = await Axios.post(
                `${NODE_HOST_URL}/admin/rcs_setting`,
                dataForList
            );
            if (data.success === true) {
                setListData(data.data);
            }
        } catch (error) {
            console.log(error.message);
        }
        setLoading(false)
    };

    const DeletePopup = (id, username) => {
        setOpenDeletePopup(true);
        setUserId(id);
        setUsername(username)
    }

    return (
        <>
            {loading && <Spining />}
            <AdminLayout>
                <div className="row">
                    <div className="col-3">
                        <h6>Bot List</h6>
                    </div>
                    <div className="col-9 mb-2">
                        <Link
                            type="button"
                            style={{
                                float: "right",
                                fontSize: "12px",
                                padding: "10px 10px 10px 10px",
                            }}
                            to={"/admin/rcs/rcs-setting-add"}
                            className="btn btn-success"
                        >
                            Add new
                        </Link>
                    </div>
                </div>

                {listData.length > 0 ?
                    <>
                        <div className='row mb-3'>
                            <div className='col-12 col-md-6'>
                                <div className="input-group float-md-right">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><FontAwesomeIcon icon={faSearch} /></span>
                                    </div>
                                    <input
                                        type="text"
                                        id="searchInput"
                                        name="searchdata"
                                        value={searchData}
                                        onChange={(e) => setSearchData(e.target.value)}
                                        className="form-control"
                                        placeholder="Search"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="table-responsive">
                            <table className="table table-striped table-sm">
                                <thead>
                                    <tr>
                                        <th className="d-xl-table-cell">ID</th>
                                        <th className="d-md-table-cell">User Name</th>
                                        <th className="d-md-table-cell">Bot ID</th>
                                        <th className="d-md-table-cell">Sender ID</th>
                                        <th className="d-md-table-cell">Type</th>
                                        <th className="d-md-table-cell">Route</th>
                                        <th className="d-md-table-cell">Description</th>
                                        <th className="d-md-table-cell">Date</th>
                                        <th className="d-md-table-cell">Status</th>
                                        <th className="d-md-table-cell">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listData
                                        .filter((x) => {
                                            return searchData.toLowerCase() === '' ? x : x.username.toLowerCase().includes(searchData);
                                        })
                                        .map((item, i) => (
                                            <tr key={i}>
                                                <td className="text-start">{item.user_id}</td>
                                                <td className="text-start">{item.username}</td>
                                                <td className="text-start">{item.bot_id}</td>
                                                <td className="text-start">{item.sender_id}</td>
                                                <td className="text-start">{item.type}</td>
                                                <td className="text-start">{item.route}</td>
                                                <td className="text-start">{item.description}</td>
                                                <td className="text-start">{dayjs(item.created).format("DD/MM/YYYY h:mm A")}</td>
                                                <td className="text-start">
                                                    {item.status === 1 ? (
                                                        <span style={{ color: "green" }}>Active</span>
                                                    ) : (
                                                        <span style={{ color: "red" }}>Inactive</span>
                                                    )}
                                                </td>
                                                <td className="text-start">
                                                    <div className="d-flex">
                                                        <Link
                                                            to={`/admin/rcs/rcs-setting-add/edit/${item._id}`}
                                                            className ="btn btn-outline-primary btn-sm mr-2"
                                                            >
                                                            <FontAwesomeIcon icon={faEdit} />
                                                        </Link>
                                                        <a
                                                            className="btn btn-outline-danger btn-sm"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => DeletePopup(item.user_id, item.username)}
                                                        >
                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>

                    </> :
                    <div className="text-center">
                        <div className="card-body">
                            {/* <img alt="Empty Data" className="project-empty-img" src={EmptyImg} /> */}
                            <p>No Data Yet</p>
                        </div>
                    </div>}

                {/* <AdvanceDataTable
          tableCell={ADMIN_RCS_SETTING_LIST}
          tableData={listData}
          isLoading={loading}
        /> */}

                {openDeletePopup &&
                    <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }} aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">{username}</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setOpenDeletePopup(false)}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <h6 style={{ color: "red" }}>Are You Sure you Want to Delete <b style={{ color: "black" }}>{username}</b> .</h6>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-info" data-dismiss="modal" onClick={() => setOpenDeletePopup(false)}>
                                        No
                                    </button>
                                    <button type="button" className="btn btn-danger" onClick={''}>Yes</button>
                                </div>
                            </div>
                        </div>
                    </div>}
            </AdminLayout>
        </>
    );
};

export default RcsSettingList;