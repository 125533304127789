import React, { useEffect, useState } from "react";
import AdvanceDataTable from "../UI/Table/AdvanceDataTable";
import { ADMIN_BILLING_SUMMARY, ADMIN_TOTAL_BILLING_SUMMARY } from "../UI/Table/ColumnConst";
import EmptyImg from "../Dashboard/assets/img/create-project.png";
import ReactDatePicker from "react-datepicker";
import AdminLayout from "../UI/Layout/AdminLayout";
import Title from "../UI/Text/Title";
import Spining from "../UI/Spining/Spining";
import { userloginDetails } from "../Utils/AdminAuth";
import Select from "react-select";
import Axios from "axios";
import { HOST_URL, NODE_HOST_URL } from "../Dashboard/api/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faCalendarAlt, faEdit, faSearch } from "@fortawesome/free-solid-svg-icons";

const AdminBilling = () => {
    const lStorage = userloginDetails();
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const adminType = lStorage.user.isAdmin;
    const [alert, setAlert] = useState({
        status: false,
        bgColor: "",
        errorMessage: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [fromdate, setFromdate] = useState(new Date());
    const [todate, setTodate] = useState(new Date());
    const [allusers, setAllusers] = useState([]);
    const [selecteduser, setSelectedusers] = useState("");
    const [billData, setBillData] = useState([]);
    const [searchData, setSearchData] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(50);
    const [openAccordion, setOpenAccordion] = useState(null);
    const [parentList, setParentList] = useState([])
    const [selectedParent, setSelectedParent] = useState("");
    const [email, setEmail] = useState([]);
    const [sms, setSms] = useState([]);
    const [voice, setVoice] = useState([]);
    const [whatsappApi, setWhatsappApi] = useState([]);
    const [whatsappCampaign, setWhatsappCampaign] = useState([]);
    const [agentDetails, setAgentDetails] = useState([]);
    const [agentCount, setAgentCount] = useState('')
    const [FinalData, setFinalData] = useState({
        totalSms: 0,
        totalVoice: 0,
        totalEmail: 0,
        totalWhatsapp: 0,
        totalRevenue: 0
    });
    const [totalWhatsappCost, setTotalWhatsappCost] = useState(0);
    const [whatsappConversationSummary, setWhatsappConversationSummary] = useState([]);
    const [rcs, setRcs] = useState([]);

    const toggleAccordion = async (id, index) => {
        setOpenAccordion(openAccordion === index ? null : index);
        setIsLoading(true);
        const ListParams = {
            method: "detailReport",
            user_id: userId,
            token: userToken,
            fromDate: currentDate(fromdate),
            toDate: currentDate(todate),
            retr_user_id: id,
        }
        try {
            const { data } = await Axios.post(`${NODE_HOST_URL}/admin/billing_report`, ListParams)
            if (data.success === true) {
                // console.log(data.data)
                setEmail(data.data.email);
                setWhatsappApi(data.data.whatsapp_api)
                setWhatsappCampaign(data?.data?.whatsapp_campaign)
                setSms(data.data.sms);
                setVoice(data.data.voice);
                totalData(data?.data?.whatsapp_campaign)
                setAgentDetails(data?.data?.agent_details)
                setAgentCount(data?.data?.agent_count)
                totalActionData(data?.data?.whatsapp_campaign, data.data.whatsapp_api)
                setWhatsappConversationSummary(data?.data?.wp_conv_summary)
                setRcs(data?.data?.rcs)
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };
    console.log(rcs)

    const totalActionData = (whatsappCampaign, whatsappApi) => {
        let totalWhatsappApiCost = 0;
        let totalWhatsappCampaignCost = 0;


        whatsappApi.forEach((x) => {
            totalWhatsappApiCost += parseFloat(x.total_billable_cost)
        });


        whatsappCampaign.forEach((x) => {
            totalWhatsappCampaignCost += parseFloat(x.per_wp_price) * parseFloat(x.total_delivered);
        });

        // Log the values
        console.log("totalWhatsappApiCost : ", totalWhatsappApiCost);
        console.log("totalWhatsappCampaignCost : ", totalWhatsappCampaignCost);
        setTotalWhatsappCost(parseFloat(totalWhatsappApiCost + totalWhatsappCampaignCost))

        // If you want to return the total cost or set it elsewhere
        // setTotalWhatsappCost(totalWhatsappApiCost + totalWhatsappCampaignCost);
    }

    console.log(totalWhatsappCost)



    const totalData = (data) => {
        let totalcost = [];

        data.map((x) => {
            return (
                totalcost = x.per_wp_price * x.total_delivered
            )
        });

    }

    const customStyles = {
        control: (provided) => ({
            ...provided,
            cursor: "pointer",
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        option: (style) => ({
            ...style,
            fontSize: "12px",
        }),
    };

    useEffect(() => {
        ParentList();
        usersList();
    }, []);

    const ParentList = async () => {
        const parentParams = {
            user_id: userId,
            method: "retrieve",
            type: adminType,
            token: userToken
        }
        try {
            const { data } = await Axios.post(`${HOST_URL}/employee_user_list.php`, parentParams)
            if (data.success === true) {
                let result = data.data
                const formattedParent = result.map(user => ({
                    value: user.id,
                    label: user.email,
                    type: user.user_type
                }));
                setParentList(formattedParent);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleParentChange = (selectedOption) => {
        setSelectedParent(selectedOption);
        if (selectedOption) {
            usersList(selectedOption.value);
        } else {
            usersList();
        }
    };

    const usersList = async (parentID) => {
        let response = await fetch(`${HOST_URL}/admin_user_list.php?user_id=${userId}&method=retrieve_all_user&type=${adminType}&token=${userToken}`);
        let result = await response.json();

        if (result.success) {
            const formattedUsers = result.data.map(user => ({
                value: user.id,
                label: user.email,
                parent: user.parent
            }));

            const newData = formattedUsers.filter(x => x.parent === parentID)

            if (parentID) {
                setAllusers(newData)
            }
            else {
                setAllusers(formattedUsers);
            }
        }
    }

    const currentDate = (currentDate) => {
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    };

    const fromdateHandler = (data) => {
        setFromdate(data);
    };

    const todateHandler = (data) => {
        setTodate(data);
    };

    const handlePagination = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = billData?.slice(indexOfFirstRecord, indexOfLastRecord);
    const totalPages = Math.ceil(billData?.length / recordsPerPage);

    const search = async () => {
        setIsLoading(true);
        setOpenAccordion(false);
        let paramdata = {
            method: "summary",
            user_id: userId,
            token: userToken,
            fromdate: currentDate(fromdate),
            todate: currentDate(todate),
            retr_user_id: selecteduser ? selecteduser.value : "all",
            user_type: selectedParent ? selectedParent.type : adminType,
            uid: selectedParent ? selectedParent.value : "all"
        }
        try {
            const { data } = await Axios.post(`${NODE_HOST_URL}/admin/billing_summary`, paramdata)
            if (data.success === true) {
                // console.log(data.data);
                setBillData(data.data);
                TotalCountData(data.data);
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);

    }

    const TotalCountData = (data) => {
        let totalSms = 0;
        let totalVoice = 0;
        let totalEmail = 0;
        let totalWhatsapp = 0;
        let totalRevenue = 0;

        data.forEach((x) => {
            totalSms += parseFloat(x.total_sms_summary) || 0;
            totalVoice += parseFloat(x.total_voice_summary) || 0;
            totalEmail += parseFloat(x.total_email_summary) || 0;
            totalWhatsapp += parseFloat(x.grand_total_summary) || 0;
            totalRevenue += parseFloat(x.total_sms_summary + x.total_voice_summary + x.total_email_summary + x.grand_total_summary)
        });

        // console.log(totalSms);
        // console.log(totalEmail);
        // console.log(totalVoice);
        // console.log(totalWhatsapp);
        // console.log(totalRevenue);
        console.log(billData)

        setFinalData({
            totalSms: totalSms,
            totalVoice: totalVoice,
            totalEmail: totalEmail,
            totalWhatsapp: totalWhatsapp,
            totalRevenue: totalRevenue
        });
    };

   


    return (
        <div>
            <AdminLayout>
                <Title isButton={false}> Billing Summary </Title>
                <div className="row">
                    <div className="col-sm-2">
                        <div className="card">
                            <div className="card-body p-2">
                                <h5 className="card-title mb-0">Total Sms</h5>
                                <h6 className="mt-0 mb-0">{FinalData.totalSms || 0}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <div className="card">
                            <div className="card-body p-2">
                                <h5 className="card-title mb-0">Total Voice</h5>
                                <h6 className="mt-0 mb-0">{FinalData.totalVoice || 0}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <div className="card">
                            <div className="card-body p-2">
                                <h5 className="card-title mb-0">Total Email</h5>
                                <h6 className="mt-0 mb-0">{FinalData.totalEmail || 0}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <div className="card">
                            <div className="card-body p-2">
                                <h5 className="card-title mb-0">Total Whatsapp</h5>
                                <h6 className="mt-0 mb-0">{FinalData.totalWhatsapp || 0}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <div className="card">
                            <div className="card-body p-2">
                                <h5 className="card-title mb-0">Total Revenue</h5>
                                <h6 className="mt-0 mb-0">{FinalData.totalRevenue || 0}</h6>
                            </div>
                        </div>
                    </div>
                </div>
                {alert.status === true ? (
                    <div className={alert.bgColor} role="alert">
                        <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                        <div className="">{alert.errorMessage}</div>
                    </div>
                ) : null}
                <div className="row">
                    <div className="">
                        <div className="card">
                            {isLoading && <Spining />}

                            <div className="card-body">
                                <div className="filter-option">
                                    <h6 className="card-subtitle text-muted">Campaign Summary</h6>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="input-group drop-down-icons mb-2 mr-sm-2 table2">
                                                <div className="input-group mb-2 mr-sm-2">
                                                    {/* <div className="input-group-text">User </div> */}
                                                    <Select
                                                        onChange={handleParentChange}
                                                        options={parentList}
                                                        value={selectedParent}
                                                        isSearchable={true}
                                                        placeholder="All Parent"
                                                        isClearable={true}
                                                        styles={customStyles}
                                                    // isDisabled={this.state.updateId ? true : false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-group drop-down-icons mb-2 mr-sm-2 table3">
                                                <div className="input-group mb-2 mr-sm-2">
                                                    {/* <div className="input-group-text">User </div> */}
                                                    <Select
                                                        onChange={(selectedOption) => setSelectedusers(selectedOption)}
                                                        options={allusers}
                                                        value={selecteduser}
                                                        isSearchable={true}
                                                        placeholder="All Users"
                                                        isClearable={true}
                                                        styles={customStyles}
                                                    // isDisabled={this.state.updateId ? true : false}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-md-5">
                                            <div className="input-group mb-2 mr-sm-2 table4">
                                                <div className="input-group-text">
                                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                                </div>

                                                <ReactDatePicker
                                                    className="form-control btn-block"
                                                    dateFormat="dd/MM/yyyy"
                                                    value={fromdate}
                                                    onChange={fromdateHandler}
                                                    selected={fromdate}
                                                    placeholderText="From Date"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="input-group mb-2 mr-sm-2 table5">
                                                <div className="input-group-text">
                                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                                </div>
                                                <ReactDatePicker
                                                    className="form-control btn-block"
                                                    dateFormat="dd/MM/yyyy"
                                                    value={todate}
                                                    onChange={todateHandler}
                                                    selected={todate}
                                                    placeholderText="Date To"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <button type="button" onClick={search} className="btn btn-sm btn-info float-right">Submit</button>
                                        </div>

                                    </div>
                                </div>

                                {/* <form className="form-row mb-3" id="EntityFormidFrom">
                                    <div className="col-md-12 d-flex justify-content-center mt-3">
                                        <button type="button" onClick={search} className="btn btn-sm btn-success">Submit</button>
                                    </div>
                                </form> */}

                                <div className="card">
                                    {/* <AdvanceDataTable
                                        tableName={""}
                                        tableCell={ADMIN_TOTAL_BILLING_SUMMARY}
                                        tableData={billData}
                                        isLoading={isLoading}
                                        isError={alert.errorMessage}
                                        filterstatus={true}
                                        pagination={true}
                                    /> */}

                                    <div className="">
                                        <div className="">

                                            <div className="p-2">

                                                <div className="">
                                                    <div className=" flex-fill">
                                                        {billData.length > 0 ?
                                                            <>
                                                                <div className='row mb-3'>
                                                                    <div className='col-6'></div>
                                                                    <div className='col-6'>
                                                                        <div className="input-group float-right">
                                                                            <div className="input-group-prepend">
                                                                                <span className="input-group-text"><FontAwesomeIcon icon={faSearch} /></span>
                                                                            </div>
                                                                            <input
                                                                                type="text"
                                                                                id="searchInput"
                                                                                name="searchdata"
                                                                                value={searchData}
                                                                                onChange={(e) => setSearchData(e.target.value)}
                                                                                className="form-control"
                                                                                placeholder="Search"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped table-sm">
                                                                        <thead>
                                                                            <tr>
                                                                                <th className="d-xl-table-cell">username</th>
                                                                                <th className="d-md-table-cell">SMS</th>
                                                                                <th className="d-md-table-cell">Voice</th>
                                                                                <th className="d-md-table-cell">Email</th>
                                                                                <th className="d-md-table-cell">Whatsapp</th>
                                                                                {/* <th className="d-md-table-cell">RCS</th> */}
                                                                                <th className="d-md-table-cell">Revenue</th>
                                                                                <th className="d-md-table-cell">Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {currentRecords
                                                                                .filter((x) => {
                                                                                    return searchData.toLowerCase() === '' ? x : x.username.toLowerCase().includes(searchData);
                                                                                })
                                                                                .map((item, i) => (
                                                                                    <React.Fragment key={i}>
                                                                                        <tr>
                                                                                            <td className="d-xl-table-cell text-start">{item.username}</td>
                                                                                            <td className="d-xl-table-cell text-end">{item.total_sms_summary}</td>
                                                                                            <td className="d-xl-table-cell text-end">{item.total_voice_summary}</td>
                                                                                            <td className="d-xl-table-cell text-end">{item.total_email_summary}</td>
                                                                                            <td className="d-md-table-cell text-end">{item.grand_total_summary}</td>
                                                                                            {/* <td className="d-md-table-cell text-end">{(item.total_rcs_summary).toFixed(2)}</td> */}
                                                                                            <td className="d-md-table-cell text-end">{item.total_sms_summary + item.total_voice_summary + item.total_email_summary + item.grand_total_summary}</td>
                                                                                            <td className="text-center">
                                                                                                {/* Edit Button */}
                                                                                                <span
                                                                                                    // className="btn btn-primary"
                                                                                                    style={{ cursor: "pointer" }}
                                                                                                    onClick={() => toggleAccordion(item.user_id, i)} // Toggle accordion on button click
                                                                                                >
                                                                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                        {/* Accordion for the current row */}
                                                                                        <tr>
                                                                                            <td colSpan="6">
                                                                                                <div
                                                                                                    id={`collapseRow${i}`}
                                                                                                    className={`accordion-collapse collapse ${openAccordion === i ? 'show' : ''}`}
                                                                                                    aria-labelledby={`headingRow${i}`}
                                                                                                >
                                                                                                    <div className="accordion-body">
                                                                                                        {sms.length > 0 && sms.some(item =>
                                                                                                            item.total_delivered || item.total_failed || item.total_messages || item.total_cost
                                                                                                        ) ?
                                                                                                            <>
                                                                                                                <strong>SMS</strong>
                                                                                                                <div className="table-responsive">
                                                                                                                    <table className="table table-striped table-sm">
                                                                                                                        <thead>
                                                                                                                            <tr>
                                                                                                                                <th style={{ backgroundColor: "#007FFF" }} className="d-xl-table-cell">Total</th>
                                                                                                                                <th style={{ backgroundColor: "#007FFF" }} className="d-xl-table-cell">Total Cost</th>
                                                                                                                                <th style={{ backgroundColor: "#007FFF" }} className="d-xl-table-cell">Deliver</th>
                                                                                                                                <th style={{ backgroundColor: "#007FFF" }} className="d-xl-table-cell">Failed</th>
                                                                                                                            </tr>
                                                                                                                        </thead>
                                                                                                                        <tbody>
                                                                                                                            {sms.map((item, i) => (
                                                                                                                                <React.Fragment key={i}>
                                                                                                                                    <tr>
                                                                                                                                        <td className="d-xl-table-cell text-end">{item.total_messages}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{item.total_cost}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{item.total_delivered}</td>
                                                                                                                                        <td className="d-xl-table-cell text-start">{item.total_failed}</td>
                                                                                                                                    </tr>
                                                                                                                                </React.Fragment>
                                                                                                                            ))}
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </div>
                                                                                                            </> : null}

                                                                                                        {voice.length > 0 && voice.some(item =>
                                                                                                            item.total_success || item.total_failed || item.total_sent ||
                                                                                                            item.total_submitted || item.total_cost
                                                                                                        ) ?
                                                                                                            <><strong className="mt-2">Voice</strong>
                                                                                                                <div className="table-responsive">
                                                                                                                    <table className="table table-striped table-sm">
                                                                                                                        <thead>
                                                                                                                            <tr>
                                                                                                                                <th style={{ backgroundColor: "#DA70D6" }} className="d-xl-table-cell">Total</th>
                                                                                                                                <th style={{ backgroundColor: "#DA70D6" }} className="d-xl-table-cell">Submitted</th>
                                                                                                                                <th style={{ backgroundColor: "#DA70D6" }} className="d-xl-table-cell">Sent</th>
                                                                                                                                <th style={{ backgroundColor: "#DA70D6" }} className="d-xl-table-cell">Success</th>
                                                                                                                                <th style={{ backgroundColor: "#DA70D6" }} className="d-xl-table-cell">Failed</th>
                                                                                                                            </tr>
                                                                                                                        </thead>
                                                                                                                        <tbody>
                                                                                                                            {voice.map((item, i) => (
                                                                                                                                <React.Fragment key={i}>
                                                                                                                                    <tr>
                                                                                                                                        <td className="d-xl-table-cell text-end">{item.total_cost}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{item.total_submitted}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{item.total_sent}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{item.total_success}</td>
                                                                                                                                        <td className="d-xl-table-cell text-start">{item.total_failed}</td>
                                                                                                                                    </tr>
                                                                                                                                </React.Fragment>
                                                                                                                            ))}
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </div>
                                                                                                            </> : null}

                                                                                                        {email.length > 0 && email.some(item =>
                                                                                                            item.total_delivered || item.total_clicked || item.total_bounced ||
                                                                                                            item.total_opened || item.total_sent || item.total_submitted
                                                                                                        ) ? (
                                                                                                            <>
                                                                                                                <strong className="mt-2">Email</strong>
                                                                                                                <div className="table-responsive">
                                                                                                                    <table className="table table-striped table-sm">
                                                                                                                        <thead>
                                                                                                                            <tr>
                                                                                                                                <th style={{ backgroundColor: "#FF4433" }} className="d-xl-table-cell">Deliver</th>
                                                                                                                                <th style={{ backgroundColor: "#FF4433" }} className="d-xl-table-cell">Click</th>
                                                                                                                                <th style={{ backgroundColor: "#FF4433" }} className="d-xl-table-cell">Bounce</th>
                                                                                                                                <th style={{ backgroundColor: "#FF4433" }} className="d-xl-table-cell">Open</th>
                                                                                                                                <th style={{ backgroundColor: "#FF4433" }} className="d-xl-table-cell">Sent</th>
                                                                                                                                <th style={{ backgroundColor: "#FF4433" }} className="d-xl-table-cell">Submit</th>
                                                                                                                            </tr>
                                                                                                                        </thead>
                                                                                                                        <tbody>
                                                                                                                            {email.map((item, i) => (
                                                                                                                                <React.Fragment key={i}>
                                                                                                                                    <tr>
                                                                                                                                        <td className="d-xl-table-cell text-start">{item.total_delivered}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{item.total_clicked}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{item.total_bounced}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{item.total_opened}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{item.total_sent}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{item.total_submitted}</td>
                                                                                                                                    </tr>
                                                                                                                                </React.Fragment>
                                                                                                                            ))}
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </div>
                                                                                                            </>
                                                                                                        ) : null}


                                                                                                        {whatsappApi.length > 0 && whatsappApi.some(item =>
                                                                                                            item.total_billable_cost || item.total_billable_count || item.total_delivered ||
                                                                                                            item.total_failed || item.total_non_billable_count || item.total_nonbillable_cost || item.total_read
                                                                                                            || item.total_sent || item.total_submitted
                                                                                                        ) ||
                                                                                                            whatsappCampaign.length > 0 && whatsappCampaign.some(item =>
                                                                                                                item.country_code || item.template_type || item.per_wp_price ||
                                                                                                                item.total_count || item.total_delivered || item.total_failed || item.total_other_count
                                                                                                            )
                                                                                                            ? <>
                                                                                                                <div className="row">
                                                                                                                    <div className="col-6">
                                                                                                                        <b>Whatsapp</b>
                                                                                                                    </div>
                                                                                                                    <div className="col-6">
                                                                                                                        <span className="float-right"> <b>Whatsapp Cost :</b> {parseFloat(totalWhatsappCost).toFixed(2)} </span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="whatsapp-box">
                                                                                                                    {/* This will be the title for the box */}

                                                                                                                    {whatsappApi.length > 0 && whatsappApi.some(item =>
                                                                                                                        item.total_billable_cost || item.total_billable_count || item.total_delivered ||
                                                                                                                        item.total_failed || item.total_non_billable_count || item.total_nonbillable_cost || item.total_read
                                                                                                                        || item.total_sent || item.total_submitted
                                                                                                                    ) ?
                                                                                                                        <>
                                                                                                                            <strong className="mt-2">Whatsapp Api</strong>
                                                                                                                            <div className="table-responsive">
                                                                                                                                <table className="table table-striped table-sm">
                                                                                                                                    <thead>
                                                                                                                                        <tr>
                                                                                                                                            <th style={{ backgroundColor: "#85A98F" }} className="d-xl-table-cell">Template Type</th>
                                                                                                                                            <th style={{ backgroundColor: "#85A98F" }} className="d-xl-table-cell">Billable Cost</th>
                                                                                                                                            <th style={{ backgroundColor: "#85A98F" }} className="d-xl-table-cell">Non Billable Cost</th>
                                                                                                                                            <th style={{ backgroundColor: "#85A98F" }} className="d-xl-table-cell">Billable Count</th>
                                                                                                                                            <th style={{ backgroundColor: "#85A98F" }} className="d-xl-table-cell">Non Billable Count</th>
                                                                                                                                            <th style={{ backgroundColor: "#85A98F" }} className="d-xl-table-cell">Sent</th>
                                                                                                                                            <th style={{ backgroundColor: "#85A98F" }} className="d-xl-table-cell">Submitted</th>
                                                                                                                                            <th style={{ backgroundColor: "#85A98F" }} className="d-xl-table-cell">Delivered</th>
                                                                                                                                            <th style={{ backgroundColor: "#85A98F" }} className="d-xl-table-cell">Read</th>
                                                                                                                                            <th style={{ backgroundColor: "#85A98F" }} className="d-xl-table-cell">Failed</th>
                                                                                                                                            <th style={{ backgroundColor: "#85A98F" }} className="d-xl-table-cell">Total Submission</th>
                                                                                                                                        </tr>
                                                                                                                                    </thead>
                                                                                                                                    <tbody>
                                                                                                                                        {whatsappApi.map((item, i) => (
                                                                                                                                            <React.Fragment key={i}>
                                                                                                                                                <tr>
                                                                                                                                                    <td className="d-xl-table-cell text-end">{item.message_type === 1 ? <span>Marketing</span> : item.message_type === 2 ? <span>Utility</span> : item.message_type === 3 ? <span>Authentication</span> : item.message_type}</td>
                                                                                                                                                    <td className="d-xl-table-cell text-start">
                                                                                                                                                        {parseFloat(item.total_billable_cost).toFixed(2)}
                                                                                                                                                    </td>

                                                                                                                                                    <td className="d-xl-table-cell text-end">{item.total_nonbillable_cost}</td>
                                                                                                                                                    <td className="d-xl-table-cell text-end">{item.total_billable_count}</td>
                                                                                                                                                    <td className="d-xl-table-cell text-end">{item.total_non_billable_count}</td>
                                                                                                                                                    <td className="d-xl-table-cell text-end">{item.total_sent}</td>
                                                                                                                                                    <td className="d-xl-table-cell text-end">{item.total_submitted}</td>
                                                                                                                                                    <td className="d-xl-table-cell text-end">{item.total_delivered}</td>
                                                                                                                                                    <td className="d-xl-table-cell text-end">{item.total_read}</td>
                                                                                                                                                    <td className="d-xl-table-cell text-end">{item.total_failed}</td>
                                                                                                                                                    <td className="d-xl-table-cell text-end">{parseFloat(item.total_submitted) + parseFloat(item.total_billable_cost) + parseFloat(item.total_billable_count) + parseFloat(item.total_delivered) + parseFloat(item.total_failed) + parseFloat(item.total_non_billable_count) + parseFloat(item.total_nonbillable_cost) + parseFloat(item.total_read) + parseFloat(item.total_sent) + parseFloat(item.total_submitted)}</td>
                                                                                                                                                </tr>
                                                                                                                                            </React.Fragment>
                                                                                                                                        ))}
                                                                                                                                    </tbody>
                                                                                                                                </table>
                                                                                                                            </div>
                                                                                                                        </>
                                                                                                                        : null
                                                                                                                    }

                                                                                                                    {whatsappCampaign.length > 0 && whatsappCampaign.some(item =>
                                                                                                                        item.country_code || item.template_type || item.per_wp_price ||
                                                                                                                        item.total_count || item.total_delivered || item.total_failed || item.total_other_count
                                                                                                                    ) ?
                                                                                                                        <>
                                                                                                                            <strong className="mt-2">Whatsapp Campaign</strong>
                                                                                                                            <div className="table-responsive">
                                                                                                                                <table className="table table-striped table-sm">
                                                                                                                                    <thead>
                                                                                                                                        <tr>
                                                                                                                                            <th style={{ backgroundColor: "#85A98F" }} className="d-xl-table-cell">Template Type</th>
                                                                                                                                            <th style={{ backgroundColor: "#85A98F" }} className="d-xl-table-cell">Country Code</th>
                                                                                                                                            <th style={{ backgroundColor: "#85A98F" }} className="d-xl-table-cell">Per Wp Price </th>
                                                                                                                                            <th style={{ backgroundColor: "#85A98F" }} className="d-xl-table-cell">Total Count</th>
                                                                                                                                            <th style={{ backgroundColor: "#85A98F" }} className="d-xl-table-cell">Total Other Count</th>
                                                                                                                                            <th style={{ backgroundColor: "#85A98F" }} className="d-xl-table-cell">Total Delivered</th>
                                                                                                                                            <th style={{ backgroundColor: "#85A98F" }} className="d-xl-table-cell">Total Failed</th>
                                                                                                                                            <th style={{ backgroundColor: "#85A98F" }} className="d-xl-table-cell">Total Cost</th>
                                                                                                                                        </tr>
                                                                                                                                    </thead>
                                                                                                                                    <tbody>
                                                                                                                                        {whatsappCampaign.map((item, i) => {
                                                                                                                                            let totalCostSum = 0;
                                                                                                                                            let totalCost = item.per_wp_price * item.total_delivered

                                                                                                                                            totalCostSum += parseFloat(totalCost);
                                                                                                                                            return (
                                                                                                                                                <React.Fragment key={i}>
                                                                                                                                                    <tr>
                                                                                                                                                        <td className="d-xl-table-cell text-end">{item.template_type === 1 ? <span>Marketing</span> : item.template_type === 2 ? <span>Utility</span> : item.template_type === 3 ? <span>Authentication</span> : item.template_type}</td>
                                                                                                                                                        <td className="d-xl-table-cell text-start">{item.country_code}</td>
                                                                                                                                                        <td className="d-xl-table-cell text-end">{item.per_wp_price}</td>
                                                                                                                                                        <td className="d-xl-table-cell text-end">{item.total_count}</td>
                                                                                                                                                        <td className="d-xl-table-cell text-end">{item.total_other_count}</td>
                                                                                                                                                        <td className="d-xl-table-cell text-end">{item.total_delivered}</td>
                                                                                                                                                        <td className="d-xl-table-cell text-end">{item.total_failed}</td>
                                                                                                                                                        <td className="d-xl-table-cell text-end">{totalCost.toFixed(2)}</td>
                                                                                                                                                    </tr>
                                                                                                                                                </React.Fragment>
                                                                                                                                            )
                                                                                                                                        })}
                                                                                                                                    </tbody>
                                                                                                                                </table>
                                                                                                                            </div>
                                                                                                                        </>
                                                                                                                        : null
                                                                                                                    }

                                                                                                                </div> </> : null}

                                                                                                                {rcs.others_count || rcs.sent_count || rcs.delivered_count || rcs.read_count || rcs.per_rcs_price || rcs.total_cost || rcs.country_code || rcs.type
                                                                                                         ?
                                                                                                            <><strong className="mt-2">RCS</strong>
                                                                                                                <div className="table-responsive">
                                                                                                                    <table className="table table-striped table-sm">
                                                                                                                        <thead>
                                                                                                                            <tr>
                                                                                                                                <th style={{ backgroundColor: "#007FFF" }} className="d-xl-table-cell">Country Code</th>
                                                                                                                                <th style={{ backgroundColor: "#007FFF" }} className="d-xl-table-cell">Delivered</th>
                                                                                                                                <th style={{ backgroundColor: "#007FFF" }} className="d-xl-table-cell">Read</th>
                                                                                                                                <th style={{ backgroundColor: "#007FFF" }} className="d-xl-table-cell">Sent</th>
                                                                                                                                <th style={{ backgroundColor: "#007FFF" }} className="d-xl-table-cell">Other</th>
                                                                                                                                <th style={{ backgroundColor: "#007FFF" }} className="d-xl-table-cell">Per RCS Price</th>
                                                                                                                                <th style={{ backgroundColor: "#007FFF" }} className="d-xl-table-cell">Total Cost</th>
                                                                                                                                <th style={{ backgroundColor: "#007FFF" }} className="d-xl-table-cell">Type</th>
                                                                                                                            </tr>
                                                                                                                        </thead>
                                                                                                                        <tbody>
                                                                                                                                <React.Fragment key={i}>
                                                                                                                                    <tr>
                                                                                                                                        <td className="d-xl-table-cell text-end">{rcs.country_code}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{rcs.delivered_count}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{rcs.read_count}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{rcs.sent_count}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{rcs.others_count}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{rcs.per_rcs_price}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{(rcs.total_cost).toFixed(2)}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{rcs.type}</td>
                                                                                                                                    </tr>
                                                                                                                                </React.Fragment>
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </div>
                                                                                                            </> : null}

                                                                                                                {/* {rcs.length > 0 && rcs.some(item =>
                                                                                                            item.others_count || item.sent_count || item.failed_count || item.delivered_count || item.read_count || item.per_rcs_price || item.total_cost || item.country_code || item.type
                                                                                                        ) ?
                                                                                                            <><strong className="mt-2">RCS</strong>
                                                                                                                <div className="table-responsive">
                                                                                                                    <table className="table table-striped table-sm">
                                                                                                                        <thead>
                                                                                                                            <tr>
                                                                                                                                <th style={{ backgroundColor: "#FFA500" }} className="d-xl-table-cell">Country Code</th>
                                                                                                                                <th style={{ backgroundColor: "#FFA500" }} className="d-xl-table-cell">Delivered</th>
                                                                                                                                <th style={{ backgroundColor: "#FFA500" }} className="d-xl-table-cell">Failed</th>
                                                                                                                                <th style={{ backgroundColor: "#FFA500" }} className="d-xl-table-cell">Read</th>
                                                                                                                                <th style={{ backgroundColor: "#FFA500" }} className="d-xl-table-cell">Sent</th>
                                                                                                                                <th style={{ backgroundColor: "#FFA500" }} className="d-xl-table-cell">Other</th>
                                                                                                                                <th style={{ backgroundColor: "#FFA500" }} className="d-xl-table-cell">Per RCS Price</th>
                                                                                                                                <th style={{ backgroundColor: "#FFA500" }} className="d-xl-table-cell">Total Cost</th>
                                                                                                                                <th style={{ backgroundColor: "#FFA500" }} className="d-xl-table-cell">Type</th>
                                                                                                                            </tr>
                                                                                                                        </thead>
                                                                                                                        <tbody>
                                                                                                                            {rcs.map((item, i) => (
                                                                                                                                <React.Fragment key={i}>
                                                                                                                                    <tr>
                                                                                                                                        <td className="d-xl-table-cell text-end">{item.country_code}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{item.delivered_count}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{item.failed_count}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{item.read_count}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{item.sent_count}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{item.others_count}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{item.per_rcs_price}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{(item.total_cost).toFixed(2)}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{item.type}</td>
                                                                                                                                    </tr>
                                                                                                                                </React.Fragment>
                                                                                                                            ))}
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </div>
                                                                                                            </> : null} */}


                                                                                                        {agentDetails.length > 0 && agentDetails.some(item =>
                                                                                                            item.allow_agent || item.peragent_cost
                                                                                                        ) ?
                                                                                                            <><strong className="mt-2">Agent Details</strong>
                                                                                                                <div className="table-responsive">
                                                                                                                    <table className="table table-striped table-sm">
                                                                                                                        <thead>
                                                                                                                            <tr>
                                                                                                                                <th style={{ backgroundColor: "#85A98F" }} className="d-xl-table-cell">Allow Agent</th>
                                                                                                                                <th style={{ backgroundColor: "#85A98F" }} className="d-xl-table-cell">Per Agent Cost</th>
                                                                                                                            </tr>
                                                                                                                        </thead>
                                                                                                                        <tbody>
                                                                                                                            {agentDetails.map((item, i) => {
                                                                                                                                let totalCostSum = 0;
                                                                                                                                let totalCost = item.per_wp_price * item.total_delivered

                                                                                                                                totalCostSum += parseFloat(totalCost);
                                                                                                                                return (
                                                                                                                                    <>
                                                                                                                                        <React.Fragment key={i}>
                                                                                                                                            <tr>
                                                                                                                                                <td className="d-xl-table-cell text-start">{item.allow_agent}</td>
                                                                                                                                                <td className="d-xl-table-cell text-end">{item.peragent_cost}</td>
                                                                                                                                            </tr>
                                                                                                                                        </React.Fragment>
                                                                                                                                    </>
                                                                                                                                )
                                                                                                                            })}

                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </div>
                                                                                                            </> : null}

                                                                                                        {whatsappConversationSummary.length > 0 && whatsappConversationSummary.some(item =>
                                                                                                            item._id || item.total_conv_summary
                                                                                                        ) ?
                                                                                                            <><strong className="mt-2">Whatsapp Conversation Summary</strong>
                                                                                                                <div className="table-responsive">
                                                                                                                    <table className="table table-striped table-sm">
                                                                                                                        <thead>
                                                                                                                            <tr>
                                                                                                                                <th style={{ backgroundColor: "#FFA500" }} className="d-xl-table-cell">ID</th>
                                                                                                                                <th style={{ backgroundColor: "#FFA500" }} className="d-xl-table-cell">Total Conversation Summary</th>
                                                                                                                            </tr>
                                                                                                                        </thead>
                                                                                                                        <tbody>
                                                                                                                            {whatsappConversationSummary.map((item, i) => (
                                                                                                                                <React.Fragment key={i}>
                                                                                                                                    <tr>
                                                                                                                                        <td className="d-xl-table-cell text-end">{item.brand_number}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{(item.total_conv_cost).toFixed(2)}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{item.agent_reply_count}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{item.user_reply_count}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{item.bot_reply_count}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{item.campaign_reply_count}</td>
                                                                                                                                        <td className="d-xl-table-cell text-end">{item.created}</td>
                                                                                                                                    </tr>
                                                                                                                                </React.Fragment>
                                                                                                                            ))}
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </div>
                                                                                                            </> : null}

                                                                                                    </div>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </React.Fragment>
                                                                                ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>


                                                                <ul className="pagination mt-3">
                                                                    {Array.from({ length: totalPages }).map((_, index) => (
                                                                        <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                                                            <button className="page-link" onClick={() => handlePagination(index + 1)}>
                                                                                {index + 1}
                                                                            </button>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </> :
                                                            <div className="text-center">
                                                                <div className="card-body">
                                                                    <img alt="Empty Data" className="project-empty-img" src={EmptyImg} />
                                                                    <p>No Data Yet</p>
                                                                </div>
                                                            </div>
                                                        }
                                                        {/* {this.state.enitytList && 
                                                                <AdvanceDataTable
                                                                    tableName={""}
                                                                    tableCell={ENTITY_MANAGEMENT_LIST}
                                                                    tableData={this.state.enitytList}
                                                                    pagination={true}
                                                                    EditEntityID={this.EditEntityID}
                                                                    userList = {this.state.allUsers}
                                                                    // isLoading={this.state.setIsLoading}
                                                                    // isError={this.state.ErrorResponse}
                                                                />} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AdminLayout>
        </div>
    );
};

export default AdminBilling;
