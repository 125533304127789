import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCheckDouble,
  faSearch,
  faEllipsisV,
  faTimes,
  faFileExcel,
  faArrowAltCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import InfiniteScroll from "react-infinite-scroll-component";
import "../assets/css/whatsapp.css";
import whatsapp from "../../Dashboard/assets/img/whatsapp.png";
import conversations from "../../Dashboard/assets/img/whstaApp-conversations.png";
import pdfIcons from "../../Dashboard/assets/img/pdf.png";
import { CONVERSATION_API } from "../../../constants/ServiceConstants";
import api from "../../../axios/Axios";
import DashboardLayout from "../../UI/Layout/DashboardLayout";
import Title from "../../UI/Text/Title";
import Alert from "../../UI/Alert/Alert";
import Spining from "../../UI/Spining/Spining";
import { WhstappAccountList, conversation } from "../api/Api";
import Reply from "./components/Reply";
import dayjs from "dayjs";
import { debounce } from "lodash";
import mapIcon from "../../Dashboard/assets/img/whatsapp/map.jpg";
import { RxCross1 } from "react-icons/rx";
import "./WhatsappConv.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import io from "socket.io-client";
import notification from "../../../assets/notification.mp3"
const ENDPOINT = "https://node.authkey.io";
var socket;

class ImageViewer extends Component {
  render() {
    const { imageUrl, onClose } = this.props;
    return (
      <div className="popup">
        <div className="popupInner">
          <img src={imageUrl} alt="Preview" />
          <button className="cancelButton" onClick={onClose}>
            <RxCross1 />
          </button>
        </div>
      </div>
    );
  }
}

class WhatsappConversation extends Component {


  constructor() {

    const lStorage = JSON.parse(localStorage.getItem("login"));
    const user_id = lStorage.user.userId;
    const token = lStorage.token;
    super();
    this.state = {
      userId: user_id,
      token: token,
      conversationNumberList: [],
      filterList: [],
      content: "",
      unreadMessage: [],
      unreadMessageNumber: [],
      active: "all",
      isLoading: false,
      selectMobile: "",
      page: 0,
      hasMore: true,
      items: Array.from({ length: 20 }),
      selectName: "",
      conversationData: [],
      brand_number: "",
      companyName: "",
      successMessage: false,
      isError: false,
      errorMessage: "",
      messageType: "",
      searchValue: "",
      searchToggle: false,
      isLoadingcov: false,
      isViewerOpen: false,
      selectedImage: null,
      option: [],
      starList: 0,
      showSearch: false,
      searchMessage: "",
      togglePopUp: false,
      mobilefornameupdate: null,
      updateNameInput: "",
      imgError: [],
      queryString: ""
    };
    this.containerRef = React.createRef();
    this.searchInput = this.searchInput.bind(this);
    this.updateName = this.updateName.bind(this);
    this.debouncedFilterApi = debounce(this.debouncedFilterApi, 1000);
  }

  componentDidMount() {
    this.getWhatsaAppNumberList();
    this.syncStateWithQueryParams();
    this.setState({ queryString: window.location.search });
    
    socket = io.connect(ENDPOINT);
    socket.on("new message", async (data) => {

      if (
        this.state.selectMobile === data.mobile &&
        data.brand_number === this.state.brand_number
      ) {

        this.setState((prevState) => ({
          conversationData: [...prevState.conversationData, data],
        }));
        const sound = new Audio(notification);
        sound.play();
        this.scrollToBottom();
        await api.post(CONVERSATION_API, {
          token: this.state.token,
          user_id: this.state.userId,
          method: "add_unread",
          brand_number: this.state.brand_number,
          from_mobile: data.mobile,
          key_value: "1",
        });

      } else {
        const { conversationNumberList } = this.state;


        const filterNoList = conversationNumberList.filter(
          (item) => item.mobile === data.mobile
        );

        if (filterNoList.length > 0) {

          const updatedList = conversationNumberList.map((item) => {
            if (
              item.mobile === data.mobile &&
              data.brand_number === this.state.brand_number
            ) {
              return {
                ...item,

                created: data.created,
                read_status: 0,
                content: data.message_content,
              };
            } else {
              return item
            }
          });

          updatedList.sort((a, b) => new Date(b.created) - new Date(a.created));


          this.setState({ conversationNumberList: updatedList });
          const sound = new Audio(notification);
          sound.play();
        } else {

          if (data.brand_number === this.state.brand_number) {
            const newNoList = {
              content: data.message_content,
              created: data.created,
              message_type: data.message_type,
              mobile: data.mobile,
              name: data.name,
              read_status: 0,
              stared: 0,
            };


            this.setState((prevState) => ({
              conversationNumberList: [
                ...prevState.conversationNumberList,
                newNoList,
              ],
            }));
            const sound = new Audio(notification);
            sound.play();
          }
        }
      }
    });

  }

  componentDidUpdate(prevProps, prevState) {
    // Sync state if the query string changes
    if (window.location.search !== prevState.queryString) {
      this.syncStateWithQueryParams();
      this.setState({ queryString: window.location.search });
    }
  }

  scrollToBottom = () => {
    if (this.containerRef.current) {
      this.containerRef.current.scrollTop = this.containerRef.current.scrollHeight;
    }
  };


  getConversationNumberList = async () => {
    this.setState({ isLoading: true, active: "all" });
    let data = {
      token: this.state.token,
      user_id: this.state.userId,
      method: "left_menunew",
      start: this.state.page,
      brand_number: this.state.brand_number,
    };
    await conversation(data).then((res) => {
      if (res.data.success === true) {
        let starCount = 0;
        res.data.data.forEach((item) => {
          if (item.stared === 1) {
            starCount = starCount + 1;
          }
        });
        this.setState((prevState) => ({
          starList: starCount,
          conversationNumberList: [
            ...prevState.conversationNumberList,
            ...res.data.data,
          ],
        }));

        this.setState({
          unreadMessage: res.data.unread_count,
          isLoading: false,
        });
      } else {
        this.setState({ ErrorResponse: res.data, isLoading: false });
      }
    });
  };

  unreadMessage = async () => {
    this.setState({ isLoading: true, active: "unread" });
    let data = {
      token: this.state.token,
      user_id: this.state.userId,
      method: "get_unread_list",
      brand_number: this.state.brand_number,
    };
    await conversation(data).then((res) => {
      if (res.data.success === true) {
        this.setState({
          conversationNumberList: res.data.data,
          isLoading: false,
        });
      } else {
        this.setState({ ErrorResponse: res.data, isLoading: false });
      }
    });
  };

  starMessage = async () => {
    this.setState({ isLoading: true, active: "star" });
    let data = {
      token: this.state.token,
      user_id: this.state.userId,
      method: "get_star_list",
      brand_number: this.state.brand_number,
    };
    await conversation(data).then((res) => {
      if (res.data.success === true) {
        let arr = res.data.data;
        let arrLenght = arr.length;
        this.setState({
          conversationNumberList: res.data.data,
          starList: arrLenght,
          isLoading: false,
        });
      } else {
        this.setState({
          ErrorResponse: res.data,
          isLoading: false,
          conversationNumberList: " ",
        });
      }
    });
  };

  getWhatsaAppNumberList = async () => {
    this.setState({ isLoading: true });
    let data = {
      token: this.state.token,
      user_id: this.state.userId,
      method: "retrieve",
    };

    await WhstappAccountList(data).then((res) => {
      if (res.data.success === true) {
        this.setState({
          brand_number: res.data.data[0].brand_number,
          companyName: res.data.data[0].comp_name,
          isLoading: true,
        });
        this.getConversationNumberList();
      } else {
        this.setState({ ErrorResponse: res.data, isLoading: false });
      }
    });
  };

  getConversation = async (mobile) => {
    const queryParams = new URLSearchParams(window.location.search);
    this.setState({ isLoading: true });
    try {
      const { data } = await api.post(CONVERSATION_API, {
        token: this.state.token,
        user_id: this.state.userId,
        method: "conv_list",
        brand_number: this.state.brand_number || queryParams.get('brand_number'),
        from_mobile: mobile,
      });
      if (data.success === true) {
        this.setState({
          conversationData: data.data,
          isLoading: false,
        });
        this.setState((prevState) => ({
          unreadMessageNumber: [...prevState.unreadMessageNumber, mobile],
        }));

        // this.getConversationNumberList();
        setTimeout(
          function () {
            let div = document.getElementById("conversation");
            div.scrollTop = div.scrollHeight;
          }.bind(this),
          100
        );
      } else {
        this.setState({ ErrorResponse: data, isLoading: false });
      }
    } catch (err) {
      console.log(err);
    }
  };

  syncStateWithQueryParams = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const queryName = queryParams.get('name');
    const queryMobile = queryParams.get('mobile');
    const queryBrand_number= queryParams.get('brand_number');
    if(queryBrand_number){
      this.setState({brand_number:queryBrand_number});
    }
    if (queryMobile && queryName) {
     await this.selectedNumberHandler(queryMobile,queryName);
    }
  };

  selectedNumberHandler = async (mobile, name) => {
    this.setState({ selectMobile: mobile, selectName: name });
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("name", name);
    queryParams.set("mobile", mobile);
    queryParams.set("brand_number", this.state.brand_number);
    window.history.pushState(null, "", `${window.location.pathname}?${queryParams.toString()}`);

    await this.getConversation(mobile);

    const updatedData = this.state.conversationNumberList.map((item) => {
      if (item.mobile === mobile) {
        return { ...item, read_status: 1 };
      }
      return item;
    });

    this.setState({ conversationNumberList: updatedData });

  };

  searchInput = async (e) => {
    const { value } = e.target;
    this.setState({ searchValue: value });
    this.debouncedFilterApi(value);
  };

  debouncedFilterApi = (value) => {
    if (value === "") {
      this.setState({
        unreadMessage: [],
        conversationNumberList: [],
      });
      this.getWhatsaAppNumberList();
    } else {
      this.setState({ isLoadingcov: true, active: "all" });
      let data = {
        token: this.state.token,
        user_id: this.state.userId,
        method: "left_menunew",
        search_keyword: value,
        brand_number: this.state.brand_number,
      };

      conversation(data).then((res) => {
        if (res.data.success === true) {
          this.setState({
            conversationNumberList: res.data.data,
          });
          this.setState({
            unreadMessage: res.data.unread_count,
            isLoadingcov: false,
          });
        } else {
          this.setState({ ErrorResponse: res.data, isLoadingcov: false });
        }

        if (res.data.success === false) {
          this.setState({
            unreadMessage: [],
            conversationNumberList: " ",
          });
        }
      });
    }
  };

  openImageViewer = (imageUrl) => {
    this.setState({
      isViewerOpen: true,
      selectedImage: imageUrl,
    });
  };

  closeImageViewer = () => {
    this.setState({
      isViewerOpen: false,
      selectedImage: null,
    });
  };

  showsearch = () => {
    this.setState({ showSearch: true });
  };

  HideSearch = () => {
    this.setState({ showSearch: false });
    this.setState({ searchMessage: "" });
  };

  handleSearchChange = (e) => {
    this.setState({ searchMessage: e.target.value });
  };
  handleImgError = (item) => {
    this.setState((prevState) => ({
      imgError: [
        ...prevState.imgError,
        item.id,
      ],
    }));

  }
  handleRefreshImg = (item) => {
    console.log(item);

    let data = {
      token: this.state.token,
      user_id: this.state.userId,
      method: "reload_image",
      brand_number: this.state.brand_number,
      mobile: item.mobile,
      id: item.id,
      resp_url: item.resp_url,
    };

    conversation(data).then((res) => {
      if (res.data.success === true) {
        const updatedData = this.state.conversationData.map((items) => {
          if (items.id === item.id) {
            return { ...items, file_url: res.data.message };
          }
          return items;
        });

        this.setState({ conversationData: updatedData });
      }
    });
  };

  renderConversationData = () => {
    if (this.state.conversationData.length === 0) {
      return (
        <div className="d-flex text-center align-items-center justify-content-center">
          <p className="empty-message">
            {" "}
            <img
              className="w-25"
              alt="whatsApp conversations"
              src={conversations}
            />{" "}
            <br />{" "}
            <p className="mt-3"> Select any number to start conversation</p>
          </p>
        </div>
      );
    } else {
      return this.state.conversationData
        .filter((x) => {
          return this.state.searchMessage.toLowerCase() === ""
            ? x
            : x.message_content
              .toLowerCase()
              .includes(this.state.searchMessage);
        })
        .map((item, index) => {
          if (item.req_from === "USER") {
            return (
              <div
                ref={this.messagesEndRef}
                key={index}
                className="row message-body"
              >
                <div className="col-sm-12 message-main-receiver">

                  <div className="receiver">
                    {this.state.imgError?.includes(item.id) && <div className="float-right cursor-pointer" onClick={() => this.handleRefreshImg(item)}>refresh</div>}
                    <div className="message-text">
                      {item.message_type === "TEXT" ? (
                        item.message_content
                      ) : item.message_type === "IMAGE" ? (
                        <div className="cursor-pointer">
                          <img
                            className="img-fluid whatsapp-image"
                            alt="img"
                            src={
                              // item.file_url.includes("amazonaws")
                              item.file_url
                              // : `https://console.authkey.io/whatsapp/chatimages/reply/${item.file_url}`
                            }
                            onError={() => this.handleImgError(item)}
                            onClick={() =>
                              this.openImageViewer(`${item.file_url}`)
                            }
                          />

                          <p
                            className="m-0 image-caption text-wrap text-break"
                            style={{ width: "17rem" }}
                          >
                            {item.image_caption ? item.image_caption : null}
                          </p>
                        </div>
                      ) : item.message_type === "DOCUMENT" ? (
                        <div className="text-center">
                          {" "}
                          <a download href={item.file_url} target="_blank">
                            <img className="img-fluid" src={pdfIcons} />{" "}
                            <p>Download</p>
                          </a>
                          <p
                            className="m-0 image-caption text-wrap text-break "
                            style={{ width: "17rem" }}
                          >
                            {item.image_caption ? item.image_caption : null}
                          </p>
                        </div>
                      ) : item.message_type === "AUDIO" ? (
                        <audio controls>
                          <source src={`${item.file_url}`} type="audio/mpeg" />
                        </audio>
                      ) : item.message_type === "VIDEO" ? (
                        <>
                          <video width="320" height="240" controls>
                            <source src={`${item.file_url}`} type="video/mp4" />
                          </video>
                          <p
                            className="m-0 image-caption text-wrap text-break"
                            style={{ width: "17rem" }}
                          >
                            {item.image_caption ? item.image_caption : null}
                          </p>
                        </>
                      ) : item.message_type === "LOCATION" ? (
                        <div className="text-center">
                          {" "}
                          <a
                            download
                            href={`https://www.google.com/maps/place/${item.message_content}`}
                            target="_blank"
                          >
                            <img
                              alt="map"
                              className="img-thumbnail"
                              src={mapIcon}
                              style={{ width: "200px", height: "100px" }}
                            />{" "}
                            <p>Location</p>
                          </a>
                        </div>
                      ) : null}

                    </div>

                    <small className="ml-1">
                      {item.status === "read" ? (
                        <FontAwesomeIcon color="#26cf8e" icon={faCheckDouble} />
                      ) : (
                        <FontAwesomeIcon icon={faCheck} />
                      )}{" "}
                      <span className="ml-1">
                        {dayjs(item.created).format("M/D/YYYY h:mm A")}
                      </span>{" "}
                    </small>

                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div className="row message-body">
                <div className="col-sm-12 message-main-sender">
                  <div className="sender">
                    <div className="message-text">
                      {item.message_type === "TEXT" ? (
                        item.message_content
                      ) : item.message_type === "IMAGE" ? (
                        <div className="cursor-pointer">
                          <img
                            alt="img"
                            src={item.file_url}
                            className="img-fluid whatsapp-image"
                            // src={`${item.file_url}`}
                            onClick={() =>
                              this.openImageViewer(`${item.file_url}`)
                            }
                          />
                          <p
                            className="m-0 image-caption text-wrap text-break"
                            style={{ width: "17rem" }}
                          >
                            {item.image_caption ? item.image_caption : null}
                          </p>
                        </div>
                      ) : item.message_type === "document" ? (
                        <div className="text-center">
                          {" "}
                          <a download target="_blank" href={`${item.file_url}`}>
                            <img className="img-fluid" src={pdfIcons} />{" "}
                            <p>Download</p>
                          </a>
                          <p
                            className="m-0 image-caption text-wrap text-break"
                            style={{ width: "17rem" }}
                          >
                            {item.image_caption ? item.image_caption : null}
                          </p>
                        </div>
                      ) : item.message_type === "AUDIO" ? (
                        <audio controls>
                          <source src={`${item.file_url}`} type="audio/mpeg" />
                        </audio>
                      ) : item.message_type === "VIDEO" ? (
                        <>
                          <video width="320" height="240" controls>
                            <source src={`${item.file_url}`} type="video/mp4" />
                          </video>
                          <p
                            className="m-0 image-caption text-wrap text-break"
                            style={{ width: "17rem" }}
                          >
                            {item.image_caption ? item.image_caption : null}
                          </p>
                        </>
                      ) : null}
                    </div>
                    {item.status === "read" ? (
                      <FontAwesomeIcon color="#26cf8e" icon={faCheckDouble} />
                    ) : (
                      <FontAwesomeIcon icon={faCheck} />
                    )}
                    <small className="ml-1">
                      {dayjs(item.created).format("M/D/YYYY h:mm A")}
                    </small>
                  </div>
                </div>
              </div>
            );
          }
        });
    }
  };

  fetchMoreData = () => {
    this.setState(
      (prevState) => ({ page: prevState.page + 100 }),
      () => {
        this.getConversationNumberList();
      }
    );
  };
  searchButton = async () => {
    this.setState((prevState) => ({
      searchToggle: !prevState.searchToggle,
    }));
    if (this.state.searchToggle === true) {
      const value = "";
      await this.debouncedFilterApi(value);
    }
  };
  optionToggle = (index) => {
    const { option } = this.state;
    const updatedoption = [...option];
    updatedoption[index] = !updatedoption[index];
    this.setState({ option: updatedoption });
  };
  addStar = async (mobile) => {
    // console.log(mobile)
    const { data } = await api.post(CONVERSATION_API, {
      token: this.state.token,
      user_id: this.state.userId,
      method: "add_started",
      brand_number: this.state.brand_number,
      from_mobile: mobile,
      key_value: 1,
    });

    if (data.success === true) {
      this.setState({
        starList: this.state.starList + 1,
      });
    }

    this.setState({ option: [] });
  };
  removeStar = async (mobile, index) => {

    // console.log(mobile)
    const { data } = await api.post(CONVERSATION_API, {
      token: this.state.token,
      user_id: this.state.userId,
      method: "add_started",
      brand_number: this.state.brand_number,
      from_mobile: mobile,
      key_value: "0",
    });
    if (data.success === true) {
      this.setState({
        starList: this.state.starList - 1,
      });
    }
    // const t= this.state.conversationNumberList.indexOf(mobile);
    // console.log(index)
    // console.log(this.state.conversationNumberList)
    // let arrConv
    // if (index) {
    //   arrConv=this.state.conversationNumberList.splice(index, 1);
    // }

    // console.log(arrConv)

    // this.setState({ option: [],
    //   conversationNumberList:arrConv,
    //  });
    window.location.reload(false);
  };
  unRead = async (mobile) => {
    const { data } = await api.post(CONVERSATION_API, {
      token: this.state.token,
      user_id: this.state.userId,
      method: "add_unread",
      brand_number: this.state.brand_number,
      from_mobile: mobile,
      key_value: "0",
    });
    if (data.success === true) {
      window.location.reload(false);
    }
  };
  read = async (mobile) => {
    const { data } = await api.post(CONVERSATION_API, {
      token: this.state.token,
      user_id: this.state.userId,
      method: "add_unread",
      brand_number: this.state.brand_number,
      from_mobile: mobile,
      key_value: "1",
    });
    if (data.success === true) {
      window.location.reload(false);
    }
  };

  openToggle = (mobile, name) => {
    this.setState({ mobilefornameupdate: null, updateNameInput: "" });
    this.setState({
      togglePopUp: true,
      mobilefornameupdate: mobile,
      updateNameInput: name,
    });
  };
  closePopup = () => {
    this.setState({ togglePopUp: false });
  };

  updateName = async () => {
    const { data } = await api.post(CONVERSATION_API, {
      token: this.state.token,
      user_id: this.state.userId,
      method: "update_name",
      brand_number: this.state.brand_number,
      from_mobile: this.state.mobilefornameupdate,
      name: this.state.updateNameInput,
    });
    if (data.success === true) {
      window.location.reload(false);
    }
  };

  render() {
    return (
      <DashboardLayout>
        {this.state.isLoading && <Spining />}

        {this.state.togglePopUp && (
          <div
            style={{
              zIndex: "1000",
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              backdropFilter: "blur(5px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "50%",
                height: "50%",
                backgroundColor: "white",
                padding: "5px",
                borderRadius: "5px",
                textAlign: "center",
                overflowY: "auto",
              }}
            >
              <button
                className="float-right"
                style={{
                  border: "none",
                  fontSize: "30px",
                  backgroundColor: "transparent",
                  color: "black",
                }}
                onClick={this.closePopup}
              >
                x
              </button>
              <div className="m-5">
                <label for="exampleFormControlInput1" class="form-label">
                  Enter Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Enter Name"
                  value={this.state.updateNameInput}
                  onChange={(e) =>
                    this.setState({ updateNameInput: e.target.value })
                  }
                />
              </div>
              <button
                type="button"
                class="btn btn-success"
                onClick={this.updateName}
              >
                Update Name
              </button>
            </div>
          </div>
        )}

        <main className="content">
          <div className="container-fluid p-0">
            <Title isButton={false}>
              {" "}
              <Link to="/dashboard/whatsapp-conversation/download">
                <FontAwesomeIcon
                  icon={faFileExcel}
                  className="text-dark mr-1"
                />
              </Link>
              WhatsApp Conversation{" "}
            </Title>
            <div className="row">
              <div className="col-12 col-lg-12 col-xxl-12">
                {this.state.isError && (
                  <Alert type={this.state.messageType}>
                    {this.state.errorMessage}
                  </Alert>
                )}
                {this.state.isViewerOpen && (
                  <ImageViewer
                    imageUrl={this.state.selectedImage}
                    onClose={this.closeImageViewer}
                  />
                )}
                <div className="app">
                  <div className="row app-one">
                    <div className="col-sm-3 side">
                      <div className="side-one">
                        <div className="heading">
                          <div className="heading-avatar d-flex justify-content-between align-items-center ">
                            <div
                              onClick={this.getConversationNumberList}
                              className="heading-avatar-icon"
                            >
                              <img alt="whatsApp Icons" src={whatsapp} />{" "}
                              <span className="whatsapp-chat-compname">
                                {this.state.companyName
                                  ? this.state.companyName
                                  : "Loading...."}
                              </span>
                            </div>
                            <div>
                              {this.state.searchToggle === false ? (
                                <button
                                  className="conv-search-icon"
                                  onClick={this.searchButton}
                                >
                                  <FontAwesomeIcon icon={faSearch} />
                                </button>
                              ) : null}
                            </div>
                          </div>

                          {this.state.searchToggle && (
                            <div className="d-flex align-item-center">
                              <input
                                type="text"
                                value={this.state.searchValue}
                                onChange={this.searchInput}
                                className="form-control"
                                placeholder="search"
                              />
                              <button
                                style={{
                                  border: "none",
                                  fontSize: "20px",
                                  opacity: "70%",
                                }}
                                onClick={this.searchButton}
                              >
                                x
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="d-flex align-items-center pl-3 p-2">
                          <a href="/dashboard/whatsapp-conversation">
                            <div
                              className={`badge  mr-3 ${this.state.active === "all"
                                ? "badge-dark"
                                : "badge-secondary"
                                }`}
                            >
                              All
                            </div>{" "}
                          </a>
                          {this.state.unreadMessage === "0" ? null : (
                            <div
                              onClick={this.unreadMessage}
                              className={`badge  mr-3 ${this.state.active === "unread"
                                ? "badge-dark"
                                : "badge-secondary"
                                }`}
                            >
                              Unread{" "}
                              <small> ({this.state.unreadMessage}) </small>
                            </div>
                          )}

                          <div
                            onClick={this.starMessage}
                            className={`badge  mr-3 ${this.state.active === "star"
                              ? "badge-dark"
                              : "badge-secondary"
                              }`}
                          >
                            Star <small> ({this.state.starList}) </small>
                          </div>
                        </div>

                        {this.state.conversationNumberList ? (
                          <InfiniteScroll
                            dataLength={
                              this.state.conversationNumberList.length
                            }
                            height={"100vh"}
                            next={this.fetchMoreData}
                            hasMore={this.state.hasMore}
                          >
                            <div className="row sideBar">
                              {this.state.isLoadingcov === true ? (
                                <Spining />
                              ) : (
                                <>
                                  {this.state.conversationNumberList === " " ? (
                                    <div>
                                      <p>No message</p>
                                    </div>
                                  ) : (
                                    <>
                                      {this.state.conversationNumberList.map(
                                        (item, index) => (
                                          <div
                                            key={index}
                                            className="sideBar-outerBody"
                                          >
                                            <div
                                              key={index}
                                              onClick={() =>
                                                this.selectedNumberHandler(
                                                  item.mobile,
                                                  item.name
                                                )
                                              }
                                              className={
                                                item.mobile ===
                                                  this.state.selectMobile
                                                  ? "sideBar-body active"
                                                  : "sideBar-body"
                                              }
                                            >
                                              <div
                                                className={`${item.read_status === 1
                                                  ? "d-flex justify-content-between"
                                                  : "d-flex justify-content-between unread-message"
                                                  }`}
                                              >
                                                <div
                                                  role="buttom"
                                                  className="sideBar-name"
                                                >
                                                  {this.state.selectMobile ===
                                                    item.mobile ? null : (
                                                    <span className="unread-m-count">
                                                      1
                                                    </span>
                                                  )}
                                                  <span className="name-meta">
                                                    {item.name
                                                      ? item.name
                                                      : item.mobile}
                                                  </span>
                                                  <small>
                                                    {item.message_type ===
                                                      "TEXT"
                                                      ? item.content
                                                      : item.message_type ===
                                                        "DOCUMENT"
                                                        ? "document"
                                                        : item.message_type ===
                                                          "AUDIO"
                                                          ? "Audio"
                                                          : item.message_type ===
                                                            "ERROR"
                                                            ? null
                                                            : item.message_type ===
                                                              "IMAGE"
                                                              ? "image"
                                                              : item.message_type ===
                                                                "VIDEO"
                                                                ? "video"
                                                                : null}
                                                  </small>
                                                </div>
                                                <div className="sideBar-time">
                                                  <span className="time-meta pull-right">
                                                    {dayjs(item.created).format(
                                                      "M/D/YYYY h:mm A"
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="drop">
                                              <div className="dropdownn">
                                                <button
                                                  onClick={() =>
                                                    this.optionToggle(index)
                                                  }
                                                  className={
                                                    this.state.option[index]
                                                      ? "active-drop-butn"
                                                      : "drop-butn"
                                                  }
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faEllipsisV}
                                                  // onClick={this.markImp}
                                                  />
                                                </button>
                                                {this.state.option[index] && (
                                                  <div className="dropdownn-content">
                                                    {item.stared === 0 ? (
                                                      <button
                                                        className="addstarbtn"
                                                        onClick={() =>
                                                          this.addStar(
                                                            item.mobile
                                                          )
                                                        }
                                                      >
                                                        Mark as star
                                                      </button>
                                                    ) : (
                                                      <button
                                                        className="removestarbtn"
                                                        onClick={() =>
                                                          this.removeStar(
                                                            item.mobile,
                                                            index
                                                          )
                                                        }
                                                      >
                                                        Mark as unstar
                                                      </button>
                                                    )}
                                                    {item.read_status === 0 ? (
                                                      <button
                                                        className="removestarbtn"
                                                        onClick={() =>
                                                          this.read(item.mobile)
                                                        }
                                                      >
                                                        Mark as read
                                                      </button>
                                                    ) : (
                                                      <button
                                                        className="addstarbtn"
                                                        onClick={() =>
                                                          this.unRead(
                                                            item.mobile,
                                                            index
                                                          )
                                                        }
                                                      >
                                                        Mark as unread
                                                      </button>
                                                    )}
                                                    <button
                                                      className="addstarbtn"
                                                      onClick={() =>
                                                        this.openToggle(
                                                          item.mobile,
                                                          item.name
                                                        )
                                                      }
                                                    >
                                                      Update Name
                                                    </button>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </InfiniteScroll>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-sm-9 conversation">
                      {(this.state.selectName || this.state.selectMobile) && (
                        <div className="heading">
                          <div className="heading-avatar">
                            <div className="heading-avatar-icon d-flex justify-content-between">
                              <div>
                                <img alt="WhatsApp" src={whatsapp} />{" "}
                                {this.state.selectName} (
                                {this.state.selectMobile})
                              </div>
                              <div className="d-flex align-items-center">
                                {this.state.showSearch ? (
                                  <>
                                    <input
                                      style={{
                                        width: "150px",
                                        marginRight: "20px",
                                      }}
                                      type="text"
                                      id="searchInput"
                                      name="searchdata"
                                      value={this.state.searchMessage} // Assuming this.state.searchMessage holds the value
                                      onChange={this.handleSearchChange} // Assuming handleSearchChange is your change handler function
                                      className="form-control"
                                      placeholder="Search Message"
                                    />
                                    <FontAwesomeIcon
                                      onClick={this.HideSearch}
                                      icon={faTimes}
                                    />
                                  </>
                                ) : (
                                  <FontAwesomeIcon
                                    onClick={this.showsearch}
                                    icon={faSearch}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="row message" id="conversation">
                        {this.renderConversationData()}
                      </div>
                      {this.state.conversationData.length > 0 && (
                        <Reply
                          brand_number={this.state.brand_number}
                          selectMobile={this.state.selectMobile}
                          sentData={this.getConversation}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </DashboardLayout>
    );
  }
}

export default WhatsappConversation;
