import React, { useState, useEffect } from 'react';
import Alert from '../../UI/Alert/Alert';
import axios from 'axios';
import AdminLayout from '../../UI/Layout/AdminLayout';
import { HOST_URL, NODE_HOST_URL } from '../../Dashboard/api/Api';
import RcsPreview from '../../Dashboard/campaign/Rcs/RcsPreview';
import RCSTemplateDetails from './RcsTemplateDetail';
const PreviewRcsTemplate = (props) => {
    const [userID, setUserID] = useState('');
    const [token, setToken] = useState('');
    const [paramID, setParamID] = useState(null);
    const [footerOptions, setFooterOptions] = useState(false);
    const [buttonOptions, setButtonOptions] = useState(false);
    const [MappingOptions, setMappingOptions] = useState(false);
    const [buttonAction, setButtonAction] = useState(false);
    const [callActionType, setCallActionType] = useState("");
    const [variableInTemplate, setVariableInTemplate] = useState([]);
    const [collaps1, setCollaps1] = useState(true);
    const [collaps2, setCollaps2] = useState(false);
    const [tampleName, setTampleName] = useState("");
    const [isFallback, setIsFallback] = useState(0);
    const [smsTemplateSID, setSmsTemplateSID] = useState("");
    const [language, setLanguage] = useState("");
    const [accountType, setAccountType] = useState("");
    const [templateType, setTemplateType] = useState(" ");
    const [headerText, setHeaderText] = useState("");
    const [headerTextPrev, setHeaderTextPrev] = useState("");
    const [bodyMessage, setBodyMessage] = useState("");
    const [preBodyMessage, setPreBodyMessage] = useState("");
    const [footerText, setFooterText] = useState("");
    const [Callbutton, setCallButton] = useState("");
    const [Urlbutton, setUrlButton] = useState("");
    const [Urlbutton2, setUrlButton2] = useState("");
    const [quickButton, setQuickButton] = useState("");
    const [tempStatus, setTempStatus] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [button1, setButton1] = useState('');
    const [button2, setButton2] = useState('');
    const [button3, setButton3] = useState('');
    const [mainurl, setMainurl] = useState('');
    const [checkedButton, setCheckedButton] = useState(false);
    const [route, setRoute] = useState("");
    const [headerOptions, setHeaderOptions] = useState(false);
    const [isopenFirst, setIsopenFirst] = useState(true);
    const [isopenSeconds, setIsopenSeconds] = useState(false);
    const [text, setText] = useState("");
    const [templateDetails, setTemplateDetails] = useState(null);
    const [templateId, setTemplateId] = useState("");
    const [cardDynamicDataArray, setCardDynamicDataArray] = useState({
        media: null,
        card_title: '',
        card_description: '',
        temp_suggestion: []
    })

    useEffect(() => {
        // Set the initial value for paramID from match.params.id
        const id = props.match?.params?.id; // Check if match.params.id exists
        setParamID(id);
    }, [props.match?.params?.id]);

    useEffect(() => {
        const lStorage = JSON.parse(localStorage.getItem("admin"));
        const user_id = lStorage?.user?.userId;
        const token = lStorage?.token;
        setUserID(user_id);
        setToken(token);

    }, [])

    useEffect(() => {
        if (userID && token && paramID) {
            getTemplateDetails(); // Call your function to fetch template details if paramID exists
        }



    }, [userID, token, paramID])

    const getButtonData = (callButtonData) => {
        setCallButton(callButtonData);
    };

    const getURLButton = (urlButtonData) => {
        setUrlButton(urlButtonData);
    };

    const getURLButton2 = (urlButtonData) => {
        setUrlButton2(urlButtonData);
    };

    const getQuickButton = (quickButtonData) => {
        setQuickButton(quickButtonData);
    };

    const handleButtonData = (buttonData) => {
        setButton1(buttonData.button1);
        setButton2(buttonData.button2);
        setButton3(buttonData.button3);
        setCheckedButton(buttonData.isButtonChecked);
        setMainurl(buttonData.mainUrl);
    };

    const generateButtonJson = () => {
        const button = [];

        if (Callbutton[0]) {
            button.push(Callbutton[0]);
        }
        if (Urlbutton[0]) {
            button.push(Urlbutton[0]);
        }
        if (Urlbutton2[0]) {
            button.push(Urlbutton2[0]);
        }
        // Uncomment the quickButton condition if needed
        // if (quickButton[0]) {
        //   button.push(quickButton[0]);
        // }
        if (button1) {
            button.push(button1);
        }
        if (button2) {
            button.push(button2);
        }
        if (button3) {
            button.push(button3);
        }

        return button;
    };
    const getTemplateDetails = async () => {

        try {
            const { data } = await axios.post(`${NODE_HOST_URL}/admin/rcs`, {
                token: token,
                user_id: userID,
                temp_id: paramID,
                method: "retrieve_temp_dtl",
            });

            if (data.success === true) {
                setTemplateDetails(data.data);
                setTemplateType(data.data.temp_type);


            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleApproval = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${NODE_HOST_URL}/admin/rcs`, {
                token: token,
                user_id: userID,
                api_temp_id: templateId,
                temp_id: paramID,
                method: "status_update",
                status: tempStatus

            })
            if (response.data.success) {
                setSuccessMessage(true)

                setTimeout(() => {
                    setSuccessMessage(false);
                }, 5000);

            }

        } catch (error) {
            console.log(error)

        }
        finally {
            setIsLoading(false);
        }
    }
    // console.log(templateDetails?.rich_card)


    return (
        <AdminLayout>
            <main className="">
                {successMessage && (
                    <Alert type={"alert-success"}>
                        {"Status Updated Successfully"}
                    </Alert>
                )}
                <div className="container-fluid p-0">
                    {/* <Title
                        isButton={true}
                        btnName={`Back To Template List`}
                        actionURL={`/admin/rcs/rcs-template-list`}
                    >
                        Rcs Template
                    </Title> */}

                    <div className="row justify-contect-center">
                        <div className="col-md-8">
                            <div className="card whatapp-app">
                                <div className="card-header">
                                    <h5 className="card-title mb-0"> Template Details </h5>
                                </div>

                                <hr className="m-0" />

                                <div className="card-body">

                                    {templateDetails && <RCSTemplateDetails data={templateDetails} />}

                                    <div className="text-right mt-3">

                                        <div className="d-flex flex-column justify-content-evenly align -item-center">
                                            <select name="" id="" className='form-control w-100 mb-3' value={tempStatus} onChange={(e) => setTempStatus(e.target.value)}>
                                                <option value="" disabled>Select status</option>
                                                <option value="1">Approve</option>
                                                <option value="5">Review</option>
                                                <option value="3">Failed</option>
                                            </select>

                                            <input type="text" value={templateId} placeholder='Enter Template ID' className='form-control mb-3 w-100' onChange={(e) => setTemplateId(e.target.value)} />

                                            <button
                                                type="submit"
                                                onClick={handleApproval}
                                                disabled={!templateId || !tempStatus}
                                                className="btn btn-sm btn-primary w-100"
                                            >
                                                {isLoading ? "Updating" : "Update"}

                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 ">
                            <div className="card" style={{ position: "sticky", top: "0px" }}>
                                <div className="card-header">
                                    <h5 className="card-title mb-0"> Template Preview </h5>
                                </div>
                                <hr className="m-0" />
                                <div className="card-body">
                                    {templateType === 'text' ? <RcsPreview temp_type={templateType} rcsContent={templateDetails.temp_body} suggestionButtons={templateDetails.temp_suggestion} /> : <RcsPreview temp_type={templateType} cardDataArray={templateDetails?.rich_card} cardDynamicDataArray={cardDynamicDataArray} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </AdminLayout>
    )
}

export default PreviewRcsTemplate;