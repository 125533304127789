import React, { useEffect } from "react";

const FacebookAuth = ({setToken}) => {
  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({  // Use window.FB instead of FB
        appId: "1142310293695277", // Replace with your actual App ID
        cookie: true,
        xfbml: true,
        version: "v19.0",
      });

      window.FB.AppEvents.logPageView();
      window.fbLoaded = true; // Set a flag to indicate SDK is ready
    };

    // Load SDK script dynamically
    if (!document.getElementById("facebook-jssdk")) {
      const script = document.createElement("script");
      script.id = "facebook-jssdk";
      script.src = "https://connect.facebook.net/en_US/sdk.js";
      script.async = true;
      script.onload = () => {
        console.log("Facebook SDK Loaded");
      };
      document.body.appendChild(script);
    }
  }, []);

  const loginWithFacebook = () => {
    if (window.FB) {
      window.FB.login(
        function (response) {
          if (response.authResponse) {
            setToken(response?.authResponse?.accessToken)
            console.log("User logged in:", response);
          } else {
            console.log("User cancelled login or did not authorize.");
          }
        },
        { scope: "public_profile,email,ads_management,ads_read" }
      );
    } else {
      console.error("Facebook SDK not loaded yet. Try again later.");
    }
  };

  return (
    <button className="btn-sm btn-primary" onClick={loginWithFacebook}>
      Login with Facebook
    </button>
  );
};

export default FacebookAuth;
