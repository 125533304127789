import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DashboardLayout from "../../UI/Layout/DashboardLayout";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { RCSAPI } from "../api/Api";

const RcsTemplateList = () => {
  const [rcsTemplateList, setRcsTemplateList] = useState([]);

  useEffect(() => {
    const getRcsTemplate = async () => {
      try {
        const lStorage = JSON.parse(localStorage.getItem("login"));
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;
        let paylod = {
          user_id: userId,
          token: userToken,
          method: "retrieve_temp",
        };
        let { data } = await RCSAPI(paylod);
        setRcsTemplateList(data.data);
      } catch (error) {
        console.log("error", error);
      }
    };
    getRcsTemplate();
  }, []);

  return (
    <DashboardLayout>
      <main className="content">
        <div className="container-fluid p-0">
          <div className="row mb-2 mb-xl-3">
            <div className="col-auto d-none d-sm-block">
              <h3>
                <strong>RCS </strong> Agent List{" "}
              </h3>
            </div>
            <div className="col-auto ml-auto text-right mt-n1">
              <Link
                className="btn btn-primary btn-sm"
                to="/dashboard/create-rcs-template"
              >
                {" "}
                <FontAwesomeIcon icon={faPlus} color="" /> Add New RCS Template{" "}
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="">
              <div className="">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title mb-0">Rcs Template List </h5>
                      </div>
                      <hr className="m-0" />
                      <div className="">
                        {rcsTemplateList ? (
                          <div className="table-responsive">
                            <table className="table table-hover  project  my-0">
                              <thead>
                                <tr>
                                  <th>Template Name</th>
                                  <th>Template Category</th>
                                  <th>Sender Id</th>
                                  <th>Template Type</th>
                                  <th>Created Date</th>
                                  <th>Status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {rcsTemplateList.map((item, i) => (
                                  <tr key={i}>
                                    <td>{item.temp_name}</td>
                                    <td>{item.temp_category}</td>
                                    <td>{item.sender_id}</td>
                                    <td>{item.temp_type}</td>
                                    <td>{item.created}</td>
                                    <td>
                                      {item.temp_status === 0 ? (
                                        <span className="badge badge-primary">
                                          {" "}
                                          Pending{" "}
                                        </span>
                                      ) : item.temp_status === 1 ? (
                                        <span className="badge bg-success">
                                          {" "}
                                          Approved{" "}
                                        </span>
                                      ) :item.temp_status === 2 ? (
                                        <span className="badge badge-secondary">
                                          {" "}
                                          Draft{" "}
                                        </span>
                                      ):item.temp_status === 5 ?(
                                        <span className="badge badge-info">
                                          {" "}
                                          In Review{" "}
                                        </span>
                                      ):(
                                        <span className="badge badge-danger">
                                          {" "}
                                          Rejected{" "}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {" "}
                                      <a
                                        href={`/dashboard/create-rcs-template/edit/${item._id}`}
                                      >
                                        <span className="badge bg-info">
                                          {" "}
                                          Edit{" "}
                                        </span>{" "}
                                      </a>{" "}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <div className="text-center">
                            <div className="card-body">
                              <p>No Recod Found....</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </DashboardLayout>
  );
};
export default RcsTemplateList;
