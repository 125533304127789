import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { NODE_HOST_URL } from '../api/Api';
import Axios from '../../../axios/Axios';

class FlashCall extends Component {

    constructor() {
        const lStorage = JSON.parse(localStorage.getItem("login"));
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;

        const today = new Date();
        const date = today.getFullYear() + '-' + (today.getMonth() + 1).toString().padStart(2, "0") + '-' + today.getDate().toString().padStart(2, "0");

        super();
        this.state = {
            user_id: userId,
            token: userToken,
            TodayDate: date,
            voiceReports: '',
            voiceReportsKeys: '',
            dateFrom: date,
            dateTo: date,
            SelecteddateFrom: today,
            SelecteddateTo: today,
            errorMessage: '',
            voicesuccessMessage: false,
            resultUpdate: false,
            bgColor: '',
            total: '',
            verified: '',
            nonVerified: '',
            submitted: '',
        }

        this.FilterDateWise = this.FilterDateWise.bind(this);
    }

    componentDidMount() {
        this.voiceReportSummery(this.state.TodayDate, this.state.TodayDate);
    }

    voiceReportSummery = async (dateFrom, dateTo) => {
        let paramData = {
            user_id: parseInt(this.state.user_id),
            token: this.state.token,
            method: 'voice_api_summary',
            fromdate: dateFrom,
            todate: dateTo
        };

        try {
            let { data } = await Axios.post(`${NODE_HOST_URL}/flashcall_voice`, paramData);

            // Check if the API response is successful
            if (data && data.success) {
                // Handle the response and set the state
                this.setState({
                    total: data.Total_Count,
                    verified: data.Verified_Call,
                    nonVerified: data.Not_Verified,
                    submitted: data.Submitted
                });
            } else {
                // Optionally handle the case where the success flag is false
                console.log("API call was not successful", data);
            }
        } catch (error) {
            // Log the error to help debug
            console.error("Error in voiceReportSummery API call:", error);
        }
    }

    FilterDateWise() {
        this.setState({ resultUpdate: true, errorMessage: "Result Updated", bgColor: "alert alert-success alert-dismissible" })
        this.voiceReportSummery(this.state.dateFrom, this.state.dateTo);
        setTimeout(() => {
            this.setState({ resultUpdate: false });
        }, 5000);
    }

    dateFrom = (date) => {
        const formattedDate = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, "0") + '-' + date.getDate().toString().padStart(2, "0");
        this.setState({
            dateFrom: formattedDate,
            SelecteddateFrom: date
        });
    };

    dateTo = (date) => {
        const formattedDate = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, "0") + '-' + date.getDate().toString().padStart(2, "0");
        this.setState({
            dateTo: formattedDate,
            SelecteddateTo: date
        });
    };

    render() {
        return (
            <div className="wrapper">
                <DashboardLeftMenu />
                <div className="main">
                    <DashboardHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className=" mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong>Flash Call Report</strong></h3>
                                </div>
                            </div>
                            <div className="">
                                <div className="card">
                                    <div className="">
                                        <h5 className="card-title mt-5">
                                            <div className="col-auto ml-auto text-right mt-n1">
                                                <nav className="text-center" aria-label="breadcrumb">
                                                    <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
                                                        <Link to="/dashboard/flash-call"><li className="breadcrumb-item active"> Today's Summary </li> </Link>
                                                        <Link to={'/dashboard/flash-call-detail-reports/' + 'voice/' + this.state.dateFrom + '/' + this.state.dateTo}> <li className="breadcrumb-item ">Details </li> </Link>
                                                    </ol>
                                                </nav>
                                            </div>
                                        </h5>
                                    </div>

                                    <div className="card-body">
                                        <div className="filter-option">
                                            <h6 className="card-subtitle text-muted">Filter </h6>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label>From Date</label>
                                                    <div className="input-group mb-2 mr-sm-2">
                                                        <DatePicker className="form-control btn-block"
                                                            dateFormat="yyyy/MM/dd"
                                                            onChange={this.dateFrom}
                                                            value={this.state.dateFrom}
                                                            selected={this.state.SelecteddateFrom}
                                                            placeholderText='From Date'
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-1 "></div>
                                                <div className="col-md-4">
                                                    <label>To Date</label>
                                                    <div className="input-group mb-2 mr-sm-2">
                                                        <DatePicker className="form-control btn-block"
                                                            dateFormat="yyyy/MM/dd"
                                                            onChange={this.dateTo}
                                                            value={this.state.dateTo}
                                                            selected={this.state.SelecteddateTo}
                                                            placeholderText='Date To'
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="input-group btn-block mb-2 mr-sm-2">
                                                        <button style={{ marginTop: "35px" }} onClick={this.FilterDateWise} className="btn btn-blue btn-sm btn-block">Search</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {this.state.resultUpdate &&
                                            <div className={this.state.bgColor} role="alert">
                                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                                {this.state.errorMessage}
                                            </div>
                                        }

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h4 className="summary-title">Flash Call</h4>
                                                        <div className="d-flex summary-data text-center">
                                                            <div className="mb-1">
                                                                <span className="text-muted"> Total </span> <br />
                                                                <span className="text-success"> {this.state.total || 0} </span>
                                                            </div>
                                                            <div className="mb-1">
                                                                <span className="text-muted"> Verified </span> <br />
                                                                <span className="text-success"> {this.state.verified || 0} </span>
                                                            </div>
                                                            <div className="mb-1">
                                                                <span className="text-muted"> Non Verified </span> <br />
                                                                <span className="text-success"> {this.state.nonVerified || 0} </span>
                                                            </div>
                                                            <div className="mb-1">
                                                                <span className="text-muted"> Submitted </span> <br />
                                                                <span className="text-success"> {this.state.submitted || 0} </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <DashboardFooter />
                </div>
            </div>
        );
    }
}
export default FlashCall;
