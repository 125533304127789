import React, { useEffect, useRef, useState } from "react";
import SuggestionsButton from "./SuggestionsButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { charaterCount, getMediaType } from "../../../Utils/Utils";
import Dialog from "react-dialog";
const CarouselCardForm = ({ formData, setFormData }) => {
  const [carousels, setCarousels] = useState(formData.carousels);
  const [activeTab, setActiveTab] = useState(0); // Tracks the active tab
  const [cursorPosition, setCursorPosition] = useState(0);
  const [varIndex, setVarIndex] = useState({ title: 1, des: 1 });
  const [isDialogOpen, setisDialogOpen] = useState({
    status: false,
    index: "",
  }); // Trackp[;?
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);

  // Add new carousel and tab
  const addCarousel = () => {
    if (carousels.length < 10) {
      const newCarousel = {
        card_width: "",
        media_height: "",
        card_title: "",
        media: {
          file_type: "",
          url: "",
        },
        card_description: "",
        temp_suggestion: [],
      };
      setCarousels([...carousels, newCarousel]);
    }
  };

  // Handle changes to carousel form data
  const handleCarouselChange = (index, e) => {
    const { name, value } = e.target;
    setCursorPosition(e.target.selectionStart);
    const updatedCarousels = [...carousels];
    updatedCarousels[index][name] = value;
    setCarousels(updatedCarousels);
    setFormData((prevData) => ({
      ...prevData,
      carousels: updatedCarousels,
    }));
  };

  const insertVar = (inputName, index) => {
    if (inputName === "card_title") {
      const textToInsert = `[title${varIndex.title}]`;
      setVarIndex((prevState) => ({
        ...prevState,
        title: prevState.title + 1,
      }));
      const updatedCarousels = [...carousels];
      const beforeText = updatedCarousels[index][inputName].slice(
        0,
        cursorPosition
      );
      const afterText =
        updatedCarousels[index][inputName].slice(cursorPosition);
      const newText = beforeText + textToInsert + afterText;

      updatedCarousels[index][inputName] = newText;
      setCarousels(updatedCarousels);
      setFormData((prevData) => ({
        ...prevData,
        carousels: updatedCarousels,
      }));
      const newCursorPosition = beforeText.length + textToInsert.length;
      setCursorPosition(newCursorPosition);

      if (titleRef.current) {
        titleRef.current.focus();
        titleRef.current.setSelectionRange(
          newCursorPosition,
          newCursorPosition
        );
      }
    }

    if (inputName === "card_description") {
      const textToInsert = `[var${varIndex.des}]`;
      setVarIndex((prevState) => ({
        ...prevState,
        des: prevState.des + 1,
      }));
      const updatedCarousels = [...carousels];
      const beforeText = updatedCarousels[index][inputName].slice(
        0,
        cursorPosition
      );
      const afterText =
        updatedCarousels[index][inputName].slice(cursorPosition);
      const newText = beforeText + textToInsert + afterText;

      updatedCarousels[index][inputName] = newText;
      setCarousels(updatedCarousels);
      setFormData((prevData) => ({
        ...prevData,
        carousels: updatedCarousels,
      }));
      const newCursorPosition = beforeText.length + textToInsert.length;
      setCursorPosition(newCursorPosition);

      if (descriptionRef.current) {
        descriptionRef.current.focus();
        descriptionRef.current.setSelectionRange(
          newCursorPosition,
          newCursorPosition
        );
      }
    }
  };

  const mediaHandler = (i, e) => {
    const url = e.target.value;
    const type = getMediaType(url);
    // Update the media object for the specific carousel at index i
    const updatedCarousels = [...carousels]; // Create a copy of the carousels array
    updatedCarousels[i] = {
      ...updatedCarousels[i], // Spread the existing carousel data
      media: {
        file_type: type, // Set the media type
        url: url, // Set the media URL
      },
    };
    // Update the carousels state with the new media object
    setCarousels(updatedCarousels);

    // Also update the formData with the modified carousels array
    setFormData((prevData) => ({
      ...prevData,
      carousels: updatedCarousels,
    }));
  };

  const deleteCarousel = () => {
    let afterDelete = carousels.filter((_, i) => i !== isDialogOpen.index);
    // delete carousels[isDialogOpen.index]
    setCarousels(afterDelete);
    setFormData((prevData) => ({
      ...prevData,
      carousels: afterDelete,
    }));
    setisDialogOpen({ status: false, index: "" });
  };

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      activeTabIndex: activeTab,
    }));
  }, [activeTab]);
  const handleClose = () => setisDialogOpen({ status: false, index: "" });

  return (
    <div className="suggested-button">
      {isDialogOpen.status && (
        <Dialog
          title="Alert"
          modal={true}
          onClose={handleClose}
          buttons={[
            {
              text: "Close",
              className: "btn-info btn-sm btn mr-2",
              onClick: () => handleClose(),
            },
            {
              text: "ok",
              className: "btn btn-sm btn-danger",
              onClick: () => deleteCarousel(),
            },
          ]}
        >
          <p>Are You Sure ? Want to Delete this Carousel </p>
        </Dialog>
      )}

      <button
        type="button"
        onClick={addCarousel}
        className="btn btn-sm btn-info"
      >
        + Add Carousel
      </button>

      {/* Tabs Navigation */}
      <div className="rcs-tabs mt-3">
        {carousels.map((_, index) => (
          <>
            <span
              key={index}
              className={`rcs-tab-button ${
                index === activeTab ? "active" : ""
              }`}
              onClick={() => setActiveTab(index)}
            >
              Carousel {index + 1}
            </span>
            <FontAwesomeIcon
              role="button"
              color="red"
              onClick={() => setisDialogOpen({ status: true, index: index })}
              icon={faTrash}
              className="mr-2"
            />
          </>
        ))}
      </div>

      {/* Tabs Content */}
      <div className="tab-content">
        {carousels.map((carousel, index) => (
          <div
            key={index}
            className={`tab-pane ${index === activeTab ? "active" : ""}`}
            role="tabpanel"
          >
            <div className="form-row">
              <div className="form-group drop-down-icons col-md-4">
                <select
                  className="form-control"
                  name="card_width"
                  value={carousel.card_width}
                  onChange={(e) => handleCarouselChange(index, e)}
                >
                  <option value="">Select Card Width</option>
                  <option value="small">Small Width</option>
                  <option value="medium">Medium Width</option>
                </select>
              </div>
              <div className="form-group drop-down-icons col-md-4">
                <select
                  className="form-control"
                  name="media_height"
                  value={carousel.media_height}
                  onChange={(e) => handleCarouselChange(index, e)}
                >
                  <option value="">Select Media height</option>
                  <option value="short">Short height</option>
                  <option value="medium">Medium height</option>
                </select>
              </div>
              <div className="form-group drop-down-icons col-md-4">
                <select
                  className="form-control"
                  name="mediaType"
                  disabled
                  value={carousel.mediaType}
                  onChange={(e) => handleCarouselChange(index, e)}
                >
                  <option value="Public">Public Image / Video </option>
                  <option value="Upload">Upload Image / Video </option>
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <input
                  type="text"
                  className="form-control"
                  name="mediaUrl"
                  value={carousel.mediaUrl}
                  onChange={(e) => mediaHandler(index, e)}
                  placeholder="Enter Public Image/Video URL"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <input
                  ref={titleRef}
                  type="text"
                  className="form-control"
                  id={`card_title` + index}
                  name="card_title"
                  value={carousel.card_title}
                  onChange={(e) => handleCarouselChange(index, e)}
                  placeholder="Enter Card Title"
                />
                <div className="d-flex mt-2 justify-content-between">
                  {charaterCount(carousel?.card_title)}
                  <span
                    className="badge badge-primary"
                    onClick={() => insertVar("card_title", index)}
                  >
                    Add Varibale
                  </span>
                </div>
              </div>
              <div className="form-group col-md-12">
                <textarea
                  ref={descriptionRef}
                  className="form-control"
                  name="card_description"
                  id={`card_description` + index}
                  value={carousel.card_description}
                  onChange={(e) => handleCarouselChange(index, e)}
                  placeholder="Enter Card Description"
                />
                <div className="d-flex mt-2 justify-content-between">
                  {charaterCount(carousel?.card_description)}
                  <span
                    className="badge badge-primary"
                    onClick={() => insertVar("card_description", index)}
                  >
                    Add Varibale
                  </span>
                </div>
              </div>
            </div>

            {/* suggestions Button ui */}

            <SuggestionsButton
              formData={formData}
              setFormData={setFormData}
              carousels={carousels}
              setCarousels={setCarousels}
              activeIndex={index}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CarouselCardForm;
