import React from 'react';

const DownloadButton = ({campaignType}) => {
  // Sample CSV data
  const sampleData = 
  campaignType === "3"
    ? [
        ['Mobile', 'Name', 'Age', 'Country'],
        ['1234567890', 'John Doe', '30', 'USA'],
        ['1234567891', 'Jane Smith', '25', 'UK'],
        ['1234567892', 'Sara Lee', '28', 'Canada']
      ]
    : [
        ["1234567890"],
        ['1234567891'],
        ['1234567892'],
      ];

  // Function to generate CSV file and trigger download
  const downloadCSV = () => {
    // Convert the sample data to CSV format
    const csvContent = sampleData
      .map(row => row.join(','))
      .join('\n');

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create a temporary link to trigger the download
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'sample.csv');
    link.style.visibility = 'hidden';

    // Append the link to the body, trigger the download, and remove the link
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div style={{width:"30%"}} className='d-flex flex-column justify-content-center align-items-end'>
      <span onClick={downloadCSV} role='button' className='text-primary cursor:hover' >
        Download Sample CSV
      </span>
      <p style={{ marginTop: '1px', fontSize: '.6rem', color: 'red',wordBreak:"break-all" }}>
       The first column  have mobile numbers
      </p>

    </div>
  );
};

export default DownloadButton;
