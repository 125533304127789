import React, { Component } from 'react';
import AdminLayout from '../../UI/Layout/AdminLayout';
import Title from '../../UI/Text/Title';
import { userloginDetails } from '../../Utils/AdminAuth';
import { HOST_URL } from '../../Dashboard/api/Api';
import Axios from 'axios';
import Spining from '../../UI/Spining/Spining';

class DefaultEmbededSignup extends Component {

    constructor() {
        const lStorage = userloginDetails();
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;


        super();

        this.state = {
            user_id: userId,
            token: userToken,
            embededSignup: "",
            isLoading: false
        }
    }

    componentDidMount() {
        this.retriveDetails();
    }
    async retriveDetails() {
        this.setState({
            successMessage: false,
            bgColor: "",
            errorMessage: "",
            isLoading: true
        });
        try {
            const apiPayload = {
                user_id: this.state.user_id,
                token: this.state.token,
                method: "retrieve_default_embsignup"
            }
            const { data } = await Axios.post(`${HOST_URL}/admin_user_list.php`, apiPayload);

            if (data.success === true) {
                this.setState({
                    embededSignup: data.data[0].route_id
                });
            }


        } catch (error) {
            this.setState({
                successMessage: true,
                bgColor: "alert alert-danger alert-dismissible",
                errorMessage: error.message,

            });
        }
        this.setState({

            isLoading: false
        });

    }

    UpdateUserDetails() {
        this.setState({
            successMessage: false,
            bgColor: "",
            errorMessage: "",
            isLoading: true
        });
        if (this.state.embededSignup === "") {
            this.setState({
                successMessage: true,
                bgColor: "alert alert-danger alert-dismissible",
                errorMessage: "select route",
                isLoading: false
            });
            return
        }
        document.querySelector("body").scrollTo(0, 0);
        window.scrollTo(0, 0);


        const bodyObject = {
            user_id: this.state.user_id,
            token: this.state.token,
            embsignup_routeid: this.state.embededSignup,
            method: "update_default_embsignup",
        };
        fetch(`${HOST_URL}/admin_user_list.php`, {
            method: "post",
            headers: {
                "content-Type": "application/json",
            },
            body: JSON.stringify(bodyObject),
        }).then((result) => {
            result.json().then((resp) => {
                if (resp.success === true) {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-success alert-dismissible",
                        errorMessage: resp.message,
                    });
                } else {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        errorMessage: resp.message,
                    });
                }
            });
        });
        this.setState({
            isLoading: false
        });
    }



    render() {


        return (

            <AdminLayout>
                {this.state.isLoading && <Spining />}

                {this.state.successMessage ? (
                    <div className={this.state.bgColor} role="alert">
                        <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                        <div className="">{this.state.errorMessage}</div>
                    </div>
                ) : null}
                <Title isButton={false}> Default Embeded Signup</Title>
                <form style={{ backgroundColor: "white", borderRadius: "15px", padding: "10px" }}>
                    <div className="row">

                        <div className="mb-3 col-md-6">
                            <label className="form-label" htmlFor="embededSignup">
                                Embeded Signup
                            </label>
                            <select
                                value={this.state.embededSignup}
                                onChange={(event) => this.setState({ embededSignup: event.target.value })}
                                className="form-control"
                            >
                                <option value="">Select Route</option>
                                <option value="41">Karix</option>
                                <option value="36">Pinnacle</option>
                            </select>
                        </div>
                        <div className="col-md-6">
                            <div className="float-right mt-4">
                                <input
                                    onClick={() => this.UpdateUserDetails()}
                                    type="button"
                                    value="Update Embeded Signup"
                                    className="btn btn-sm  btn-primary"
                                />
                            </div>
                        </div>

                    </div>


                </form>
            </AdminLayout>

        );
    }
}
export default DefaultEmbededSignup;