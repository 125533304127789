import React, { Component } from 'react';
import { CAMPAIGN_CONTACT_LIST_API } from '../../../constants/ServiceConstants';
import api from '../../../axios/Axios';
import DashboardLayout from '../../UI/Layout/DashboardLayout';
import Title from '../../UI/Text/Title';
import AdvanceDataTable from '../../UI/Table/AdvanceDataTable';
import { CAMPAIGN_CONTACT_DATA } from '../../UI/Table/ColumnConst';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { NODE_HOST_URL } from '../api/Api';
import Axios from '../../../axios/Axios';
import { Link } from 'react-router-dom/cjs/react-router-dom';

class ViewContactList extends Component {
  constructor(props) {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const user_id = lStorage.user.userId;
    const token = lStorage.token;
    super(props);

    this.state = {
      userId: user_id,
      token: token,
      ContactListData: [],
      filterContact: '',
      setIsLoading: true,
      ErrorResponse: '',
      id: this.props.match.params.id,
      type: this.props.match.params.list_type,
      openPopup: false,
      selectedContact: {}, 
      contactDetails: [],
      contactDetailPopup: false,
      mobileNumber: ''
    };
  }

  componentDidMount() {
    this.getContactList();
  }

  getContactList = async () => {
    try {
      const { data } = await api.post(`${NODE_HOST_URL}/contact_list`, {
        token: this.state.token,
        user_id: this.state.userId,
        list_id: this.state.id,
        method: 'list_data',
        list_type: this.state.type,
      });

      if (data.success === true) {
        const newArray = data.data.map(item => ({
          ...item,
          list_type: this.state.type,
        }));
        this.setState({ ContactListData: newArray, setIsLoading: false });
      } else {
        this.setState({ ErrorResponse: data, setIsLoading: false });
      }
    } catch (err) {
      console.log(err);
    }
  };

  filterData = async () => {
    this.setState({ isError: false });
    try {
      const { data } = await api.post(CAMPAIGN_CONTACT_LIST_API, {
        token: this.state.token,
        user_id: this.state.userId,
        method: "filter",
        list_id: this.state.id,
        mobile: this.state.filterContact,
      });
      if (data.success === true) {
        this.setState({ ContactListData: data.data, setIsLoading: false, searchDataTable: true });
      } else {
        this.setState({ errorMessage: data.message, isError: true, messageType: 'alert-danger', setIsLoading: false });
      }
    } catch (err) {
      console.log(err);
    }
  };

  resetFilter = () => {
    this.getContactList();
    this.setState({
      searchDataTable: false,
      filterContact: '',
    });
  };


  openModal = (contact) => {
    this.setState({ selectedContact: contact, openPopup: true });
  };


  closeModal = () => {
    this.setState({ openPopup: false, selectedContact: null });
  };






  render() {
    return (
      <DashboardLayout>
        <main className="content">
          <div className="container-fluid p-0">
            <Title
              isButton={true}
              btnName={`Back`}
              actionURL={`/dashboard/contact-list`}
            >
              Contact List Data {this.state.id}
            </Title>

            <div className="">
              <div className="p-2">
                <div className="flex-fill">
                  <div className="table-responsive">
                    <table className="table table-striped table-sm">
                      <thead>
                        <tr>
                          <th className="d-xl-table-cell">Mobile</th>
                          <th className="d-md-table-cell">Name</th>
                          <th className="d-md-table-cell">Other Columns</th>
                          <th className="d-md-table-cell">Date Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.ContactListData.map((item, i) => (
                          <tr key={i}>
                            <td className="d-xl-table-cell"><Link style={{ cursor: "pointer" }} to={this.state.type!=="contact"?`/dashboard/view-contact-list/${this.state.id}/${this.state.type}/${item.mobile}/${item._id}`:"#"} >{item.mobile}</Link></td>
                            <td className="d-xl-table-cell">{item.list_type === "contact" ? item.col_A : item.firstname}</td>
                            <td className="d-xl-table-cell">
                              <span
                                style={{ cursor: "pointer" }}
                                className="badge bg-danger"
                                onClick={() => this.openModal(item)}
                              >
                                <FontAwesomeIcon
                                  icon={faInfoCircle}
                                  color="#77ff8e"
                                />{" "}
                                Column Name
                              </span>
                            </td>
                            <td className="d-md-table-cell">{item.created}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {this.state.openPopup && (
              <div
                className={`modal fade ${this.state.openPopup ? "show" : ""}`}
                style={{ display: this.state.openPopup ? "block" : "none" }}
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Details of {this.state.selectedContact.firstname}
                      </h5>
                    </div>
                    <div
                      className="modal-body"
                      style={{ maxHeight: "400px", overflowY: "auto" }}
                    >
                      <div className="list-group">
                        <div className="d-flex flex-column">
                          {Object.entries(this.state.selectedContact)
                            .filter(
                              ([key]) =>
                                !["firstname", "mobile", "created"].includes(
                                  key
                                )
                            )
                            .map(([billingKey, billingValue], j) => (
                              <div
                                key={j}
                                className="d-flex justify-content-between align-items-center mb-2"
                              >
                                <span className="font-weight-bold">
                                  {billingKey}:
                                </span>
                                <span>{billingValue}</span>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={this.closeModal}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {this.state.contactDetailPopup && (
              <div
                className={`modal fade ${
                  this.state.contactDetailPopup ? "show" : ""
                }`}
                style={{
                  display: this.state.contactDetailPopup ? "block" : "none",
                }}
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Billing Information for {this.state.mobile}
                      </h5>
                    </div>
                    <div
                      className="modal-body"
                      style={{ maxHeight: "400px", overflowY: "auto" }}
                    >
                      <div className="list-group">
                        {this.state.contactDetails.map((x, index) => (
                          <div key={index} className="list-group-item">
                            {Object.keys(x.billingDetails).map((key, idx) => (
                              <div key={idx}>
                                <h5 className="mb-3">{key}</h5>
                                {x.billingDetails[key].map((item, i) => (
                                  <div key={i} className="d-flex flex-column">
                                    {Object.entries(item).map(
                                      ([billingKey, billingValue], j) => (
                                        <div
                                          key={j}
                                          className="d-flex justify-content-between align-items-center mb-2"
                                        >
                                          <span className="font-weight-bold">
                                            {billingKey}:
                                          </span>
                                          <span>{billingValue}</span>
                                        </div>
                                      )
                                    )}
                                  </div>
                                ))}
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() =>
                          this.setState({ contactDetailPopup: false })
                        }
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* <AdvanceDataTable
              tableName={'List Data'}
              tableCell={CAMPAIGN_CONTACT_DATA}
              tableData={this.state.ContactListData}
              isLoading={this.state.setIsLoading}
              isError={this.state.ErrorResponse}
            /> */}
          </div>
        </main>
      </DashboardLayout>
    );
  }
}

export default ViewContactList;
