import React, { useState } from 'react'
import DashboardLeftMenu from '../DashboardLeftMenu'
import DashboardHeader from '../../Header/DashboardHeader'
import DashboardFooter from '../../Footer/DashboardFooter'
import { userId } from '../../Utils/userAuth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import Spining from '../../UI/Spining/Spining'
import Axios from 'axios'
import { NODE_HOST_URL } from '../api/Api'

const ShopifyWebhook = () => {
    const [webhookValues, setWebhookValues] = useState('');
    const [WebhookUrl, setWebhookUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState({
        status: false,
        bgColor: "",
        errorMessage: ""
    })
    const [disable, setDisable] = useState(false)


    const retrieveAuthkey = async()=>{
        setIsLoading(true);
        try {
            const payload = {
                user_id:"",
                token:"",
                method:"retrieve_authkey"
            }
            const {data} = await Axios.post(`${NODE_HOST_URL}/shopify`,payload);
        } catch (error) {
            
        }
        setIsLoading(false);
    }


    const handleWebhookValues = (e) => {
        setWebhookValues(e.target.value)
        if (e.target.value !== "select") {
            setWebhookUrl(`https://napi.authkey.io/webhook/shopify/${e.target.value}/${userId}`)
            setDisable(true)
        }
        else {
            setWebhookUrl('')
            setDisable(false)
            setError({
                status: true,
                bgColor: "alert alert-danger",
                errorMessage: "Please Select Webhook"
            })
        }
        setTimeout(() =>{
            setError({status: false})
        },3000)
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(WebhookUrl);
        setError({
            status: true,
            bgColor: "alert alert-success",
            errorMessage: "Copy to Clipboard"
        })
        setTimeout(() => {
            setError({ status: false })
        }, 2000)
    };

    return (
        <div className="wrapper">
           {isLoading && <Spining/>}
            <DashboardLeftMenu />
            <div className="main">
                <DashboardHeader />
                <main className="content">
                    <div className="container-fluid p-0">
                        <div className="row mb-2 mb-xl-3">
                            <div className="col-auto d-none d-sm-block">
                                <h3><strong>Webhook </strong> Setup </h3>
                            </div>
                        </div>
                        <div className="row">

                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title mb-0">Create Shopify Webhook</h5>
                                </div>
                                <div className="card-body">
                                    {
                                        error.status ?
                                            <div className={error.bgColor} role="alert">
                                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                </button>
                                                <div className="">
                                                    {error.errorMessage}
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                    <div className='row'>
                                        <div className='col-5'>
                                            <select className='form-control' value={webhookValues} onChange={handleWebhookValues}>
                                                <option value="select">Select Webhooks</option>
                                                <option value="customer-creation">Customer Creation</option>
                                                <option value="customer-deletion">Customer Deletion</option>
                                                <option value="customer-disable">Customer Disable</option>
                                                <option value="customer-update">Customer Update</option>
                                                <option value="cart-creation">Cart Creation</option>
                                                <option value="cart-update">Cart Update</option>
                                                <option value="checkout-creation">Checkout Creation</option>
                                                <option value="checkout-deletion">Checkout Deletion</option>
                                                <option value="checkout-update">Checkout Update</option>
                                                <option value="collection-creation">Collection Creation</option>
                                                <option value="collection-deletion">Collection Deletion</option>
                                                <option value="collection-update">Collection Update</option>
                                                <option value="order-creation">Order Creation</option>
                                                <option value="order-cancellation">Order Cancellation</option>
                                                <option value="order-deletion">Order Deletion</option>
                                                <option value="order-payment">Order Payment</option>
                                                <option value="order-update">Order Update</option>
                                            </select>
                                        </div>
                                        <div className='col-7'>
                                            <input type='text' disabled={disable ? true : false} className='form-control' value={WebhookUrl} placeholder='Webhook URL' />
                                            <FontAwesomeIcon icon={faCopy}
                                                className='position-absolute'
                                                style={{ right: '20px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}
                                                onClick={copyToClipboard}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <DashboardFooter />
            </div>
        </div>
    )
}

export default ShopifyWebhook
