import React, { Component } from "react";
import DashboardLayout from "../../UI/Layout/DashboardLayout";
import Title from "../../UI/Text/Title";
import Card from "../../UI/Card/Card";
import Alert from "../../UI/Alert/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import Spining from "../../UI/Spining/Spining";
import { HOST_URL } from "../api/Api";
class UploadContactList extends Component {
  constructor(props) {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const user_id = lStorage.user.userId;
    const token = lStorage.token;
    super();

    this.state = {
      userId: user_id,
      token: token,
      uploadID: "",
      image_url: "",
      isLoading: false,
      setIsLoading: true,
      errorMessage: "",
      messageType: "",
    };
    this.uploadData = this.uploadData.bind(this);
    this.fileChangedHandler = this.fileChangedHandler.bind(this);
  }

  uploadData() {
    this.setState({ isLoading: true });
    document.querySelector("body").scrollTo(0, 0);
    const data = new FormData();
    data.append("csvfile", this.state.image_url);
    data.append("user_id", this.state.userId);
    data.append("token", this.state.token);
    data.append("method", "upload");
    data.append("list_id", this.props.match.params.id);
    fetch(`${HOST_URL}/contact-list.php`, {
      method: "POST",
      body: data,
    }).then((result) => {
      result.json().then((resp) => {
        console.log(resp);
        if (resp.success === true) {
          document.querySelector("body").scrollTo(0, 0);
          this.setState({
            isError: true,
            messageType: "alert-success",
            errorMessage: resp.message,
            isLoading: false,
          });
          setTimeout(() => {
            this.props.history.push("/dashboard/contact-list");
          }, 1000);
        } else {
          this.setState({
            isError: true,
            messageType: "alert-danger",
            errorMessage: resp.message,
            isLoading: false,
          });
        }
      });
    });
  }

  fileChangedHandler(e) {
    console.log(URL.createObjectURL(e.target.files[0]));
    if (e.target.files[0]) {
      this.setState({ image_url: e.target.files[0] });
    }
  }

  render() {
    return (
      <DashboardLayout>
        {this.state.isLoading && <Spining />}

        <main className="content">
          <div className="container-fluid p-0">
            <Title isButton={false}> Create Contact List </Title>
            <Card CardTitle={`Contact List`}>
              <div className="row">
                <div className="col-md-8">
                  {this.state.isError && (
                    <Alert type={this.state.messageType}>
                      {this.state.errorMessage}
                    </Alert>
                  )}

                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">AMD CSV File</div>
                    </div>
                    <input
                      onChange={this.fileChangedHandler}
                      type="file"
                      className="form-control"
                    />
                  </div>

                  <p className="mt-4">
                    (upload csv only) Sample{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                      href="/uplodContact.csv"
                    >
                      {" "}
                      <FontAwesomeIcon icon={faFileCsv} /> Download{" "}
                    </a>{" "}
                  </p>

                  <div className="col-md-12 ml-auto text-right">
                    <button
                      onClick={this.uploadData}
                      className="btn btn-primary btn-sm"
                    >
                      <FontAwesomeIcon icon={faUpload} /> Upload
                    </button>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <p className="tooglebox">
                      <a
                        className=""
                        data-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                        href="/dashboard/email-template#collapseExample"
                      >
                        Note
                      </a>
                    </p>
                    <ol className="mt-3 template-mng">
                      <li>
                      While uploading the CSV, pass the sender ID as null if you want to use the template ID for all approved sender IDs.
                      </li>
                      <li>
                      During the CSV upload, always use the approved sender ID. Otherwise, don't use it when sending SMS.
                      </li>
                      <li>
                      When uploading the CSV, don't remove the first row of the sample file.
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </main>
      </DashboardLayout>
    );
  }
}

export default UploadContactList;
