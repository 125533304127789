import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import DashboardHeader from "../../Header/DashboardHeader";
import DashboardFooter from "../../Footer/DashboardFooter";
import DashboardLeftMenu from "../../Dashboard/DashboardLeftMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faInfoCircle,
  faMobile,
  faBan,
  faDownload,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import EmptyImg from "../../Dashboard/assets/img/create-project.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { HOST_URL } from "../api/Api";

class EmailDetailReports extends Component {
  constructor(props) {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;

    super(props);
    this.state = {
      user_id: userId,
      token: userToken,
      emailDetailsReportList: "",
      responceMessage: "",
      status: "",
      senderid: "",
      mobile: "",
      email: "",
      channel: this.props.match.params.channel,
      dateFrom: this.props.match.params.dateFrom,
      dateTo: this.props.match.params.dateTo,
      SelecteddateFrom: "",
      SelecteddateTo: "",
      successMessage: false,
      errorMessage: "",
      bgColor: "",
      isPopoverOpen: null,
      popoverPosition: "bottom"
    };
    this.popupRef = React.createRef();
  }

  componentDidMount() {
    this.detailsReport();
    this.FilderDetailsWithChannel()
    document.addEventListener('mousedown', this.handleClickOutside);
  }


  componentWillUnmount() {
    // Clean up event listener when the component unmounts
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.popupRef.current && !this.popupRef.current.contains(event.target)) {
      this.setState({ isPopoverOpen: null, });
    }
  };


  handleButtonClick = (index) => {
    this.setState({
      isPopoverOpen: index,
    });
  };

  togglePopover = (i, event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const viewportHeight = window.innerHeight;

    // Determine whether there is more space above or below
    const popoverPosition = rect.bottom + 200 > viewportHeight ? "top" : "bottom";

    this.setState({ isPopoverOpen: i, popoverPosition });
  };
  detailsReport() {
    //console.log('channel',this.state.channel)
    fetch(
      `${HOST_URL}/user_report.php?user_id=${this.state.user_id}&method=details&channel=email&token=${this.state.token}&date_from=${this.state.dateFrom}&date_to=${this.state.dateTo}`
    ).then((response) => {
      response.json().then((result) => {
        // console.log('result',result)
        if (result.success === true) {
          if (this.state.channel === "email") {
            this.setState({ emailDetailsReportList: result.message });
          }
        } else {
          this.setState({ responceMessage: result.message });
        }
      });
    });
  }

  FilderDetailsWithChannel() {
    this.setState({ emailDetailsReportList: "" });
    fetch(
      `${HOST_URL}/user_report.php?user_id=${this.state.user_id}&method=details&channel=email&token=${this.state.token}&date_from=${this.state.dateFrom}&date_to=${this.state.dateTo}&mobile=${this.state.mobile}&sernderid=${this.state.sernderid}&status=${this.state.status}&email=${this.state.email}`
    ).then((response) => {
      response.json().then((result) => {
        //  console.log(result)
        if (result.success === true && result.message && result.message.length > 0) {
          if (this.state.channel === "email") {
            this.setState({ emailDetailsReportList: result.message,
              successMessage: false,  
              errorMessage: "",     
             });
          }
        } else {
          this.setState({
            successMessage: true,
            errorMessage: result.message,
            bgColor: "alert alert-danger alert-dismissible",
          });
        }
      });
    });
  }

  dataDownload() {
    var date1 = new Date(this.state.dateFrom);
    var date2 = new Date(this.state.dateTo);
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    //console.log('Difference_In_Days', Math.trunc(Difference_In_Days))

    if (Math.trunc(Difference_In_Days) <= 15) {
      window.location.href = `${HOST_URL}/user_report.php?user_id=${this.state.user_id}&method=download&channel=email&token=${this.state.token}&date_from=${this.state.dateFrom}&date_to=${this.state.dateTo}`;
    } else {
      document.querySelector("body").scrollTo(0, 0);
      this.setState({
        successMessage: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: `Please go to the Download Center to download data older than 15 days.`,
      });
    }
  }

  dateFrom = (date) => {
    let dated =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      ("0" + date.getDate()).slice(-2);
    this.setState({
      dateFrom: dated,
      SelecteddateFrom: date,
    });
  };

  dateTo = (date) => {
    let dated =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      ("0" + date.getDate()).slice(-2);
    this.setState({
      dateTo: dated,
      SelecteddateTo: date,
    });
  };

  setStatus() {
    if (this.state.channel === "email") {
      return (
        <>
          <option value=""> All </option>
          <option value="opened"> Opened </option>
          <option value="sent"> Sent </option>
          <option value="delivered"> Delivered </option>
          <option value="bounce"> Bounce </option>
        </>
      );
    } else {
      return (
        <>
          <option value=""> No Channel Selected </option>
        </>
      );
    }
  }

  render() {
    return (
      <div className="wrapper">
        <DashboardLeftMenu />
        <div className="main">
          <DashboardHeader />
          <main className="content">
            <div className="container-fluid p-0">
              <div className=" mb-2 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                  <h3>
                    <strong>Email Reports </strong> Details{" "}
                  </h3>
                </div>
              </div>
              <div className="">
                <div className="card">
                  <div className="">
                    <h5 className="card-title  mt-5">
                      <div className="col-auto ml-auto text-right mt-n1">
                        <nav className="text-center" aria-label="breadcrumb">
                          <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
                            <Link to="/dashboard/email-report">
                              <li className="breadcrumb-item">
                                {" "}
                                Today's Summary{" "}
                              </li>{" "}
                            </Link>
                            <Link
                              to={
                                "/dashboard/email-detail-reports/" +
                                "email/" +
                                this.state.dateFrom +
                                "/" +
                                this.state.dateTo
                              }
                            >
                              {" "}
                              <li className="breadcrumb-item active">
                                Details{" "}
                              </li>{" "}
                            </Link>
                          </ol>
                        </nav>
                      </div>
                    </h5>
                  </div>

                  <div className="card-body">
                    {this.state.successMessage ? (
                      <div className={this.state.bgColor} role="alert">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                        <div className="">
                          {this.state.errorMessage}{" "}
                          <Link to="/dashboard/download-centre">
                            Download Centre
                          </Link>
                        </div>
                      </div>
                    ) : null}
                    <div className="filter-option">
                      <h6 className="card-subtitle text-muted">Filter </h6>
                      <div className="row">
                        {/* <div className="col-md-4">
                        <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-text"><FontAwesomeIcon icon={faCalendarCheck}/></div>
                            <DatePicker className="form-control btn-block"
                            dateFormat="yyyy/MM/dd"
                            onChange={this.dateFrom}
                            value={this.state.dateFrom}
                            selected={this.state.SelecteddateFrom}
                            placeholderText='From Date'
                          /> 
                        </div>
                    </div> */}
                        {/* <div className="col-md-4">
                        <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-text"><FontAwesomeIcon icon={faCalendarCheck}/></div>
                            <DatePicker className="form-control btn-block"
                                dateFormat="yyyy/MM/dd"
                                onChange={this.dateTo}
                                value={this.state.dateTo}
                                selected={this.state.SelecteddateTo}
                                placeholderText='Date To'
                                /> 
                        </div>
                    </div> */}

                        {/* <div className="col-md-4">
                          <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-text">
                              <FontAwesomeIcon icon={faCalendarCheck} />
                            </div>
                            <div>
                            <DatePicker
                                className="form-control btn-block"
                                dateFormat="yyyy/MM/dd"
                                value={this.state.dateFrom}
                                onChange={this.dateFrom}
                                selected={this.state.SelecteddateFrom}
                                placeholderText="From Date"
                              />
                            </div>
                          </div>
                        </div> */}
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-text d-flex align-items-center justify-content-center" style={{ width: '40px' }}>
                              <FontAwesomeIcon icon={faCalendarCheck} />
                            </div>
                            <DatePicker
                              className="form-control"
                              dateFormat="yyyy/MM/dd"
                              value={this.state.dateFrom}
                              onChange={this.dateFrom}
                              selected={this.state.SelecteddateFrom}
                              placeholderText="From Date"
                            />
                          </div>
                        </div>

                        {/* <div className="col-12 col-md-6 col-lg-4">
                          <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-text d-flex align-items-center justify-content-center">
                              <FontAwesomeIcon icon={faCalendarCheck} />
                            </div>
                            <div>
                              <DatePicker
                                className="form-control btn-block"
                                dateFormat="yyyy/MM/dd"
                                value={this.state.dateTo}
                                onChange={this.dateTo}
                                selected={this.state.SelecteddateTo}
                                placeholderText="Date To"
                              />
                            </div>
                          </div>
                        </div> */}

                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="input-group mb-2 mr-sm-2 d-flex">
                            <div className="input-group-text d-flex align-items-center justify-content-center" style={{ width: '40px' }}>
                              <FontAwesomeIcon icon={faCalendarCheck} />
                            </div>
                            <DatePicker
                              className="form-control"
                              dateFormat="yyyy/MM/dd"
                              value={this.state.dateTo}
                              onChange={this.dateTo}
                              selected={this.state.SelecteddateTo}
                              placeholderText="Date To"
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-text">
                              <FontAwesomeIcon icon={faBan} />
                            </div>
                            <select
                              onChange={(event) =>
                                this.setState({ status: event.target.value })
                              }
                              className="form-control"
                            >
                              <option value=""> Select Status </option>
                              {this.setStatus()}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-md-6">
                          <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-text">
                              <FontAwesomeIcon icon={faMobile} />
                            </div>
                            {this.state.channel === "email" ? (
                              <input
                                onChange={(event) =>
                                  this.setState({ email: event.target.value })
                                }
                                type="text"
                                placeholder="Enter Email"
                                className="form-control"
                              />
                            ) : (
                              <input
                                onChange={(event) =>
                                  this.setState({ mobile: event.target.value })
                                }
                                type="text"
                                placeholder="Enter Mobile NO"
                                className="form-control"
                              />
                            )}
                          </div>
                        </div>

                        <div className="col-md-3">
                          <button
                            type="button"
                            onClick={() => this.dataDownload()}
                            className="btn-success btn-sm btn-block ml-auto mb-2"
                          >
                            <FontAwesomeIcon icon={faDownload} /> Download
                            Reports{" "}
                          </button>
                        </div>

                        <div className="col-md-3">
                          <button
                            type="button"
                            onClick={() => this.FilderDetailsWithChannel()}
                            className="btn-primary btn-block btn-sm ml-auto"
                          >
                            {" "}
                            <FontAwesomeIcon icon={faSearch} /> Filter Search{" "}
                          </button>
                        </div>
                      </div>
                    </div>

                    <hr />

                    <div className="row">
                      <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                        <div
                          style={{ overflow: "scroll", height: 400 + "px" }}
                          className=" flex-fill"
                        >
                          <div className="">
                            {this.state.channel === "email" &&
                              this.state.emailDetailsReportList !== "" ? (
                              <table className="table table-striped table-sm my-0">
                                <thead>
                                  <tr>
                                    <th>Status</th>
                                    <th>Email</th>
                                    <th>Cost</th>
                                    <th>Message</th>
                                    <th>Sender</th>
                                    <th>Submitted </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.emailDetailsReportList.map(
                                    (item, i) => (
                                      <tr key={i}>
                                        <td>
                                          {item.status === "Delivered" ? (
                                            <span className="badge btn-success">
                                              {item.status}
                                            </span>
                                          ) : item.status === "Opened" ? (
                                            <span className="badge bg-primary">
                                              {item.status}
                                            </span>
                                          ) : item.status === "Sent" ? (
                                            <span className="badge bg-success">
                                              {item.status}
                                            </span>
                                          ) : item.status === "Clicked" ? (
                                            <span className="badge bg-info">
                                              {item.status}
                                            </span>
                                          ) : (
                                            <span className="badge bg-danger">
                                              {item.status}
                                            </span>
                                          )}
                                        </td>

                                        <td>{item.to_email}</td>
                                        <td>{item.email_cost}</td>

                                        <td style={{ position: "relative" }}>
                                          <span
                                            className="badge bg-info"
                                            onClick={() =>
                                              this.handleButtonClick(i)
                                            }
                                            data-tip
                                            data-event="click focus"
                                            data-for={"registerTip" + i}
                                            style={{
                                              cursor: "pointer",
                                              fontSize: 15,
                                            }}
                                          >
                                            {" "}
                                            <small
                                              style={{ fontSize: 10 + "px" }}
                                            >
                                              Message Show / Hide{" "}
                                            </small>{" "}
                                            <FontAwesomeIcon
                                              icon={faInfoCircle}
                                              color="yellow"
                                              size={5}
                                            />
                                          </span>
                                          {/* <ReactTooltip
                                            //  globalEventOff="click"
                                            className="tooltip  templateTooltip"
                                            html={true}
                                            clickable={true}
                                            onClick={() => {
                                            //ReactTooltip.show(this.fooRef);
                                            }}
                                            id={"registerTip" + i}
                                            place="bottom"
                                            effect="solid"
                                          >
                                            {item.message}
                                          </ReactTooltip> */}
                                          {this.state.isPopoverOpen === i && (
                                            <div ref={this.popupRef} className="message_box"> <div dangerouslySetInnerHTML={{ __html: item.message }}></div></div>
                                          )}
                                        </td>

                                        <td>{item.from_email}</td>
                                        <td>{item.submitted_on}</td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            ) : (
                              <div className="text-center">
                                <div className="card-body">
                                  <img
                                    alt="Empty Data"
                                    className="project-empty-img"
                                    src={EmptyImg}
                                  />
                                  <small>
                                    {this.state.responceMessage} in{" "}
                                    <strong> {this.state.channel} </strong>{" "}
                                    Between {this.state.dateFrom} to{" "}
                                    {this.state.dateTo}{" "}
                                  </small>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <DashboardFooter />
        </div>
      </div>
    );
  }
}

export default EmailDetailReports;
