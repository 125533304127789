import React, { Component } from 'react';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import EmptyImg from "../../Dashboard/assets/img/create-project.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import { HOST_URL } from '../api/Api';

class VoicePriceList extends Component {

    constructor(){

        const lStorage = JSON.parse(localStorage.getItem("login"));
		const userId= lStorage.user.userId;
        const userToken= lStorage.token;
        
        super();

        this.state={
            user_id: userId,
            token: userToken,
            emailCost:'',
            serchData:'',
            paymentTransactionList:null,
            successMessage:false,
            errorMessage:'',
            bgColor:"alert alert-success alert-dismissible",
            disable: true
        }
    }
    
    componentDidMount(){
       this.SearchDataOnLoad();
    } 

    SearchDataOnLoad(){
        fetch(` ${HOST_URL}/user_pricelist.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response)=> {
            response.json().then((result)=>{
                // console.log(result)
                if(result.success===true){
                    this.setState({paymentTransactionList:result});
                    this.setState({emailCost:result.email_cost});
                }else{
                    this.setState({
                        successMessage:true,
                        successBg:"alert alert-danger alert-dismissible",
                        errorMessage:result.message
                    }) 
                }
            })
        })
    }

    
searchUser(){

    if(this.state.serchData===''){
        this.SearchDataOnLoad();
        this.setState({successMessage:true,errorMessage:"Enter Country or Country Code Name",bgColor:"alert alert-danger alert-dismissible"});	
    }else{
    fetch(`${HOST_URL}/user_pricelist.php?user_id=${this.state.user_id}&method=search&keyword=${this.state.serchData}&token=${this.state.token}`).then((response)=> {
        response.json().then((result)=>{
           //  console.log(result)
           this.setState({disable: true})
            if(result.success===true){
                this.setState({bgColor:"alert alert-success alert-dismissible",errorMessage:"Data Updated"});
                this.setState({paymentTransactionList:result});
                this.setState({emailCost:result.email_cost});
                this.setState({disable: false})
            }else{
                this.setState({
                    successMessage:true,
                    successBg:"alert alert-danger alert-dismissible",
                    errorMessage:result.message
                }) 
                this.setState({disable: false})
            }
        })
    })
    }
}


render() {
    return (
<div className="wrapper">
    <DashboardLeftMenu />
    <div className="main">
        <DashboardHeader />
        <main className="content">
            <div className="container-fluid p-0">
                <div className=" row mb-2 mb-xl-3">
                    <div className="col-auto d-none d-sm-block">
                        <h3><strong> Voice Price </strong> List </h3>
                    </div>
                </div>

                {
                            this.state.successMessage? 
                                    <div className={this.state.bgColor} role="alert">
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">×</span>
                          </button>
                                <div className="">
                                        {this.state.errorMessage}
                                    </div>
                                </div>
                                    :
                                    null
                                }
                                

                <div className="row">
                        <div className="">
                            <div className="">
                                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                
                                    <div className="card flex-fill">
    
                                    <div className="card-body">
                                    <div className="">
                                    <div className="filter-option">
                                     <h6 className="card-subtitle text-muted">Filter </h6> 
                                     <div className="row">
                               
                                     <div className="col-md-9 text-right">
                                     <div className="input-group mb-2 mr-sm-2">
                                    <div className="input-group-text"><FontAwesomeIcon icon={faSearch}/></div>
                                    <input type="text" onChange={(event) => { this.setState({serchData: event.target.value}) }} placeholder="Enter Your Country Code or Country Name" className="form-control"/>
                                    </div>
                                    </div>
                                    <div className="col-md-3">
                                    <div className="input-group mb-2 mr-sm-2">
                                        <input type="button" disabled={this.state.disable ? true : false} onClick={()=> this.searchUser()} value="Search" className="btn mt-1 btn-block btn-blue btn-sm ml-auto "/>
                                    </div>
                                </div>

                                </div>



                       

                                    </div>
                                    </div>
                                          

                                    {this.state.paymentTransactionList?

                                    
                                        <div className="table-responsive">
                                        <table className="table table-hover my-0">
                                        <thead>
                                            <tr>
                                                <th className="d-xl-table-cell">Country</th>
                                                <th className="d-xl-table-cell">Voice Cost</th>
                                                <th>Voice Pulse</th>
                                            </tr>
                                        </thead>
                                            <tbody>

                                            {
                                            this.state.paymentTransactionList.data.map((item,i)=>
                                        <tr>
                                            <td>{item.country_name} ({item.country_code})</td>
                                           
                                            {item.voice_cost > 0 ? <td>Rs.{parseFloat(item.voice_cost).toFixed(3)}</td> : <td>NA</td>}
                                            {item.voice_pulse > 0 ? <td>{item.voice_pulse} Sec</td>: <td>NA</td>}
                                        </tr>
                                        
                                        )
                                    }                                    
                                               
                                            </tbody>
                                        </table>
                                        </div>
                                        :
                                        <div className="text-center">
                                        <div className="card-body">
                                            <img alt="Empty Data" className="project-empty-img" src={EmptyImg} />
                                            <p>No Pricelist Found </p>

                                            </div>
                                        </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>    
        </main>
        <DashboardFooter />
    </div>
</div>
        );
    }
}

export default VoicePriceList;