import React, { useEffect, useState } from "react";
import { wp_get_billable_notbillable } from "../../api/Api";

function BillableValue(props) {
  const [value, setValue] = useState({});

  useEffect(() => {
    wp_get_billable_notbillable(props.listId, props.templteId).then((res) => {
      props.enableCampaingButton(false);
      setValue(res.data);
    });
  }, [props.listId, props.templteId]);

  return (
    <>
      <div className="Campaign-status mt-2 mr-4">
        <p className="m-0">
          <strong>Billable : </strong>
          <span className="badge badge-primary badge-pill">
            {value.billable}
          </span>
        </p>
      </div>

      <div className="Campaign-status mt-2">
        <p className="m-0">
          <strong> Not Billable : </strong>
          <span className="badge badge-primary badge-pill">
            {value.not_billable}
          </span>
        </p>
      </div>
    </>
  );
}

export default BillableValue;
