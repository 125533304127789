import React, { useEffect, useState } from "react";
import AdvanceDataTable from "../../UI/Table/AdvanceDataTable";
import { ADMIN_KARIX_TOKEN_PINNACLE_SIGNUP, ADMIN_KARIX_WABA_PINNACLE_SIGNUP, ADMIN_PINNACLE_SIGNUP, ADMIN_WHATSAPP_SETUP } from "../../UI/Table/ColumnConst";
import AdminLayout from "../../UI/Layout/AdminLayout";
import Title from "../../UI/Text/Title";
import Spining from "../../UI/Spining/Spining";
import { userloginDetails } from "../../Utils/AdminAuth";
import Axios from "axios";
import { HOST_URL, NODE_HOST_URL } from "../../Dashboard/api/Api";
import ReactDatePicker from "react-datepicker";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const KarixEmbededSignup = () => {
    const lStorage = userloginDetails();
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const adminType = lStorage.user.isAdmin;
    const [alert, setAlert] = useState({
        status: false,
        bgColor: "",
        errorMessage: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [resData, setResData] = useState([]);
    const [fromdate, setFromdate] = useState(new Date());
    const [todate, setTodate] = useState(new Date());
    const [karixToken , setKarixToken] = useState([]);
    const [karixWaba , setKarixWaba] = useState([]);

    useEffect(() => {
        handleSearch();
    }, [])

    const fromdateHandler = (data) => {
        setFromdate(data);
    };

    const todateHandler = (data) => {
        setTodate(data);
    };

    const currentDate = (currentDate) => {
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    };


    const handleSearch = async () => {
        setIsLoading(true);

        try {
            const dataforsearch = {
                user_id: userId,
                token: userToken,
                method: "retrieve_karix_emb_signup",
                fromdate: currentDate(fromdate)+ " " + "00:00:00",
                todate: currentDate(todate)+ " " + "23:59:59"
            };
            console.log(dataforsearch)
            const res = await Axios.post(`${NODE_HOST_URL}/admin/karix_list`, dataforsearch);
            console.log(res.data.data)
            if (res.data.success === true) {
                if (res.data.data) {
                    // setResData(res.data.data)
                    setKarixToken(res.data.data.karix_token);
                    setKarixWaba(res.data.data.karix_waba);
                } else {
                    setResData([])
                }
            } else {
                setAlert({
                    status: true,
                    bgColor: "danger",
                    errorMessage: res.data.message,
                });
            }


        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };


    return (
        <div>
            <AdminLayout>
                <Title isButton={false}> Karix Embeded Signup </Title>

                <div className="row">
                    <div className="">
                        <div className="card">
                            {alert.status === true ? (
                                <div className={alert.bgColor} role="alert">
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="alert"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <div className="">{alert.errorMessage}</div>
                                </div>
                            ) : null}

                            {isLoading && <Spining />}

                            <div className="card-body">
                                <div className="card">
                                    <div className="row m-3">
                                        <div className="col-md-5">
                                            <div className="input-group mb-2 mr-sm-2">
                                                <div className="input-group-text">
                                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                                </div>

                                                <ReactDatePicker
                                                    className="form-control btn-block"
                                                    dateFormat="dd/MM/yyyy"
                                                    value={fromdate}
                                                    onChange={fromdateHandler}
                                                    selected={fromdate}
                                                    placeholderText="From Date"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="input-group mb-2 mr-sm-2">
                                                <div className="input-group-text">
                                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                                </div>
                                                <ReactDatePicker
                                                    className="form-control btn-block"
                                                    dateFormat="dd/MM/yyyy"
                                                    value={todate}
                                                    onChange={todateHandler}
                                                    selected={todate}
                                                    placeholderText="Date To"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <button type="button" onClick={handleSearch} className="btn btn-sm btn-info float-right">Submit</button>
                                        </div>

                                    </div>
                                </div>
                                <div className="card">
                                    {/* <h6 className="m-2">Karix Token</h6>
                                    <AdvanceDataTable
                                        tableName={""}
                                        tableCell={ADMIN_KARIX_TOKEN_PINNACLE_SIGNUP}
                                        tableData={karixToken}
                                        isLoading={isLoading}
                                        isError={alert.errorMessage}
                                        filterstatus={true}
                                        pagination={false}
                                    />
                                    <h6 className="m-2">Karix Waba</h6> */}
                                    <AdvanceDataTable
                                        tableName={""}
                                        tableCell={ADMIN_KARIX_WABA_PINNACLE_SIGNUP}
                                        tableData={karixWaba}
                                        isLoading={isLoading}
                                        isError={alert.errorMessage}
                                        filterstatus={true}
                                        pagination={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AdminLayout>
        </div>
    );
};

export default KarixEmbededSignup;
