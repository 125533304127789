import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import AdminHeader from '../Header/AdminHeader';
import AdminFooter from '../Footer/AdminFooter';
import AdminLeftMenu from '../Navbar/AdminLeftMenu';
import EmptyImg from "../Dashboard/assets/img/create-project.png";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { HOST_URL } from '../Dashboard/api/Api';
import { userloginDetails } from '../Utils/AdminAuth';
import Axios from '../../axios/Axios';

const ResellerTransactionDetails = (props) => {
    const lStorage = userloginDetails();
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const empType = lStorage.user.isAdmin;
    const { id, userType } = useParams();
    const param = useParams();
    

    const [email, setEmail] = useState('');
    const [selectUserTransactionDetails, setSelectedUserTransactionDetails] = useState([])

    useEffect(() => {
        getUserDetails();
        getTransactionDetails();
    },[]);

    const getUserDetails = async () => {
        try {
            const response = await fetch(
                `${HOST_URL}/admin_user_list.php?user_id=${userId}&method=retrieve&type=userdetails&ret_user_id=${id}&token=${userToken}`
            );

            const result = await response.json();

            if (result.success === true) {
                setEmail(result.data[0].email);
            } else {
                // setSuccessMessage(true);
                // setSuccessBg('alert alert-danger alert-dismissible');
                // setErrorMessage(result.message);
            }
        } catch (error) {
            console.error('Error fetching user details:', error);
            // setSuccessMessage(true);
            // setSuccessBg('alert alert-danger alert-dismissible');
            // setErrorMessage('An error occurred while fetching user details.');
        }
    };

    const getTransactionDetails = async () => {
        try {
            let { data } = await Axios.post(`${HOST_URL}/admin_user_list.php?user_id=${userId}&method=retrieve&type=transaction&ret_user_id=${id}&token=${userToken}`);
            if (data.success) {
                setSelectedUserTransactionDetails(data.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    
    return (
        <div>
            <div className="wrapper">
                <AdminLeftMenu />
                <div className="main">
                    <AdminHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3>User Details <strong className='text-info'> {email} </strong>  </h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="card">

                                    <div className="card-header">
                                        <h5 className="card-title mb-0">
                                            <div className="col-auto ml-auto text-right mt-n1">
                                                <nav className="text-center" aria-label="breadcrumb">
                                                    <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
                                                        <Link to={'/admin/edit-employee/' + id + `/` + userType}><li className="breadcrumb-item ">Update Details </li> </Link>
                                                        <Link to={'/admin/reseller-transaction-details/' + id + `/` + userType}>  <li className="breadcrumb-item active"> Transaction Details </li></Link>

                                                        <Link to={'/admin/reseller-price-details/' + id + `/` + userType}>
                                                            <li className="breadcrumb-item"> Add Price </li></Link>
                                                            {empType === 'admin' && <Link to={'/admin/reseller-add-price/' + id + `/` + userType}>  <li className="breadcrumb-item"> Add Credit </li></Link>}
                                                        <Link to={'/admin/reseller-billing-mode/' + id + `/` + userType}>  <li className="breadcrumb-item"> Billing Mode </li></Link>
                                                    </ol>
                                                </nav>
                                            </div>
                                        </h5>
                                    </div>

                                    <div className="card-body">

                                        <div className="row">
                                            <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                                <div className="card table-responsive flex-fill">

                                                    {selectUserTransactionDetails ?

                                                        <table className="table  table-hover my-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Remark Name</th>
                                                                    <th className="">Amount</th>
                                                                    <th className="">Transaction</th>
                                                                    <th className="">Status</th>
                                                                    <th className="">Created Date / Time</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {
                                                                    selectUserTransactionDetails.map((item, i) =>
                                                                        <tr key={i}>

                                                                            <td>{item.remark}</td>
                                                                            <td>{item.amount}</td>
                                                                            <td>{item.transaction_id}</td>
                                                                            <td>
                                                                                {
                                                                                    item.status === 1 ?
                                                                                        <span className="badge bg-success">  Success </span>
                                                                                        :

                                                                                        item.status === 0 ?
                                                                                            <>
                                                                                                <span className="badge bg-info"> Pending  </span>
                                                                                            </>
                                                                                            :
                                                                                            <span className="badge bg-danger"> Failed    </span>
                                                                                }


                                                                            </td>

                                                                            <td>{item.created}</td>
                                                                        </tr>

                                                                    )
                                                                }



                                                            </tbody>
                                                        </table>
                                                        :
                                                        <div className="text-center">
                                                            <div className="card-body">
                                                                <img alt="Empty Data" className="project-empty-img" src={EmptyImg} />
                                                                <p>No Any Transaction Details Found</p>

                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </main>
                    <AdminFooter />
                </div>
            </div>
        </div>
    )
}

export default ResellerTransactionDetails