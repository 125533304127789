import React from "react";
import styles from "./DeleteModal.module.css"; // Import the CSS module

const DeleteModal = ({ show, onClose, onDelete }) => {
  if (!show) return null; // Render nothing if `show` is false

  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <div className={styles.header}>
          <h4>Delete Confirmation</h4>
          <button className={styles.closeButton} onClick={onClose}>
            &times;
          </button>
        </div>
        <div className={styles.body}>
          <p>Are you sure you want to delete this item? This action cannot be undone.</p>
        </div>
        <div className={styles.footer}>
          <button className={`${styles.btn} ${styles.secondary}`} onClick={onClose}>
            Cancel
          </button>
          <button className={`${styles.btn} ${styles.danger}`} onClick={onDelete}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
