import React, { useState } from "react";
import "../../../../style/components/whatsAppPreview.css";
import { bg_whatsapp, whatsapp_brand } from "../../../../images/WhatsAppImages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faGlobe } from "@fortawesome/free-solid-svg-icons";
import 'bootstrap/dist/css/bootstrap.min.css';

const WhatsappCampaignListPreview = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openTemplatePopup = props.openTemplatePopup;

    // Toggle modal visibility
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    // Getting company info from localStorage
    const companyname = localStorage.getItem("wp-cmpy-name");
    let truncatedcompanyname;
    if (companyname) {
        truncatedcompanyname = companyname.length <= 15 ? companyname : companyname.substring(0, 15) + '...';
    }

    const companyimg = localStorage.getItem("wp-cmpy-img");

    return (
        <div>
            {/* Bootstrap Modal */}
            <div
                className={`modal fade ${openTemplatePopup ? 'show' : ''}`}
                tabIndex="-1"
                aria-labelledby="whatsappPreviewModal"
                aria-hidden={!openTemplatePopup}
                style={{ display: openTemplatePopup ? 'block' : 'none' }}
            >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="whatsappPreviewModal">
                                WhatsApp Campaign Preview
                            </h5>
                            <button
                                type="button"
                                className="close"
                                onClick={() => props.closePopup()}
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {props && props.templateDetails && (
                                <>
                                    {props.templateDetails.map((template, index) => (
                                        <div key={index} className="row">
                                            <div className="col-7">
                                                <div
                                                    style={{ backgroundImage: `url(${bg_whatsapp})`, height: "80vh" }}
                                                    className="whatsapp-review whatsapp-bg"
                                                >
                                                    <div className="text-section">
                                                        <div className="whats-app-header whatsappImg">
                                                            <div>
                                                                <img
                                                                    src={whatsapp_brand}
                                                                    alt="Company Logo"
                                                                    className="img-fluid"
                                                                />
                                                            </div>
                                                            <div>
                                                                <p>{truncatedcompanyname}</p>
                                                            </div>
                                                        </div>

                                                        <div className="body-message bodyMessage">
                                                            <p>{template.temp_body}</p>
                                                            <small>{template.temp_footer}</small>

                                                            {template.temp_button && template.temp_button.map((item, btnIndex) => (
                                                                <div key={btnIndex} className="button-text">
                                                                    {/* Check for Phone Number Button */}
                                                                    {item.countryCode && item.phone && (
                                                                        <a href={`tel:+${item.countryCode}${item.phone}`} className="btn btn-link">
                                                                            <FontAwesomeIcon icon={faPhone} /> {item.text}
                                                                        </a>
                                                                    )}
                                                                    {/* Check for URL Button */}
                                                                    {item.url && item.urltext && (
                                                                        <a href={item.url} className="btn btn-link">
                                                                            <FontAwesomeIcon icon={faGlobe} /> {item.urltext}
                                                                        </a>
                                                                    )}
                                                                    {/* Check for Second URL Button */}
                                                                    {item.url && item.urltext2 && (
                                                                        <a href={item.url2} className="btn btn-link">
                                                                            <FontAwesomeIcon icon={faGlobe} /> {item.urltext2}
                                                                        </a>
                                                                    )}

                                                                    {item?.button1 && <p className="mb-1">{item?.button1}</p>}
                                                                    {item?.button2 && <p className="mb-1">{item?.button2}</p>}
                                                                    {item?.button3 && <p className="mb-1">{item?.button3}</p>}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-5">
                                                <ul>
                                                    <li style={{ fontSize: "13px" }}>
                                                        <strong>Template name:</strong> {template.temp_name}
                                                    </li>
                                                    <li style={{ fontSize: "13px" }}>
                                                        <strong>Language:</strong> {template.temp_language}
                                                    </li>
                                                    <li style={{ fontSize: "13px" }}>
                                                        <strong>Category:</strong> {template.temp_category}
                                                    </li>
                                                    <li style={{ fontSize: "13px" }}>
                                                        <strong>Type:</strong> {template.temp_type}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal Backdrop */}
            {isModalOpen && <div className="modal-backdrop fade show" />}
        </div>
    );
};

export default WhatsappCampaignListPreview;
