import React from "react";
import { charaterCount, insertVariable } from "../../../Utils/Utils";
import SuggestionsButtonUi from "./SuggestionsButton";

const TextForm = ({ formData, setFormData }) => {

    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            content: value,
        }));
    };

    return (
        <div className="form-row suggested-button">
            <div className="form-group col-md-12">
                <textarea
                    id="content"
                    className="form-control"
                    name="content"
                    rows={5}
                    value={formData.content}
                    onChange={handleTextChange}
                    placeholder="Enter message content"
                />
            </div>
            <div className="form-group col-md-12">
                <div className="d-flex justify-content-between">
                    {charaterCount(formData.content)}
                    <span className="badge badge-primary" onClick={() => insertVariable(document.getElementById('content'), formData, setFormData)}>Add Varibale</span>
                </div>

            </div>
            <div className="form-group col-md-12">
                <SuggestionsButtonUi formData={formData} setFormData={setFormData} />
            </div>
        </div>
    );
};

export default TextForm;
