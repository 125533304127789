import React, { Component } from "react";
import { Link } from "react-router-dom";
import AdminHeader from "../Header/AdminHeader";
import AdminFooter from "../Footer/AdminFooter";
import AdminLeftMenu from "../Navbar/AdminLeftMenu";
import EmptyImg from "../Dashboard/assets/img/create-project.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { HOST_URL, NODE_HOST_URL } from "../Dashboard/api/Api";
import { userId } from "../Utils/userAuth";
import dayjs from "dayjs";
import Axios from "../../axios/Axios";
import Select from "react-select";
import Spining from "../UI/Spining/Spining";

class UserPriceDetails extends Component {
  constructor() {
    const lStorage =
      JSON.parse(localStorage.getItem("admin")) ||
      JSON.parse(localStorage.getItem("emp")) ||
      JSON.parse(localStorage.getItem("reseller"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const empType = lStorage.user.isAdmin;

    super();

    this.state = {
      user_id: userId,
      token: userToken,
      emp_type: empType,
      id: "",
      price_update: true,
      emailCost: "",
      countryCode: "",
      addEmailCost: "",
      smsCost: "",
      voiceCost: "",
      voivePulse: "",
      wp_sending_cost: "",
      wp_conv_cost: "",
      successMessage: false,
      selectUserPriceDetails: "",
      seletedUserId: "",
      selectedUserType: "",
      errorMessage: "",
      bgColor: "",
      openOtherPrice: true,
      rcsPrice: false,
      rcscountryCode: "",
      botid: "",
      basicrcscost: "",
      a2p_single_rcs_cost: "",
      a2p_conversation_cost: "",
      p2a_conversation_cost: "",
      margin: "",
      isChecked: false,
      rcsPriceList: [],
      _id: "",
      csvData: "",
      status: 1,
      frozen: 0,
      showModal: false,
      confirmCode: "",
      popupStatus: false,
      popupColor: "",
      popupMessage: "",
      utility: "",
      authentication: "",
      userBotId: [],
      bulkMargin: "",
      channel: "whatsapp",
      entryType: "single",
      countryList: "",
      smsCountryCode: "",
      voiceCountryCode: "",
      searchList: "",
      whatsappStatus: "",
      whatsappFrozen: "",
      smsStatus: 1,
      smsFrozen: 0,
      voiceStatus: "",
      voiceFrozen: "",
      loading: false,
      marginType: "",
    };
  }

  customStyles = {
    control: (provided) => ({
      ...provided,
      cursor: "pointer",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (style) => ({
      ...style,
      fontSize: "12px",
    }),
  };

  componentDidMount() {
    this.getUserDetails();
    this.getRcsData();
    this.getBotid();
    this.getCountryList();
    this.setState({
      seletedUserId: this.props.match.params.id,
      selectedUserType: this.props.match.params.userType,
    });
    this.allUSerDetails();
  }

  allUSerDetails = () => {
    fetch(
      `${HOST_URL}/admin_user_list.php?user_id=${this.state.user_id}&method=retrieve&type=pricelist&ret_user_id=${this.props.match.params.id}&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        // console.log("pricelist", result);
        if (result.success === true) {
          // console.log(result.data)
          this.setState({ selectUserPriceDetails: result.data });
          this.setState({ emailCost: result.email_cost });
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  };

  getUserDetails() {
    this.setState({ seletedUserId: this.props.match.params.id });
    fetch(
      `${HOST_URL}/admin_user_list.php?user_id=${this.state.user_id}&method=retrieve&type=userdetails&ret_user_id=${this.props.match.params.id}&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        // console.log('user',result);
        if (result.success === true) {
          this.setState({
            email: result.data[0].email,
          });
        } else {
          this.setState({
            successMessage: true,
            successBg: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  }

  // addPriceTo() {
  //   document.querySelector("body").scrollTo(0, 0);
  //   this.setState({ seletedUserId: this.props.match.params.id });
  //   fetch(
  //     `${HOST_URL}/user_pricelist.php?user_id=${this.state.user_id}&method=create&country_code=${this.state.countryCode}&voice_cost=${this.state.voiceCost}&sms_cost=${this.state.smsCost}&wp_sending=${this.state.wp_sending_cost}&wp_conv=${this.state.wp_conv_cost}&pulse=${this.state.voivePulse}&ret_user_id=${this.props.match.params.id}&token=${this.state.token}&wp_util_cost=${this.state.utility}&wp_auth_cost=${this.state.authentication}`
  //   ).then((response) => {
  //     response.json().then((result) => {
  //       //console.log(result);
  //       if (result.success === true) {
  //         this.setState({
  //           successMessage: true,
  //           bgColor: "alert alert-success alert-dismissible",
  //           errorMessage: result.message,
  //         });
  //         window.location.reload();
  //       } else {
  //         this.setState({
  //           successMessage: true,
  //           bgColor: "alert alert-danger alert-dismissible",
  //           errorMessage: result.message,
  //         });
  //       }
  //     });
  //   });
  // }

  updatePrice(
    id,
    countrycode,
    smscost,
    voiceCost,
    voicepulse,
    wp_conv_cost,
    wp_sending_cost,
    wp_auth_cost,
    wp_util_cost
  ) {
    document.querySelector("body").scrollTo(0, 0);
    let data = this.state.countryList.find((x) => x.value == countrycode);
    this.setState({
      seletedUserId: this.props.match.params.id,
      countryCode: data,
      smsCountryCode: data,
      voiceCountryCode: data,
      smsCost: smscost,
      voiceCost: voiceCost,
      voivePulse: voicepulse,
      wp_conv_cost: wp_conv_cost,
      wp_sending_cost: wp_sending_cost,
      price_update: false,
      successMessage: true,
      errorMessage: "Add Price Details",
      bgColor: "alert alert-info alert-dismissible",
      id: id,
      utility: wp_util_cost,
      authentication: wp_auth_cost,
    });
  }

  // updateUserPrice() {
  //   this.setState({ seletedUserId: this.props.match.params.id });
  //   fetch(
  //     `${HOST_URL}/user_pricelist.php?user_id=${this.state.user_id}&method=update&id=${this.state.id}&type=admin&voice_cost=${this.state.voiceCost}&sms_cost=${this.state.smsCost}&wp_sending=${this.state.wp_sending_cost}&wp_conv=${this.state.wp_conv_cost}&pulse=${this.state.voivePulse}&token=${this.state.token}&wp_util_cost=${this.state.utility}&wp_auth_cost=${this.state.authentication}`
  //   ).then((response) => {
  //     response.json().then((result) => {
  //       //console.log(result);
  //       if (result.success === true) {
  //         this.setState({
  //           successMessage: true,
  //           bgColor: "alert alert-success alert-dismissible",
  //           errorMessage: result.message,
  //         });
  //         window.location.reload();
  //       } else {
  //         this.setState({
  //           successMessage: true,
  //           bgColor: "alert alert-danger alert-dismissible",
  //           errorMessage: result.message,
  //         });
  //       }
  //     });
  //   });
  // }

  // buttonHnadlar = (event) => {
  //   event.target.checked ? this.setState({ isChecked: true }) : this.setState({ isChecked: false });
  // };

  // handleFileChange = (event) => {
  //   const file = event.target.files[0];

  //   if (file) {
  //     const reader = new FileReader();

  //     reader.onload = (e) => {
  //       const text = e.target.result;
  //       const rows = text.split("\n").map((row) => row.trim()).filter(row => row.length > 0);  // Trim and remove empty rows

  //       // Optional: Process rows to extract headers and values
  //       const headers = rows[0].split(","); // Assuming the first row is the header
  //       const data = rows.slice(1).map((row) => {
  //         const values = row.split(",");
  //         const obj = {};
  //         headers.forEach((header, index) => {
  //           obj[header] = values[index];
  //         });
  //         return obj;
  //       });

  //       this.setState({ csvData: file })
  //     };

  //     reader.readAsText(file);
  //   }
  // };

  isValid = () => {
    if (
      this.state.rcscountryCode === undefined ||
      this.state.rcscountryCode === ""
    ) {
      this.setState({
        successMessage: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please Enter Country Code",
      });
      return false;
    }
    if (this.state.botid === undefined || this.state.botid === "") {
      this.setState({
        successMessage: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please Enter Bot Id",
      });
      return false;
    }
    if (this.state.margin === undefined || this.state.margin === "") {
      this.setState({
        successMessage: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please Enter Margin",
      });
      return false;
    }
    // if(this.state.id){
    //   if(this.state.status === undefined || this.state.status === ''){
    //     this.setState({
    //       successMessage: true,
    //       bgColor: "alert alert-danger alert-dismissible",
    //       errorMessage: "Please Select Status",
    //     });
    //     return false
    //   }
    //   if(this.state.frozen === undefined || this.state.frozen === ''){
    //     this.setState({
    //       successMessage: true,
    //       bgColor: "alert alert-danger alert-dismissible",
    //       errorMessage: "Please Select Frozen",
    //     });
    //     return false
    //   }
    //   return fasle;
    // }
    return true;
  };

  addRcsPrice = async () => {
    this.setState({ loading: true });
    // if (this.isValid()) {
    const paramData = {
      user_id: this.state.user_id,
      retr_user_id: this.props.match.params.id,
      token: this.state.token,
      method: "add_single_country_pricercs",
      country_code: this.state.rcscountryCode.value,
      bot_id: this.state.botid || "67890",
      basic_sms_cost: parseFloat(this.state.basicrcscost),
      p2a_conv_cost: parseFloat(this.state.p2a_conversation_cost),
      a2p_conv_cost: parseFloat(this.state.a2p_conversation_cost),
      a2p_single_sms_cost: parseFloat(this.state.a2p_single_rcs_cost),
      is_frozen: this.state.frozen === 1 ? true : false,
      is_active: this.state.status === 1 ? true : false,
    };
    // console.log(paramData)
    try {
      let { data } = await Axios.post(`${NODE_HOST_URL}/user`, paramData);
      if (data) {
        this.setState({
          successMessage: true,
          bgColor: "alert alert-success alert-dismissible",
          errorMessage: data.message,
        });
        setTimeout(() => {
          this.setState({ successMessage: false });
        }, 4000);
        this.getRcsData();
        this.setState({
          rcscountryCode: "",
          botid: "",
          margin: "",
          _id: "",
        });
      }
    } catch (error) {
      console.log(error);
    }
    this.setState({ loading: false });
    // }
  };

  getRcsData = async () => {
    const retrieveParam = {
      user_id: this.state.user_id,
      userId: this.props.match.params.id,
      token: this.state.token,
      method: "retrieve_price",
    };
    try {
      let { data } = await Axios.post(
        `${NODE_HOST_URL}/admin/rcs_setting`,
        retrieveParam
      );
      if (data.success) {
        // console.log(data)
        this.setState({ rcsPriceList: data.data });
      }
    } catch (error) {
      console.log(error);
    }
  };

  getRcsPrice = (
    id,
    countrycode,
    botid,
    rcscost,
    a2pcost,
    a2pconvcost,
    p2acost
  ) => {
    let data = this.state.countryList.find((x) => x.value == countrycode);
    this.setState({
      _id: id,
      rcscountryCode: data,
      botid: botid,
      basicrcscost: rcscost,
      a2p_single_rcs_cost: a2pcost,
      a2p_conversation_cost: a2pconvcost,
      p2a_conversation_cost: p2acost,
    });
  };

  // updateRcsPrice = async () => {
  //   const paramupdateData = {
  //     user_id: this.state.user_id,
  //     token: this.state.token,
  //     retr_user_id: this.props.match.params.id,
  //     bot_id: this.state.botid,
  //     // bot_id: "4467895",
  //     country_code: this.state.rcscountryCode,
  //     basic_sms_cost: parseFloat(this.state.basicrcscost),
  //     p2a_conv_cost: parseFloat(this.state.p2a_conversation_cost),
  //     a2p_conv_cost: parseFloat(this.state.a2p_conversation_cost),
  //     a2p_single_sms_cost: parseFloat(this.state.a2p_single_rcs_cost),
  //     method: "update_rcsprice",
  //     parent_type: this.state.emp_type,
  //     client_type: this.props.match.params.userType,
  //   }
  //   try {
  //     let { data } = await Axios.post(`${NODE_HOST_URL}/rcs_setting`, paramupdateData);
  //     if (data.success) {
  //       this.setState({
  //         successMessage: true,
  //         bgColor: "alert alert-success alert-dismissible",
  //         errorMessage: data.message,
  //       });
  //       setTimeout(() => {
  //         this.setState({ successMessage: false })
  //         window.location.reload();
  //       }, 4000)
  //       this.getRcsData();
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // UploadCsv = async () => {
  //   let formData = new FormData();

  //   formData.append('file', this.state.csvData);
  //   formData.append('user_id', this.state.user_id);
  //   formData.append('method', 'add_price');
  //   formData.append('token', this.state.token);
  //   formData.append('userId', this.props.match.params.id);

  //   try {
  //     let { data } = await Axios.post(`${NODE_HOST_URL}/admin/rcs_setting`, formData);
  //     if (data.success) {
  //       this.setState({
  //         successMessage: true,
  //         bgColor: "alert alert-success alert-dismissible",
  //         errorMessage: data.message,
  //       });
  //       this.getRcsData();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  confirmationCode = async () => {
    this.setState({ loading: true });
    if (this.state.confirmCode === "123456") {
      let smsParamData = {
        user_id: this.state.user_id,
        token: this.state.token,
        method: "add_bulk_sms_price",
        retr_user_id: this.props.match.params.id,
        sms_cost_margin: this.state.bulkMargin,
        margin_type: this.state.marginType,
      };

      let voiceParamData = {
        user_id: this.state.user_id,
        token: this.state.token,
        method: "add_bulk_voice_price",
        retr_user_id: this.props.match.params.id,
        voice_cost_margin: this.state.bulkMargin,
        margin_type: this.state.marginType,
      };

      let whatsappParamData = {
        user_id: this.state.user_id,
        token: this.state.token,
        method: "add_bulk_whatsapp_price",
        retr_user_id: this.props.match.params.id,
        wp_sending_cost_margin: this.state.bulkMargin,
        wp_conv_cost_margin: this.state.bulkMargin,
        wp_auth_cost_margin: this.state.bulkMargin,
        wp_util_cost_margin: this.state.bulkMargin,
        margin_type: this.state.marginType,
      };

      let rcsParamData = {
        user_id: this.state.user_id,
        retr_user_id: this.props.match.params.id,
        token: this.state.token,
        method: "add_bulk_rcs_price",
        bot_id: this.state.botid || "67890",
        basic_sms_cost_margin: this.state.bulkMargin,
        p2a_conv_cost_margin: this.state.bulkMargin,
        a2p_conv_cost_margin: this.state.bulkMargin,
        a2p_single_sms_cost_margin: this.state.bulkMargin,
        margin_type: this.state.marginType,
      };
      try {
        let { data } = await Axios.post(
          `${NODE_HOST_URL}/user`,
          this.state.channel === "whatsapp"
            ? whatsappParamData
            : this.state.channel === "sms"
            ? smsParamData
            : this.state.channel === "voice"
            ? voiceParamData
            : this.state.channel === "rcs"
            ? rcsParamData
            : null
        );
        if (data.success) {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-success alert-dismissible",
            errorMessage: data.message,
          });
          this.setState({ showModal: false, confirmCode: "" });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      } catch (error) {
        console.log(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          this.setState({
            popupStatus: true,
            popupColor: "alert alert-danger alert-dismissible",
            popupMessage: error.response.data.message,
          });
        } else {
          this.setState({
            popupStatus: true,
            popupColor: "alert alert-danger alert-dismissible",
            popupMessage:
              "An unexpected error occurred. Please try again later.",
          });
        }
      }
    } else {
      this.setState({
        popupStatus: true,
        popupColor: "alert alert-danger",
        popupMessage: "Confirmation Code is not Correct",
      });
    }
    this.setState({ loading: false });
  };

  getBotid = async () => {
    const senderParamData = {
      user_id: this.state.user_id,
      token: this.state.token,
      method: "userwise_bot",
      client_id: this.props.match.params.id,
    };
    try {
      let { data } = await Axios.post(
        `${NODE_HOST_URL}/admin/rcs`,
        senderParamData
      );
      if (data.success) {
        this.setState({
          userBotId: data.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleEntryChange = (e) => {
    const { value } = e.target;
    if (this.state.entryType !== value) {
      this.setState({ entryType: value });
    } else {
      this.setState({ entryType: "" });
    }
  };

  getCountryList = async () => {
    try {
      let { data } = await Axios.post(`${HOST_URL}/country_master.php`);
      if (data.success) {
        let result = data.data;
        const formattedCountry = result.map((user) => ({
          value: user.country_code,
          label: user.country_name,
        }));
        // setCountryList(formattedCountry);
        this.setState({ countryList: formattedCountry });
      }
    } catch (error) {
      console.log(error);
    }
  };

  openPopup = () => {
    if (this.state.marginType === undefined || this.state.marginType === "") {
      this.setState({
        successMessage: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please Select Margin Type",
      });
      return false;
    }
    if (this.state.bulkMargin === undefined || this.state.bulkMargin === "") {
      this.setState({
        successMessage: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please Enter Margin",
      });
      return false;
    } else {
      this.setState({ showModal: true });
    }
  };

  handleWhatsappCountryCode = (selectedOption) => {
    this.setState({ countryCode: selectedOption });
  };

  handleSmsCountryCode = (selectedOption) => {
    this.setState({ smsCountryCode: selectedOption });
  };

  handleVoiceCountryCode = (selectedOption) => {
    this.setState({ voiceCountryCode: selectedOption });
  };

  handleRcsCountryCode = (selectedOption) => {
    this.setState({ rcscountryCode: selectedOption });
  };

  AddSmsPrice = async () => {
    this.setState({ loading: true });
    document.querySelector("body").scrollTo(0, 0);
    let paramData = {
      user_id: this.state.user_id,
      token: this.state.token,
      retr_user_id: this.props.match.params.id,
      country_code: this.state.smsCountryCode.value,
      sms_cost: this.state.smsCost,
      method: "add_sms_price",
      is_active: this.state.smsStatus === 1 ? true : false,
      is_frozen: this.state.smsFrozen === 1 ? true : false,
    };
    try {
      let { data } = await Axios.post(`${NODE_HOST_URL}/user`, paramData);
      if (data.success) {
        this.setState({
          successMessage: true,
          bgColor: "alert alert-success alert-dismissible",
          errorMessage: data.message,
        });
        this.setState({ smsCountryCode: "", smsCost: "", id: "" });
        setTimeout(() => {
          this.setState({
            successMessage: false,
          });
        }, 3000);
        this.allUSerDetails();
      } else {
        this.setState({
          successMessage: true,
          bgColor: "alert alert-danger alert-dismissible",
          errorMessage: data.message,
        });
      }
    } catch (error) {
      console.log(error);
    }
    this.setState({ loading: false });
  };

  AddWhatsappPrice = async () => {
    this.setState({ loading: true });
    document.querySelector("body").scrollTo(0, 0);
    let paramData = {
      user_id: this.state.user_id,
      token: this.state.token,
      retr_user_id: this.props.match.params.id,
      country_code: this.state.countryCode.value,
      wp_sending_cost: this.state.wp_sending_cost,
      wp_conv_cost: this.state.wp_conv_cost,
      wp_auth_cost: this.state.authentication,
      wp_util_cost: this.state.utility,
      method: "add_whatsapp_price",
      is_active: this.state.smsStatus === 1 ? true : false,
      is_frozen: this.state.smsFrozen === 1 ? true : false,
    };
    try {
      let { data } = await Axios.post(`${NODE_HOST_URL}/user`, paramData);
      if (data.success) {
        this.setState({
          successMessage: true,
          bgColor: "alert alert-success alert-dismissible",
          errorMessage: data.message,
        });
        this.setState({
          id: "",
          countryCode: "",
          wp_sending_cost: "",
          wp_conv_cost: "",
          authentication: "",
          utility: "",
        });
        setTimeout(() => {
          this.setState({
            successMessage: false,
          });
        }, 3000);
        this.allUSerDetails();
      } else {
        this.setState({
          successMessage: true,
          bgColor: "alert alert-danger alert-dismissible",
          errorMessage: data.message,
        });
      }
    } catch (error) {
      console.log(error);
    }
    this.setState({ loading: false });
  };

  AddVoicePrice = async () => {
    this.setState({ loading: true });
    document.querySelector("body").scrollTo(0, 0);
    let paramData = {
      user_id: this.state.user_id,
      token: this.state.token,
      retr_user_id: this.props.match.params.id,
      country_code: this.state.voiceCountryCode.value,
      voice_cost: this.state.voiceCost,
      voice_pulse: this.state.voicePulse,
      method: "add_voice_price",
      is_active: this.state.smsStatus === 1 ? true : false,
      is_frozen: this.state.smsFrozen === 1 ? true : false,
    };
    try {
      let { data } = await Axios.post(`${NODE_HOST_URL}/user`, paramData);
      if (data.success) {
        this.setState({
          successMessage: true,
          bgColor: "alert alert-success alert-dismissible",
          errorMessage: data.message,
        });
        this.setState({
          id: "",
          voiceCountryCode: "",
          voiceCost: "",
          voicePulse: "",
        });
        setTimeout(() => {
          this.setState({
            successMessage: false,
          });
        }, 3000);
        this.allUSerDetails();
      } else {
        this.setState({
          successMessage: true,
          bgColor: "alert alert-danger alert-dismissible",
          errorMessage: data.message,
        });
      }
    } catch (error) {
      console.log(error);
    }
    this.setState({ loading: false });
  };

  render() {
    return (
      <div className="wrapper">
        <AdminLeftMenu />
        <div className="main">
          <AdminHeader />
          {this.state.loading && <Spining />}
          <main className="content">
            <div className="container-fluid p-0">
              <div className="row mb-2 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                  <h3>
                    User Details{" "}
                    <strong className="text-info"> {this.state.email} </strong>{" "}
                  </h3>
                </div>
              </div>

              <div className="row">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title mb-0">
                      <div className="col-auto ml-auto text-right mt-n1">
                        <nav className="text-center" aria-label="breadcrumb">
                          <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
                            <Link
                              to={
                                "/admin/user-details/" +
                                this.state.seletedUserId +
                                `/` +
                                this.state.selectedUserType
                              }
                            >
                              <li className="breadcrumb-item ">
                                User Details{" "}
                              </li>{" "}
                            </Link>
                            <Link
                              to={
                                "/admin/user-transaction-details/" +
                                this.state.seletedUserId +
                                `/` +
                                this.state.selectedUserType
                              }
                            >
                              <li className="breadcrumb-item">
                                {" "}
                                Transaction Details{" "}
                              </li>
                            </Link>
                            <Link
                              to={
                                "/admin/user-price-details/" +
                                this.state.seletedUserId +
                                `/` +
                                this.state.selectedUserType
                              }
                            >
                              <li className="breadcrumb-item active">
                                {" "}
                                Add Price{" "}
                              </li>
                            </Link>
                            {this.state.emp_type === "admin" && (
                              <Link
                                to={
                                  "/admin/user-billing-mode/" +
                                  this.state.seletedUserId +
                                  `/` +
                                  this.state.selectedUserType
                                }
                              >
                                {" "}
                                <li className="breadcrumb-item">
                                  {" "}
                                  Billing Mode{" "}
                                </li>
                              </Link>
                            )}
                            {this.state.emp_type === "reseller" ? null : (
                              <>
                                <Link
                                  to={
                                    "/admin/user-add-price/" +
                                    this.state.seletedUserId +
                                    `/` +
                                    this.state.selectedUserType
                                  }
                                >
                                  {" "}
                                  <li className="breadcrumb-item">
                                    {" "}
                                    Add Credit{" "}
                                  </li>
                                </Link>
                                <Link
                                  to={
                                    "/admin/user-entity-pass/" +
                                    this.state.seletedUserId +
                                    `/` +
                                    this.state.selectedUserType
                                  }
                                >
                                  {" "}
                                  <li className="breadcrumb-item">
                                    {" "}
                                    Entity By Pass{" "}
                                  </li>
                                </Link>
                                <Link
                                  to={
                                    "/admin/change-currency/" +
                                    this.state.seletedUserId +
                                    `/` +
                                    this.state.selectedUserType
                                  }
                                >
                                  {" "}
                                  <li className="breadcrumb-item ">
                                    {" "}
                                    Change Currency{" "}
                                  </li>
                                </Link>

                                <Link
                                  to={
                                    "/admin/bulk-sms-route/" +
                                    this.state.seletedUserId +
                                    `/` +
                                    this.state.selectedUserType
                                  }
                                >
                                  {" "}
                                  <li className="breadcrumb-item ">
                                    {" "}
                                    Bulk Sms Route Setting{" "}
                                  </li>
                                </Link>
                                <Link
                                  to={`/admin/agent-setting/${this.state.seletedUserId}/${this.state.selectedUSerType}`}
                                >
                                  <li className="breadcrumb-item">
                                    Agent Setting
                                  </li>
                                </Link>
                              </>
                            )}
                          </ol>
                        </nav>
                      </div>
                    </h5>
                  </div>

                  {/* <div className="card-body">
                    <div className="filter-option">
                      <div className="card">
                        <div className="card-body">
                          <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0 ml-3">
                            <span className="breadcrumb bg-transparent p-0 mt-1 mb-0" onClick={`this.setState({openOtherPrice : true})`}> <Link to={`/admin/user-price-details/ + ${this.state.seletedUserId}`}><li className={this.state.openOtherPrice ? "breadcrumb-item active" : "breadcrumb-item"}>Other Price</li></Link></span>
                            <span className="breadcrumb bg-transparent p-0 mt-1 mb-0"> <Link to={`/admin/user-price-details/ + ${this.state.seletedUserId}`}><li className="breadcrumb-item">Rcs Price</li></Link></span>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="card-body">
                    {this.state.successMessage ? (
                      <div className={this.state.bgColor} role="alert">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                        <div className="">{this.state.errorMessage}</div>
                      </div>
                    ) : null}

                    <div className="filter-option">
                      <div className="card">
                        {/* <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0 ml-3">
                          <span
                            className="breadcrumb bg-transparent p-0 mt-1 mb-0"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.setState({ openOtherPrice: !this.state.openOtherPrice, rcsPrice: false })}
                          >
                            <li className={this.state.openOtherPrice ? "breadcrumb-item active" : "breadcrumb-item"}>
                              Channels
                            </li>
                          </span>
                          <span className="breadcrumb bg-transparent p-0 mt-1 mb-0" style={{ cursor: "pointer" }} onClick={() => this.setState({ rcsPrice: true, openOtherPrice: false })}>
                            <li className={this.state.rcsPrice ? "breadcrumb-item active" : "breadcrumb-item"}>RCS</li>
                          </span>
                        </ol> */}

                        <div className="row">
                          <div className="col-5">
                            <select
                              className="form-control m-3"
                              value={this.state.channel}
                              onChange={(e) =>
                                this.setState({
                                  channel: e.target.value,
                                  id: "",
                                })
                              }
                            >
                              <option value="">Select Channel</option>
                              <option value="whatsapp">Whatsapp</option>
                              <option value="sms">SMS</option>
                              <option value="voice">Voice</option>
                              <option value="rcs">RCS</option>
                            </select>
                          </div>
                          {this.state.channel !== "" && (
                            <div className="col-6">
                              <div className="d-flex align-items-center">
                                <div className="form-check m-3">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="single-entry"
                                    checked={this.state.entryType === "single"}
                                    onChange={this.handleEntryChange}
                                    value="single"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="single-entry"
                                  >
                                    Single Entry
                                  </label>
                                </div>
                                {this.state.channel === "voice" ? null : (
                                  <div className="form-check ml-3">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="bulk-entry"
                                      checked={this.state.entryType === "bulk"}
                                      onChange={this.handleEntryChange}
                                      value="bulk"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="bulk-entry"
                                    >
                                      Bulk Entry
                                    </label>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="card-body">
                          {this.state.channel !== "" &&
                          this.state.entryType === "bulk" ? (
                            <>
                              <div className="row">
                                <div className="col-4">
                                  <select
                                    className="form-control"
                                    value={this.state.marginType}
                                    onChange={(e) =>
                                      this.setState({
                                        marginType: e.target.value,
                                        successMessage: false,
                                      })
                                    }
                                  >
                                    <option value="">Select Margin Type</option>
                                    <option value="percentage">
                                      Percentage
                                    </option>
                                    <option value="currency">Currency</option>
                                  </select>
                                </div>
                                <div className="col-4">
                                  <input
                                    type="text"
                                    disabled={
                                      this.state.marginType ? false : true
                                    }
                                    className="form-control"
                                    value={this.state.bulkMargin}
                                    onChange={(e) =>
                                      this.setState({
                                        bulkMargin: e.target.value,
                                        successMessage: false,
                                      })
                                    }
                                    placeholder={
                                      this.state.marginType === "percentage"
                                        ? "Enter Margin Percentage"
                                        : this.state.marginType
                                        ? "Enter Margin Currency"
                                        : "Enter Margin"
                                    }
                                  />
                                </div>
                                <div className="col-4">
                                  <button
                                    onClick={this.openPopup}
                                    className="btn btn-secondary"
                                    style={{
                                      fontSize: "12px",
                                      padding: "10px 10px 10px 10px",
                                    }}
                                  >
                                    <span className="mr-2">
                                      <FontAwesomeIcon icon={faPlus} />
                                    </span>
                                    Add Price
                                  </button>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <p
                                    className="mt-3"
                                    style={{ fontSize: "13px", color: "red" }}
                                  >
                                    *<b>NOTE:</b> Add <b>Margin</b> according to
                                    your <b>Currency</b>
                                  </p>
                                </div>
                              </div>
                            </>
                          ) : null}
                          {this.state.channel !== "" &&
                          this.state.entryType === "single" ? (
                            <>
                              {this.state.channel === "whatsapp" && (
                                <>
                                  <div className="row">
                                    <div className="col-md-4">
                                      <label className="form-label">
                                        County Code
                                      </label>
                                      <div className="input-group drop-down-icons mb-2 mr-sm-2 table2">
                                        <div className="input-group mb-2 mr-sm-2">
                                          {/* <div className="input-group-text">User </div> */}
                                          <Select
                                            onChange={
                                              this.handleWhatsappCountryCode
                                            }
                                            options={this.state.countryList}
                                            // options={countryList
                                            //     .filter(x => !selectUserPriceDetails.some(detail => detail.country_code === x.value)) // Exclude countries that match the selectedUserPriceDetails
                                            //     .map(x => ({ value: x.value, label: x.label })) // Ensure correct formatting for the Select component
                                            // }
                                            value={this.state.countryCode}
                                            isSearchable={true}
                                            placeholder="Select Country"
                                            isClearable={true}
                                            styles={this.customStyles}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-3">
                                      <label className="form-label">
                                        {" "}
                                        conversation cost
                                      </label>
                                      <input
                                        type="text"
                                        value={this.state.wp_conv_cost}
                                        onChange={(event) =>
                                          // setWp_Conv_Cost(event.target.value)
                                          this.setState({
                                            wp_conv_cost: event.target.value,
                                          })
                                        }
                                        placeholder="Rs."
                                        className="form-control"
                                      />
                                    </div>
                                    <div className="col-md-3">
                                      <label className="form-label">
                                        {" "}
                                        Marketing Cost
                                      </label>
                                      <input
                                        type="text"
                                        value={this.state.wp_sending_cost}
                                        onChange={(event) =>
                                          // setWpSendingCost(event.target.value)
                                          this.setState({
                                            wp_sending_cost: event.target.value,
                                          })
                                        }
                                        placeholder="Rs."
                                        className="form-control"
                                      />
                                    </div>
                                    <div className="col-md-3">
                                      <label className="form-label">
                                        {" "}
                                        Utility Cost
                                      </label>
                                      <input
                                        type="text"
                                        value={this.state.utility}
                                        onChange={(event) =>
                                          // setUtility(event.target.value)
                                          this.setState({
                                            utility: event.target.value,
                                          })
                                        }
                                        placeholder="Rs."
                                        className="form-control"
                                      />
                                    </div>
                                    <div className="col-md-3">
                                      <label className="form-label">
                                        {" "}
                                        Authentication Cost
                                      </label>
                                      <input
                                        type="text"
                                        value={this.state.authentication}
                                        onChange={(event) =>
                                          // setAuthentication(event.target.value)
                                          this.setState({
                                            authentication: event.target.value,
                                          })
                                        }
                                        placeholder="Rs."
                                        className="form-control"
                                      />
                                    </div>

                                    {this.state.id && (
                                      <>
                                        <div className="col-md-3">
                                          <label className="form-label">
                                            Status
                                          </label>
                                          <select
                                            className="form-control"
                                            value={this.state.smsStatus}
                                            disabled={true}
                                            onChange={(e) =>
                                              this.setState({
                                                smsStatus: e.target.value,
                                              })
                                            }
                                          >
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                          </select>
                                        </div>

                                        <div className="col-md-3">
                                          <label className="form-label">
                                            Frozen
                                          </label>
                                          <select
                                            className="form-control"
                                            value={this.state.smsFrozen}
                                            disabled={true}
                                            onChange={(e) =>
                                              this.setState({
                                                smsFrozen: e.target.value,
                                              })
                                            }
                                          >
                                            <option value="1">Frozen</option>
                                            <option value="0">
                                              non-Forzen
                                            </option>
                                          </select>
                                        </div>
                                      </>
                                    )}

                                    <div className="row">
                                      <div className="col-md-6">
                                        <p
                                          className="mt-3 ml-3"
                                          style={{
                                            fontSize: "13px",
                                            color: "red",
                                          }}
                                        >
                                          *<b>NOTE:</b> Add <b>Price</b>{" "}
                                          according to your <b>Currency</b>
                                        </p>
                                      </div>
                                    </div>

                                    <div className="col-md-12 text-right">
                                      {!this.state.id ? (
                                        <div
                                          style={{ marginTop: 40 }}
                                          className="input-group text-right"
                                        >
                                          <input
                                            type="button"
                                            onClick={() =>
                                              this.AddWhatsappPrice()
                                            }
                                            value="Add"
                                            className="btn btn-sm btn-primary text-center"
                                          />
                                        </div>
                                      ) : (
                                        <div
                                          style={{ marginTop: 40 }}
                                          className="input-group"
                                        >
                                          <input
                                            type="button"
                                            onClick={() =>
                                              this.AddWhatsappPrice()
                                            }
                                            value="Update"
                                            className="btn btn-sm btn-primary text-center"
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                              {this.state.channel === "sms" && (
                                <>
                                  <div className="row">
                                    <div className="col-md-4">
                                      <label className="form-label">
                                        County Code
                                      </label>
                                      <div className="input-group drop-down-icons mb-2 mr-sm-2 table2">
                                        <div className="input-group mb-2 mr-sm-2">
                                          {/* <div className="input-group-text">User </div> */}
                                          <Select
                                            onChange={this.handleSmsCountryCode}
                                            options={this.state.countryList}
                                            value={this.state.smsCountryCode}
                                            isSearchable={true}
                                            placeholder="Select Country"
                                            isClearable={true}
                                            styles={this.customStyles}
                                            // isDisabled={this.state.updateId ? true : false}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <label className="form-label">
                                        {" "}
                                        Cost
                                      </label>
                                      <input
                                        type="text"
                                        value={this.state.smsCost}
                                        onChange={(event) =>
                                          this.setState({
                                            smsCost: event.target.value,
                                          })
                                        }
                                        placeholder="Rs."
                                        className="form-control"
                                      />
                                    </div>

                                    {this.state.id && (
                                      <div className="col-md-3">
                                        <label className="form-label">
                                          Status
                                        </label>
                                        <select
                                          className="form-control"
                                          value={this.state.smsStatus}
                                          onChange={(e) =>
                                            this.setState({
                                              smsStatus: e.target.value,
                                            })
                                          }
                                        >
                                          <option value="1">Active</option>
                                          <option value="0">Inactive</option>
                                        </select>
                                      </div>
                                    )}
                                  </div>
                                  {this.state.id && (
                                    <div className="row">
                                      <div className="col-md-3">
                                        <label className="form-label">
                                          Frozen
                                        </label>
                                        <select
                                          className="form-control"
                                          value={this.state.smsFrozen}
                                          onChange={(e) =>
                                            this.setState({
                                              smsFrozen: e.target.value,
                                            })
                                          }
                                        >
                                          <option value="1">Frozen</option>
                                          <option value="0">non-Forzen</option>
                                        </select>
                                      </div>
                                    </div>
                                  )}
                                  <div className="row">
                                    <div className="col-md-6">
                                      <p
                                        className="mt-2 ml-2"
                                        style={{
                                          fontSize: "13px",
                                          color: "red",
                                        }}
                                      >
                                        *<b>NOTE:</b> Add <b>Price</b> according
                                        to your <b>Currency</b>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-md-12 text-right">
                                    {!this.state.id ? (
                                      <div
                                        style={{ marginTop: 40 }}
                                        className="input-group text-right"
                                      >
                                        <input
                                          type="button"
                                          onClick={() => this.AddSmsPrice()}
                                          value="Add"
                                          className="btn btn-sm btn-primary text-center"
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        style={{ marginTop: 40 }}
                                        className="input-group"
                                      >
                                        <input
                                          type="button"
                                          onClick={() => this.AddSmsPrice()}
                                          value="Update"
                                          className="btn btn-sm btn-primary text-center"
                                        />
                                      </div>
                                    )}
                                  </div>{" "}
                                </>
                              )}
                              {this.state.channel === "voice" && (
                                <>
                                  <div className="row">
                                    <div className="col-md-4">
                                      <label className="form-label">
                                        County Code
                                      </label>
                                      <div className="input-group drop-down-icons mb-2 mr-sm-2 table2">
                                        <div className="input-group mb-2 mr-sm-2">
                                          {/* <div className="input-group-text">User </div> */}
                                          <Select
                                            onChange={
                                              this.handleVoiceCountryCode
                                            }
                                            options={this.state.countryList.filter(
                                              (x) => x.value === "91"
                                            )}
                                            value={this.state.voiceCountryCode}
                                            isSearchable={true}
                                            placeholder="Select Country"
                                            isClearable={true}
                                            styles={this.customStyles}
                                            // isDisabled={this.state.updateId ? true : false}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <label className="form-label">Cost</label>
                                      <input
                                        type="text"
                                        value={this.state.voiceCost}
                                        onChange={(event) =>
                                          this.setState({
                                            voiceCost: event.target.value,
                                          })
                                        }
                                        placeholder="Rs."
                                        className="form-control"
                                      />
                                    </div>
                                    <div className="col-md-4">
                                      <label className="form-label">
                                        {" "}
                                        Pulse
                                      </label>
                                      <select
                                        value={this.state.voicePulse}
                                        onChange={(event) =>
                                          this.setState({
                                            voicePulse: event.target.value,
                                          })
                                        }
                                        className="form-control"
                                      >
                                        <option value=""> Select </option>
                                        <option value="15">15</option>
                                        <option value="30">30</option>
                                        {/* <option value="60">60</option> */}
                                      </select>
                                    </div>
                                  </div>

                                  {this.state.id && (
                                    <div className="row">
                                      <div className="col-md-4">
                                        <label className="form-label">
                                          Status
                                        </label>
                                        <select
                                          className="form-control"
                                          value={this.state.smsStatus}
                                          disabled={true}
                                          onChange={(e) =>
                                            this.setState({
                                              smsStatus: e.target.value,
                                            })
                                          }
                                        >
                                          <option value="1">Active</option>
                                          <option value="0">Inactive</option>
                                        </select>
                                      </div>

                                      <div className="col-md-4">
                                        <label className="form-label">
                                          Frozen
                                        </label>
                                        <select
                                          className="form-control"
                                          value={this.state.smsFrozen}
                                          disabled={true}
                                          onChange={(e) =>
                                            this.setState({
                                              smsFrozen: e.target.value,
                                            })
                                          }
                                        >
                                          <option value="1">Frozen</option>
                                          <option value="0">non-Forzen</option>
                                        </select>
                                      </div>
                                    </div>
                                  )}

                                  <div className="row">
                                    <div className="col-md-6">
                                      <p
                                        className="mt-2 ml-2"
                                        style={{
                                          fontSize: "13px",
                                          color: "red",
                                        }}
                                      >
                                        *<b>NOTE:</b> Add <b>Price</b> according
                                        to your <b>Currency</b>
                                      </p>
                                    </div>
                                  </div>

                                  <div className="col-md-12 text-right">
                                    {!this.state.id ? (
                                      <div
                                        style={{ marginTop: 40 }}
                                        className="input-group text-right"
                                      >
                                        <input
                                          type="button"
                                          onClick={this.AddVoicePrice}
                                          value="Add"
                                          className="btn btn-sm btn-primary text-center"
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        style={{ marginTop: 40 }}
                                        className="input-group"
                                      >
                                        <input
                                          type="button"
                                          onClick={this.AddVoicePrice}
                                          value="Update"
                                          className="btn btn-sm btn-primary text-center"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}
                              {this.state.channel === "rcs" && (
                                <div className="row">
                                  <div className="col-md-4">
                                    <label className="form-label">
                                      County Code
                                    </label>
                                    <div className="input-group drop-down-icons mb-2 mr-sm-2 table2">
                                      <div className="input-group mb-2 mr-sm-2">
                                        {/* <div className="input-group-text">User </div> */}
                                        <Select
                                          onChange={this.handleRcsCountryCode}
                                          options={this.state.countryList.filter(
                                            (x) => x.value === "91"
                                          )}
                                          value={this.state.rcscountryCode}
                                          isSearchable={true}
                                          placeholder="Select Country"
                                          isClearable={true}
                                          styles={this.customStyles}
                                          // isDisabled={this.state.updateId ? true : false}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <label className="form-label">Bot Id</label>
                                    <select
                                      value={this.state.botid}
                                      className="form-control"
                                      onChange={(e) =>
                                        this.setState({ botid: e.target.value })
                                      }
                                    >
                                      <option value={""}>select Bot ID</option>
                                      {this.state.userBotId?.map((x) => (
                                        <option value={x.bot_id}>
                                          {x.bot_id}
                                        </option>
                                      ))}
                                    </select>
                                  </div>

                                  {/* {!rcsID && <div className="col-md-4">
                                                                     <label className="form-label">Margin</label>
                                                                     <input
                                                                         type="text"
                                                                         value={margin}
                                                                         onChange={(event) =>
                                                                             setMargin(event.target.value)
                                                                         }
                                                                         placeholder="Rs."
                                                                         className="form-control"
                                                                     />
                                                                 </div>} */}

                                  <div className="col-md-4">
                                    <label className="form-label">
                                      Basic Cost
                                    </label>
                                    <input
                                      type="text"
                                      value={this.state.basicrcscost}
                                      onChange={(event) =>
                                        this.setState({
                                          basicrcscost: event.target.value,
                                        })
                                      }
                                      placeholder="Rs."
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <label className="form-label">
                                     Rich Media Cost
                                    </label>
                                    <input
                                      type="text"
                                      value={this.state.a2p_single_rcs_cost}
                                      onChange={(event) =>
                                        this.setState({
                                          a2p_single_rcs_cost:
                                            event.target.value,
                                        })
                                      }
                                      placeholder="Rs."
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <label className="form-label">
                                      A2P Conversation Cost
                                    </label>
                                    <input
                                      type="text"
                                      value={this.state.a2p_conversation_cost}
                                      onChange={(event) =>
                                        this.setState({
                                          a2p_conversation_cost:
                                            event.target.value,
                                        })
                                      }
                                      placeholder="Rs."
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <label className="form-label">
                                      P2A Conversation Cost
                                    </label>
                                    <input
                                      type="text"
                                      value={this.state.p2a_conversation_cost}
                                      onChange={(event) =>
                                        this.setState({
                                          p2a_conversation_cost:
                                            event.target.value,
                                        })
                                      }
                                      placeholder="Rs."
                                      className="form-control"
                                    />
                                  </div>

                                  {this.state._id && (
                                    <div className="col-md-4">
                                      <label className="form-label">
                                        Status
                                      </label>
                                      <select
                                        className="form-control"
                                        value={this.state.status}
                                        onChange={(e) =>
                                          this.setState({
                                            status: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                      </select>
                                    </div>
                                  )}

                                  {this.state._id && (
                                    <div className="col-md-4">
                                      <label className="form-label">
                                        Frozen
                                      </label>
                                      <select
                                        className="form-control"
                                        value={this.state.frozen}
                                        onChange={(e) =>
                                          this.setState({
                                            frozen: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="1">Frozen</option>
                                        <option value="0">non-Forzen</option>
                                      </select>
                                    </div>
                                  )}

                                  <div className="row">
                                    <div className="col-md-6">
                                      <p
                                        className="mt-3 ml-3"
                                        style={{
                                          fontSize: "13px",
                                          color: "red",
                                        }}
                                      >
                                        *<b>NOTE:</b> Add <b>Price</b> according
                                        to your <b>Currency</b>
                                      </p>
                                    </div>
                                  </div>

                                  <div className="col-md-12 text-right">
                                    {this.state._id ? (
                                      <div
                                        style={{ marginTop: 40 }}
                                        className="input-group"
                                      >
                                        <input
                                          type="button"
                                          onClick={() => this.addRcsPrice()}
                                          value="Update"
                                          className="btn btn-sm btn-primary text-center"
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        style={{ marginTop: 40 }}
                                        className="input-group text-right"
                                      >
                                        <input
                                          type="button"
                                          onClick={() => this.addRcsPrice()}
                                          value="Add"
                                          className="btn btn-sm btn-primary text-center"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </>
                          ) : null}
                        </div>

                        {/* {this.state.emp_type === "reseller" ? null : (
                          <>

                            {this.state.openOtherPrice && (
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="form-label">County Code</label>
                                    <input
                                      type="text"
                                      defaultValue={this.state.countryCode}
                                      onChange={(event) =>
                                        this.setState({ countryCode: event.target.value })
                                      }
                                      placeholder="91"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <h6 className="ml-2">SMS</h6>
                                  <div className="col-md-3">
                                    <label className="form-label">SMS Cost</label>
                                    <input
                                      type="text"
                                      defaultValue={this.state.smsCost}
                                      onChange={(event) =>
                                        this.setState({ smsCost: event.target.value })
                                      }
                                      placeholder="Rs."
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <h6 className="ml-2">Voice</h6>
                                  <div className="col-md-3">
                                    <label className="form-label">Voice Cost</label>
                                    <input
                                      type="text"
                                      defaultValue={this.state.voiceCost}
                                      onChange={(event) =>
                                        this.setState({ voiceCost: event.target.value })
                                      }
                                      placeholder="Rs."
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <label className="form-label">Voice Pulse</label>
                                    <select
                                      value={this.state.voivePulse}
                                      onChange={(event) =>
                                        this.setState({ voivePulse: event.target.value })
                                      }
                                      className="form-control"
                                    >
                                      <option value=""> Select </option>
                                      <option value="15">15</option>
                                      <option value="30">30</option>
                                      <option value="60">60</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="row">
                                  <h6 className="ml-2">Whatsapp</h6>
                                  <div className="col-md-3">
                                    <label className="form-label">WP conv cost</label>
                                    <input
                                      type="text"
                                      defaultValue={this.state.wp_conv_cost}
                                      onChange={(event) =>
                                        this.setState({ wp_conv_cost: event.target.value })
                                      }
                                      placeholder="Rs."
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <label className="form-label">WP Marketing Cost</label>
                                    <input
                                      type="text"
                                      defaultValue={this.state.wp_sending_cost}
                                      onChange={(event) =>
                                        this.setState({ wp_sending_cost: event.target.value })
                                      }
                                      placeholder="Rs."
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <label className="form-label">WP Utility Cost</label>
                                    <input
                                      type="text"
                                      defaultValue={this.state.utility}
                                      onChange={(event) =>
                                        this.setState({ utility: event.target.value })
                                      }
                                      placeholder="Rs."
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <label className="form-label">WP Authentication Cost</label>
                                    <input
                                      type="text"
                                      defaultValue={this.state.authentication}
                                      onChange={(event) =>
                                        this.setState({ authentication: event.target.value })
                                      }
                                      placeholder="Rs."
                                      className="form-control"
                                    />
                                  </div>

                                  <div className="col-md-12 text-right">
                                    {this.state.price_update ? (
                                      <div style={{ marginTop: 40 }} className="input-group text-right">
                                        <input
                                          type="button"
                                          onClick={() => this.addPriceTo()}
                                          value="Add"
                                          className="btn btn-sm btn-primary text-center"
                                        />
                                      </div>
                                    ) : (
                                      <div style={{ marginTop: 40 }} className="input-group">
                                        <input
                                          type="button"
                                          onClick={() => this.updateUserPrice()}
                                          value="Update"
                                          className="btn btn-sm btn-primary text-center"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        )} */}
                      </div>
                    </div>

                    {this.state.channel !== "rcs" && (
                      <>
                        {this.state.channel &&
                        this.state.selectUserPriceDetails?.length > 0 ? (
                          <div className="row">
                            <div className="col-6">
                              <h3
                                style={{ cursor: "pointer", fontSize: "15px" }}
                                className="card-title m-3"
                              >
                                <b>
                                  {this.state.channel === "whatsapp" ? (
                                    <span>Whatsapp</span>
                                  ) : this.state.channel === "sms" ? (
                                    <span>SMS</span>
                                  ) : this.state.channel === "voice" ? (
                                    <span>Voice</span>
                                  ) : null}
                                </b>
                              </h3>
                            </div>
                            <div className="col-6">
                              <input
                                type="text"
                                id="searchInput"
                                name="searchList"
                                value={this.state.searchList}
                                onChange={(e) =>
                                  this.setState({ searchList: e.target.value })
                                }
                                className="form-control mb-2"
                                placeholder="Enter Country"
                              />
                            </div>
                          </div>
                        ) : null}

                        <div className="row">
                          <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                            <div className="card table-responsive flex-fill">
                              {this.state.channel &&
                              this.state.selectUserPriceDetails.length > 0 ? (
                                <table className="table table-hover my-0">
                                  <thead>
                                    <tr>
                                      <th>Country Code</th>
                                      <th className="">Currency</th>
                                      {this.state.channel === "sms" && (
                                        <th className="">SMS Cost</th>
                                      )}
                                      {this.state.channel === "voice" && (
                                        <th className="">Voice / Pulse Cost</th>
                                      )}
                                      {/* <th className=""> Email </th> */}
                                      {this.state.channel === "whatsapp" && (
                                        <>
                                          <th className=""> WP conv cost </th>
                                          <th className="">
                                            {" "}
                                            WP Sending Cost{" "}
                                          </th>
                                          <th className=""> Utility </th>
                                          <th className=""> Authentication </th>
                                        </>
                                      )}
                                      <th className=""> Date / Time</th>
                                      {this.state.emp_type ===
                                      "reseller" ? null : (
                                        <>
                                          <th className=""> Edit </th>
                                        </>
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.selectUserPriceDetails
                                      .filter((x) => {
                                        return this.state.searchList.toLowerCase() ===
                                          ""
                                          ? x
                                          : x.country_name
                                              .toLowerCase()
                                              .includes(this.state.searchList);
                                      })
                                      .map((item, i) => {
                                        if (
                                          (this.state.channel === "whatsapp" &&
                                            (item.wp_conv_cost === 0 ||
                                              item.wp_conv_cost === null)) ||
                                          (this.state.channel === "sms" &&
                                            (item.sms_cost === 0 ||
                                              item.sms_cost === null)) ||
                                          (this.state.channel === "voice" &&
                                            (item.voice_cost === 0 ||
                                              item.voice_cost === null))
                                        ) {
                                          return null;
                                        }
                                        return (
                                          <tr key={i}>
                                            <td>
                                              {item.country_name} (
                                              {item.country_code})
                                            </td>
                                            <td>{item.currency_name}</td>
                                            {this.state.channel === "sms" && (
                                              <td>{item.sms_cost}</td>
                                            )}
                                            {this.state.channel === "voice" && (
                                              <td>
                                                {item.voice_cost} /{" "}
                                                {item.voice_pulse}
                                              </td>
                                            )}
                                            {/* <td>{this.state.emailCost}</td> */}
                                            {this.state.channel ===
                                              "whatsapp" && (
                                              <>
                                                <td>{item.wp_conv_cost}</td>
                                                <td>{item.wp_sending_cost}</td>
                                                <td>{item.wp_util_cost}</td>
                                                <td>{item.wp_auth_cost}</td>
                                              </>
                                            )}
                                            <td>{item.created}</td>
                                            {this.state.emp_type ===
                                            "reseller" ? null : (
                                              <>
                                                <td>
                                                  <span
                                                    onClick={() =>
                                                      this.updatePrice(
                                                        item.id,
                                                        item.country_code,
                                                        item.sms_cost,
                                                        item.voice_cost,
                                                        item.voice_pulse,
                                                        item.wp_conv_cost,
                                                        item.wp_sending_cost,
                                                        item.wp_auth_cost,
                                                        item.wp_util_cost
                                                      )
                                                    }
                                                    className="badge bg-success"
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={faEdit}
                                                    />{" "}
                                                    Edit
                                                  </span>
                                                </td>
                                              </>
                                            )}
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>
                              ) : (
                                <div className="text-center">
                                  <div className="card-body">
                                    <img
                                      alt="Empty Data"
                                      className="project-empty-img"
                                      src={EmptyImg}
                                    />
                                    <p>No Any User Price Found</p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {this.state.channel === "rcs" && (
                      <div className="row">
                        <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                          <div className="card table-responsive flex-fill">
                            {this.state.rcsPriceList ? (
                              <table className="table table-hover my-0">
                                <thead>
                                  <tr>
                                    <th>Country Code</th>
                                    <th className="">Bot ID</th>
                                    <th className="">Rcs Cost</th>
                                    <th className="">A2P Sinagle Rcs Cost</th>
                                    <th className="">
                                      {" "}
                                      A2P Conversation Cost{" "}
                                    </th>
                                    <th className="">
                                      {" "}
                                      P2A Conversation Cost{" "}
                                    </th>
                                    <th className=""> Date/Time </th>
                                    {this.state.emp_type ===
                                    "reseller" ? null : (
                                      <>
                                        <th className=""> Edit </th>
                                      </>
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.rcsPriceList.map((item, i) => (
                                    <tr key={i}>
                                      <td>{item.country_code}</td>
                                      <td>{item.bot_id}</td>
                                      <td>{item.basic_sms_cost}</td>
                                      <td>{item.a2p_single_sms_cost}</td>
                                      <td>{item.a2p_conv_cost}</td>
                                      <td>{item.p2a_conv_cost}</td>
                                      <td>
                                        {dayjs(item.created_date).format(
                                          "DD/MM/YYYY h:mm A"
                                        )}
                                      </td>
                                      {this.state.emp_type ===
                                      "reseller" ? null : (
                                        <>
                                          <td>
                                            <span
                                              onClick={() =>
                                                this.getRcsPrice(
                                                  item._id,
                                                  item.country_code,
                                                  item.bot_id,
                                                  item.basic_sms_cost,
                                                  item.a2p_single_sms_cost,
                                                  item.a2p_conv_cost,
                                                  item.p2a_conv_cost
                                                )
                                              }
                                              className="badge bg-success"
                                            >
                                              <FontAwesomeIcon icon={faEdit} />{" "}
                                              Edit
                                            </span>
                                          </td>
                                        </>
                                      )}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            ) : (
                              <div className="text-center">
                                <div className="card-body">
                                  <img
                                    alt="Empty Data"
                                    className="project-empty-img"
                                    src={EmptyImg}
                                  />
                                  <p>No Any User Price Found</p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {this.state.showModal && (
                    <div
                      className="modal fade show d-block"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Confirmation Code
                            </h5>
                          </div>
                          <div className="modal-body">
                            {this.state.popupStatus ? (
                              <div
                                className={this.state.popupColor}
                                role="alert"
                              >
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="alert"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">×</span>
                                </button>
                                <div className="">
                                  {this.state.popupMessage}
                                </div>
                              </div>
                            ) : null}
                            <div className="row">
                              <div className="col-12">
                                <label>Enter the Code</label>
                                <input
                                  type="number"
                                  value={this.state.confirmCode}
                                  onChange={(e) =>
                                    this.setState({
                                      confirmCode: e.target.value,
                                      popupStatus: false,
                                    })
                                  }
                                  placeholder="Please Enter the Code"
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() =>
                                this.setState({ showModal: false })
                              }
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              onClick={this.confirmationCode}
                              className="btn btn-info"
                            >
                              Confirm
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </main>
          <AdminFooter />
        </div>
      </div>
    );
  }
}

export default UserPriceDetails;
