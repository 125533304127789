import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Dialog from 'react-dialog';
const SuggestionsButtonUi = ({ formData, setFormData, activeIndex, carousels, setCarousels }) => {
    const [suggestions, setSuggestions] = useState(formData.suggestionsButton);
    const [isDialogOpen, setisDialogOpen] = useState({ status: false, index: '' });
    // Add new carousel and tab
    const addSuggestions = () => {
        if (suggestions.length < 3) {
            const newSuggestions = {
                type_of_action: "",
                suggestion_text: "",
                suggestion_postback: "",
                url: "",
                phone_number: '',
            };
            setSuggestions([...suggestions, newSuggestions]);
        }
    };

    // Handle changes to carousel form data
    const handleCarouselChange = (index, e) => {
        const { name, value } = e.target;
        const updatedSuggestions = [...suggestions];
        updatedSuggestions[index][name] = value;
        setSuggestions(updatedSuggestions);

        // this is for UI only iin care of template type != text

        if (formData.templateType === "text") {
            setFormData((prevData) => ({
                ...prevData,
                suggestionsButton: updatedSuggestions,
            }));
        }

        if (formData.templateType === 'richcardstandalone') {
            // this is for Inside rich 
            setFormData((prevData) => ({
                ...prevData, standaloneCard: {
                    ...prevData.standaloneCard,
                    temp_suggestion: updatedSuggestions
                }
            }));
        }

        if (formData.templateType === 'richcardcarousel') {
            // this is for Inside rich 
            const updatedCarousels = [...carousels];
            updatedCarousels[activeIndex] = {
                ...updatedCarousels[activeIndex], // Spread the existing carousel data
                temp_suggestion: suggestions
            };

            setCarousels(updatedCarousels);

            // Also update the formData with the modified carousels array
            setFormData((prevData) => ({
                ...prevData,
                carousels: updatedCarousels,
            }));
        }
    };

    const deleteSuggestions = () => {
        let afterDelete = suggestions.filter((_, i) => i !== isDialogOpen.index);
        setSuggestions(afterDelete);
        setFormData((prevData) => ({
            ...prevData,
            suggestionsButton: afterDelete,
        }));
        setisDialogOpen({ status: false, index: '' })
    };

    const handleClose = () => setisDialogOpen({ status: false, index: '' })

    return (
        <div className="suggested-button">

            {
                isDialogOpen.status &&
                <Dialog
                    title="Alert"
                    modal={true}
                    onClose={handleClose}
                    buttons={
                        [{
                            text: "Close",
                            className: 'btn-info btn-sm btn mr-2',
                            onClick: () => handleClose()
                        },
                        {
                            text: "ok",
                            className: 'btn btn-sm btn-danger',
                            onClick: () => deleteSuggestions()
                        }]
                    }>
                    <p>Are You Sure ? Want to Delete this Button </p>
                </Dialog>
            }


            <h5 className="suggested-title">Suggested Action/Reply Button</h5>
            <div className="text-right">
                <button type="button" onClick={addSuggestions} className="btn btn-sm btn-info">
                    + Add Suggestions
                </button>
            </div>

            {/* suggestions Button ui */}
            {suggestions.map((item, index) => (
                <div key={index}>
                    <div className="form-row mt-2">
                        <div className={`form-group  drop-down-icons ${item.type_of_action === "url_action" || item.type_of_action === "dialer_action" ? 'col-md-2' : 'col-md-3'} `}>
                            <select
                                className="form-control"
                                name="type_of_action"
                                value={item.type_of_action}
                                onChange={(e) => handleCarouselChange(index, e)}
                            >
                                <option value="">Select Type</option>
                                <option value="reply">Reply</option>
                                <option value="url_action">URL Action</option>
                                <option value="dialer_action">Dialer Action</option>
                            </select>
                        </div>
                        <div className={`form-group ${item.type_of_action === "url_action" || item.type_of_action === "dialer_action" ? 'col-md-3' : 'col-md-4'} `}>
                            <input
                                type="text"
                                className="form-control"
                                name="suggestion_text"
                                value={item.suggestion_text}
                                onChange={(e) => handleCarouselChange(index, e)}
                                placeholder="Suggestions Text"
                            />
                        </div>
                        <div className={`form-group ${item.type_of_action === "url_action" || item.type_of_action === "dialer_action" ? 'col-md-3' : 'col-md-4'} `}>
                            <input
                                type="text"
                                className="form-control"
                                name="suggestion_postback"
                                value={item.suggestion_postback}
                                onChange={(e) => handleCarouselChange(index, e)}
                                placeholder="Suggestions Postback"
                            />
                        </div>

                        {item.type_of_action === "url_action" && (
                            <div className={`form-group ${item.type_of_action === "url_action" ? 'col-md-3' : 'col-md-4'} `}>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="url"
                                    value={item.url}
                                    onChange={(e) => handleCarouselChange(index, e)}
                                    placeholder="Enter URL / URI to open"
                                />
                            </div>
                        )}

                        {item.type_of_action === "dialer_action" && (
                            <div className={`form-group ${item.type_of_action === "dialer_action" ? 'col-md-3' : 'col-md-4'} `}>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="phone_number"
                                    value={item.phone_number}
                                    onChange={(e) => handleCarouselChange(index, e)}
                                    placeholder="phone number to dial"
                                />
                            </div>
                        )}

                        <div onClick={() => setisDialogOpen({ status: true, index: index })} className="form-group col-md-1">
                            <span className="btn btn-sm btn-danger"><FontAwesomeIcon icon={faTrash} /></span>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default SuggestionsButtonUi;
