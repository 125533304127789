import React from "react";
import CarouselCardForm from "./CarouselCardForm";
import StandaloneCardForm from "./StandaloneCardForm";
import TextForm from "../components/TextForm";

const TemplateForm = ({ templateType, formData, setFormData }) => {
    if (templateType === "text") {
        return <TextForm formData={formData} setFormData={setFormData} />;
    }
    if (templateType === "richcardcarousel") {
        return <CarouselCardForm formData={formData} setFormData={setFormData} />;
    }
    if (templateType === "richcardstandalone") {
        return <StandaloneCardForm formData={formData} setFormData={setFormData} />;
    }
    return null;
};

export default TemplateForm;
