import React from "react";
import styles from "./rcsTemplateDetail.module.css";
import { FaCopy } from "react-icons/fa";

const RCSTemplateDetails = ({ data }) => {
    const { temp_type, rich_card, temp_suggestion, temp_body, bot_id, temp_category, temp_language, temp_name, media_height } = data;
    if (temp_type === "richcardstandalone") {
        return (
            <div className={styles.richCardStandalone}>

                <div className={styles.row}>
                    <label className={styles.label}>Bot ID:</label>
                    <div className={styles.contentWithCopy}>
                        <span style={{ wordBreak: "break-all" }}>{bot_id}</span>
                        {bot_id && (
                            <button
                                className={styles.copyButton}
                                onClick={() =>
                                    navigator.clipboard.writeText(bot_id)
                                }
                            >
                                <FaCopy />
                            </button>
                        )}
                    </div>
                </div>
                <div className={styles.row}>
                    <label className={styles.label}>Template Name:</label>
                    <div className={styles.contentWithCopy}>
                        <span style={{ wordBreak: "break-all" }}>{temp_name}</span>
                        {temp_name && (
                            <button
                                className={styles.copyButton}
                                onClick={() =>
                                    navigator.clipboard.writeText(temp_name)
                                }
                            >
                                <FaCopy />
                            </button>
                        )}
                    </div>
                </div>
                <div className={styles.row}>
                    <label className={styles.label}>Template Category:</label>
                    <div className={styles.contentWithCopy}>
                        <span style={{ wordBreak: "break-all" }}>{temp_category}</span>
                        {temp_category && (
                            <button
                                className={styles.copyButton}
                                onClick={() =>
                                    navigator.clipboard.writeText(temp_category)
                                }
                            >
                                <FaCopy />
                            </button>
                        )}
                    </div>
                </div>
                <div className={styles.row}>
                    <label className={styles.label}>Template Type:</label>
                    <div className={styles.contentWithCopy}>
                        <span style={{ wordBreak: "break-all" }}>{temp_type}</span>
                        {temp_type && (
                            <button
                                className={styles.copyButton}
                                onClick={() =>
                                    navigator.clipboard.writeText(temp_type)
                                }
                            >
                                <FaCopy />
                            </button>
                        )}
                    </div>
                </div>
                <div className={styles.row}>
                    <label className={styles.label}>Template Language</label>
                    <div className={styles.contentWithCopy}>
                        <span style={{ wordBreak: "break-all" }}>{temp_language}</span>
                        {temp_language && (
                            <button
                                className={styles.copyButton}
                                onClick={() =>
                                    navigator.clipboard.writeText(temp_language)
                                }
                            >
                                <FaCopy />
                            </button>
                        )}
                    </div>
                </div>
                <div className={styles.row}>
                    <label className={styles.label}>{rich_card[0].media?.file_type === "image" ? "Image" : "Video"} URL :</label>
                    <div className={styles.contentWithCopy}>
                        <span style={{ wordBreak: "break-all" }}>{rich_card[0].media?.url || "No URL available"}</span>
                        {rich_card[0].media?.url && (
                            <button
                                className={styles.copyButton}
                                onClick={() =>
                                    navigator.clipboard.writeText(rich_card[0].media.url)
                                }
                            >
                                <FaCopy />
                            </button>
                        )}
                    </div>
                </div>

                <div className={styles.row}>
                    <label className={styles.label}>Title:</label>
                    <div className={styles.contentWithCopy}>
                        <span style={{ wordBreak: "break-all" }}>{rich_card[0].card_title}</span>
                        {rich_card[0].card_title && (
                            <button
                                className={styles.copyButton}
                                onClick={() =>
                                    navigator.clipboard.writeText(rich_card[0].card_title)
                                }
                            >
                                <FaCopy />
                            </button>
                        )}
                    </div>
                </div>
                <div className={styles.row}>
                    <label className={styles.label}>Description:</label>
                    <div className={styles.contentWithCopy}>
                        <span style={{ wordBreak: "break-all" }}>{rich_card[0].card_description}</span>
                        {rich_card[0].card_description && (
                            <button
                                className={styles.copyButton}
                                onClick={() =>
                                    navigator.clipboard.writeText(rich_card[0].card_description)
                                }
                            >
                                <FaCopy />
                            </button>
                        )}
                    </div>
                </div>
                <div className={styles.row}>
                    <label className={styles.label}>Card Orientation:</label>
                    <div className={styles.contentWithCopy}>
                        <span style={{ wordBreak: "break-all" }}>{rich_card[0].card_orientation}</span>
                        {rich_card[0].card_orientation && (
                            <button
                                className={styles.copyButton}
                                onClick={() =>
                                    navigator.clipboard.writeText(rich_card[0].card_orientation)
                                }
                            >
                                <FaCopy />
                            </button>
                        )}
                    </div>
                </div>
                <div className={styles.row}>
                    <label className={styles.label}>Card Alignment:</label>
                    <div className={styles.contentWithCopy}>
                        <span style={{ wordBreak: "break-all" }}>{rich_card[0].card_alignment}</span>
                        {rich_card[0].card_alignment && (
                            <button
                                className={styles.copyButton}
                                onClick={() =>
                                    navigator.clipboard.writeText(rich_card[0].card_alignment)
                                }
                            >
                                <FaCopy />
                            </button>
                        )}
                    </div>
                </div>
                <div className={styles.row}>
                    <label className={styles.label}>Card Width:</label>
                    <div className={styles.contentWithCopy}>
                        <span style={{ wordBreak: "break-all" }}>{rich_card[0].card_width}</span>
                        {rich_card[0].card_width && (
                            <button
                                className={styles.copyButton}
                                onClick={() =>
                                    navigator.clipboard.writeText(rich_card[0].card_width)
                                }
                            >
                                <FaCopy />
                            </button>
                        )}
                    </div>
                </div>
                <div className={styles.row}>
                    <label className={styles.label}>Media height:</label>
                    <div className={styles.contentWithCopy}>
                        <span style={{ wordBreak: "break-all" }}>{rich_card[0].media_height}</span>
                        {rich_card[0].media_height && (
                            <button
                                className={styles.copyButton}
                                onClick={() =>
                                    navigator.clipboard.writeText(rich_card[0].media_height)
                                }
                            >
                                <FaCopy />
                            </button>
                        )}
                    </div>
                </div>

                {/* Suggestions Section */}
                {rich_card[0].temp_suggestion && rich_card[0].temp_suggestion.length > 0 && (
                    <div className={styles.row}>
                        <label className={styles.label}>Buttons:</label>
                        <div className={styles.buttonsContainer}>
                            {rich_card[0].temp_suggestion.map((item, subIndex) => (
                                <div className="bg-secondary p-2" style={{borderRadius:".5rem"}}>
                                    <div
                                        key={subIndex}

                                    >
                                        <label htmlFor="" style={{ fontWeight: "bold", marginBottom:"0" }}>{item.type_of_action === "url_action"
                                            ? "Url"
                                            : "Phone"}</label>
                                        <div >
                                            <span>
                                                {item.type_of_action === "reply"
                                                    ? item.suggestion_text
                                                    : item.type_of_action === "url_action"
                                                        ? item.url
                                                        : item.phone_number}
                                            </span>
                                            <button className={styles.copyButton} onClick={() => navigator.clipboard.writeText(item.type_of_action === "reply"
                                                ? item.suggestion_text
                                                : item.type_of_action === "url_action"
                                                    ? item.url
                                                    : item.phone_number)}>
                                                <FaCopy />
                                            </button>

                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="" style={{ fontWeight: "bold",marginBottom:"0" }}>Suggestion Text</label>
                                        <div>
                                            <span>{item.suggestion_text}</span>
                                            <button className={styles.copyButton} onClick={() => navigator.clipboard.writeText(item.suggestion_text)}>
                                                <FaCopy />
                                            </button>
                                        </div>

                                    </div>
                                    <div>
                                        <label htmlFor="" style={{ fontWeight: "bold",marginBottom:"0" }}>Suggestion Postback</label>
                                        <div>
                                            <span>{item.suggestion_postback}</span>
                                            <button className={styles.copyButton} onClick={() => navigator.clipboard.writeText(item.suggestion_postback)}>
                                                <FaCopy />
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        );
    }



    if (temp_type === "richcardcarousel") {
        return (
            <>
                <div className={styles.richCardCarouselTopCard}>
                    <div className={styles.row}>
                        <label className={styles.label}>Bot ID:</label>
                        <div className={styles.contentWithCopy}>
                            <span style={{ wordBreak: "break-all" }}>{bot_id}</span>
                            {bot_id && (
                                <button
                                    className={styles.copyButton}
                                    onClick={() =>
                                        navigator.clipboard.writeText(bot_id)
                                    }
                                >
                                    <FaCopy />
                                </button>
                            )}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <label className={styles.label}>Template Name:</label>
                        <div className={styles.contentWithCopy}>
                            <span style={{ wordBreak: "break-all" }}>{temp_name}</span>
                            {temp_name && (
                                <button
                                    className={styles.copyButton}
                                    onClick={() =>
                                        navigator.clipboard.writeText(temp_name)
                                    }
                                >
                                    <FaCopy />
                                </button>
                            )}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <label className={styles.label}>Template Category:</label>
                        <div className={styles.contentWithCopy}>
                            <span style={{ wordBreak: "break-all" }}>{temp_category}</span>
                            {temp_category && (
                                <button
                                    className={styles.copyButton}
                                    onClick={() =>
                                        navigator.clipboard.writeText(temp_category)
                                    }
                                >
                                    <FaCopy />
                                </button>
                            )}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <label className={styles.label}>Template Type:</label>
                        <div className={styles.contentWithCopy}>
                            <span style={{ wordBreak: "break-all" }}>{temp_type}</span>
                            {temp_type && (
                                <button
                                    className={styles.copyButton}
                                    onClick={() =>
                                        navigator.clipboard.writeText(temp_type)
                                    }
                                >
                                    <FaCopy />
                                </button>
                            )}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <label className={styles.label}>Template Language</label>
                        <div className={styles.contentWithCopy}>
                            <span style={{ wordBreak: "break-all" }}>{temp_language}</span>
                            {temp_language && (
                                <button
                                    className={styles.copyButton}
                                    onClick={() =>
                                        navigator.clipboard.writeText(temp_language)
                                    }
                                >
                                    <FaCopy />
                                </button>
                            )}
                        </div>
                    </div>

                </div>
                <div className={styles.carousal}>
                    {rich_card.map((card, index) => (
                        <div key={card._id || index} className={styles.richCardCarouselItem}>
                            {/* Card URL */}
                            <div className={styles.row}>
                                <label className={styles.label}>
                                    {card.media?.file_type === "image" ? "Image" : "Video"} URL:
                                </label>
                                <div className={styles.contentWithCopy}>
                                    <span style={{ wordBreak: "break-all" }}>
                                        {card.media?.url || "No URL available"}
                                    </span>
                                    {card.media?.url && (
                                        <button
                                            className={styles.copyButton}
                                            onClick={() => navigator.clipboard.writeText(card.media.url)}
                                        >
                                            <FaCopy />
                                        </button>
                                    )}
                                </div>
                            </div>

                            {/* Card Title */}
                            <div className={styles.row}>
                                <label className={styles.label}>Title:</label>
                                <div className={styles.contentWithCopy}>
                                    <span style={{ wordBreak: "break-all" }}>{card.card_title}</span>
                                    {card.card_title && (
                                        <button
                                            className={styles.copyButton}
                                            onClick={() => navigator.clipboard.writeText(card.card_title)}
                                        >
                                            <FaCopy />
                                        </button>
                                    )}
                                </div>
                            </div>

                            {/* Card Description */}
                            <div className={styles.row}>
                                <label className={styles.label}>Description:</label>
                                <div className={styles.contentWithCopy}>
                                    <span style={{ wordBreak: "break-all" }}>{card.card_description}</span>
                                    {card.card_description && (
                                        <button
                                            className={styles.copyButton}
                                            onClick={() =>
                                                navigator.clipboard.writeText(card.card_description)
                                            }
                                        >
                                            <FaCopy />
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className={styles.row}>
                                <label className={styles.label}>Card Orientation:</label>
                                <div className={styles.contentWithCopy}>
                                    <span style={{ wordBreak: "break-all" }}>{card.card_orientation}</span>
                                    {card.card_orientation && (
                                        <button
                                            className={styles.copyButton}
                                            onClick={() =>
                                                navigator.clipboard.writeText(card.card_orientation)
                                            }
                                        >
                                            <FaCopy />
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className={styles.row}>
                                <label className={styles.label}>Card Alignment:</label>
                                <div className={styles.contentWithCopy}>
                                    <span style={{ wordBreak: "break-all" }}>{card.card_alignment}</span>
                                    {card.card_alignment && (
                                        <button
                                            className={styles.copyButton}
                                            onClick={() =>
                                                navigator.clipboard.writeText(card.card_alignment)
                                            }
                                        >
                                            <FaCopy />
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className={styles.row}>
                                <label className={styles.label}>Card Width:</label>
                                <div className={styles.contentWithCopy}>
                                    <span style={{ wordBreak: "break-all" }}>{card.card_width}</span>
                                    {card.card_width && (
                                        <button
                                            className={styles.copyButton}
                                            onClick={() =>
                                                navigator.clipboard.writeText(card.card_width)
                                            }
                                        >
                                            <FaCopy />
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className={styles.row}>
                                <label className={styles.label}>Media height:</label>
                                <div className={styles.contentWithCopy}>
                                    <span style={{ wordBreak: "break-all" }}>{card.media_height}</span>
                                    {card.media_height && (
                                        <button
                                            className={styles.copyButton}
                                            onClick={() =>
                                                navigator.clipboard.writeText(card.media_height)
                                            }
                                        >
                                            <FaCopy />
                                        </button>
                                    )}
                                </div>
                            </div>

                            {/* Suggestions Section */}
                            {card.temp_suggestion && card.temp_suggestion.length > 0 && (
                                <div className={styles.row}>
                                    <label className={styles.label}>Buttons:</label>
                                    <div className={styles.buttonsContainer}>
                                        {card.temp_suggestion.map((item, subIndex) => (
                                            <div className="bg-secondary p-2" style={{borderRadius:".5rem"}}>
                                                <div
                                                    key={subIndex}

                                                >
                                                    <label htmlFor="" style={{ fontWeight: "bold",marginBottom:"0" }}>{item.type_of_action === "url_action"
                                                        ? "Url"
                                                        : "Phone"}</label>
                                                    <div >
                                                        <span>
                                                            {item.type_of_action === "reply"
                                                                ? item.suggestion_text
                                                                : item.type_of_action === "url_action"
                                                                    ? item.url
                                                                    : item.phone_number}
                                                        </span>
                                                        <button className={styles.copyButton} onClick={() => navigator.clipboard.writeText(item.type_of_action === "reply"
                                                            ? item.suggestion_text
                                                            : item.type_of_action === "url_action"
                                                                ? item.url
                                                                : item.phone_number)}>
                                                            <FaCopy />
                                                        </button>

                                                    </div>
                                                </div>
                                                <div>
                                                    <label htmlFor="" style={{ fontWeight: "bold",marginBottom:"0" }}>Suggestion Text</label>
                                                    <div>
                                                        <span>{item.suggestion_text}</span>
                                                        <button className={styles.copyButton} onClick={() => navigator.clipboard.writeText(item.suggestion_text)}>
                                                            <FaCopy />
                                                        </button>
                                                    </div>

                                                </div>
                                                <div>
                                                    <label htmlFor="" style={{ fontWeight: "bold",marginBottom:"0" }}>Suggestion Postback</label>
                                                    <div>
                                                        <span>{item.suggestion_postback}</span>
                                                        <button className={styles.copyButton} onClick={() => navigator.clipboard.writeText(item.suggestion_postback)}>
                                                            <FaCopy />
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </>
        );
    }



    if (temp_type === "text") {
        return (
            <div className={styles.textSuggestions}>
                {/* Template Body */}
                <div className={styles.row}>
                    <label className={styles.label}>Template Body:</label>
                    <div className={styles.contentWithCopy}>
                        <span>{temp_body}</span>
                        <button className={styles.copyButton} onClick={() => navigator.clipboard.writeText(temp_body)}>
                            <FaCopy />
                        </button>
                    </div>
                </div>

                {/* Suggestions Section */}
                {temp_suggestion.length > 0 && <div className={styles.row}>
                    <label className={styles.label}>Buttons:</label>
                    <div className={styles.buttonsContainer}>
                        {temp_suggestion.length > 0 ? (
                            temp_suggestion.map((item, index) => (
                                <div className="bg-secondary p-2" style={{borderRadius:".5rem"}}>
                                    <div
                                        key={index}
                                        
                                    >
                                        <label htmlFor="" style={{ fontWeight: "bold",marginBottom:"0" }}>{item.type_of_action === "url_action"
                                            ? "Url"
                                            : "Phone"}</label>
                                        <div >
                                            <span>
                                                {item.type_of_action === "reply"
                                                    ? item.suggestion_text
                                                    : item.type_of_action === "url_action"
                                                        ? item.url
                                                        : item.phone_number}
                                            </span>
                                            <button className={styles.copyButton} onClick={() => navigator.clipboard.writeText(item.type_of_action === "reply"
                                                ? item.suggestion_text
                                                : item.type_of_action === "url_action"
                                                    ? item.url
                                                    : item.phone_number)}>
                                                <FaCopy />
                                            </button>

                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="" style={{ fontWeight: "bold",marginBottom:"0" }}>Suggestion Text</label>
                                        <div>
                                            <span>{item.suggestion_text}</span>
                                            <button className={styles.copyButton} onClick={() => navigator.clipboard.writeText(item.suggestion_text)}>
                                                <FaCopy />
                                            </button>
                                        </div>

                                    </div>
                                    <div>
                                        <label htmlFor="" style={{ fontWeight: "bold",marginBottom:"0" }}>Suggestion Postback</label>
                                        <div>
                                            <span>{item.suggestion_postback}</span>
                                            <button className={styles.copyButton} onClick={() => navigator.clipboard.writeText(item.suggestion_postback)}>
                                                <FaCopy />
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            ))
                        ) : (
                            <span className={styles.noSuggestions}>No suggestions available</span>
                        )}
                    </div>
                </div>}
            </div>
        );
    }


    return <p className={styles.invalidTemplate}>Invalid template type.</p>;
};



export default RCSTemplateDetails;
