import {
    TbMessage2Pin,
    TbMessage2Bolt,
    TbMessage2Plus,
    TbMessage2Check,
    TbMessage2Share,
    TbMessage2X,
} from "react-icons/tb";
import React from "react";
const OverviewCard = ({ title, value }) => {
    const getIcon = () => {
        switch (title) {
            case "Total Chats":
                return (
                    <span className="iconCircluate yellowish mr-2">
                        <TbMessage2Pin className="text-white" />
                    </span>
                );
            case "Live Chats":
                return (
                    <span className="iconCircluate voiletish mr-2">
                        <TbMessage2Bolt className="text-white" />
                    </span>
                );
            case "Resolved Chats":
                return (
                    <span className="iconCircluate blueish mr-2">
                        <TbMessage2Check className="text-white" />
                    </span>
                );
            case "Read Chats":
                return (
                    <span className="iconCircluate blueish mr-2">
                        <TbMessage2Check className="text-white" />
                    </span>
                );
            case "Unread Chats":
                return (
                    <span className="iconCircluate greenish mr-2">
                        <TbMessage2Check className="text-white" />
                    </span>
                );
            case "Repeated Chats":
                return (
                    <span className="iconCircluate yellowish mr-2">
                        <TbMessage2Check className="text-white" />
                    </span>
                );
            case "Average Response Time":
                return (
                    <span className="iconCircluate orangeish mr-2">
                        <TbMessage2Plus className="text-white" />
                    </span>
                );
            case "Customer Satisfaction":
                return (
                    <span className="iconCircluate greenish mr-2">
                        <TbMessage2Share className="text-white" />
                    </span>
                );
            default:
                return (
                    <span className="iconCircluate reddish mr-2">
                        <TbMessage2X className="text-white" />
                    </span>
                );
        }
    };
    return (
        <div className="bg-white shadow-lg rounded-lg p-3 flex items-center">
            <div className="mr-4">{getIcon()}</div>
            <div>
                <h2 className="text-sm font-semibold text-gray-700">{title}</h2>
                <p className="font-bold text-blue-600">{value}</p>
            </div>
        </div>
    )
}

export default OverviewCard;