import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons'


class RcsApplyedList extends Component {

    constructor() {

        const lStorage = JSON.parse(localStorage.getItem("login"));
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;

        super();
        this.state = {
            user_id: userId,
            token: userToken,
            rcsList: '',
            successMessage: false,
            bgColor: ''
        }
    }

    componentDidMount() {
        fetch(`https://googlercs.authkey.io/authkey/manage_rcs.php?userid=${this.state.user_id}&method=retrieve&token=${this.state.token}&method=list`).then((response) => {
            response.json().then((result) => {
                console.log(result);
                if (result.success === true) {
                    this.setState({ rcsList: result });
                } else {
                    this.setState({ successMessage: true, bgColor: "alert alert-danger alert-dismissible", errorMessage: 'Somthing Wrong' });
                }
            })
        })

    }


    render() {
        document.querySelector('body').scrollTo(0, 0);
        return (
            <div className="wrapper">
                <DashboardLeftMenu />
                <div className="main">
                    <DashboardHeader />
                    <main className="content">
                        <div className="container-fluid p-0">

                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong>RCS </strong> List </h3>
                                </div>

                                <div className="col-auto ml-auto text-right mt-n1">
                                    <Link className="btn btn-primary btn-sm" to="/dashboard/apply-rcs"> <FontAwesomeIcon icon={faPlus} color='' /> Apply New RCS </Link>
                                </div>
                            </div>


                            {
                                this.state.successMessage ?
                                    <div className={this.state.bgColor} role="alert">
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                        <div className="">
                                            {this.state.errorMessage}
                                        </div>
                                    </div>
                                    :
                                    null
                            }


                            <div className="row">

                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="card-title mb-0">Applyed RCS List </h5>
                                        </div>
                                        <hr className="m-0" />
                                        <div className="">

                                            {

                                                this.state.rcsList ?

                                                    <div className="table-responsive">


                                                        <table className="table table-hover  project  my-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Brand Name</th>
                                                                    <th> Website</th>
                                                                    <th>Created Date</th>
                                                                    <th>Status</th>
                                                                    <th>Action</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>



                                                                {
                                                                    this.state.rcsList.data.map((item, i) =>
                                                                        <tr key={i}>
                                                                            <td>{item.brand_name}</td>
                                                                            <td>{item.website}</td>
                                                                            <td>{item.created}</td>
                                                                            <td>
                                                                                {
                                                                                    item.is_active === 0 ?
                                                                                        <span className="badge bg-danger"> <FontAwesomeIcon icon={faCheckCircle} color='' /> Pending </span>
                                                                                        :
                                                                                        <span className="badge bg-success"> <FontAwesomeIcon icon={faCheckCircle} color='' /> Active </span>
                                                                                }
                                                                            </td>
                                                                            <td><Link to={'/dashboard/apply-rcs/'}><span className="badge bg-info"> <FontAwesomeIcon icon={faEdit} color='' /> Edit </span></Link></td>
                                                                        </tr>
                                                                    )
                                                                }





                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    :
                                                    <div className="text-center">
                                                        <div className="card-body">
                                                            <p className='text-danger'>No Recod Found</p>
                                                        </div>
                                                    </div>
                                            }


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <DashboardFooter />
                </div>
            </div>
        );
    }
}

export default RcsApplyedList;