import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AdminHeader from "../Header/AdminHeader";
import AdminFooter from "../Footer/AdminFooter";
import AdminLeftMenu from "../Navbar/AdminLeftMenu";
import EmptyImg from "../Dashboard/assets/img/create-project.png";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { userloginDetails } from "../Utils/AdminAuth";
import { HOST_URL, NODE_HOST_URL } from "../Dashboard/api/Api";
import Axios from "../../axios/Axios";

const BillingMode = (props) => {
  const lStorage = userloginDetails();
  const userId = lStorage.user.userId;
  const userToken = lStorage.token;
  const empType = lStorage.user.isAdmin;
  const { id, userType } = useParams();
  const param = useParams();

  const [email, setEmail] = useState("");
  const [smsType, setSmsType] = useState("");
  const [whatsappType, setWhatsappType] = useState("");
  const [emailType, setEmailType] = useState("");
  const [voiceType, setVoiceType] = useState("");
  const [rcsType, setRcsType] = useState("");
  const [agent, setAgent] = useState("");
  const [perAgentCost, setPerAgentCost] = useState("");
  const [perConversationCost, setPerConversationCost] = useState("");
  const [exceedLimitCost, setExceedLimitCost] = useState("");
  const [refund, setRefund] = useState("");
  const [error, setError] = useState({
    errorStatus: false,
    bgColor: "",
    errorMessage: "",
  });

  useEffect(() => {
    getUserDetails();
    BillingModeList();
  }, []);

  const getUserDetails = async () => {
    try {
      const response = await fetch(
        `${HOST_URL}/admin_user_list.php?user_id=${userId}&method=retrieve&type=userdetails&ret_user_id=${id}&token=${userToken}`
      );

      const result = await response.json();

      if (result.success === true) {
        setEmail(result.data[0].email);
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const handleSmsTypeChange = (e) => {
    const { value } = e.target;
    if (smsType !== value) {
      setSmsType(value);
    } else {
      setSmsType("");
    }
    setError({ errorStatus: false });
  };

  const handleWhatsappTypeChange = (e) => {
    const { value } = e.target;
    if (whatsappType !== value) {
      setWhatsappType(value);
    } else {
      setWhatsappType("");
    }
    setError({ errorStatus: false });
  };

  const handleEmailTypeChange = (e) => {
    const { value } = e.target;
    if (whatsappType !== value) {
      setEmailType(value);
    } else {
      setEmailType("");
    }
    setError({ errorStatus: false });
  };

  const handleVoiceTypeChange = (e) => {
    const { value } = e.target;
    if (whatsappType !== value) {
      setVoiceType(value);
    } else {
      setVoiceType("");
    }
    setError({ errorStatus: false });
  };

  const handleRcsTypeChange = (e) => {
    const { value } = e.target;
    if (whatsappType !== value) {
      setRcsType(value);
    } else {
      setRcsType("");
    }
    setError({ errorStatus: false });
  };

  const handleAgentTypeChange = (e) => {
    const { value } = e.target;
    if (whatsappType !== value) {
      setAgent(value);
    } else {
      setAgent("");
    }
    setError({ errorStatus: false });
  };

  const handleRefundChange = (e) => {
    const { value } = e.target;
    if (refund !== value) {
      setRefund(value);
    } else {
      setRefund("");
    }
    setError({ errorStatus: false });
  };

  const isValid = () => {
    if (smsType === "" || smsType === undefined) {
      setError({
        errorStatus: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please select SMS",
      });
      return false;
    }

    if (whatsappType === "" || whatsappType === undefined) {
      setError({
        errorStatus: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please select Whatsapp",
      });
      return false;
    }
    if (emailType === "" || emailType === undefined) {
      setError({
        errorStatus: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please select Email",
      });
      return false;
    }
    if (voiceType === "" || voiceType === undefined) {
      setError({
        errorStatus: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please select Voice",
      });
      return false;
    }
    if (rcsType === "" || rcsType === undefined) {
      setError({
        errorStatus: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please select RCS",
      });
      return false;
    }
    if (agent === "" || agent === undefined) {
      setError({
        errorStatus: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please select Agent",
      });
      return false;
    }

    if (agent === "peragent") {
      if (perAgentCost === "" || perAgentCost === undefined) {
        setError({
          errorStatus: true,
          bgColor: "alert alert-danger alert-dismissible",
          errorMessage: "Please Enter Per Agent Cost",
        });
        return false;
      }
    }

    if (perConversationCost === "" || perConversationCost === undefined) {
      setError({
        errorStatus: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please Enter Per Conversation Cost",
      });
      return false;
    }

    if (refund === "" || refund === undefined) {
      setError({
        errorStatus: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please select Auto Refund",
      });
      return false;
    }

    return true;
  };

  const SubmitBillingMode = async () => {
    if (isValid()) {
      let param = {
        user_id: userId,
        token: userToken,
        per_agent: perAgentCost ? perAgentCost : 0,
        voice_submission: voiceType === "voiceSubmission" ? 0 : 1,
        auto_refund: refund === "yes" ? 1 : 0,
        per_agent_conv: perConversationCost ? perConversationCost : 0,
        whatsapp_deliver: whatsappType === "whatsappDeliver" ? 1 : 0,
        retr_user_id: id,
        sms_deliver: smsType === "smsDeliver" ? 1 : 0,
        method: "billingmodel",
        email_deliver: emailType === "emailDeliver" ? 1 : 0,
        rcs_deliver: rcsType === "rcsDeliver" ? 1 : 0,
        per_agent_module: agent === "peragent" ? "per_agent" : "per_conv",
        exceed_price: exceedLimitCost,
      };
      try {
        let { data } = await Axios.post(
          `${NODE_HOST_URL}/admin/billing_model`,
          param
        );
        if (data.success) {
          setError({
            errorStatus: true,
            bgColor: "alert alert-success alert-dismissible",
            errorMessage: data.message,
          });

          setTimeout(() => {
            setError({ errorStatus: false });
          }, 3000);
        } else {
          setError({
            errorStatus: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: data.message,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const BillingModeList = async () => {
    let paramdata = {
      user_id: userId,
      token: userToken,
      retr_user_id: id,
      method: "list_model",
    };
    try {
      let { data } = await Axios.post(
        `${NODE_HOST_URL}/admin/billing_model`,
        paramdata
      );
      if (data.success) {
        
        let sms = data.data[0].sms === 1 ? "smsDeliver" : "smsSubmission";
        let whatsapp =
          data.data[0].whatsapp === 1
            ? "whatsappDeliver"
            : "whatsappSubmission";
        let email =
          data.data[0].email === 1 ? "emailDeliver" : "emailSubmission";
        let voice =
          data.data[0].voice === 1 ? "voiceDeliver" : "voiceSubmission";
        let rcs = data.data[0].rcs === 1 ? "rcsDeliver" : "rcsSubmission";
        let agentModule =
          data.data[0].agent_module === "per_conv"
            ? "perconversation"
            : "peragent";
        let autoRefund = data.data[0].auto_refund === 1 ? "yes" : "no";

       
        setSmsType(sms);
        setWhatsappType(whatsapp);
        setEmailType(email);
        setVoiceType(voice);
        setRcsType(rcs);
        setAgent(agentModule);
        setPerAgentCost(data.data[0].per_agent_price);
        setPerConversationCost(agentModule==="perconversation"?data.data[0].per_conv_price:data.data[0].conv_limit);
        setRefund(autoRefund);
        setExceedLimitCost(data.data[0].limit_exceed_price)

      }
    } catch (error) {
      console.log(error);
    }
  };

  
  return (
    <div>
      <div className="wrapper">
        <AdminLeftMenu />
        <div className="main">
          <AdminHeader />
          <main className="content">
            <div className="container-fluid p-0">
              <div className="row mb-2 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                  <h3>
                    User Details{" "}
                    <strong className="text-info"> {email} </strong>{" "}
                  </h3>
                </div>
              </div>
              <div className="row">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title mb-0">
                      <div className="col-auto ml-auto text-right mt-n1">
                        <nav className="text-center" aria-label="breadcrumb">
                          <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
                            <Link
                              to={"/admin/edit-employee/" + id + `/` + userType}
                            >
                              <li className="breadcrumb-item ">
                                Update Details{" "}
                              </li>{" "}
                            </Link>
                            <Link
                              to={
                                "/admin/reseller-transaction-details/" +
                                id +
                                `/` +
                                userType
                              }
                            >
                              {" "}
                              <li className="breadcrumb-item ">
                                {" "}
                                Transaction Details{" "}
                              </li>
                            </Link>

                            <Link
                              to={
                                "/admin/reseller-price-details/" +
                                id +
                                `/` +
                                userType
                              }
                            >
                              <li className="breadcrumb-item"> Add Price </li>
                            </Link>
                            {empType === "admin" && (
                              <Link
                                to={
                                  "/admin/reseller-add-price/" +
                                  id +
                                  `/` +
                                  userType
                                }
                              >
                                {" "}
                                <li className="breadcrumb-item">
                                  {" "}
                                  Add Credit{" "}
                                </li>
                              </Link>
                            )}
                            <Link
                              to={
                                "/admin/reseller-billing-mode/" +
                                id +
                                `/` +
                                userType
                              }
                            >
                              {" "}
                              <li className="breadcrumb-item active">
                                {" "}
                                Billing Mode{" "}
                              </li>
                            </Link>
                          </ol>
                        </nav>
                      </div>
                    </h5>
                  </div>

                  <div className="card-body">
                    {error.errorStatus && (
                      <div className={error.bgColor} role="alert">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                        <div>{error.errorMessage}</div>
                      </div>
                    )}

                    <div className="filter-option">
                      {/* <h6 className="card-subtitle text-muted">Add New Price</h6> */}
                      <div className="card">
                        <h5 className="ml-3">Channels</h5>
                        <div className="row">
                          <div className="col-3">
                            <h6 className="ml-3">SMS</h6>
                          </div>
                          <div className="col-6">
                            <div className="col-6">
                              <div className="d-flex align-items-center">
                                <div className="form-check mr-3">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="sms-Deliver"
                                    checked={smsType === "smsDeliver"}
                                    onChange={handleSmsTypeChange}
                                    value="smsDeliver"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="sms-Deliver"
                                  >
                                    Deliver
                                  </label>
                                </div>
                                <div className="form-check ml-3">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="sms-submission"
                                    checked={smsType === "smsSubmission"}
                                    onChange={handleSmsTypeChange}
                                    value="smsSubmission"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="sms-submission"
                                  >
                                    Submission
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-3">
                            <h6 className="ml-3">Whatsapp</h6>
                          </div>
                          <div className="col-6">
                            <div className="col-6">
                              <div className="d-flex align-items-center">
                                <div className="form-check mr-3">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="whatspp-Deliver"
                                    checked={whatsappType === "whatsappDeliver"}
                                    onChange={handleWhatsappTypeChange}
                                    value="whatsappDeliver"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="whatspp-Deliver"
                                  >
                                    Deliver
                                  </label>
                                </div>
                                <div className="form-check ml-3">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="whatspp-submission"
                                    checked={
                                      whatsappType === "whatsappSubmission"
                                    }
                                    onChange={handleWhatsappTypeChange}
                                    value="whatsappSubmission"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="whatspp-submission"
                                  >
                                    Submission
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-3">
                            <h6 className="ml-3">Email</h6>
                          </div>
                          <div className="col-6">
                            <div className="col-6">
                              <div className="d-flex align-items-center">
                                <div className="form-check mr-3">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="email-Deliver"
                                    checked={emailType === "emailDeliver"}
                                    onChange={handleEmailTypeChange}
                                    value="emailDeliver"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="email-Deliver"
                                  >
                                    Deliver
                                  </label>
                                </div>
                                <div className="form-check ml-3">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="email-submission"
                                    checked={emailType === "emailSubmission"}
                                    onChange={handleEmailTypeChange}
                                    value="emailSubmission"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="email-submission"
                                  >
                                    Submission
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-3">
                            <h6 className="ml-3">Voice</h6>
                          </div>
                          <div className="col-6">
                            <div className="col-6">
                              <div className="d-flex align-items-center">
                                <div className="form-check mr-3">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="voice-Deliver"
                                    checked={voiceType === "voiceDeliver"}
                                    onChange={handleVoiceTypeChange}
                                    value="voiceDeliver"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="voice-Deliver"
                                  >
                                    Deliver
                                  </label>
                                </div>
                                <div className="form-check ml-3">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="voice-submission"
                                    checked={voiceType === "voiceSubmission"}
                                    onChange={handleVoiceTypeChange}
                                    value="voiceSubmission"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="voice-submission"
                                  >
                                    Submission
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-3">
                            <h6 className="ml-3">RCS</h6>
                          </div>
                          <div className="col-6">
                            <div className="col-6">
                              <div className="d-flex align-items-center">
                                <div className="form-check mr-3">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="rcs-Deliver"
                                    checked={rcsType === "rcsDeliver"}
                                    onChange={handleRcsTypeChange}
                                    value="rcsDeliver"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="rcs-Deliver"
                                  >
                                    Deliver
                                  </label>
                                </div>
                                <div className="form-check ml-3">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="rcs-submission"
                                    checked={rcsType === "rcsSubmission"}
                                    onChange={handleRcsTypeChange}
                                    value="rcsSubmission"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="rcs-submission"
                                  >
                                    Submission
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-3">
                            <h6 className="ml-3">Agent</h6>
                          </div>
                          <div className="col-6">
                            <div className="col-6">
                              <div className="d-flex align-items-center">
                                <div className="form-check mr-3">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={agent === "peragent"}
                                    onChange={handleAgentTypeChange}
                                    value="peragent"
                                  />
                                  <label className="form-check-label">
                                    Per Agent
                                  </label>
                                </div>
                                <div className="form-check ml-3">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={agent === "perconversation"}
                                    onChange={handleAgentTypeChange}
                                    value="perconversation"
                                  />
                                  <label className="form-check-label">
                                    Per Conversation
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row m-3">
                          {agent === "peragent" && (
                            <>
                              <div className="col-4">
                                <label>
                                  <b>Per Agent Cost</b>
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  value={perAgentCost}
                                  required
                                  onChange={(e) =>
                                    setPerAgentCost(e.target.value)
                                  }
                                  placeholder="Enter per agent cost"
                                />
                              </div>
                              <div className="col-4">
                                <label>
                                  <b>Conversation limit</b>
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  value={perConversationCost}
                                  required
                                  onChange={(e) =>
                                    setPerConversationCost(e.target.value)
                                  }
                                  placeholder="Enter conversation limit"
                                />
                              </div>
                              <div className="col-4">
                                <label>
                                  <b>Conversation limit (Exceed Price)</b>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={exceedLimitCost}
                                  required
                                  onChange={(e) =>
                                    setExceedLimitCost(e.target.value)
                                  }
                                  placeholder="Enter conversation (Exceed Price)"
                                />
                              </div>
                            </>
                          )}

                          {agent === "perconversation" && (
                            <div className="col-6">
                              <label>
                                <b>Per Conversation Cost</b>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                value={perConversationCost}
                                onChange={(e) =>
                                  setPerConversationCost(e.target.value)
                                }
                                placeholder="Enter per Conversation cost"
                              />
                            </div>
                          )}
                        </div>

                        <div className="row">
                          <div className="col-3">
                            <h6 className="ml-3">Auto Refund</h6>
                          </div>
                          <div className="col-6">
                            <div className="col-6">
                              <div className="d-flex align-items-center">
                                <div className="form-check mr-3">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={refund === "yes"}
                                    onChange={handleRefundChange}
                                    value="yes"
                                  />
                                  <label className="form-check-label">
                                    yes
                                  </label>
                                </div>
                                <div className="form-check ml-3">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={refund === "no"}
                                    onChange={handleRefundChange}
                                    value="no"
                                  />
                                  <label className="form-check-label">No</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-6">
                            <button
                              type="button"
                              onClick={SubmitBillingMode}
                              className="btn btn-primary m-3"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <AdminFooter />
        </div>
      </div>
    </div>
  );
};

export default BillingMode;
