import ChartSection from "../ChartSection";
import OverviewCard from "../OverviewCard";
import Table from "../Table";
import React, { useState } from "react";
import style from './newDashboard.module.css'
import { IoCloseSharp } from "react-icons/io5";
import { faTable, faChartLine } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
const NewDashboard = ({brand_number, todayReport, reportSummary, agentList, selectFilter, handleFilterChange, handleDateChange, startDate, endDate, calendarOpen, handleOpenCalendar,setCalendarOpen }) => {

  const [chatOpened, setChatOpened] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const { total_chat, live_chat, answer_chat, missed_chat, total_repeat, agentwise_all_chat:agentWiseReport } = todayReport;
  const handleClose = () => {
    setChatOpened(prevState => !prevState);
  }

  const toggleView = (tab) => {
    setActiveTab(tab);
  };

  let headers = [];
  let rows = [];
  let chatDataByDay = [];
  let agentPerfData = [];
  let perfHeaders = [];
  let perfRows = [];



  if (reportSummary && reportSummary.length > 0) {
    chatDataByDay = reportSummary.map((item) => ({
      Date: item.date,
      "Total Chats": item.total_chat,
      "New Chats": item.new_chat,
      "Read Chats": item.read_chat,
      "Unread Chats": item.unread_chat,
      "Repeated Chats": item.repeated_chat,
    }));

    // Extract headers and rows for the table
    headers = Object.keys(chatDataByDay[0]); // Extract the keys for headers
    rows = chatDataByDay.map((data) => Object.values(data));
  }

  if (selectFilter === 'thisWeek' || selectFilter === 'thisMonth') {
    chatDataByDay.push({
      "Total Chats": total_chat?.length,
      Date: "Today",
      "Read Chats": answer_chat?.length,
      "Unread Chats": missed_chat?.length,
      "Repeated Chats": total_repeat?.length
    })
  }

  if (agentWiseReport && agentWiseReport.length > 0) {
    agentPerfData = agentWiseReport.map((item) => ({
      "Agent ID":item.agent_id,
      "Agent Name": item.agent_name,
      "Total Chats": item.total_chat?.length,
      "Read Chats": item.answer_chat?.length,
      "Unread Chats": item.missed_chat?.length,
      "Repeated Chats":item.total_repeat?.length,
      "New Chats": item.new_chat?.length,
      "History": `/dashboard/chat-report/agent-report/${item.agent_id}?name=${item.agent_name}`
    }))
  perfHeaders = Object.keys(agentPerfData[0]);
  perfRows = agentPerfData.map((data) => Object.values(data));

}
const formatDate = (date) => {
  const options = { timeZone: 'Asia/Kolkata', year: 'numeric', month: '2-digit', day: '2-digit' };
  const formattedDate = new Date(date).toLocaleString('en-IN', options);
  const [day, month, year] = formattedDate.split('/');
  return `${year}-${month}-${day}`;
};
const getPlaceholderText = () => {

  if (startDate && endDate) {
    return `${formatDate(startDate)} / ${formatDate(endDate)}`;
  } else if (startDate) {
    return `${formatDate(startDate)} / End date`;
  }
  return "Select a date range";
};

return (
  <div className={style.dashboardContainer}>
    <div className="d-flex justify-content-start align-items-center w-100">
      <h4 className="text-2xl font-bold text-gray-800">Admin Dashboard</h4>

    </div>

    <main>

      {/* Overview Section */}
      <div className={style.overviewSection}>
        <OverviewCard title="Total Chats" value={total_chat?.length} />
        <OverviewCard title="Live Chats" value={live_chat?.length} />
        <OverviewCard title="Read Chats" value={answer_chat?.length} />
        <OverviewCard title="Unread Chats" value={missed_chat?.length} />
        <OverviewCard title="Repeated Chats" value={total_repeat?.length} />
        {/* <OverviewCard title="Average Response Time" value="2m 30s" />
          <OverviewCard title="Customer Satisfaction" value="95%" /> */}
      </div>

      {/* Charts Section */}
      <div className={style.chartSection}>
        <div className="d-flex flex-column bg-white shadow-lg rounded-lg justify-content-between align-items-center w-100 mb-4 p-3">
          <h5 className=" font-semibold">
            Chats
          </h5>
          <div className={`d-flex justify-content-between align-items-center w-100 mb-4 `}>
            <div className={`d-flex  justify-content-between align-items-center  `}>
              <div className="d-flex justify-content-center align-items-center">
                <button
                  className={`py-1 px-2 ${activeTab === 1 ? style.activeChartBtn : null
                    }`}
                  style={{
                    // borderRight: "none",
                    borderRadius: "8px",
                    
                  }}
                  onClick={() => toggleView(1)}

                >
                  <FontAwesomeIcon icon={faChartLine} />
                </button>
                {/* <button
                  className={`py-1 px-2 ${activeTab === 2 ? style.activeChartBtn : null
                    }`}
                  style={{
                    borderLeft: "none",
                    borderTopRightRadius: "8px",
                    borderBottomRightRadius: "8px",
                  }}
                  onClick={() => toggleView(2)}
                >
                  <FontAwesomeIcon icon={faTable} />
                </button> */}
              </div>

            </div>



            <div className={`d-flex ${selectFilter === "custom" ? 'justify-content-between' : 'justify-content-end'} align-items-center`}>
              {selectFilter === "custom" &&
                <div className="d-flex">
              
                  <DatePicker
                    onChange={handleDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    onClickOutside={() => setCalendarOpen(false)}
                    open={calendarOpen}
                    onInputClick={handleOpenCalendar}
                    className="form-control"
                    dateFormat="yyyy-MM-dd"
                    placeholderText={getPlaceholderText()}
                    isClearable    
                            
                  />

                </div>
              }
              <select value={selectFilter} onChange={handleFilterChange} className="border rounded p-2" style={{ cursor: "pointer" }}>
                <option value="thisWeek">This Week</option>
                <option value="thisMonth">This month</option>
                <option value="thisYear">This Year</option>
                <option value="custom">Custom</option>
              </select>

            </div>


          </div>
          {activeTab === 1 && (chatDataByDay.length===0?<div>No data Available</div>: <ChartSection title="" type="line" data={chatDataByDay} />)}
          {activeTab === 2 && (chatDataByDay.length===0?<div>No data Available</div>:<Table headers={headers} rows={rows} />)}

        </div>


        {/* <div className="d-flex flex-column justify-content-start align-items-center bg-white shadow-lg rounded-lg p-4 w-100">
            <div className="d-flex justify-content-between align-items-center w-100 mb-4">
              <h5 className=" font-semibold">
                Chats on {selectedDate}
              </h5>

              <input
                type="date"
                className="border rounded p-2"
                onChange={(e) => setSelectedDate(e.target.value)}
                value={selectedDate}
              />
            </div>

            {chartData.length > 0 ? (

              <ChartSection title="Chat Statistics" type="pie" data={chartData} />



            ) : (

              <div className="text-gray-600 flex-grow-1 d-flex justify-content-center align-items-center">
                <p >Select a valid date to view the chart.</p>
              </div>
            )}
          </div> */}
      </div>

      {/* Agent Performance Section */}
      <div className={style.agentSection}>
        <h4 className="text-xl font-semibold text-gray-800 w-100">Today's Agent Performance</h4>
        <div className="bg-white shadow-lg rounded-lg p-4 w-100">
          <Table
            headers={perfHeaders}
            rows={perfRows}
          />
        </div>

      </div>

      {/* Chat History Section */}

      {/*<div className={style.ChatSection}>
        <h4 className="text-xl font-semibold text-gray-800  w-100">Chat History</h4>
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="bg-white shadow-lg rounded-lg p-4 w-100">
            <input
              type="text"
              placeholder="Search Chats..."
              className="border border-gray-300 rounded-md p-2 w-full mb-4"
            />
            <Table
              headers={["Agent", "Customer Name", "Timestamp", "View Chat"]}
              rows={[
                ["Agent 1", "Customer A", "2023-10-01 10:00",`/dashboard/whatsapp-conversation/?name=Ergual&mobile=34622204455&brand_number=${brand_number}`],
                ["Agent 2", "Customer B", "2023-10-01 10:05", "view"],
                ["Agent 3", "Customer C", "2023-10-01 10:10", "view"],
              ]}
              setChatOpened={setChatOpened}
            />
          </div>
          {chatOpened && (
            <>
              <div className={style.overlay} onClick={handleClose} /> 
              <div className={`bg-white shadow-lg rounded-lg p-3 ${style.chatDetailCard}`}>
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="text-xl font-semibold text-gray-700">Detailed Chat History</h5>
                  <IoCloseSharp onClick={handleClose} className={`${style.closeIcon}`} />
                </div>
                <div className={style.details}>
                  <p><strong>Agent:</strong> Agent 1</p>
                  <p><strong>Customer:</strong> Customer A</p>
                  <p><strong>2023-10-01 10:00:</strong> Hello, how can I help you?</p>
                  <p><strong>2023-10-01 10:01:</strong> I have an issue with my order.</p>
                  <p><strong>2023-10-01 10:02:</strong> Can you provide me with your order number?</p>
                  <p><strong>Agent:</strong> Agent 1</p>
                  <p><strong>Customer:</strong> Customer A</p>
                  <p><strong>2023-10-01 10:00:</strong> Hello, how can I help you?</p>
                  <p><strong>2023-10-01 10:01:</strong> I have an issue with my order.</p>
                  <p><strong>2023-10-01 10:02:</strong> Can you provide me with your order number?</p>

                </div>
              </div>
            </>
          )}

        </div>

      </div>/*}


      {/* User Management Section */}
      {/* <div className={style.userManagement}>
        <h4 className="text-xl font-semibold text-gray-800 w-100">User Management</h4>
        <div className="bg-white shadow-lg rounded-lg p-4 mb-6 w-100">
          <Table
            headers={["User", "Role", "Contact", "Status", "Activity"]}
            rows={[
              ["User 1", "Admin", "user1@example.com", "Active", "Busy"],
              ["User 2", "Agent", "user2@example.com", "Inactive", "Idle"],
              ["User 3", "Customer", "user3@example.com", "Active", "Idle"],
            ]}
          />
        </div>

      </div> */}
    </main>
  </div>
)
}

export default NewDashboard;