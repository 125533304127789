import React, { Component } from 'react';
import AdminHeader from '../../Header/AdminHeader';
import { Link } from 'react-router-dom';
import AdminFooter from '../../Footer/AdminFooter';
import AdminLeftMenu from '../../Navbar/AdminLeftMenu';
import EmptyImg from "../../Dashboard/assets/img/create-project.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import { userloginDetails } from '../../Utils/AdminAuth';
import { HOST_URL } from '../../Dashboard/api/Api';
class BlogList extends Component {

    constructor() {

        const lStorage = userloginDetails();
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;
        const isAdmin = lStorage.isAdmin;
        super();

        this.state = {
            user_id: userId,
            token: userToken,
            userType: isAdmin,
            bloglist: '',
            successMessage: false,
            errorMessage: '',
            bgColor: "",
        }

    }

    componentDidMount() {
        this.onserch();
    }

    onserch() {
        fetch(`${HOST_URL}/blog.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}&user_type=${this.state.userType}`).then((response) => {
            response.json().then((result) => {
                // console.log('Blog result',result)
                if (result.success === true) {
                    this.setState({ bloglist: result.data });
                } else {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-danger",
                        errorMessage: result.message
                    })
                }
            })
        })
    }

    deleteFaqList(id) {
        document.querySelector('body').scrollTo(0, 0);
        fetch(`${HOST_URL}/blog.php?user_id=${this.state.user_id}&id=${id}&method=delete&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                //console.log(result.success);
                if (result.success === true) {
                    this.setState({
                        successMessage: true,
                        successBg: "alert alert-success alert-dismissible",
                        errorMessage: result.message
                    })
                    setTimeout(function () { window.location = "/admin/blog-list"; }, 1000);

                } else {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })


    }


    render() {
        // console.log(this.state.bloglist);
        return (
            <div className="wrapper blog-cover">
                <AdminLeftMenu />
                <div className="main">
                    <AdminHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong> Blog </strong> List </h3>
                                </div>
                                <div className="col-auto ml-auto text-right mt-n1"><a className="btn btn-primary btn-sm" href="/admin/add-blog"> Add New Blog </a></div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                    <div className="card table-content-show flex-fill">


                                        <div className="card-body">

                                            {
                                                this.state.successMessage ?
                                                    <div className={this.state.bgColor} role="alert">
                                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                            <span aria-hidden="true">×</span>
                                                        </button>
                                                        <div className="">
                                                            {this.state.errorMessage}
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }

                                            {this.state.bloglist ?

                                                <div style={{ height: 400 + 'px' }} className="table-responsive">
                                                    <table className="table table-striped table-xs table-sm my-0">
                                                        <thead className="sticky-top">
                                                            <tr>

                                                                <th>Category Name </th>
                                                                <th>Title</th>
                                                                <th>Meta Discription </th>
                                                                <th>url </th>
                                                                <th width="10%">Action </th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                this.state.bloglist.map((item, i) =>

                                                                    <tr key={i}>


                                                                        <td >{item.cat_name}</td>
                                                                        <td >{item.page_title}</td>
                                                                        <td>{item.meta}</td>
                                                                        <td>
                                                                            <Link className="mr-3 text-success" target="_blank" to={'/blog/' + item.url}>
                                                                                {item.url} </Link></td>

                                                                        <td>

                                                                            <Link className="mr-3" to={'/admin/edit-blog/' + item.id}>
                                                                                <FontAwesomeIcon icon={faEdit} />
                                                                            </Link>

                                                                            <span style={{ cursor: 'pointer' }} onClick={() => this.deleteFaqList(item.id)} className="mr-3 text-danger">
                                                                                <FontAwesomeIcon icon={faTrash} />
                                                                            </span>

                                                                            <Link className="mr-3 text-success" target="_blank" to={'/blog/' + item.url}>
                                                                                <FontAwesomeIcon icon={faEye} />
                                                                            </Link>



                                                                        </td>




                                                                    </tr>

                                                                )
                                                            }


                                                        </tbody>
                                                    </table>
                                                </div>
                                                :
                                                <div className="text-center">
                                                    <div className="card-body">
                                                        <img alt="Empty Data" className="project-empty-img" src={EmptyImg} />
                                                        <p>No Any Login History </p>

                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <AdminFooter />
                </div>
            </div>

        );
    }
}
export default BlogList;