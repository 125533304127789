import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'
import AdminHeader from "../Header/AdminHeader";
import Dialog from 'react-dialog';
import AdminFooter from "../Footer/AdminFooter";
import AdminLeftMenu from "../Navbar/AdminLeftMenu";
import EmptyImg from "../Dashboard/assets/img/create-project.png";
import { HOST_URL } from '../Dashboard/api/Api';
import { userloginDetails } from '../Utils/AdminAuth';
import Select from "react-select";
import AdvanceDataTable from '../UI/Table/AdvanceDataTable';
import { ENTITY_MANAGEMENT_LIST } from '../UI/Table/ColumnConst';

class EntityManagement extends Component {

    constructor() {

        // const lStorage = JSON.parse(localStorage.getItem("login"));
        // const userId = lStorage.user.userId;
        // const userToken = lStorage.token;

        const lStorage = userloginDetails();
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;
        const adminType = lStorage.user.isAdmin;

        super();
        this.state = {
            user_id: userId,
            token: userToken,
            type: adminType,
            entity_id: '',
            updateId: '',
            ret_user_id: '',
            deleteID: '',
            delete_entity_id: '',
            company_name: '',
            updateEntity: false,
            enitytList: null,
            errorMessage: '',
            isDialogOpen: false,
            successMessage: false,
            bgColor: "alert alert-success alert-dismissible",
            chainid: "",
            operator: "",
            allUsers: [],
            selectedUser: "",
            searchData: "",
            currentPage: 1,
            recordsPerPage: 100,
        }
    }

    componentDidMount() {

        this.ListOfEnlityId();
        this.getUserList();

    }

    getUserList = async () => {
        let response = await fetch(`${HOST_URL}/admin_user_list.php?user_id=${this.state.user_id}&method=retrieve_all_user&type=${this.state.type}&token=${this.state.token}`);
        let result = await response.json();

        if (result.success) {
            // console.log(result.data)
            const formattedUsers = result.data.map(user => ({
                value: user.id,
                label: user.email
            }));

            this.setState({ allUsers: formattedUsers });
        }
    }


    ListOfEnlityId() {
        fetch(`${HOST_URL}/admin_user_list.php?user_id=${this.state.user_id}&method=retrieve_entity&token=${this.state.token}&type=${this.state.type}`).then((response) => {
            response.json().then((result) => {
                // console.log(result)
                if (result.success === true) {
                    this.setState({ enitytList: result.data });
                }

            })
        })
    }

    ChangeEntityStatus(id, userid, status) {
        fetch(`${HOST_URL}/admin_user_list.php?user_id=${this.state.user_id}&method=active_inactive_entity&token=${this.state.token}&type=${this.state.type}&ret_user_id=${userid}&id=${id}&status=${status}`).then((response) => {
            response.json().then((result) => {
                this.ListOfEnlityId();

            })
        })
    }

    CreateEntityId() {

        var entidyData = {
            user_id: this.state.user_id,
            token: this.state.token,
            entity_id: this.state.entity_id,
            method: 'add_entity',
            company_name: this.state.company_name,
            chainid: this.state.chainid,
            operator: this.state.operator,
            ret_user_id: this.state.selectedUser.value,
            type: this.state.type
        };

        fetch(`${HOST_URL}/admin_user_list.php`, {
            method: "post",
            headers: {
                'content-Type': 'application/json'
            },
            body: JSON.stringify(entidyData)
        }).then((result) => {
            result.json().then((resp) => {
                if (resp.success === true) {
                    this.setState({ successMessage: true, bgColor: "alert alert-success alert-dismissible", errorMessage: resp.message });
                    document.getElementById("EntityFormidFrom").reset();
                    this.ListOfEnlityId();
                    setTimeout(() => {
                        this.setState({ successMessage: false })
                    }, 500)
                } else {
                    this.setState({ bgColor: "alert alert-danger alert-dismissible", successMessage: true, errorMessage: resp.message });
                }
            })
        })
    }

    EditEntityID(id, userid) {
        fetch(`${HOST_URL}/admin_user_list.php?user_id=${this.state.user_id}&method=retrieve_entity_id&id=${id}&token=${this.state.token}&type=${this.state.type}&ret_user_id=${userid}`).then((response) => {
            response.json().then((result) => {
                // console.log(result)
                if (result.success) {
                    this.setState({
                        updateId: id,
                        ret_user_id: userid,
                        updateEntity: true,
                        entity_id: result.data[0].entity_id,
                        company_name: result.data[0].company_name,
                        chainid: result.data[0].chainid,
                        operator: result.data[0].operator,
                    });

                    const user = this.state.allUsers.find(x => x.value === result.data[0].user_id);
                    if (user) {
                        this.setState({
                            selectedUser: { label: user.label, value: user.value }
                        });
                    }
                }
            })
        })
    }


    DeleteEntityID(id) {
        fetch(`${HOST_URL}/entityid.php?user_id=${this.state.user_id}&method=delete&id=${id}&entity_id=${this.state.delete_entity_id}&token=${this.state.token}`).then((response) => {
            // console.log(response);
            response.json().then((result) => {
                if (result.success === true) {
                    this.setState({ successMessage: true, bgColor: "alert alert-success alert-dismissible", deleteID: '' });
                    this.setState({ errorMessage: result.message });
                    this.ListOfEnlityId();
                    this.handleClose();
                } else {
                    this.setState({ successMessage: true });
                    this.setState({ bgColor: "alert alert-danger alert-dismissible" });
                    this.setState({ errorMessage: result.message });
                }
            })
        })
    }

    UpdateEntityID() {
        fetch(`${HOST_URL}/admin_user_list.php?user_id=${this.state.user_id}&method=update_entity&id=${this.state.updateId}&entity_id=${this.state.entity_id}&company_name=${this.state.company_name}&chainid=${this.state.chainid}&operator=${this.state.operator}&token=${this.state.token}&ret_user_id=${this.state.ret_user_id}&type=${this.state.type}`).then((response) => {
            response.json().then((result) => {
                if (result.success === true) {
                    this.setState({ successMessage: true, bgColor: "alert alert-success alert-dismissible" });
                    this.setState({ errorMessage: result.message });
                    document.getElementById("EntityFormidFrom").reset();
                    this.ListOfEnlityId();
                    setTimeout(() => {
                        window.location.reload();
                    }, 500)
                    // this.setState({})
                } else {
                    this.setState({ successMessage: true });
                    this.setState({ bgColor: "alert alert-danger alert-dismissible" });
                    this.setState({ errorMessage: result.message });
                }
            })
        })

    }

    openDialog = (id, entity_id) => this.setState({
        isDialogOpen: true,
        deleteID: id,
        delete_entity_id: entity_id
    })

    handleClose = () => this.setState({ isDialogOpen: false, deleteID: '', delete_entity_id: '' })

    customStyles = {
        control: (provided) => ({
            ...provided,
            cursor: "pointer",
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        option: (style) => ({
            ...style,
            fontSize: "12px",
        }),
    };

     handlePagination = (pageNumber) => {
        this.setState({currentPage : pageNumber})
    };


    render() {
        const indexOfLastRecord = this.state.currentPage * this.state.recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - this.state.recordsPerPage;
        const currentRecords = this?.state?.enitytList?.slice(indexOfFirstRecord, indexOfLastRecord);
        const totalPages = Math.ceil(this?.state?.enitytList?.length / this.state.recordsPerPage);
        return (

            <div>
                <div className="wrapper">
                    <AdminLeftMenu />
                    <div className="main">
                        <AdminHeader />
                        <main className="content">
                            <div className="card">
                                <div className="card-body">

                                    <div className="container-fluid p-0">

                                        {
                                            this.state.isDialogOpen &&

                                            <Dialog
                                                title="Alert"
                                                modal={true}
                                                // onClose={this.handleClose}
                                                buttons={
                                                    [{
                                                        text: "Close",
                                                        className: 'btn-info btn-sm btn mr-2',
                                                        // onClick: () => this.handleClose()
                                                    },
                                                    {
                                                        text: "ok",
                                                        className: 'btn btn-sm btn-danger',
                                                        // onClick: () => this.DeleteEntityID(this.state.deleteID)
                                                    }

                                                    ]


                                                }>
                                                <p>Are You Sure ? This action will also delete all sender ids associated with this entity id </p>
                                            </Dialog>
                                        }

                                        <div className="">

                                            <div className="row">




                                                <div className="col-md-12">

                                                    {
                                                        this.state.successMessage ?
                                                            <div className={this.state.bgColor} role="alert">
                                                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                                    <span aria-hidden="true">×</span>
                                                                </button>
                                                                <div className="">
                                                                    {this.state.errorMessage}
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }


                                                    <h5 className="card-title">Entity Management</h5>

                                                    <div className="">
                                                        <form className="form-row" id="EntityFormidFrom">
                                                            <div className="mb-3  col-md-6">
                                                                <Select
                                                                    onChange={(selectedOption) => this.setState({ selectedUser: selectedOption })}
                                                                    options={this.state.allUsers}
                                                                    value={this.state.selectedUser}
                                                                    isSearchable={true}
                                                                    placeholder="All Users"
                                                                    styles={this.customStyles}
                                                                    isDisabled={this.state.updateId ? true : false}
                                                                />
                                                            </div>
                                                        </form>

                                                        <form className="form-row" id="EntityFormidFrom">
                                                            <div className="mb-3  col-md-6">
                                                                <input type="text" defaultValue={this.state.company_name} onChange={(event) => this.setState({ company_name: event.target.value })} placeholder="Company Name" className="form-control" id="inputPasswordNew2" />
                                                            </div>

                                                            <div className="mb-3 col-md-6">
                                                                <input type="text" defaultValue={this.state.entity_id} onChange={(event) => this.setState({ entity_id: event.target.value })} placeholder="Enter Entity ID" className="form-control" id="inputPasswordNew2" />
                                                            </div>


                                                        </form>

                                                        <form className="form-row" id="EntityFormidFrom">
                                                            <div className="mb-3  col-md-6">
                                                                <input type="text" defaultValue={this.state.chainid} onChange={(event) => this.setState({ chainid: event.target.value })} placeholder="Chain ID" className="form-control" id="inputPasswordNew2" />
                                                            </div>

                                                            <div className="mb-3 col-md-6">
                                                                <select class="form-control" name="operator" value={this.state.operator} onChange={(e) => this.setState({ operator: e.target.value })} >
                                                                    <option value="">Please Select Chain Operator</option>
                                                                    <option value="Airtel">Airtel</option>
                                                                    <option value="Vilpower">Vilpower</option>
                                                                    <option value="Jio">Jio</option>
                                                                    <option value="SmartPing">SmartPing</option>
                                                                    <option value="BSNL">BSNL</option>
                                                                    <option value="MTNL">MTNL</option>
                                                                    <option value="Tata">Tata</option>
                                                                </select>
                                                            </div>
                                                        </form>

                                                        <form className="form-row" id="EntityFormidFrom">
                                                            <div className="col-md-12 d-flex justify-content-center">
                                                                {this.state.updateEntity
                                                                    ? <button type="button" onClick={() => this.UpdateEntityID()} className="btn btn-sm btn-success">Update Entity ID</button>
                                                                    : <button type="button" onClick={() => this.CreateEntityId()} className="btn btn-sm btn-success">Create New Entity</button>
                                                                }
                                                            </div>
                                                        </form>



                                                    </div>
                                                </div>
                                            </div>

                                            <div className="">
                                                <div className="">

                                                    <div className="p-2">

                                                        <div className="">
                                                            <div className=" flex-fill">
                                                                {this.state.enitytList ?
                                                                    <>
                                                                        <div className='row mb-3'>
                                                                            <div className='col-6'></div>
                                                                            <div className='col-6'>
                                                                                <div className="input-group float-right">
                                                                                    <div className="input-group-prepend">
                                                                                        <span className="input-group-text"><FontAwesomeIcon icon={faSearch} /></span>
                                                                                    </div>
                                                                                    <input
                                                                                        type="text"
                                                                                        id="searchInput"
                                                                                        name="searchdata"
                                                                                        value={this.state.searchData}
                                                                                        onChange={(e) => this.setState({ searchData: e.target.value })}
                                                                                        className="form-control"
                                                                                        placeholder="Search"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="table-responsive">
                                                                            <table className="table table-striped table-sm">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th className=" d-xl-table-cell"> Action </th>
                                                                                        <th className=" d-md-table-cell">Status</th>
                                                                                        <th className=" d-md-table-cell">Entity ID</th>
                                                                                        <th className=" d-md-table-cell">User</th>
                                                                                        <th className=" d-md-table-cell">Company Name</th>
                                                                                        <th className=" d-md-table-cell">Chain ID</th>
                                                                                        <th className=" d-md-table-cell">Operator</th>
                                                                                        <th className=" d-xl-table-cell">Created Date / Time </th>

                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        currentRecords.filter((x) => {
                                                                                            return this.state.searchData.toLowerCase() === '' ? x : x.entity_id.toLowerCase().includes(this.state.searchData);
                                                                                        }).map((item, i) =>
                                                                                            <tr key={i}>

                                                                                                <td>
                                                                                                    <span onClick={() => this.EditEntityID(item.id, item.user_id)} className="badge bg-primary mr-1"> <FontAwesomeIcon icon={faEdit} /> </span>
                                                                                                </td>
                                                                                                <td className=" d-xl-table-cell">{item.chain_status === 0 ? <span onClick={() => this.ChangeEntityStatus(item.id, item.user_id, 1)} className="badge bg-danger">No</span> : <span onClick={() => this.ChangeEntityStatus(item.id, item.user_id, 0)} className="badge bg-success">Yes</span>}</td>
                                                                                                <td className=" d-xl-table-cell">{item.entity_id}</td>
                                                                                                <td className="d-xl-table-cell">
                                                                                                    {this.state.allUsers.find(x => x.value === item.user_id)?.label}
                                                                                                </td>
                                                                                                <td className=" d-xl-table-cell">{item.company_name}</td>
                                                                                                <td className=" d-xl-table-cell">{item.chainid}</td>
                                                                                                <td className=" d-xl-table-cell">{item.operator}</td>
                                                                                                <td className=" d-md-table-cell">{item.created}</td>

                                                                                            </tr>
                                                                                        )}
                                                                                </tbody>
                                                                            </table>                                                                            
                                                                        </div>
                                                                        <ul className="pagination mt-3">
                                                                                {Array.from({ length: totalPages }).map((_, index) => (
                                                                                    <li key={index} className={`page-item ${this.state.currentPage === index + 1 ? 'active' : ''}`}>
                                                                                        <button className="page-link" onClick={() => this.handlePagination(index + 1)}>
                                                                                            {index + 1}
                                                                                        </button>
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                    </> :
                                                                    <div className="text-center">
                                                                        <div className="card-body">
                                                                            <img alt="Empty Data" className="project-empty-img" src={EmptyImg} />
                                                                            <p>No Entity ID Yet</p>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {/* {this.state.enitytList && 
                                                                <AdvanceDataTable
                                                                    tableName={""}
                                                                    tableCell={ENTITY_MANAGEMENT_LIST}
                                                                    tableData={this.state.enitytList}
                                                                    pagination={true}
                                                                    EditEntityID={this.EditEntityID}
                                                                    userList = {this.state.allUsers}
                                                                    // isLoading={this.state.setIsLoading}
                                                                    // isError={this.state.ErrorResponse}
                                                                />} */}
                                                                
                                                            
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                        <AdminFooter />
                    </div>
                </div>
            </div>

        );
    }
}

export default EntityManagement;