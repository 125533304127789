import React, { useState } from "react";
import EmptyImg from "../../Dashboard/assets/img/create-project.png";
import DashboardLeftMenu from "../DashboardLeftMenu";
import DashboardHeader from "../../Header/DashboardHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEye,

} from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";
import { NODE_HOST_URL } from "../api/Api";

const FixedUrlReport = () => {
  const lStorage = JSON.parse(localStorage.getItem("login"));
  const userId = lStorage.user.userId;
  const userToken = lStorage.token;
  const date = new Date();

  const [error, setError] = useState({
    errorStatus: false,
    errorMessage: "",
    bgColor: "",
  });
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(date);
  const [channel, setChannel] = useState("");
  const [urlDataReport, setUrlDataReport] = useState([]);
  const [disable , setDisable] = useState(false)

  const addDays = (date, days) => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + days);
    return newDate;
  };

  const onChange = (e) => {
    setChannel(e.target.value);
    setError({ errorStatus: false });
  };

  const isValid = () => {
    if (channel === undefined || channel === "") {
      setError({
        errorStatus: true,
        errorMessage: "Please Select Channel",
        bgColor: "alert alert-danger",
      });
      return false;
    } else {
      return true;
    }
  };

  const urlDetails = async () => {
    if (isValid()) {
      try {
        let response = await fetch(
          `${NODE_HOST_URL}/url_fixed_track?user_id=${userId}&method=fixed_url_summary&token=${userToken}&channel=${channel}&fromdate=${currentDate(
            startDate
          )}&todate=${currentDate(endDate)}`
        );
        let result = await response.json();
        setDisable(true)
        if (result.success) {
          setUrlDataReport(result.data);
          setDisable(false)
        } else {
          setError({
            errorStatus: true,
            errorMessage: result.message,
            bgColor: "alert alert-danger",
          });
          setDisable(false)

          setTimeout(() => {
            setError({
              errorStatus: false,
            });
          }, 2000);
        }
      } catch (error) {
        setDisable(false)
        console.log(error);
      }
    }
  };

  const currentDate = (currentDate) => {
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const sendData = (short_url) => {
    const url = `/dashboard/fixed-url-report/${channel}/${currentDate(
      startDate
    )}/${currentDate(endDate)}/${encodeURIComponent(short_url)}`;
    window.open(url, "_blank");
  };

  return (
    <div className="wrapper">
      <DashboardLeftMenu />
      <div className="main">
        <DashboardHeader />
        <main className="content">
          <div className="card">
            <div className="card-body">
              <div className="container-fluid p-0">
                <div className="row">
                  <div className="col-md-12">
                    {error.errorStatus && (
                      <div className={error.bgColor} role="alert">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                        <div>{error.errorMessage}</div>
                      </div>
                    )}
                    <h5 className="card-title">Fixed Url Report</h5>
                    <form className="form-row" id="EntityFormidFrom">
                      <div className="mb-3 col-md-4">
                        <label>Select Channel</label>
                        <select
                          class="form-control"
                          name="channel"
                          value={channel}
                          onChange={onChange}
                        >
                          <option value="">Select Channel</option>
                          <option value="whatsapp">Whatsapp</option>
                          {/* <option value="sms">Sms</option>
                                                    <option value="rcs">Rcs</option> */}
                        </select>
                      </div>

                      <div className="mb-3 col-12 col-md-4">
                        <label className="ml-4 d-block">From Date</label>
                        <ReactDatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={startDate}
                          className="form-control ms-2 ml-4"
                          onChange={(date) => setStartDate(date)}
                          selectsStart
                          startDate={startDate}
                        />
                      </div>

                      <div className="mb-3 col-12 col-md-4">
                        <label className="ml-4 d-block">To Date</label>
                        <ReactDatePicker
                          dateFormat="dd/MM/yyyy"
                          className="form-control ms-2 ml-4"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          minDate={startDate}
                          maxDate={addDays(startDate, 30)}
                        />
                      </div>
                    </form>

                    <form className="form-row" id="EntityFormidFrom">
                      <div className="col-md-12 d-flex justify-content-center">
                        <button
                          type="button"
                          className="btn btn-sm btn-success"
                          onClick={urlDetails}
                          disabled={disable ? true : false}
                        >
                          Search
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="">
                  <div className="p-2">
                    <div className="flex-fill">
                      {urlDataReport.length > 0 ? (
                        <div className="table-responsive">
                          <table className="table table-striped table-sm">
                            <thead>
                              <tr>
                                <th className=" d-xl-table-cell"> User Id </th>
                                <th className=" d-md-table-cell">Short Url</th>
                                <th className=" d-md-table-cell">
                                  Total Click
                                </th>
                                <th className=" d-xl-table-cell">
                                  Selected Date{" "}
                                </th>
                                <th className=" d-xl-table-cell">Action </th>
                              </tr>
                            </thead>
                            <tbody>
                              {urlDataReport.map((item, i) => (
                                <tr key={i}>
                                  <td className=" d-xl-table-cell">
                                    {item.user_id}
                                  </td>
                                  <td className=" d-xl-table-cell">
                                    {item.short_url}
                                  </td>
                                  <td className=" d-xl-table-cell">
                                    {item.count}
                                  </td>
                                  <td className=" d-md-table-cell">
                                    {item.created}
                                  </td>
                                  <td>
                                    <a
                                      href={`${NODE_HOST_URL}/url_fixed_track?user_id=${userId}&method=fixed_url_download_summary&token=${userToken}&channel=${channel}&fromdate=${currentDate(
                                        startDate
                                      )}&todate=${currentDate(
                                        endDate
                                      )}&short_url=${item.short_url}`}
                                      download
                                      target="_blank"
                                    >
                                      <span className="badge bg-primary mr-1">
                                        <FontAwesomeIcon icon={faDownload} />
                                      </span>
                                    </a>
                                    <span
                                      onClick={() => sendData(item.short_url)}
                                      className="badge bg-info"
                                    >
                                      <FontAwesomeIcon icon={faEye} />
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>{" "}
                        </div>
                      ) : (
                        <div className="text-center">
                          <div className="card-body">
                            <img
                              alt="Empty Data"
                              className="project-empty-img"
                              src={EmptyImg}
                            />
                            <p>Data Not Found</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default FixedUrlReport;
