import React, { Component } from "react";
import DashboardLayout from "../../UI/Layout/DashboardLayout";
import Title from "../../UI/Text/Title";
import Card from "../../UI/Card/Card";
import WhatsAppPreview from "../whatsapp/components/WhatsAppPreview";
import ReactTelInput from "react-telephone-input";
import flags from "../../Dashboard/assets/img/flags.png";
import { WHATSAPP_TEMPLATE_LIST } from "../../../constants/ServiceConstants";
import api from "../../../axios/Axios";
import { no_data } from "../../../images/WhatsAppImages";
import Alert from "../../UI/Alert/Alert";
import { HOST_URL, } from "../api/Api";
import { WhstappAccountList } from "../../Dashboard/api/Api";
class SendWhatsappMessageSingle extends Component {
    constructor() {
        const lStorage = JSON.parse(localStorage.getItem("login"));
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;
        super();

        this.state = {
            user_id: userId,
            token: userToken,
            recipientNumber: "",
            authkey: "",
            UserDetails: "",
            templatePreView: "",
            showButton: false,
            WhatsAppTemplate: [],
            wid: "",
            message: "",
            successMessage: false,
            errorMessage: "",
            bgColor: "",
            defaultCountry: "in",
            countryCode: "91",
            whatsapplimittotal: "",
            showLimits: false,
            route: '',
            file: null,
            paramData: [],
            formData: {},
            url: '',
            fileName: '',
            headerVariable: { headerFileName: "", headerData: "" },
            disableButton: false,
            template_Type: '',
            media_type:'',
        };
    }

    getRoute = async () => {
        try {
            let response = await fetch(`${HOST_URL}/get_user_route.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`)
            let result = await response.json()
            if (result.success) {
                this.setState({ route: result.data })
            }
        } catch (error) {

        }

    }

    handleInputChange = (telephone, selectedCountry) => {
        if (selectedCountry) {
            const { dialCode, iso2 } = selectedCountry;
            this.setState({
                countryCode: dialCode,
                selectedCountryCode: iso2,
            });
        }
    };

    componentDidMount() {
        this.getTemplateList();
        this.getRoute();
        this.getUserDetails();
        WhstappAccountList().then((res) => {
            if (res.data.data === undefined) {
                this.setState({ showLimits: true });
            } else {
                this.setState({ showLimits: false });
            }
        });
    }

    getTemplateList = () => {
        WhstappAccountList().then((res) => {
            if (res.data.data === undefined) {
                fetch(
                    `${HOST_URL}/whatsapp_template.php?user_id=${this.state.user_id}&method=retrieve_for_test&token=${this.state.token}`
                ).then((response) => {
                    response.json().then((result) => {
                        if (result.success === true) {
                            result.data.map((item) => {
                                if (item.temp_status === 1) {
                                    this.setState({
                                        WhatsAppTemplate: [...this.state.WhatsAppTemplate, item],
                                    });
                                }
                            });
                        } else {
                            this.setState({
                                successMessage: true,
                                bgColor: "alert alert-danger alert-dismissible",
                                errorMessage: result.message,
                            });
                        }
                    });
                });
            } else {
                fetch(
                    `${HOST_URL}/whatsapp_template.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`
                ).then((response) => {
                    response.json().then((result) => {
                        if (result.success === true) {
                            result.data.map((item) => {
                                if (item.temp_status === 1) {
                                    this.setState({
                                        WhatsAppTemplate: [...this.state.WhatsAppTemplate, item],
                                    });
                                }
                            });
                        } else {
                            this.setState({
                                successMessage: true,
                                bgColor: "alert alert-danger alert-dismissible",
                                errorMessage: result.message,
                            });
                        }
                    });
                });
            }
        });
    };

    getUserDetails = () => {
        fetch(
            `${HOST_URL}/profile.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.success === true) {
                    const userDetails = result.data[0];
                    const testwhatsapplimit = userDetails.test_whatsapp_limit1 || 0;
                    const testwhatsappsent = userDetails.test_whatsapp_sent || 0;
                    const desiredParameterValue = Math.max(
                        testwhatsapplimit - testwhatsappsent,
                        0
                    );

                    this.setState({
                        UserDetails: userDetails,
                        testwhatsapplimits: testwhatsapplimit,
                        test_whatsapp_sent: testwhatsappsent,
                        desiredParameterValue: desiredParameterValue.toString(),
                    });
                } else {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        errorMessage: result.message,
                    });
                }
            })
            .catch((error) => {
                console.error("Error fetching user details:", error);
            });
    };

    updatetextwhatsapplimit = () => {
        fetch(
            `${HOST_URL}/profile.php?user_id=${this.state.user_id}&method=update_limit&token=${this.state.token}`
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.success === true) {
                    this.getUserDetails();
                } else {
                    console.error(
                        "Failed to update WhatsApp template limit:",
                        result.message
                    );
                }
            })
            .catch((error) => {
                console.error("Error updating WhatsApp template limit:", error);
            });
    };

    sendWhatsAppTestMessage = async () => {
        this.setState({ disableButton: true });
        try {
            // Set isLoading to true
            this.setState({ setIsLoading: true });
            console.log("Hello World")

            // if (!this.state.wid) {
            //     this.setState({
            //         errorMessage: "Please select a WhatsApp Template.",
            //         successMessage: true,
            //         bgColor: "alert alert-danger alert-dismissible",
            //     });
            //     return;
            // }

            // if (this.state.template_Type === 'Media' && !this.state.file) {
            //     this.setState({
            //         errorMessage: "Please Choose a File.",
            //         successMessage: true,
            //         bgColor: "alert alert-danger alert-dismissible",
            //     });
            //     return;
            // }


            // if (!this.state.recipientNumber.trim()) {
            //     this.setState({
            //         errorMessage: "Recipient Mobile Number is required.",
            //         successMessage: true,
            //         bgColor: "alert alert-danger alert-dismissible",
            //     });
            //     return;
            // } else {
            //     const mobileNumberRegex = /^[0-9]{10}$/; 

            //     if (!mobileNumberRegex.test(this.state.recipientNumber.trim())) {
            //         this.setState({
            //             errorMessage: "Please enter a valid 10-digit mobile number.",
            //             successMessage: true,
            //             bgColor: "alert alert-danger alert-dismissible",
            //         });
            //         return;
            //     }
            // }

            let param = {
                mobile: this.state.recipientNumber,
                wid: this.state.wid,
                country_code: this.state.countryCode,
                type: this.state.template_Type,
                bodyValues: this.state.formData,
                headerValues: this.state.headerVariable,
            };
            console.log(param)

            const res = await WhstappAccountList();

            let authkey = "";
            let response = null;

            if (res.data.data === undefined) {
                response = await fetch("https://console.authkey.io/restapi/requestjson.php", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json; charset=UTF-8",
                        'Authorization': `Basic 523912703d2967f9`,
                    },
                    body: JSON.stringify(param),
                });
            } else {
                authkey = this.state.UserDetails.authkey;
                response = await fetch("https://console.authkey.io/restapi/requestjson.php", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json; charset=UTF-8",
                        'Authorization': `Basic ${authkey}`,
                    },
                    body: JSON.stringify(param),
                });
            }


            if (!response.ok) {
                throw new Error('Failed to send the message');
            }

            const result = await response.json();


            this.updatetextwhatsapplimit();


            window.scrollTo(0, 0);


            if (result.status === "Success") {
                this.setState({
                    errorMessage: result.Message,
                    successMessage: true,
                    bgColor: "alert alert-success alert-dismissible",
                    recipientNumber: "",
                    wid: "",
                    paramData: [],
                    formData: {},
                    headerVariable: {},
                    file: null,
                    disableButton: false
                });

                // setTimeout(() => {
                //     window.location.reload();
                // }, 3000);
            } else {
                this.setState({
                    errorMessage: "Failed to send message. Please try again.",
                    successMessage: true,
                    bgColor: "alert alert-danger alert-dismissible",
                });
            }

            console.log("Is Loading set to false");

        } catch (error) {
            console.error(error);
            this.setState({
                errorMessage: "An error occurred. Please try again.",
                successMessage: true,
                bgColor: "alert alert-danger alert-dismissible",
            });
        } finally {
            // Set isLoading to false
            this.setState({ setIsLoading: false });
        }
    };


    selectedTemplate = async (e) => {
        this.setState({ wid: e.target.value });

        const filterTemplate = this.state.WhatsAppTemplate.filter(x => x.id == e.target.value);

        const bodyVariables = filterTemplate[0].temp_body.match(/{{([^}]+)}}/g);
        console.log(filterTemplate)
        const mediaData = JSON.parse(filterTemplate[0].temp_header);
        this.setState({ bodyVar: bodyVariables, template_Type: filterTemplate[0]?.temp_type, media_type: mediaData?.TemplateType })
        this.extractData(filterTemplate[0].temp_body);


        try {
            const { data } = await api.post(WHATSAPP_TEMPLATE_LIST, {
                token: this.state.token,
                user_id: this.state.user_id,
                id: e.target.value,
                method: "retrieveid",
            });
            let urlbtn = [];
            let urlbtn2 = [];
            let callbtn = [];
            let button1 = [];
            let button2 = [];
            let button3 = [];
            if (data.success === true) {
                // console.log(data)
                if (data.data[0].temp_button) {
                    let buttonData = data.data[0].temp_button;
                    buttonData.map((item, index) => {
                        let key = Object.keys(item);
                        if (key.includes("urltext2")) {
                            urlbtn2[0] = item;
                        }
                        if (key.includes("urltext")) {
                            urlbtn[0] = item;
                        }
                        if (key.includes("phone")) {
                            callbtn[0] = item;
                        }
                        if (key.includes("button1")) {
                            button1[0] = item;
                        }
                        if (key.includes("button2")) {
                            button2[0] = item;
                        }
                        if (key.includes("button3")) {
                            button3[0] = item;
                        }
                    });
                }

                let PreviewTemplateData = {
                    tampleName: data.data[0].temp_name,
                    language: data.data[0].temp_language,
                    accountType: data.data[0].temp_category,
                    templateType: data.data[0].temp_type,
                    headerText: data.data[0].temp_header,
                    headerOptions: data.data[0].temp_header === "" ? false : true,
                    footerText: data.data[0].temp_footer,
                    footerOptions: data.data[0].temp_header === "" ? false : true,
                    temp_button: data.data[0].temp_button,
                    bodyMessage: data.data[0].temp_body,
                    button: data.data[0].temp_button,
                    temp_status: data.data[0].temp_status,
                    Urlbutton: urlbtn,
                    Urlbutton2: urlbtn2,
                    Callbutton: callbtn,
                    button1: button1,
                    button2: button2,
                    button3: button3,
                    quickButton:
                        data.data[0].temp_button.length > 0 &&
                            data.data[0].temp_button[0].actionType === "quickReply"
                            ? [data.data[0].temp_button[0]]
                            : "",
                    temp_attribute: [],
                    isLoading: false,
                };
                this.setState({ templatePreView: PreviewTemplateData });
            }
        } catch (err) {
            console.log(err);
        }
    };

    extractData = (data) => {
        const pattern = /{{([^}]+)}}/g;
        const matches = [];
        let match;
        while ((match = pattern.exec(data)) !== null) {
            matches.push(match[1]);
        }
        this.setState({ paramData: matches })
        return matches;
    };

    handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
    
        if (selectedFile) {
            const fileType = selectedFile.type;
            const fileNameParts = selectedFile.name.split('.');
            const mediaName = fileNameParts.slice(0, fileNameParts.length - 1).join('.');
            const mediaType = fileNameParts[fileNameParts.length - 1];
    
            // Set disableButton to false at the start to enable the button for a valid file
            this.setState({ disableButton: false });
    
            // Check for the selected media type and file format accordingly
            if (this.state.media_type === 'image') {
                // Check for image formats (jpeg, png)
                if (fileType === 'image/jpeg' || fileType === 'image/png') {
                    this.setState({ file: selectedFile });
                } else {
                    this.setState({
                        errorMessage: "Please upload an image (jpg, jpeg, png).",
                        successMessage: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        disableButton: true // Disable the button if validation fails
                    });
                    this.setState({ file: null });
                    return;
                }
            } else if (this.state.media_type === 'document') {
                // Check for PDF file type
                if (fileType === 'application/pdf') {
                    this.setState({ file: selectedFile });
                } else {
                    this.setState({
                        errorMessage: "Please upload a PDF file.",
                        successMessage: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        disableButton: true // Disable the button if validation fails
                    });
                    this.setState({ file: null });
                    return;
                }
            } else if (this.state.media_type === 'video') {
                // Check for video formats (mp4, mov)
                if (fileType === 'video/mp4' || fileType === 'video/mov') {
                    this.setState({ file: selectedFile });
                } else {
                    this.setState({
                        errorMessage: "Please upload a video (mp4, mov).",
                        successMessage: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        disableButton: true // Disable the button if validation fails
                    });
                    this.setState({ file: null });
                    return;
                }
            } else {
                // Handle unsupported media type
                this.setState({
                    errorMessage: "Unsupported media type selected.",
                    successMessage: true,
                    bgColor: "alert alert-danger alert-dismissible",
                    disableButton: true // Disable the button if validation fails
                });
                this.setState({ file: null });
                return;
            }
    
            // Prepare FormData for file upload
            this.setState({disableButton: true})
            const data = new FormData();
            data.append("amdfile", selectedFile);
            data.append("doc_name", mediaName);
            data.append("doc_type", mediaType);
            data.append("user_id", this.state.user_id);
            data.append("token", this.state.token);
            data.append("method", "create");
    
            // Send the file to the server via fetch
            fetch(`${HOST_URL}/uploadFileWhatsapp.php`, {
                method: "POST",
                body: data,
            }).then((result) => {
                result.json().then((resp) => {
                    if (resp.success === true) {
                        this.setState({
                            url: resp.url,
                            fileName: mediaName,
                            headerVariable: { headerFileName: mediaName, headerData: resp.url }
                        });
                        this.setState({disableButton: false})
                    } else {
                        alert("Error uploading file: " + resp.message);
                    }
                });
            });
        } else {
            alert("No file selected.");
            this.setState({ file: null, disableButton: true }); // Reset disableButton if no file is selected
        }
    };
    
    
    

    ChangeVariable = (event) => {
        const { name, value } = event.target;
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                [name]: value,
            },
        }));
    };

    render() {
        const { defaultCountry, showLimits } = this.state;
        const { testwhatsapplimits, test_whatsapp_sent } = this.state;
        console.log(this.state.media_type)
        console.log(this.state.file)

        return (
            <DashboardLayout>
                <main className="content">
                    <div className="container-fluid p-0">
                        <Title> WhatsApp </Title>
                        <div className="row">
                            <div className="col-md-8">
                                {this.state.successMessage && (
                                    <Alert type={this.state.bgColor}>
                                        {this.state.errorMessage}
                                    </Alert>
                                )}

                                <Card CardTitle={`Send test message on whatsApp`}>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label
                                                    for="colFormLabel"
                                                    className="col-form-label"
                                                >
                                                    Select WhatsApp Template
                                                </label>
                                                <div className="input-group drop-down-icons mb-2">
                                                    <select
                                                        value={this.state.wid}
                                                        onChange={this.selectedTemplate}
                                                        className="form-control"
                                                    >
                                                        <option value="">Select Template</option>
                                                        {this.state.WhatsAppTemplate.map((item, index) => (
                                                            <option key={index} value={item.id}>
                                                                {item.temp_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                {showLimits && (
                                                    <div className="form-group">
                                                        {/* ... (rest of your form-group code) */}
                                                        <p className="text-sm">
                                                            Current Sent Limit:{" "}
                                                            <span className="text-success">
                                                                {testwhatsapplimits}
                                                            </span>
                                                        </p>
                                                        <p className="text-sm mb-0">
                                                            Used Sent Limit:{" "}
                                                            <span className="text-danger">
                                                                {test_whatsapp_sent}
                                                            </span>
                                                        </p>
                                                        {testwhatsapplimits == test_whatsapp_sent && (
                                                            <span className="alert alert-danger mt-3 mb-0">
                                                                Please Contact the Person
                                                            </span>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {this.state.paramData.map((item, x) => (
                                            <div className="input-group mb-2 mr-sm-2" key={x}>
                                                <div className="input-group-text w-25">
                                                    {`{{${item}}}`}
                                                </div>
                                                <input type="text" name={item} placeholder={`Enter {{${item}}}`} onChange={this.ChangeVariable} className="form-control" />
                                            </div>
                                        ))}

                                        {this.state.template_Type === "Media" ? <div className="input-group mb-2 mt-4 mr-sm-2">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Image/Document Upload</div>
                                            </div>
                                            <input
                                                type="file"
                                                name="file"
                                                accept=".jpg, .jpeg, .png, .pdf, .mp4, .mov"
                                                onChange={this.handleFileChange}
                                                className="form-control"
                                            />
                                        </div> : null}


                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label for="colFormLabel" className="col-form-label">
                                                    Recipient Mobile Number
                                                </label>

                                                <div className="">
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="">
                                                                <ReactTelInput
                                                                    defaultCountry={defaultCountry}
                                                                    flagsImagePath={flags}
                                                                    onChange={this.handleInputChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={this.state.recipientNumber}
                                                            onChange={(event) =>
                                                                this.setState({
                                                                    recipientNumber: event.target.value,
                                                                })
                                                            }
                                                            id="inlineFormInputGroup"
                                                            placeholder="Enter whatsApp Number"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {showLimits ? (
                                        <button
                                            type="button"
                                            onClick={() => this.sendWhatsAppTestMessage()}
                                            className="btn btn-sm button-cus"
                                            disabled={
                                                this.state.testwhatsapplimits ===
                                                    this.state.test_whatsapp_sent || this.state.disableButton ? true : false
                                            }
                                        >
                                            Send
                                        </button>
                                    ) : (<button
                                        type="button"
                                        onClick={() => this.sendWhatsAppTestMessage()}
                                        className="btn btn-sm button-cus"
                                        disabled={this.state.disableButton ? true : false}
                                    >
                                        Send
                                    </button>)}

                                </Card>
                            </div>

                            <div className="col-md-4 ">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0"> Template Preview </h5>
                                    </div>
                                    <hr className="m-0" />
                                    <div className="card-body">
                                        {this.state.templatePreView ? (
                                            <WhatsAppPreview
                                                previewData={this.state.templatePreView}
                                                route={this.state.route}
                                            />
                                        ) : (
                                            <div className="text-center">
                                                <img className="img-fluid" src={no_data} />
                                                <p className="text-right">
                                                    Please select WhatsApp Templte to preview
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </DashboardLayout>
        );
    }
}

export default SendWhatsappMessageSingle;
