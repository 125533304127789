import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const Table = ({ headers, rows, setChatOpened = false }) => {

    const handleView = () => {
        setChatOpened(prevState => !prevState);
    }

    return (
        <div className="table-responsive">
            <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                    <tr>
                        {headers.map((header, index) => (
                            <th key={index} className="text-white">{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((cell, cellIndex) => (
                                <td
                                    key={cellIndex}
                                    className={`${cell === "Idle" || cell === "Active"
                                        ? "text-success font-semibold"
                                        : cell === "Busy" || cell === "Inactive"
                                            ? "text-danger font-semibold"
                                            : ""
                                        }${headers[cellIndex] === "History" ? 'cursor-pointer text-primary' : ''} `}
                                    
                                >
                                    {headers[cellIndex] === "History" ? (
                                        <Link to={cell}>
                                            View Report
                                        </Link>
                                    ): headers[cellIndex] === "View Chat"?(
                                        <Link to={cell}>
                                            View 
                                        </Link>
                                    )
                                    : (
                                        cell
                                    )
                                }
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

    )

};

export default Table;