import React, { Component } from "react";
import Dialog from "react-dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import DashboardLayout from "../../UI/Layout/DashboardLayout";
import Axios from "axios";
import { checkUserUrl } from "../../Utils/Utils";
import { HOST_URL } from "../api/Api";

class MyProfile extends Component {
  constructor() {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const url = lStorage.url;

    super();

    this.state = {
      UserDetails: [],
      user_id: userId,
      token: userToken,
      fullname: "",
      url: url,
      address_line_1: "",
      city: "",
      country: "",
      currency: "",
      enable_ip_whitelist: 0,
      userisWhitelable: false,
      whiteLableIP: "",
      mobile: "",
      id: "",
      expiryDate: "",
      otp: "",
      otpScreen: false,
      isDialogOpen: false,
      copySuccess: "Click To Copy",
      successMessage: false,
      errorMessage: "",
      kylas_crm_id: "",
      bgColor: "alert alert-danger alert-dismissible",
    };

    this.updateProfile = this.updateProfile.bind(this);
  }

  componentDidMount() {
    this.getUserDetails();
  }

  getUserDetails() {
    fetch(
      `${HOST_URL}/profile.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        // console.log(result)
        if (result.success === true) {
          this.setState({
            UserDetails: result.data[0],
            id: result.data[0].id,
            fullname: result.data[0].fullname,
            address_line_1: result.data[0].address_line_1,
            city: result.data[0].city,
            country: result.data[0].country,
            currency: result.data[0].currency,
            mobile: result.data[0].mobile,
            expiryDate: result.data[0].expiry,
            whiteLableIP: result.data[0].whitelist_ip,
            kylas_crm_id: result.data[0].kylas_crm_id,
            enable_ip_whitelist: result.data[0].enable_ip_whitelist,
          });
        }
      });
    });
  }

  reGenerateAuthKey() {
    fetch(
      `${HOST_URL}/authkey.php?user_id=${this.state.user_id}&method=update&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        // console.log(result)
        if (result.success === true) {
          document.querySelector("body").scrollTo(0, 0);
          this.handleClose();
          this.setState({
            successMessage: true,
            bgColor: "alert alert-success alert-dismissible",
            errorMessage: result.message,
          });
          this.getUserDetails();
        }
      });
    });
  }

  whiteLabelHandlar(e) {
    const { checked } = e.target;
    if (checked === true) {
      this.setState({ enable_ip_whitelist: 1 });
    } else {
      this.setState({ enable_ip_whitelist: 0, whiteLableIP: "" });
    }
  }

  openDialog = () => this.setState({ isDialogOpen: true });

  handleClose = () => this.setState({ isDialogOpen: false });

  copyToClipboard = (e) => {
    this.input.select();
    document.execCommand("copy");
    e.target.focus();
    this.setState({ copySuccess: "Copied!" });
  };

  updateProfile() {
    const updateUserDetails = {
      user_id: this.state.user_id,
      fullname: this.state.fullname,
      address_line_1: this.state.address_line_1,
      city: this.state.city,
      mobile: this.state.mobile,
      id: this.state.id,
      whitelist_ip: this.state.whiteLableIP,
      enable_ip_whitelist: this.state.enable_ip_whitelist,
      method: "update",
      token: this.state.token,
      country: this.state.country,
      kylas_crm_id: this.state.kylas_crm_id,
    };

    fetch(`${HOST_URL}/profile.php`, {
      method: "post",
      headers: {
        "content-Type": "application/json",
      },
      body: JSON.stringify(updateUserDetails),
    }).then((result) => {
      result.json().then((resp) => {
        if (resp.success === true) {
          document.querySelector("body").scrollTo(0, 0);
          this.setState({
            successMessage: true,
            bgColor: "alert alert-success alert-dismissible",
            errorMessage: resp.message,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: resp.message,
          });
        }
      });
    });
  }

  sendOtpMessage = () => {
    Axios.post(
      `${HOST_URL}/authkey.php?user_id=${this.state.user_id}&method=sendotp&token=${this.state.token}`
    ).then((res) => {
      if (res.data.success === true) {
        this.handleClose();
        this.setState({
          successMessage: true,
          bgColor: "alert alert-success alert-dismissible",
          errorMessage: res.data.message,
        });
      } else {
        this.setState({
          successMessage: true,
          bgColor: "alert alert-success alert-dismissible",
          errorMessage: res.data.message,
        });
      }
    });
  };

  otpVerify = () => {
    document.querySelector("body").scrollTo(0, 0);
    Axios.post(
      `${HOST_URL}/authkey.php?user_id=${this.state.user_id}&method=validate&token=${this.state.token}&otp=${this.state.otp}`
    ).then((res) => {
      if (res.data.success === true) {
        this.handleClose();
        this.reGenerateAuthKey();
        this.setState({
          successMessage: true,
          bgColor: "alert alert-success alert-dismissible",
          errorMessage: res.data.message,
          otpScreen: false,
        });
      } else {
        this.setState({
          successMessage: true,
          bgColor: "alert alert-danger alert-dismissible",
          errorMessage: res.data.message,
        });
      }
    });
  };

  generateAuthKey = () => {
    this.sendOtpMessage();
    this.setState({ otpScreen: true });
  };

  render() {
    return (
      <DashboardLayout>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="row mb-2 mb-xl-3">
              {this.state.isDialogOpen && (
                <Dialog
                  modal={true}
                  title={"Alert"}
                  isDraggable={true}
                  onClose={this.handleClose}
                  buttons={[
                    {
                      text: "Close",
                      className: "btn-danger btn-sm btn mr-2",
                      onClick: () => this.handleClose(),
                    },
                    {
                      text: "Yes",
                      className: "btn btn-sm btn-info",
                      onClick: () => this.generateAuthKey(),
                    },
                  ]}
                >
                  <h4>Are You Sure ?</h4>
                  <p> Want to re-geneate auth key </p>
                </Dialog>
              )}

              {this.state.successMessage ? (
                <div className={this.state.bgColor} role="alert">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div className="">{this.state.errorMessage}</div>
                </div>
              ) : null}

              <div className="col-auto d-none d-sm-block">
                <h3>
                  <strong>Profile </strong> Details{" "}
                </h3>
              </div>

              <div className="col-auto ml-auto text-right mt-n1"></div>
              <div
                className="alert card alert-outline alert-dismissible"
                role="alert"
              >
                <div className="card-body">
                  <div className="row">
                    {!this.state.otpScreen ? (
                      <div className="col-md-6 border-right">
                        <h3 className="alert-heading"> Your Auth Key : </h3>
                        <input
                          className="profile-auth-key"
                          type="text"
                          ref={(input) => (this.input = input)}
                          value={this.state.UserDetails.authkey}
                        />
                        <br />
                        <button
                          onClick={this.copyToClipboard}
                          className="mt-4 btn btn-primary btn-sm"
                        >
                          {" "}
                          {this.state.copySuccess}{" "}
                          <FontAwesomeIcon icon={faCopy} color="#fff" />{" "}
                        </button>
                        <button
                          onClick={() => this.openDialog()}
                          className="btn btn-sm mt-4 btn-success"
                          type="button"
                        >
                          Re Generate Key
                        </button>
                      </div>
                    ) : (
                      <div className="col-md-6 border-right">
                        <h3 className="alert-heading"> Enter OTP to Verify</h3>
                        <input
                          className="form-control"
                          placeholder="Enter OTP"
                          type="text"
                          onChange={(e) =>
                            this.setState({ otp: e.target.value })
                          }
                        />

                        <button
                          onClick={() => this.otpVerify()}
                          className="btn btn-sm mt-4 btn-success"
                          type="button"
                        >
                          Verify OTP
                        </button>
                      </div>
                    )}

                    {checkUserUrl(this.state.url) && (
                      <div className="col-md-6 mt-3 text-center">
                        <p>
                          If you want to change your auth key, please drop a
                          mail on <br />{" "}
                          <Link to="mailto:hello@authkey.io">
                            {" "}
                            hello@authkey.io
                          </Link>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-body">
                  <form>
                    <div className="row">
                      <div className="mb-3 col-md-8">
                        <label className="form-label" htmlFor="inputFirstName">
                          Full Name
                        </label>
                        <input
                          type="text"
                          Value={this.state.UserDetails.fullname}
                          onChange={(event) =>
                            this.setState({ fullname: event.target.value })
                          }
                          className="form-control"
                          id="inputFirstName"
                          placeholder="Enter Full Name"
                        />
                      </div>

                      <div className="mb-3 col-md-4">
                        <label className="form-label" htmlFor="inputFirstName">
                          Account Expiry Date{" "}
                        </label>

                        <div className="input-group mb-2 mr-sm-2">
                          <div className="input-group-text">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="green"
                              size="lg"
                            />
                          </div>
                          <input
                            type="text"
                            defaultValue={this.state.UserDetails.expiry}
                            className="form-control"
                            readOnly
                            id="inputFirstName"
                            placeholder="Enter Full Name"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputEmail4">
                          Email
                        </label>
                        <input
                          type="email"
                          value={this.state.UserDetails.email}
                          readOnly
                          className="form-control"
                          id="inputEmail4"
                          placeholder="Email"
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputFirstName">
                          Enter Mobile Number
                        </label>
                        <input
                          type="text"
                          Value={this.state.UserDetails.mobile}
                          readOnly
                          onChange={(event) =>
                            this.setState({ mobile: event.target.value })
                          }
                          className="form-control"
                          id="inputFirstName"
                          placeholder="Enter 10 Digit Mobile Number"
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="inputAddress">
                        Address
                      </label>
                      <textarea
                        defaultValue={this.state.UserDetails.address_line_1}
                        className="form-control"
                        onChange={(event) =>
                          this.setState({ address_line_1: event.target.value })
                        }
                        placeholder="1234 Main St"
                      ></textarea>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputCity">
                          Country
                        </label>
                        <input
                          type="text"
                          Value={this.state.UserDetails.country}
                          onChange={(event) =>
                            this.setState({ country: event.target.value })
                          }
                          placeholder="Country"
                          className="form-control"
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputCity">
                          City
                        </label>
                        <input
                          Value={this.state.UserDetails.city}
                          onChange={(event) =>
                            this.setState({ city: event.target.value })
                          }
                          type="text"
                          placeholder="City"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div class="col-md-12 mt-2">
                        <h3>Please enter Kylas User Id</h3>
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="kylas">
                          kylas User Id
                        </label>
                        <input
                          Value={this.state.UserDetails.kylas_crm_id}
                          onChange={(event) =>
                            this.setState({ kylas_crm_id: event.target.value })
                          }
                          type="text"
                          placeholder="kylas User Id"
                          className="form-control"
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="userid">
                          User Id
                        </label>
                        <input
                          Value={this.state.user_id}
                          disabled={true}
                          type="text"
                          placeholder="userID"
                          className="form-control"
                        />
                      </div>
                    </div>

                    {/* <div className={this.state.enable_ip_whitelist ===1 ? "alert alert-info" : "alert alert-danger" }>
                                                <div className="form-check">
                                                    <input className="form-check-input" defaultChecked={this.state.UserDetails.enable_ip_whitelist ? 'checked' : null} onClick={(e) => this.whiteLabelHandlar(e)} type="checkbox" value="" id="whitelableIP" />
                                                    <label className="form-check-label" for="whitelableIP">
                                                        Want to Add  White Label IP Address
                                                    </label>
                                                </div>
                                            </div>
                                            {this.state.enable_ip_whitelist === 1 ?
                                                null
                                            :
                                            <div className="mt-3 row">
                                                <div className='col-md-6'>
                                                    <input onChange={(event) => this.setState({ whiteLableIP: event.target.value })} type="text" defaultValue={this.state.whiteLableIP} placeholder="Example : 192.168.4.2, 193.168.4.2, 196.128.4.2" className="form-control" />
                                                    <small className="form-text text-info text-muted">Add comma(,) if you have multiple IP address</small>
                                                </div>
                                            </div>
                                            } */}

                    <button
                      type="button"
                      onClick={() => this.updateProfile()}
                      className="btn btn-sm float-right btn-primary"
                    >
                      Update Profile
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      </DashboardLayout>
    );
  }
}

export default MyProfile;
