import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../UI/Layout/DashboardLayout";
import { FaUserCircle } from "react-icons/fa";
import Title from "../../../UI/Text/Title";
import AdvanceDataTable from "../../../UI/Table/AdvanceDataTable";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDatePicker from "react-datepicker";
import Axios from "../../../../axios/Axios";
import { NODE_HOST_URL } from "../../api/Api";
import { ADMIN_LOGIN_HISTORY } from "../../../UI/Table/ColumnConst";

const AgentLoginHistory = () => {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const user_id = lStorage.user.userId;
    const token = lStorage.token;
    const [fromdate, setFromdate] = useState(new Date());
    const [todate, setTodate] = useState(new Date());
    const [agent, setAgent] = useState('')
    const [AgentLoginHistoryData, setAgentLoginHistoryData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [agentList, setAgentList] = useState([]);
    const [disable , setDisable] = useState(false)

    const fromdateHandler = (data) => {
        setFromdate(data);
    };

    const todateHandler = (data) => {
        setTodate(data);
    };

    const currentDate = (currentDate) => {
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    };

    useEffect(() => {
        SearchHistory();
        showAgent();
    }, [])

    const showAgent = async () => {
        setIsLoading(true);
        const data = {
            user_id,
            method: "retrieve_agent",
            token,
            user_type: "admin",
        };

        try {
            const response = await fetch(`${NODE_HOST_URL}/whatsapp_agent`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
                body: JSON.stringify(data),
            });
            const result = await response.json();
            if (result.success) {
                // console.log(result.data)
                setAgentList(result.data);
                // setAgentList(result.data);
                // setFilteredAgents(result.data); // Set initial data for filtered agents
            } else {
                console.log("Failed to fetch data:", response.statusText);
            }
        } catch (error) {
            console.error("Error occurred while fetching data:", error);
        }
        setIsLoading(false);
    };

    const SearchHistory = async () => {
        setIsLoading(true)
        const paramData = {
            user_id: user_id,
            token: token,
            fromdate: currentDate(fromdate),
            todate: currentDate(todate),
            agent_id: agent || " ",
            method: "agent_login_history"
        }
        try {
            let { data } = await Axios.post(`${NODE_HOST_URL}/agent_login_history`, paramData)
            setDisable(true)
            if (data.success) {
                console.log(data)
                setAgentLoginHistoryData(data.data)
                setDisable(false)
            }
            else{
                setAgentLoginHistoryData([]);
                setDisable(false)
            }
        } catch (error) {
            console.log(error)
            // setAgentLoginHistoryData([]);
            setDisable(false)
        }
        setIsLoading(false)
    }

    const Download = async () => {
        let paramData = {
            user_id: user_id,
            token: token,
            fromdate: currentDate(fromdate),
            todate: currentDate(todate),
            agent_id: agent || " ",
            method: "download_agent_login_history"
        }
        try {
            let { data } = await Axios.post(`${NODE_HOST_URL}/agent_login_history`, paramData);
            if (data.success) {
                console.log(data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    console.log(AgentLoginHistoryData)


    return (
        <>
            <DashboardLayout>
                <main className="content">
                    <div className="container-fluid p-0">
                        <Title
                        >
                            Agent Login History
                        </Title>

                        <div className="row">
                            <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                <div className="card flex-fill">
                                    <div className="card-body">
                                        <div className="filter-option">
                                            <div className="row mt-3">
                                            <div className="col-md-1 ">
                                                <div className=" text-center  btn-block mb-2 mr-sm-2">
                                                <label className="mt-2" for="inlineFormCustomSelectPref">From</label>
                                                </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="input-group mb-2 mr-sm-2">
                                                        <div className="input-group-text">
                                                            <FontAwesomeIcon icon={faCalendarAlt} />
                                                        </div>

                                                        <ReactDatePicker
                                                            className="form-control btn-block"
                                                            dateFormat="dd/MM/yyyy"
                                                            value={fromdate}
                                                            onChange={fromdateHandler}
                                                            selected={fromdate}
                                                            placeholderText="From Date"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-1 ">
                                                <div className=" text-center  btn-block mb-2 mr-sm-2">
                                                <label className="mt-2" for="inlineFormCustomSelectPref">To</label>
                                                </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="input-group mb-2 mr-sm-2">
                                                        <div className="input-group-text">
                                                            <FontAwesomeIcon icon={faCalendarAlt} />
                                                        </div>
                                                        <ReactDatePicker
                                                            className="form-control btn-block"
                                                            dateFormat="dd/MM/yyyy"
                                                            value={todate}
                                                            onChange={todateHandler}
                                                            selected={todate}
                                                            placeholderText="Date To"
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='row mt-2'>
                                               
                                                <div className='col-5 drop-down-icons'>
                                                    {/* {error.errorStatus ? (
                              <div className={error.bgcolor} role="alert">
                                <strong>Alert!</strong> {error.errorMessage}
                              </div>
                            ) : null} */}
                                                    <select className="form-control" value={agent} onChange={(e) => setAgent(e.target.value)}>
                                                        <option value=" ">Select Agent</option>
                                                        {agentList.map((x) => (
                                                            <option value={x.id}>{x.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className='col-6'>
                                                    {AgentLoginHistoryData?.length > 0 && <a
                                                        type="button"
                                                        className="btn btn-primary float-right ml-3"
                                                        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, height: "45px" }}
                                                        href={`${NODE_HOST_URL}/agent_login_history?user_id=${user_id}&token=${token}&fromdate=${currentDate(fromdate)}&todate=${currentDate(todate)}&agent_id=${agent || " "}&method=download_agent_login_history`}
                                                    >
                                                        Download
                                                    </a>}
                                                    <button type="button" onClick={SearchHistory} disabled={disable ? true : false} className="btn btn-info float-right">Search</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <AdvanceDataTable
                            tableName={""}
                            tableCell={ADMIN_LOGIN_HISTORY}
                            tableData={AgentLoginHistoryData}
                            isLoading={isLoading}
                        //   isError={this.state.ErrorResponse}
                        />


                    </div>
                </main>
            </DashboardLayout>
        </>
    );
};

export default AgentLoginHistory;
