import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSave,
} from "@fortawesome/free-solid-svg-icons";
import { WHATSAPP_TEMPLATE_LIST } from "../../../constants/ServiceConstants";
import api from "../../../axios/Axios";
import Alert from "../../UI/Alert/Alert";
import Title from "../../UI/Text/Title";
import DashboardLayout from "../../UI/Layout/DashboardLayout";
// import Picker from '@emoji-mart/react';
// import data from '@emoji-mart/data';
import axios from "../../../axios/Axios";
import { HOST_URL } from "../api/Api";
import WhatsAppPreviewAuthentication from "./WhatsappPreviewAuthentication";
import Select from "react-select";

class WhatsAppTemplateAuthentication extends Component {
    constructor(props) {
        const lStorage = JSON.parse(localStorage.getItem("login"));
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;
        super(props);

        this.inputRef = React.createRef();
        this.buttonRef = React.createRef();
        this.ftinputRef = React.createRef();
        this.state = {
            userID: userId,
            token: userToken,
            paramID: this.props.match.params.id,
            footerOptions: false,
            buttonOptions: false,
            MapingOptions: false,
            buttonAction: false,
            callActionType: "",
            varibaleInTemplate: [],
            collaps1: true,
            collaps2: false,
            tampleName: "",
            isFallback: 0,
            smsTemplateSID: "",
            language: {label: "English",value: "en"},
            accountType: "",
            templateType: " ",
            headerTextPrev: "",
            bodyMessage: "",
            preBodyMessage: "",
            footerText: "",
            Callbutton: "",
            Urlbutton: "",
            Urlbutton2: "",
            quickButton: "",
            temp_status: "",
            addVariableCount: 1,
            button: [],
            SampleParameter: [],
            isLoading: false,
            successMessage: false,
            errorMessage: "",
            bgColor: "",
            isPickerVisible: false,
            currentEmoji: "",
            searchQuery: "",
            replacementText: "",
            inputFields: [""],
            newBodymsg: "",
            charCount: 0,
            maxChars: 1024,
            headerUpload: "",
            tempImage: null,
            specialUser: null,
            uploadStatus: null,
            ftcharCount: 0,
            ftmaxChars: 60,
            allButton: [],
            selectedOption: 'copy_code',
            securityRecommendation: true,
            expiryTime: false,
            selectExpiryTime: 90,
            buttonCopyCode: 'Copy Code',
            autofill: 'Auto-fill',
            selectTime: '5',
            packageName: '',
            signatureHash: '',
            errorPackageName: false,
            errorPackageColor: '',
            errorPackageMessage: '',
            errorSelectTime: false,
            errorSelectTimeColor: '',
            errorSelectTimeMessage: '',
            headerText: "{{1}} is your verification code.",
            securityText: 'For your security, do not share this code.',
            selectedLanguage: []
        };

        this.getHeaderData = this.getHeaderData.bind(this);
        this.getButtonData = this.getButtonData.bind(this);
        this.getURLBuutton = this.getURLBuutton.bind(this);
        this.getURLBuutton2 = this.getURLBuutton2.bind(this);
        this.getQuickButton = this.getQuickButton.bind(this);
        this.headerOptions = this.headerOptions.bind(this);
        this.bodyMessageHandler = this.bodyMessageHandler.bind(this);
        this.getTemplateData = this.getTemplateData.bind(this);
        this.insertVariable = this.insertVariable.bind(this);
        this.setSampleData = this.setSampleData.bind(this);
        this.footerHandler = this.footerHandler.bind(this);

        // this.handleOriginalTextChange = this.handleOriginalTextChange.bind(this);
        // this.handleSearchQueryChange = this.handleSearchQueryChange.bind(this);
        // this.handleReplacementTextChange = this.handleReplacementTextChange.bind(this);
        // this.performSearchAndReplace = this.performSearchAndReplace.bind(this);
    }


    // componentDidUpdate(prevProps, prevState) {
    //     if (prevState.allButton !== this.state.allButton) {
    //         this?.state?.allButton?.map((item, index) => {
    //             console.log(item, index)
    //             let key = Object.keys(item);
    //             if (key.includes("urltext2")) {
    //                 this.setState({
    //                     Urlbutton2: item,
    //                 })
    //             }
    //             if (key.includes("urltext")) {
    //                 this.setState({
    //                     Urlbutton: item,
    //                 })
    //             }
    //             if (key.includes("phone")) {
    //                 this.setState({
    //                     Callbutton: item,
    //                 })
    //             }
    //         })
    //     }
    // }




    componentDidMount() {
        const lcStorage = JSON.parse(localStorage.getItem("login"));
        const tempuserId = lcStorage.user.userId;
        const tempuser_id = tempuserId.toString();
        const tempuserToken = lcStorage.token;

        axios
            .get(
                `${HOST_URL}/get_user_route.php?user_id=${tempuser_id}&method=retrieve&token=${tempuserToken}`
            )
            .then((res) => {
                if (res.data.success === true) {
                    //  setSpecialUser(res.data.data);
                    this.setState({
                        specialUser: res.data.data,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });

        this.RetrieveLanguage();
    }

    customStyles = {
        control: (provided) => ({
            ...provided,
            cursor: "pointer",
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        option: (style) => ({
            ...style,
            fontSize: "12px",
        }),
    };

    RetrieveLanguage = async () => {
        try {
            let { data } = await axios.post(`${HOST_URL}/whatsapp_template_language.php?user_id=${this.state.userID}&method=retrieve&id=null&token=${this.state.token}`)
            if (data.success) {
                const newData = data.data.map((x) => {
                    return { label: x.title, value: x.code }
                })
                this.setState({ selectedLanguage: newData })
                this.state.paramID && this.getTmplateDetails(newData);
            }
        } catch (error) {
            console.log(error)
        }
    }

    handleLanguageChange = (selectedOption) => {
        this.setState({ language: selectedOption })
    };

    getHeaderData(headerData) {


        if (headerData.errMssg) {
            let err_msg = headerData.errMssg.toString();
            this.setState({
                errorMessage: err_msg,
                successMessage: true,
                bgColor: "alert-danger",
            });
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        } else {
            if (
                (headerData.image ||
                    headerData.video ||
                    headerData.document ||
                    headerData.text) &&
                this.state.specialUser === 34
            ) {
                this.setState({
                    uploadStatus: true,
                });
            }
            if (this.state.templateType === "Text") {
                this.setState({
                    uploadStatus: true,
                });
            }
            this.setState({
                headerUpload: headerData.headerUploadMedia,
                tempImage: headerData.tempImage,
            });
            const obj = headerData;
            if (obj.hasOwnProperty("headerUploadMedia")) {
                delete obj.headerUploadMedia;
            }

            this.setState({
                headerText: obj,
            });
        }
    }

    getButtonData(callButtonData) {
        this.setState({ Callbutton: callButtonData });
    }

    getURLBuutton(urlButtnData) {
        this.setState({ Urlbutton: urlButtnData });
    }
    getURLBuutton2(urlButtnData) {
        this.setState({ Urlbutton2: urlButtnData });
    }

    getQuickButton(quickButton) {
        this.setState({ quickButton: quickButton });
    }

    generateButtonJson() {
        let button = [];
        if (this.state.Callbutton[0]) {
            button.push(this.state.Callbutton[0]);
        }
        if (this.state.Urlbutton[0]) {
            button.push(this.state.Urlbutton[0]);
        }
        if (this.state.Urlbutton2[0]) {
            button.push(this.state.Urlbutton2[0]);
        }
        if (this.state.quickButton[0]) {
            button.push(this.state.quickButton[0]);
        }
        return button;
    }

    isValid = () => {
        // Check if the selected option is either 'zero_tap' or 'one_tap'
        if (this.state.selectedOption === 'zero_tap' || this.state.selectedOption === 'one_tap') {
            // Validate packageName
            if (!this.state.packageName) {
                setTimeout(() => {
                    this.setState({
                        errorPackageName: true,
                        errorPackageColor: 'alert alert-danger',
                        errorPackageMessage: "Please Select Package Name"
                    });
                }, 500)

                return false; // Validation failed
            }

            // Validate signatureHash
            if (!this.state.signatureHash) {
                setTimeout(() => {
                    this.setState({
                        errorPackageName: true,
                        errorPackageColor: 'alert alert-danger',
                        errorPackageMessage: "Please Select App Signature Hash Name"
                    });
                }, 500)

                return false; // Validation failed
            }

            if (this.state.signatureHash.length !== 11) {
                setTimeout(() => {
                    this.setState({
                        errorPackageName: true,
                        errorPackageColor: 'alert alert-danger',
                        errorPackageMessage: "Type 11 Characters only"
                    });
                }, 200)
                return false; // Validation failed
            }
            return true
        }

        if (!this.state.tampleName) {
            this.setState({
                errorMessage: "Enter Template Name",
                successMessage: true,
                bgColor: "alert-danger",
            })
        }

        // If all validations pass
        return true; // Validation successful
    }


    crateTemplate = async (status) => {
        if (this.isValid()) {
            this.setState({
                errorMessage: "",
                successMessage: false,
                bgColor: "",
                isLoading: false,
            });

            // if (!(this.state.templateType === "Text" || this.state.templateType === "Media")) {
            //     this.setState({
            //         errorMessage: "Please select template type",
            //         successMessage: true,
            //         bgColor: "alert-danger",
            //         isLoading: false,
            //     });
            //     return
            // }
            this.setState({ isLoading: true });
            let formValidation = this.formValidation();

            if (formValidation === true) {
                let buttom = this.generateButtonJson();
                try {
                    const { data } = await api.post(WHATSAPP_TEMPLATE_LIST, {
                        token: this.state.token,
                        user_id: this.state.userID,
                        temp_name: this.state.tampleName,
                        temp_category: "Authentication",
                        temp_type: this.state.selectedOption,
                        temp_header: '',
                        temp_body: this.state.securityRecommendation ? this.state.headerText + this.state.securityText : this.state.headerText,
                        temp_footer: this.state.selectTime,
                        temp_language: this.state.language.value,
                        temp_button: '',
                        full_content: this.state.securityRecommendation ? this.state.headerText + this.state.securityText : this.state.headerText,
                        temp_attribute: this.state.securityRecommendation ? this.state.headerText + this.state.securityText : this.state.headerText,
                        fall_back: '',
                        fall_sid: '',
                        image_upload_id: '',
                        temp_status: status,
                        method: "create_auth",
                        one_tap: this.state.selectedOption === 'one_tap' ? 'one_tap' : '',
                        add_security: this.state.expiryTime,
                        package_name: this.state.packageName,
                        signature_hash: this.state.signatureHash,
                    });
                    document.querySelector("body").scrollTo(0, 0);
                    if (data.success === true) {
                        document.querySelector("body").scrollTo(0, 0);
                        this.setState({
                            errorMessage: data.message,
                            successMessage: true,
                            bgColor: "alert-success",
                            isLoading: false,
                        });
                        setTimeout(() => {
                            this.props.history.push("whatsapp-template-list");
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth'
                            });
                        }, 1000);
                    } else {
                        this.setState({
                            errorMessage: data.message,
                            successMessage: true,
                            bgColor: "alert-danger",
                            isLoading: false,
                        });
                    }
                } catch (err) {
                    document.querySelector("body").scrollTo(0, 0);
                    console.log(err);
                }
            }

        }
    };

    updateTemplate = async (status) => {

        this.setState({ isLoading: true });
        let formValidation = this.formValidation();
        if (formValidation === true) {
            let buttom = this.generateButtonJson();
            try {
                const { data } = await api.post(WHATSAPP_TEMPLATE_LIST, {
                    token: this.state.token,
                    user_id: this.state.userID,
                    temp_name: this.state.tampleName,
                    temp_category: "Authentication",
                    temp_type: this.state.selectedOption,
                    temp_header: '',
                    temp_body: this.state.securityRecommendation ? this.state.headerText + this.state.securityText : this.state.headerText,
                    temp_footer: this.state.selectTime,
                    temp_language: this.state.language.value,
                    temp_button: '',
                    full_content: this.state.securityRecommendation ? this.state.headerText + this.state.securityText : this.state.headerText,
                    temp_attribute: this.state.securityRecommendation ? this.state.headerText + this.state.securityText : this.state.headerText,
                    fall_back: '',
                    fall_sid: '',
                    image_upload_id: '',
                    temp_status: status,
                    method: "create_auth",
                    one_tap: this.state.selectedOption === 'one_tap' ? 'one_tap' : '',
                    add_security: this.state.expiryTime,
                    package_name: this.state.packageName,
                    signature_hash: this.state.signatureHash,
                });
                // console.log("data.success==>", data);
                if (data.success === true) {
                    document.querySelector("body").scrollTo(0, 0);
                    this.setState({
                        errorMessage: data.message,
                        successMessage: true,
                        bgColor: "alert-success",
                        isLoading: false,
                    });
                } else {
                    this.setState({
                        errorMessage: data.message,
                        successMessage: true,
                        bgColor: "alert-danger",
                        isLoading: false,
                    });
                }
            } catch (err) {
                document.querySelector("body").scrollTo(0, 0);
                console.log(err);
            }
        }


    };

    getTmplateDetails = async (languageData) => {
        this.setState({ isLoading: true });
        try {
            const { data } = await api.post(WHATSAPP_TEMPLATE_LIST, {
                token: this.state.token,
                user_id: this.state.userID,
                id: this.state.paramID,
                method: "retrieveid",
            });

            if (data.success === true) {
                console.log(data.data)
                let resultData = data.data
                let filterLanguageData = languageData.filter(x => x.value == data.data[0].temp_language)
                // await this.setState({
                //     tampleName: data.data[0].temp_name,
                //     language: data.data[0].temp_language,
                //     // accountType: data.data[0].temp_category,
                //     // templateType: data.data[0].temp_type,
                //     headerText: data.data[0].temp_header,
                //     headerOptions: data.data[0].temp_header === "" ? false : true,
                //     footerText: data.data[0].temp_footer,
                //     footerOptions: data.data[0].temp_header === "" ? false : true,
                //     temp_button: data.data[0].temp_button,
                //     allButton: data.data[0].temp_button,
                //     bodyMessage: data.data[0].temp_body,
                //     button: data.data[0].temp_button,
                //     temp_status: data.data[0].temp_status,
                //     // Urlbutton:
                //     //   data.data[0].temp_button.length > 0 &&
                //     //   data.data[0].temp_button[0].actionType === "calltoaction"
                //     //     ? [data.data[0].temp_button[1]]
                //     //     : "",
                //     // Callbutton:
                //     //   data.data[0].temp_button.length > 0 &&
                //     //   data.data[0].temp_button[0].actionType === "calltoaction"
                //     //     ? [data.data[0].temp_button[0]]
                //     //     : "",
                //     quickButton:
                //         data?.data[0]?.temp_button?.length > 0 &&
                //             data?.data[0]?.temp_button[0]?.actionType === "quickReply"
                //             ? [data.data[0].temp_button[0]]
                //             : "",
                //     temp_attribute: [],
                //     isLoading: false,
                // });
                await this.setState({
                    tampleName: resultData[0]?.temp_name,
                    language: filterLanguageData[0],
                    selectedOption: resultData[0]?.temp_type,
                    packageName: resultData[0]?.package_name,
                    signatureHash: resultData[0]?.signature_hash,
                    selectTime: resultData[0]?.temp_footer,
                    securityRecommendation: resultData[0]?.temp_body ? true : false,
                    expiryTime: resultData[0]?.temp_footer ? true : false
                })
                if (this?.state?.button?.length > 0) {
                    this.setState({
                        buttonOptions: true,
                    });
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    headerOptions() {
        this.setState({ headerOptions: !this.state.headerOptions });
    }

    next() {
        this.setState({ isopenFirst: false, isopenSeconds: true });
    }

    getVaribale() {
        var str = this.state.bodyMessage;
        var regex = /{#([^#]+)#}/g;
        let m;
        var tempArr = [];
        while ((m = regex.exec(str)) !== null) {
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            tempArr.push(m[1]);
        }
        this.setState({ varibaleInTemplate: tempArr });
    }

    bodyMessageHandler(e) {
        const newText = e.target.value;
        const currentCharCount = newText.length;

        if (currentCharCount <= this.state.maxChars) {
            this.setState({ bodyMessage: e.target.value });
            this.setState({ preBodyMessage: e.target.value });
            this.getVaribale();
            this.setState({
                text: newText,
                charCount: currentCharCount,
            });
        }
    }

    footerHandler(e) {
        const newftText = e.target.value;
        const ftcurrentCharCount = newftText.length;

        if (ftcurrentCharCount <= this.state.ftmaxChars) {
            this.setState({ footerText: e.target.value });


            this.setState({
                textt: newftText,
                ftcharCount: ftcurrentCharCount,
            });
        }
    }



    formValidation() {
        const regex = /^[a-z_0-9]*$/;
        const text = this.state.tampleName.trim();
        const uploadStatus = this.state.uploadStatus;

        // Check if a file is required but not uploaded
        if (uploadStatus === null && this.state.specialUser === 34) {
            this.setState({
                errorMessage: "Please upload the file",
                successMessage: false,
                bgColor: "alert-danger",
                isLoading: false,
            });
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            return false;
        }

        // Validate template name
        if (!regex.test(text)) {
            this.setState({
                errorMessage: "Template name cannot contain spaces, special characters, or capital letters. Only lowercase letters and underscores are allowed.",
                successMessage: false,
                bgColor: "alert-danger",
                isLoading: false,
            });
            return false;
        }

        // If selected option is 'zero_tap' or 'one_tap', validate additional fields
        if (this.state.selectedOption === 'zero_tap' || this.state.selectedOption === 'one_tap') {
            let hasError = false;

            // Validate packageName
            if (!this.state.packageName) {
                this.setState({
                    errorPackageName: true,
                    errorPackageColor: 'alert alert-danger',
                    errorPackageMessage: "Please select Package Name"
                });
                hasError = true;
            }

            // Validate signatureHash
            if (!this.state.signatureHash) {
                this.setState({
                    errorPackageName: true,
                    errorPackageColor: 'alert alert-danger',
                    errorPackageMessage: "Please select App Signature Hash Name"
                });
                hasError = true;
            } else if (this.state.signatureHash.length !== 11) {
                this.setState({
                    errorPackageName: true,
                    errorPackageColor: 'alert alert-danger',
                    errorPackageMessage: "Signature hash must be exactly 11 characters long"
                });
                hasError = true;
            }

            if (hasError) {
                return false; // Validation failed
            }
        }

        // If all validations pass
        this.setState({
            errorMessage: "",
            successMessage: false,
            errorPackageName: false,
            errorPackageColor: '',
            errorPackageMessage: ""
        });
        return true;
    }


    getTemplateData(smsTemplateId) {
        this.setState({
            smsTemplateSID: smsTemplateId,
            isFallback: 1,
        });
    }

    // insertVariable() {
    //   var textarea = document.getElementById("message-body");
    //   var cursorPosition = textarea.selectionStart;
    //   var text = textarea.value;
    //   var variable = "{{" + this.state.addVariableCount + "}}";
    //   this.setState({
    //     addVariableCount: this.state.addVariableCount + 1,
    //   });
    //   var updatedText =
    //     text.slice(0, cursorPosition) + variable + text.slice(cursorPosition);
    //   textarea.value = updatedText;

    //   // Move the cursor position after the inserted variable
    //   textarea.selectionStart = cursorPosition + variable.length;
    //   textarea.selectionEnd = cursorPosition + variable.length;
    //   textarea.focus();
    // }

    insertVariable = async (e) => {
        e.preventDefault(e);
        const { current } = this.inputRef;
        const { selectionStart, selectionEnd } = current;
        const newValue =
            this.state.bodyMessage.substring(0, selectionStart) +
            " {{" + this.state.addVariableCount + "}} " +
            this.state.bodyMessage.substring(selectionEnd);

        if (this.state.inputFields.length < 11) {
            // await this.setState((prevState) => ({
            //   bodyMessage:
            //     prevState.bodyMessage + " {{" + this.state.addVariableCount + "}}",
            // }));
            await this.setState({
                bodyMessage: newValue,
            });

            await this.setState({
                preBodyMessage: this.state.bodyMessage,
            });
            await this.setState({
                addVariableCount: this.state.addVariableCount + 1,
            });
        }
        if (this.state.inputFields.length < 11) {
            this.setState((prevState) => ({
                inputFields: [...prevState.inputFields, ""], // Adding an empty string as a new input field
            }));
        }
        this.inputRef.current.focus();
    };

    getVariable = () => {
        let tempArr = [];
        var regex = /{{([^}]+)}}/g;
        let m;
        let SampleParameter = {};
        while ((m = regex.exec(this.state.bodyMessage)) !== null) {
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            SampleParameter[`${m[1]}`] = "";
            tempArr.push(m[1]);
        }
        this.setState({
            varibaleInTemplate: tempArr,
            SampleParameter: SampleParameter,
        });
    };

    // setSampleData = (e) => {
    //   let key = e.target.name;
    //   let value = e.target.value;
    //   let object = this.state.SampleParameter;
    //   console.log(key,value,object)
    //   if (object.hasOwnProperty(key)) {
    //     object[key] = value;
    //   } else {
    //     object[key] = value;
    //   }
    //   this.setState({
    //     SampleParameter: object,
    //   });

    // };

    performSearchAndReplace() {
        const { preBodyMessage, replacementText } = this.state;
        // const regex = new RegExp(this.state.searchQuery, 'g');
        return preBodyMessage.replace(this.state.searchQuery, replacementText);
    }
    setSampleData = async (e) => {
        e.preventDefault();

        let key = e.target.name;
        let value = e.target.value;
        let object = this.state.SampleParameter;
        console.log(key, value, object);
        if (object.hasOwnProperty(key)) {
            object[key] = value;
        } else {
            object[key] = value;
        }
        this.setState({
            SampleParameter: object,
        });

        let searchName = "{{" + e.target.name + "}}";
        let newValue = e.target.value;
        await this.setState({
            preBodyMessage: this.state.bodyMessage,
        });

        await this.setState({ searchQuery: searchName });

        await this.setState({ replacementText: newValue });
        let newmsg = this.performSearchAndReplace();
        this.setState({ preBodyMessage: newmsg });
    };

    backCursor() {
        if (this.inputRef.current) {
            const currentPosition = this.inputRef.current.selectionStart;
            const newPosition = Math.max(0, currentPosition - 1);

            this.inputRef.current.focus();
            this.inputRef.current.setSelectionRange(newPosition, newPosition);
        }
    }

    handleChange = (event) => {
        event.preventDefault();
        this.setState({
            bodyMessage: event.target.value,
        });
    };

    // addBold = async (e) => {
    //   e.preventDefault();
    //   await this.setState(prevState => ({
    //     bodyMessage: prevState.bodyMessage + "**"
    //   }));
    //   this.backCursor();
    // };

    // addBold (){
    //   var textarea = document.getElementById("message-body");
    //   var cursorPosition = textarea.selectionStart;
    //   var text = textarea.value;
    //   var variable = "**";
    //   var updatedText =
    //     text.slice(0, cursorPosition) + variable + text.slice(cursorPosition);
    //   textarea.value = updatedText;
    //   this.backCursor();
    // }

    addEmoji = (e) => {
        e.preventDefault();
        this.setState((prevState) => ({
            isPickerVisible: !prevState.isPickerVisible, // Toggles the state from true to false or vice versa
        }));
    };

    // handleClickOutside = (event) => {

    //   if (this.buttonRef.current && !this.buttonRef.current.contains(event.target)) {
    //     this.setState({
    //       isPickerVisible: false
    //     });
    //   }
    // };

    emoji = async (e) => {
        const nemoji = e.emoji;
        const { current } = this.inputRef;
        const { selectionStart, selectionEnd } = current;
        const newValue =
            this.state.bodyMessage.substring(0, selectionStart) +
            nemoji +
            this.state.bodyMessage.substring(selectionEnd);
        // await this.setState({
        //   currentEmoji: nemoji,
        // });

        // await this.setState((prevState) => ({
        //   bodyMessage: prevState.bodyMessage + this.state.currentEmoji,
        // }));
        await this.setState({
            bodyMessage: newValue,
        });
        await this.setState({
            preBodyMessage: this.state.bodyMessage,
        });
        this.setState((prevState) => ({
            isPickerVisible: !prevState.isPickerVisible, // Toggles the state from true to false or vice versa
        }));
    };

    addBold = async (e) => {
        e.preventDefault();
        const input = document.getElementById("message-body");
        const startPos = input.selectionStart;
        const endPos = input.selectionEnd;
        const { bodyMessage } = this.state;
        const beforeSelection = bodyMessage.substring(0, startPos);
        const selectedText = bodyMessage.substring(startPos, endPos);
        const afterSelection = bodyMessage.substring(endPos);

        if (selectedText === "") {
            await this.setState((prevState) => ({
                bodyMessage: prevState.bodyMessage + "**",
            }));
            await this.setState({
                preBodyMessage: this.state.bodyMessage,
            });
            this.backCursor();
        } else {
            const newText =
                beforeSelection + "*" + selectedText + "*" + afterSelection;
            this.setState({ bodyMessage: newText });
            this.inputRef.current.focus();
        }
    };
    strikThrough = async (e) => {
        e.preventDefault();
        const input = document.getElementById("message-body");
        const startPos = input.selectionStart;
        const endPos = input.selectionEnd;
        const { bodyMessage } = this.state;
        const beforeSelection = bodyMessage.substring(0, startPos);
        const selectedText = bodyMessage.substring(startPos, endPos);
        const afterSelection = bodyMessage.substring(endPos);

        if (selectedText === "") {
            await this.setState((prevState) => ({
                bodyMessage: prevState.bodyMessage + "~~",
            }));
            await this.setState({
                preBodyMessage: this.state.bodyMessage,
            });
            this.backCursor();
        } else {
            const newText =
                beforeSelection + "~" + selectedText + "~" + afterSelection;
            this.setState({ bodyMessage: newText });
            this.inputRef.current.focus();
        }
    };

    addItalic = async (e) => {
        e.preventDefault();
        const input = document.getElementById("message-body");
        const startPos = input.selectionStart;
        const endPos = input.selectionEnd;
        const { bodyMessage } = this.state;
        const beforeSelection = bodyMessage.substring(0, startPos);
        const selectedText = bodyMessage.substring(startPos, endPos);
        const afterSelection = bodyMessage.substring(endPos);

        if (selectedText === "") {
            await this.setState((prevState) => ({
                bodyMessage: prevState.bodyMessage + "__",
            }));

            await this.setState({
                preBodyMessage: this.state.bodyMessage,
            });
            this.backCursor();
        } else {
            const newText =
                beforeSelection + "_" + selectedText + "_" + afterSelection;
            this.setState({ bodyMessage: newText });
            this.inputRef.current.focus();
        }
    };

    monoSpace = async (e) => {
        e.preventDefault();
        const input = document.getElementById("message-body");
        const startPos = input.selectionStart;
        const endPos = input.selectionEnd;
        const { bodyMessage } = this.state;
        const beforeSelection = bodyMessage.substring(0, startPos);
        const selectedText = bodyMessage.substring(startPos, endPos);
        const afterSelection = bodyMessage.substring(endPos);

        if (selectedText === "") {
            await this.setState((prevState) => ({
                bodyMessage: prevState.bodyMessage + "``````",
            }));
            await this.setState({
                preBodyMessage: this.state.bodyMessage,
            });
            if (this.inputRef.current) {
                const currentPosition = this.inputRef.current.selectionStart;
                const newPosition = Math.max(0, currentPosition - 3);

                this.inputRef.current.focus();
                this.inputRef.current.setSelectionRange(newPosition, newPosition);
            }
        } else {
            const newText =
                beforeSelection + "```" + selectedText + "```" + afterSelection;
            this.setState({ bodyMessage: newText });
            this.inputRef.current.focus();
        }
    };

    handleOptionChange = (event) => {
        this.setState({ selectedOption: event.target.value });
    };

    handleSecurityRecommendation = () => {
        this.setState({ securityRecommendation: !this.state.securityRecommendation });
    };

    handleExpiryTime = () => {
        this.setState({ expiryTime: !this.state.expiryTime });
    };

    CopyCodeChange = (event) => {
        const { value } = event.target;

        // Set state only if length is less than or equal to 25
        if (value.length <= 25) {
            this.setState({ buttonCopyCode: value });
        }
    };


    render() {
        // console.log(this.state.quickButton);
        // console.log(this.state.headerText + this.state.securityText)
        console.log(this.props.match.params.id)

        return (
            <DashboardLayout>
                {/* {this.state.isLoading && <Spining />} */}
                <main className="content">
                    <div className="container-fluid p-0">
                        <Title
                            isButton={true}
                            btnName={`Back To Template List`}
                            actionURL={`/dashboard/whatsapp-template-list`}
                        >
                            WhatsApp Template
                        </Title>

                        <div className="row justify-contect-center">
                            <div className="col-md-8">
                                {this.state.successMessage && (
                                    <Alert type={this.state.bgColor}>
                                        {this.state.errorMessage}
                                    </Alert>
                                )}

                                <div className="card whatapp-app">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0"> Template Details </h5>
                                    </div>

                                    <hr className="m-0" />

                                    <div className="card-body">
                                        {/* <Collapsible
                                            open={this.state.collaps1}
                                            triggerStyle={{ backgroundColor: "#065f54" }}
                                            trigger="Create Template"
                                        > */}
                                        <div className="p-3">
                                            <h3>Create Template</h3>
                                            <div className="input-group mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={this.state.tampleName}
                                                    onChange={(e) =>
                                                        this.setState({ tampleName: e.target.value.toLowerCase() })
                                                    }
                                                    name="tampleName"
                                                    placeholder="Enter Template Name (Ex : template_name)"
                                                />


                                            </div>
                                            <div className="input-group mb-3 drop-down-icons">
                                                {/* <select
                                                    value={this.state.language}
                                                    onChange={(e) =>
                                                        this.setState({ language: e.target.value })
                                                    }
                                                    name="language"
                                                    className="form-control"
                                                >
                                                    <option value={""}>Select Language</option>
                                                    <option value={"hi"}>Hindi</option>
                                                    <option value={"en"}>English</option>
                                                    <option value={"en_US"}>English(US)</option>
                                                </select> */}
                                                <Select
                                                    onChange={this.handleLanguageChange}
                                                    options={this.state.selectedLanguage}
                                                    value={this.state.language}
                                                    isSearchable={true}
                                                    placeholder="Select Language"
                                                    // isClearable={true}
                                                    styles={this.customStyles}
                                                // isDisabled={this.state.updateId ? true : false}
                                                />
                                            </div>
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" disabled value="Authentication" />

                                            </div>
                                            {/* <div className="input-group mb-3 drop-down-icons">
                          <select
                            disabled={this.state.paramID}
                            value={this.state.templateType}
                            onChange={(e) =>
                              this.setState({
                                templateType: e.target.value,
                                collaps1: false,
                                collaps2: true,
                              })
                            }
                            name="templateType"
                            className="form-control"
                          >
                            <option value={" "}>Select Template Type</option>
                            <option value={"Text"}>Text</option>
                            <option value={"Media"}>Media</option>
                          </select>
                        </div> */}
                                        </div>
                                        {/* </Collapsible> */}

                                        {/* <Collapsible
                                            open={this.state.collaps2}
                                            triggerStyle={{ backgroundColor: "#065f54" }}
                                            trigger="Code Delivery Setup"
                                            overflowWhenOpen='visible'
                                        > */}
                                        <div className="p-3">
                                            <div className="whatsapp-box">
                                                <div className="mb-3">
                                                    <h3>Code Delivery Setup</h3>
                                                    <p style={{ fontSize: "13px" }}>Choose How customer send the code from whatsapp to you app. Edits to this section won't require review or count towards edit limits, Learn how to send <span style={{ color: "blue" }}>authentication message template</span></p>
                                                    <div>
                                                        <h3>Select an option:</h3>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                value="zero_tap"
                                                                checked={this.state.selectedOption === 'zero_tap'}
                                                                onChange={this.handleOptionChange}
                                                            />
                                                            <span style={{ fontSize: "13px" }} className="ml-2">Zero-tap auto-fill</span><br />
                                                            <p style={{ fontSize: "10px" }} className="ml-3">This is recommended as the easiest option for your customers. Zero-tap will automatically send code without requiring your customer to tap a button An auto fill or copy code message will be sent if zero tap and autofill aren't possible</p>
                                                        </label>
                                                        <br />
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                value="one_tap"
                                                                checked={this.state.selectedOption === 'one_tap'}
                                                                onChange={this.handleOptionChange}
                                                            />
                                                            <span style={{ fontSize: "13px" }} className="ml-2">One-tap auto-fill</span>
                                                            <p style={{ fontSize: "10px" }} className="ml-3">The code sends to your app when customers tap the button . A copy code message will be sent if autofill isn't possile</p>
                                                        </label>
                                                        <br />
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                value="copy_code"
                                                                checked={this.state.selectedOption === 'copy_code'}
                                                                onChange={this.handleOptionChange}
                                                            />
                                                            <span style={{ fontSize: "13px" }} className="ml-2">Copy Code</span>
                                                            <p style={{ fontSize: "10px" }} className="ml-3">Basic authentication with quick setup. Your customers copy and paste the code into your app</p>
                                                        </label>
                                                        <br />
                                                        {this.state.selectedOption === 'one_tap' || this.state.selectedOption === 'zero_tap' ?
                                                            (
                                                                <>
                                                                    {this.state.paramID ? null :
                                                                        <div className="row">
                                                                            <div className="col-6">
                                                                                <label style={{ color: "black", fontSize: "13px" }}>Package Name</label>
                                                                                <input type="text" name="packagename" className="form-control" value={this.state.packageName} onChange={(e) => this.setState({ packageName: e.target.value })} placeholder="Enter Package Name" />
                                                                                <p style={{ fontSize: "11px" }}>Note: Enter only URL like: www.google.com</p>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <label style={{ color: "black", fontSize: "13px" }}>App Signature Hash</label>
                                                                                <input type="text" name="packagename" className="form-control" value={this.state.signatureHash} onChange={(e) => this.setState({ signatureHash: e.target.value })} placeholder="Enter app signature hash" />
                                                                                <p style={{ fontSize: "11px" }}>Note: Enter only 11 Characters</p>
                                                                            </div>
                                                                        </div>}
                                                                    {this.state.errorPackageName && (
                                                                        <Alert type={this.state.errorPackageColor}>
                                                                            {this.state.errorPackageMessage}
                                                                        </Alert>
                                                                    )}
                                                                </>
                                                            ) : null}

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="whatsapp-box">
                                                <div className="mb-3">
                                                    <label>
                                                        <h4>Content</h4>
                                                    </label>
                                                    <br />
                                                    <p style={{ fontSize: "13px" }}>
                                                        Content for authentication message templates can't be edited. You can additional content to the message below
                                                    </p>
                                                    <br />
                                                    <div>
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                checked={this.state.securityRecommendation}
                                                                onChange={this.handleSecurityRecommendation}
                                                            />
                                                            <span style={{ fontSize: "13px" }} className="ml-2">Add Security recommendation</span>
                                                        </label>
                                                        <br />
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                checked={this.state.expiryTime}
                                                                onChange={this.handleExpiryTime}
                                                            />
                                                            <span style={{ fontSize: "13px" }} className="ml-2">Add expiry time for the code</span>
                                                        </label>
                                                        <br />
                                                        {/* <p>Add Security recommendation is {this.state.securityRecommendation ? 'checked' : 'unchecked'}</p>
                                                            <p>Add expiry time for the code is {this.state.expiryTime ? 'checked' : 'unchecked'}</p> */}
                                                    </div>
                                                </div>
                                                {/* {this.state.footerOptions ? (
                                                        <div className="">
                                                            <input
                                                                type="text"
                                                                ref={this.ftinputRef}
                                                                defaultValue={this.state.footerText}

                                                                onChange={this.footerHandler
                                                                }
                                                                value={this.state.footerText}
                                                                className="form-control"
                                                                id="inputFirstName"
                                                                placeholder="Enter footer mesage"
                                                            />
                                                            <div
                                                                className="float-right"
                                                                style={{ fontSize: "13px" }}
                                                            >
                                                                Character : {this.state.ftcharCount}/
                                                                {this.state.ftmaxChars}
                                                            </div>
                                                        </div>

                                                    ) : null} */}
                                                {this.state.expiryTime === true ? (
                                                    <div className="container mt-3">
                                                        <div className="bg-light text-dark p-3" style={{ width: '450px', borderRadius: '10px' }}>
                                                            <label>Expires in</label><br />
                                                            <div className="input-group mb-3 drop-down-icons">
                                                                {this.state.errorSelectTime && (
                                                                    <Alert type={this.state.errorSelectTimeColor}>
                                                                        {this.state.errorSelectTimeMessage}
                                                                    </Alert>
                                                                )}
                                                                <select
                                                                    value={this.state.selectTime}
                                                                    onChange={(e) => this.setState({ selectTime: e.target.value })}
                                                                    className="form-control"
                                                                >
                                                                    <option value={""}>Select Time</option>
                                                                    {Array.from({ length: 90 }, (_, i) => (
                                                                        <option key={i + 1} value={i + 1}>
                                                                            {i + 1} minutes
                                                                        </option>
                                                                    ))}
                                                                </select>

                                                            </div>

                                                        </div>
                                                    </div>
                                                ) : null}

                                            </div>

                                            <div className="whatsapp-box">
                                                <div className="mb-3">
                                                    <label>
                                                        <h4>Buttons</h4>
                                                    </label>
                                                    <br />
                                                    {/* <p style={{ fontSize: "13px" }}>
                                                        You can customize the button text for both auto-fill and copy code.Even when zero-tap is turned on, buttons are still needed for the backup code delivery method
                                                    </p>
                                                    <br /> */}

                                                    {/* <label>
                                                        <h6>Copy Code</h6>
                                                    </label><br /> */}
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            value={this.state.buttonCopyCode}
                                                            className="form-control"
                                                            onChange={this.CopyCodeChange}
                                                            disabled
                                                            maxLength="25"
                                                        />
                                                        {/* <span style={{ minWidth: "50px", textAlign: "center" }} className="input-group-text">
                                                                {this.state.buttonCopyCode.length}/25
                                                            </span> */}
                                                    </div>

                                                    {this.state.selectedOption === 'one_tap' || this.state.selectedOption === 'zero_tap' ? (
                                                        <>
                                                            {/* <label>
                                                                <h6>Auto-fill</h6>
                                                            </label><br /> */}
                                                            <input type="text" value={this.state.autofill} disabled className="form-control mt-3" onChange={(e) => this.setState({ autofill: e.target.value })} /><br />
                                                        </>
                                                    ) : null}

                                                </div>
                                            </div>
                                        </div>
                                        {/* </Collapsible> */}

                                        {/* <FallBack sendSMStemplateId={this.getTemplateData} /> */}

                                        <div className="text-right">
                                            {this.state.paramID ? (
                                                <>
                                                    {this.state.specialUser === 34 ? null : (
                                                        <button
                                                            onClick={() => this.updateTemplate()}
                                                            className="btn btn-sm btn-info"
                                                        >
                                                            {" "}
                                                            <FontAwesomeIcon icon={faSave} /> Update Template
                                                        </button>
                                                    )}
                                                </>
                                            ) : (
                                                <div className="text-right">
                                                    <button
                                                        onClick={() => this.crateTemplate(0)}
                                                        className="btn btn-sm btn-info"
                                                    >
                                                        <FontAwesomeIcon icon={faSave} /> Save Draft
                                                    </button>

                                                    <button
                                                        type="submit"
                                                        onClick={() => this.crateTemplate(0)}
                                                        className="btn btn-sm btn-primary"
                                                    >
                                                        {" "}
                                                        Save & Send For Approvel{" "}
                                                        <FontAwesomeIcon icon={faSave} />
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 ">
                                <div className="card" style={{ position: "sticky", top: "0px" }}>
                                    <div className="card-header">
                                        <h5 className="card-title mb-0"> Template Preview </h5>
                                    </div>
                                    <hr className="m-0" />
                                    <div className="card-body">
                                        <WhatsAppPreviewAuthentication previewData={this.state} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </DashboardLayout>
        );
    }
}

export default WhatsAppTemplateAuthentication;
