import React, { useEffect, useState } from "react";
import AdvanceDataTable from "../UI/Table/AdvanceDataTable";
import { ADMIN_BILLING_SUMMARY, ADMIN_TOTAL_BILLING_SUMMARY } from "../UI/Table/ColumnConst";
import EmptyImg from "../Dashboard/assets/img/create-project.png";
import ReactDatePicker from "react-datepicker";
import AdminLayout from "../UI/Layout/AdminLayout";
import Title from "../UI/Text/Title";
import Spining from "../UI/Spining/Spining";
import { userloginDetails } from "../Utils/AdminAuth";
import Select from "react-select";
import Axios from "axios";
import { HOST_URL, NODE_HOST_URL } from "../Dashboard/api/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faCalendarAlt, faEdit, faSearch } from "@fortawesome/free-solid-svg-icons";

const VendorSummary = () => {
    const lStorage = userloginDetails();
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const adminType = lStorage.user.isAdmin;
    const [alert, setAlert] = useState({
        status: false,
        bgColor: "",
        errorMessage: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [fromdate, setFromdate] = useState(new Date());
    const [todate, setTodate] = useState(new Date());
    const [allusers, setAllusers] = useState([]);
    const [selecteduser, setSelectedusers] = useState("");
    const [billData, setBillData] = useState([]);
    const [searchData, setSearchData] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(50);
    const [openAccordion, setOpenAccordion] = useState(null);
    const [parentList, setParentList] = useState([])
    const [selectedParent, setSelectedParent] = useState("");
    const [email, setEmail] = useState([]);
    const [sms, setSms] = useState([]);
    const [voice, setVoice] = useState([]);
    const [whatsappApi, setWhatsappApi] = useState([]);
    const [whatsappCampaign, setWhatsappCampaign] = useState([]);
    const [agentDetails, setAgentDetails] = useState([]);
    const [agentCount, setAgentCount] = useState('');
    const [route, setRoute] = useState([]);
    const [selectedRoute, setSelectedRoute] = useState('');
    const [whatsappBilling, setWhatsappBilling] = useState([]);
    const [openItem, setOpenItem] = useState(false);
    const [openBillingItem, setOpenBillingItem] = useState(false)
    const [totalValue, setTotalValue] = useState({
        totalRead: 0,
        totalSent: 0,
        totalDelivered: 0,
        totalFailed: 0,
        totalSubmitted: 0,
        totalOptout: 0
    });
    const [totalBillingValue, setTotalBillingValue] = useState({
        totalSent: 0,
        totalDelivered: 0,
        totalFailed: 0,
        totalCost: 0
    });

    const toggleAccordion = () => {
        setOpenItem(!openItem);
    };

    const toggleAccordionwhatsappBilling = () => {
        setOpenBillingItem(!openBillingItem); // Toggle the accordion open state
    };

    const data = [
        { id: 1, name: 'John Doe', age: 30, address: '1234 Elm Street' },
        { id: 2, name: 'Jane Smith', age: 25, address: '5678 Oak Avenue' },
        { id: 3, name: 'Paul Johnson', age: 40, address: '9101 Maple Road' },
    ];

    useEffect(() => {
        ParentList();
        usersList();
        getRouteList();
    }, []);

    const getRouteList = async () => {
        const paramData = {
            user_id: userId,
            method: "retrieve",
            token: userToken,
            type: adminType
        }
        try {
            const { data } = await Axios.post(`${HOST_URL}/route.php`, paramData);
            if (data.success) {
                // console.log(data.data)
                let filterData = data.data.filter(x => x.channel === "whatsapp");
                console.log(filterData);
                setRoute(filterData);
            }
        } catch (error) {
            console.log(error)
        }
    }

    // const toggleAccordion = async (id, index) => {
    //     setOpenAccordion(openAccordion === index ? null : index);
    //     const ListParams = {
    //         method: "detailReport",
    //         user_id: userId,
    //         token: userToken,
    //         fromDate: currentDate(fromdate),
    //         toDate: currentDate(todate),
    //         retr_user_id: id,
    //     }
    //     try {
    //         const { data } = await Axios.post(`${NODE_HOST_URL}/admin/billing_report`, ListParams)
    //         if (data.success === true) {
    //             // console.log(data.data)
    //             setEmail(data.data.email);
    //             setWhatsappApi(data.data.whatsapp_api)
    //             setWhatsappCampaign(data?.data?.whatsapp_campaign)
    //             setSms(data.data.sms);
    //             setVoice(data.data.voice);
    //             totalData(data?.data?.whatsapp_campaign)
    //             setAgentDetails(data?.data?.agent_details)
    //             setAgentCount(data?.data?.agent_count)
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    const totalData = (data) => {
        let totalcost = [];

        data.map((x) => {
            console.log(x);
            return (
                totalcost = x.per_wp_price * x.total_delivered
            )
        });

        console.log(totalcost);

    }
    const customStyles = {
        control: (provided) => ({
            ...provided,
            cursor: "pointer",
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        option: (style) => ({
            ...style,
            fontSize: "12px",
        }),
    };

    const ParentList = async () => {
        const parentParams = {
            user_id: userId,
            method: "retrieve",
            type: adminType,
            token: userToken
        }
        try {
            const { data } = await Axios.post(`${HOST_URL}/employee_user_list.php`, parentParams)
            if (data.success === true) {
                let result = data.data
                const formattedParent = result.map(user => ({
                    value: user.id,
                    label: user.email,
                    type: user.user_type
                }));
                setParentList(formattedParent);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleParentChange = (selectedOption) => {
        setSelectedParent(selectedOption);
        if (selectedOption) {
            usersList(selectedOption.value);
        } else {
            usersList();
        }
    };

    const usersList = async (parentID) => {
        let response = await fetch(`${HOST_URL}/admin_user_list.php?user_id=${userId}&method=retrieve_all_user&type=${adminType}&token=${userToken}`);
        let result = await response.json();

        if (result.success) {
            const formattedUsers = result.data.map(user => ({
                value: user.id,
                label: user.email,
                parent: user.parent
            }));

            const newData = formattedUsers.filter(x => x.parent === parentID)

            if (parentID) {
                setAllusers(newData)
            }
            else {
                setAllusers(formattedUsers);
            }
        }
    }

    const currentDate = (currentDate) => {
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    };

    const fromdateHandler = (data) => {
        setFromdate(data);
    };

    const todateHandler = (data) => {
        setTodate(data);
    };

    const handlePagination = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = billData?.slice(indexOfFirstRecord, indexOfLastRecord);
    const totalPages = Math.ceil(billData?.length / recordsPerPage);

    const search = async () => {
        setIsLoading(true);
        let paramdata = {
            method: "summary",
            user_id: userId,
            token: userToken,
            fromdate: currentDate(fromdate),
            todate: currentDate(todate),
            retr_user_id: selecteduser ? selecteduser.value : "all",
            user_type: selectedParent ? selectedParent.type : adminType,
            uid: selectedParent ? selectedParent.value : "all"
        }
        try {
            const { data } = await Axios.post(`${NODE_HOST_URL}/admin/billing_summary`, paramdata)
            if (data.success === true) {
                // console.log(data.data);
                setBillData(data.data);
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);

    }

    const SearchVendorSummary = async () => {
        setIsLoading(true);
        let VendorParamdata = {
            method: "vendor_summary",
            user_id: userId,
            token: userToken,
            fromdate: currentDate(fromdate),
            todate: currentDate(todate),
            routeid: selectedRoute,
        }
        try {
            const { data } = await Axios.post(`${NODE_HOST_URL}/admin/vendor_summary`, VendorParamdata);
            if (data.success) {
                // console.log(data)
                setWhatsappApi(data.data.whatsapp_api);
                setWhatsappBilling(data.data.whatsapp_billing);
                totalWhatsappApiData(data.data.whatsapp_api);
                totalWhatsappBillingData(data.data.whatsapp_billing)
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    }

    const totalWhatsappApiData = (data) => {
        let totalSent = 0;
        let totalDelivered = 0;
        let totalFailed = 0;
        let totalRead = 0;
        let toatlSubmitted = 0;
        let totalOptout = 0;

        data.map((x) => {
            let innerData = x.data
            console.log(innerData)
            totalSent += parseInt(innerData.total_sent);
            totalDelivered += parseInt(innerData.total_delivered);
            totalFailed += parseInt(innerData.total_failed);
            totalRead += parseInt(innerData.total_read);
            toatlSubmitted += parseInt(innerData.total_submitted);
            totalOptout += parseInt(innerData.total_optout);
        });

        setTotalValue({
            totalRead: totalRead,
            totalSent: totalSent,
            totalDelivered: totalDelivered,
            totalFailed: totalFailed,
            totalSubmitted: toatlSubmitted,
            totalOptout: totalOptout
        });

    }

    const totalWhatsappBillingData = (data) => {
        let totalSent = 0;
        let totalDelivered = 0;
        let totalFailed = 0;
        let totalCost = 0;

        data.map((x) => {
            let innerData = x.data
            console.log(innerData)
            totalSent += parseInt(innerData.total_count);
            totalDelivered += parseInt(innerData.delivered_count);
            totalFailed += parseInt(innerData.failed_count);
            totalCost += parseFloat(innerData.total_wp_price * innerData.delivered_count)
        });

        setTotalBillingValue({
            totalSent: totalSent,
            totalDelivered: totalDelivered,
            totalFailed: totalFailed,
            totalCost: totalCost
        });

        console.log(totalCost);

    }

    console.log(whatsappApi);
    console.log(whatsappBilling);

    return (
        <div>
            <AdminLayout>
                <Title isButton={false}>Vendor Summary</Title>
                <div className="row">
                    {/* Total Sent Card */}
                    <div className="col-sm-4">
                        <div className="card">
                            <div className="card-body p-3">
                                <h5 className="card-title mb-0">Total Sent</h5>
                                <h6 className="mt-0 mb-0">{totalValue.totalSent + totalBillingValue.totalSent || 0}</h6>
                            </div>
                        </div>
                    </div>

                    {/* Total Delivered Card */}
                    <div className="col-sm-4">
                        <div className="card">
                            <div className="card-body p-3">
                                <h5 className="card-title mb-0">Total Delivered</h5>
                                <h6 className="mt-0 mb-0">{totalValue.totalDelivered + totalBillingValue.totalDelivered || 0}</h6>
                            </div>
                        </div>
                    </div>

                    {/* Total Failed Card */}
                    <div className="col-sm-4">
                        <div className="card">
                            <div className="card-body p-3">
                                <h5 className="card-title mb-0">Total Failed</h5>
                                <h6 className="mt-0 mb-0">{totalValue.totalFailed + totalBillingValue.totalFailed || 0}</h6>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Alert Section */}
                {alert.status === true ? (
                    <div className={alert.bgColor} role="alert">
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        <div>{alert.errorMessage}</div>
                    </div>
                ) : null}

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            {isLoading && <Spining />}

                            <div className="card-body">
                                <div className="filter-option">
                                    <h6 className="card-subtitle text-muted">Vendor Summary</h6>

                                    {/* Filter Section */}
                                    <div className="row mb-3">
                                        <div className="col-md-4">
                                            <select value={selectedRoute} className="form-control" onChange={(e) => setSelectedRoute(e.target.value)}>
                                                <option value="">Select Route</option>
                                                {route?.map((x) => (
                                                    <option key={x.id} value={x.id}>{x.route_name}</option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="input-group">
                                                {/* <div className="input-group-text">
                                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                                </div> */}
                                                <ReactDatePicker
                                                    className="form-control"
                                                    dateFormat="dd/MM/yyyy"
                                                    value={fromdate}
                                                    onChange={fromdateHandler}
                                                    selected={fromdate}
                                                    placeholderText="From Date"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="input-group">
                                                {/* <div className="input-group-text">
                                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                                </div> */}
                                                <ReactDatePicker
                                                    className="form-control"
                                                    dateFormat="dd/MM/yyyy"
                                                    value={todate}
                                                    onChange={todateHandler}
                                                    selected={todate}
                                                    placeholderText="Date To"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-md-12 d-flex justify-content-center">
                                            <button type="button" onClick={SearchVendorSummary} className="btn btn-sm btn-info">Search</button>
                                        </div>
                                    </div>
                                </div>

                                {/* Whatsapp Api Accordion */}
                                {whatsappApi.length > 0 && (
                                    <div className="accordion mb-4" id="accordionTable">
                                        <div className="card">
                                            <div className="card-header d-flex justify-content-between align-items-center" id="headingWhatsappApi">
                                                <h2 className="mb-0">
                                                    <button
                                                        className="btn btn-link"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseWhatsappApi"
                                                        aria-expanded={openItem ? 'true' : 'false'}
                                                        aria-controls="collapseWhatsappApi"
                                                        onClick={() => toggleAccordion()}
                                                    >
                                                        Whatsapp API
                                                    </button>
                                                </h2>
                                                <div className="d-flex">
                                                    <span className="ml-4" style={{ fontSize: "12px" }}><b>Total Delivered:</b> {totalValue.totalDelivered}</span>
                                                    <span className="ml-4" style={{ fontSize: "12px" }}><b>Total Sent:</b> {totalValue.totalSent}</span>
                                                    <span className="ml-4" style={{ fontSize: "12px" }}><b>Total Read:</b> {totalValue.totalRead}</span>
                                                    <span className="ml-4" style={{ fontSize: "12px" }}><b>Total Failed:</b> {totalValue.totalFailed}</span>
                                                    <span className="ml-4" style={{ fontSize: "12px" }}><b>Total Submitted:</b> {totalValue.totalSubmitted}</span>
                                                    <span className="ml-4" style={{ fontSize: "12px" }}><b>Total Optout:</b> {totalValue.totalOptout}</span>
                                                </div>
                                            </div>

                                            <div id="collapseWhatsappApi" className={`collapse ${openItem ? 'show' : ''}`} aria-labelledby="headingWhatsappApi" data-bs-parent="#accordionTable">
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table className="table table-striped table-sm">
                                                            <thead>
                                                                <tr>
                                                                    {['Date', 'Billable Cost', 'Non Billable Cost', 'Billable Count', 'Non Billable Count', 'Submitted', 'Optout', 'Sent', 'Delivered', 'Failed', 'Read'].map((header, idx) => (
                                                                        <th key={idx} style={{ backgroundColor: "#85A98F" }} className="d-xl-table-cell">{header}</th>
                                                                    ))}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {whatsappApi.map((item) => {
                                                                    const data = item.data;
                                                                    return (
                                                                        <tr key={item.id}>
                                                                            <td>{item.date}</td>
                                                                            <td>{data.total_billable_cost}</td>
                                                                            <td>{data.total_nonbillable_cost}</td>
                                                                            <td>{data.total_billable_count}</td>
                                                                            <td>{data.total_nonbillable_count}</td>
                                                                            <td>{data.total_submitted}</td>
                                                                            <td>{data.total_optout}</td>
                                                                            <td>{data.total_sent}</td>
                                                                            <td>{data.total_delivered}</td>
                                                                            <td>{data.total_failed}</td>
                                                                            <td>{data.total_read}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* Whatsapp Billing Accordion */}
                                {whatsappBilling.length > 0 && (
                                    <div className="accordion" id="accordionTable">
                                        <div className="card">
                                            <div className="card-header d-flex justify-content-between align-items-center" id="headingWhatsappBilling">
                                                <h2 className="mb-0">
                                                    <button
                                                        className="btn btn-link"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseWhatsappBilling"
                                                        aria-expanded={openBillingItem ? 'true' : 'false'}
                                                        aria-controls="collapseWhatsappBilling"
                                                        onClick={() => toggleAccordionwhatsappBilling()}
                                                    >
                                                        Whatsapp Billing
                                                    </button>
                                                </h2>
                                                <div className="d-flex">
                                                    <span className="ml-4" style={{ fontSize: "14px" }}><b>Total Sent:</b> {totalBillingValue.totalSent}</span>
                                                    <span className="ml-4" style={{ fontSize: "14px" }}><b>Total Delivered:</b> {totalBillingValue.totalDelivered}</span>
                                                    <span className="ml-4" style={{ fontSize: "14px" }}><b>Total Failed:</b> {totalBillingValue.totalFailed}</span>
                                                    <span className="ml-4" style={{ fontSize: "14px" }}><b>Total Cost:</b> {totalBillingValue.totalCost}</span>
                                                </div>
                                            </div>

                                            <div id="collapseWhatsappBilling" className={`collapse ${openBillingItem ? 'show' : ''}`} aria-labelledby="headingWhatsappBilling" data-bs-parent="#accordionTable">
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table className="table table-striped table-sm">
                                                            <thead>
                                                                <tr>
                                                                    {['Date', 'Template Type', 'Total', 'Price', 'Delivered', 'Failed', 'Other', 'Total Cost'].map((header, idx) => (
                                                                        <th key={idx} style={{ backgroundColor: "#85A98F" }} className="d-xl-table-cell">{header}</th>
                                                                    ))}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {whatsappBilling.map((item) => {
                                                                    const data = item.data;
                                                                    return (
                                                                        <tr key={item.id}>
                                                                            <td>{item.date}</td>
                                                                            <td>{data.template_type === 1 ? 'Marketing' : data.template_type === 2 ? 'Utility' : 'Authentication'}</td>
                                                                            <td>{data.total_count}</td>
                                                                            <td>{data.total_wp_price}</td>
                                                                            <td>{data.delivered_count}</td>
                                                                            <td>{data.failed_count}</td>
                                                                            <td>{data.other_count}</td>
                                                                            <td>{data.total_wp_price * data.delivered_count}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </AdminLayout>
        </div>

    );
};

export default VendorSummary;
