import React, { Component } from "react";
import DashboardHeader from "../../Header/DashboardHeader";
import DashboardFooter from "../../Footer/DashboardFooter";
import DashboardLeftMenu from "../DashboardLeftMenu";
import { HOST_URL } from "../api/Api";
class CreateProject extends Component {
  constructor() {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;

    super();
    this.state = {
      user_id: userId,
      token: userToken,
      project_name: null,
      website: null,
      method: "create",
      successMessage: false,
      bgColor: "alert alert-success alert-dismissible",
    };
  }

  createProject() {
    fetch(`${HOST_URL}/project_template.php`, {
      method: "post",
      headers: {
        "content-Type": "application/json",
      },
      body: JSON.stringify(this.state),
    }).then((result) => {
      //	console.log(result);
      result.json().then((resp) => {
        if (resp.success === true) {
          this.setState({ successMessage: true });
          this.setState({ errorMessage: resp.message });
          this.setState({ bgColor: "alert alert-success alert-dismissible" });
          this.props.history.push("project-list");
        } else {
          this.setState({ successMessage: true });
          this.setState({ errorMessage: resp.message });
          this.setState({ bgColor: "alert alert-danger alert-dismissible" });
        }
      });
    });
  }

  render() {
    return (
      <div className="wrapper">
        <DashboardLeftMenu />
        <div className="main">
          <DashboardHeader />

          <main className="content">
            <div className="container-fluid p-0">
              <div className="row mb-2 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                  <h3>
                    <strong>Create New</strong> Project{" "}
                  </h3>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="card">
                    <div className="card-body">
                      {this.state.successMessage ? (
                        <div className={this.state.bgColor} role="alert">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                          <div className="">{this.state.errorMessage}</div>
                        </div>
                      ) : null}

                      <form>
                        <div className="row justify-content-center">
                          <div className="mb-3 col-md-12">
                            <div className="text-center">
                              <h2 className="font-weight-bold mb-4">
                                Add Your Amazing Project
                              </h2>

                              <p>
                                {" "}
                                The project is an identifier under which you can
                                configure multiple events. Each project can
                                represent your website, mobile app or any other
                                specific platform.{" "}
                              </p>
                            </div>

                            <div className="input-group mb-2">
                              <input
                                type="text"
                                onChange={(event) => {
                                  this.setState({
                                    project_name: event.target.value,
                                  });
                                }}
                                className="form-control"
                                id="inputEmail4"
                                placeholder="Enter Project Name (For Example: eCommerce)"
                              />
                            </div>

                            <div className="input-group">
                              <input
                                type="text"
                                onChange={(event) => {
                                  this.setState({
                                    website: event.target.value,
                                  });
                                }}
                                className="form-control mt-2"
                                id="inputEmail4"
                                placeholder="www.example.com (Optional)"
                              />
                            </div>

                            <button
                              type="button"
                              onClick={() => {
                                this.createProject();
                              }}
                              className="btn btn-block mt-3 btn-primary"
                            >
                              Create a project
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>

          <DashboardFooter />
        </div>
      </div>
    );
  }
}

export default CreateProject;
