import React, { useEffect, useState } from 'react';
import './whatsappForm/Carousel.css';
import { faBold, faCode, faInfoCircle, faItalic, faSmile, faStrikethrough, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { HOST_URL, WhstappGalleryList } from '../api/Api';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { userId, userToken } from '../../Utils/userAuth';

const Carousel = (props) => {
    let previewData = props.previewData
    const finalRetrieveData = props.previewData.ReceiveFinalData
    console.log(finalRetrieveData)
    const { sendData, button, cradLength, moveCard, moveWindow, sendCardContent, sendFile, sendIndex, onForwardFunctionSend, onBackwardFunctionSend, SendfinalData, sendMedia, mediaidData, sendImage } = props;
    const [cards, setCards] = useState([1]);
    const [selectedCard, setSelectedCard] = useState(1);
    const [selectedIndex, setSelectedIndex] = useState(1);
    const [cardContent, setCardContent] = useState({ 1: '' });
    const [enableButton, setEnableButton] = useState(false);
    const [moveCards, setMoveCards] = useState('');
    const [move, setMove] = useState(1);
    const [image, setImage] = useState(null);
    const [WhatsappPreview, setWhatsappPreview] = useState(false);
    const [headerImage, setHeaderImage] = useState({}); // To manage header images
    const [majorData, setMajorData] = useState({})
    const [receiveData, setReceiveData] = useState({})
    const [fileType, setFileType] = useState('')
    const [file, setFile] = useState(null);
    const [mediaId, setMediaID] = useState('');
    const [arrayId, setArrayId] = useState([]);
    const [headerHandler, setHeaderHandler] = useState({ 1: '' });
    const [imageSrc, setImageSrc] = useState({});
    const [receiveRetData , setRetReceiveData] = useState(finalRetrieveData);


    const onMessageChange = (e) => {
        const newContent = e.target.value;
        setCardContent(prevContent => ({
            ...prevContent,
            [selectedCard]: newContent
        }));
    };
    const [media, setMedia] = useState({ 1: '' });
    const [medialist, setMediaList] = useState();
    const [imageURL, setImageURL] = useState();

    const history = useHistory();

    // const CreateSession = async () => {
    //     const myHeaders = new Headers();
    //     myHeaders.append("apikey", "daeec986-6368-11ef-b1d4-02c8a5e042bd");

    //     const requestOptions = {
    //         method: "POST",
    //         headers: myHeaders,
    //         redirect: "follow"
    //     };

    //     let response = await Axios.get(`https://partnersv1.pinbot.ai/v3/app/uploads`, {
    //         headers: {
    //             'apikey': "daeec986-6368-11ef-b1d4-02c8a5e042bd"  // Add other headers if needed
    //         }
    //     })

    //     console.log(response)

    // };

    // console.log(cardContent)




    // console.log(props.finalData)

    useEffect(() => {
        setReceiveData(props.finalData)
    }, [props.finalData])

    // useEffect(()=>{
    //     setCards(prevContent => ({
    //         ...prevContent,
    //          receiveData
    //       }));
    // },[receiveData])

    // console.log(receiveData)
    // console.log(cards)

    const changeImage = (e) => {
        const file = e.target.files;
        if (file) {
            const newImageURL = URL.createObjectURL(file[0]);
            setImageURL(newImageURL);
            setMedia(prevImages => ({
                ...prevImages,
                [selectedCard]: newImageURL
            }));
        }
    };

    // const handleUpload = () => {
    //     const file = image[selectedCard];
    //     if (file) {
    //         sendFile(file.name);
    //     }
    // };

    const addCard = () => {
        if (cards.length < 10) {
            const newCardId = cards.length + 1;
            setCards([...cards, newCardId]);
            setCardContent(prevContent => ({ ...prevContent, [newCardId]: '' }));
        }
    };

    const HandleButton = () => {
        setEnableButton(cards.length > 1);
    };

    const DeleteCards = () => {
        if (cards.length > 0) {
            const updatedCards = cards.slice(0, -1);
            setCards(updatedCards);

            if (updatedCards.length > 0) {
                setSelectedCard(updatedCards[0]);
            } else {
                setSelectedCard(null);
            }

        }
    };


    const Action = (card, index) => {
        setSelectedCard(card);
        setSelectedIndex(index);
    };

    const moveCardForward = () => {
        setSelectedCard(selectedCard + 1);
        setSelectedIndex(selectedIndex + 1);
    }

    const moveCardBackward = () => {
        setSelectedCard(selectedCard - 1);
        setSelectedIndex(selectedIndex - 1);
    }




    useEffect(() => {
        WhstappGalleryList().then((res) => {
            // console.log("res.data.data", res.data.data);
            setMediaList(res.data.data);
        });
    }, []);



    // console.log(JSON.stringify(majorData))
    useEffect(() => {
        const SendDataToparent = () => {
            sendData(selectedCard);
            sendIndex(selectedIndex);
            button(enableButton);
            cradLength(cards);
            moveWindow(moveCards);
            setMove(props.move);
            sendCardContent(cardContent);
            SendfinalData(majorData);
            sendMedia(media);
            mediaidData(arrayId);
            sendImage(imageSrc);
        };
        SendDataToparent();
        HandleButton();
        setMoveCards(selectedCard);
    }, [selectedCard, sendData, enableButton, button, cards, cradLength, selectedCard, moveCard, moveCards, moveWindow, cardContent, sendCardContent, sendIndex, selectedIndex, SendfinalData, majorData, sendMedia, media, mediaidData, arrayId, sendImage, imageSrc]);

    const Redirect = () => {
        history.push('/dashboard/whatsapp-gallery')
    }

    const onImageChange = (e) => {
        const selectedValue = e.target.value;
        // Set the selected media URL to state
        setMedia(prevMedia => ({
            ...prevMedia,
            [selectedCard]: selectedValue
        }));
    };

    const onUrlChange = (e) => {
        setImageURL(e.target.value);
    }

    // console.log(media)

    const setImageinText = () => {
        // Assuming `media` is what you want to set for the card
        setMedia(prevMedia => ({
            ...prevMedia,
            [selectedCard]: imageURL
        }));
    };

    // console.log(medialist)

    // console.log(media);
    // console.log(imageURL)

    // const SendImage = async () => {
    //     let data = {
    //         token: userToken,
    //         user_id: userId,
    //         method: "upload_image_meta",
    //         image_url: media
    //     }
    //     console.log(data)
    //     let response = await fetch("https://console.authkey.io/api/whatsapp_template.php", {
    //         method: "post",
    //         headers: {
    //             "content-Type": "application/json",
    //         },
    //         body: JSON.stringify(data),
    //     });
    //     let result = await response.json()
    //     if (result) {
    //         console.log(result)
    //         generateJSONData(result.message);
    //     }
    // }

    // console.log(props.previewData);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setImageSrc(prevContent => ({
                    ...prevContent,
                    [selectedCard]: reader.result // Store the image source for display
                }));
            };

            if (selectedFile.type.startsWith('image/')) {
                setFile(prevContent => ({
                    ...prevContent,
                    [selectedCard]: selectedFile // Store the selected file for the card
                }));
            } else {
                alert('Please select a valid image file');
            }

            reader.readAsDataURL(selectedFile); // Read the file as a data URL
        }
    };

    console.log(imageSrc)

    // console.log(file);
    // console.log(previewData); 

    const SubmitFile = async () => {
        // Prepare formData for the first API call
        let formData1 = new FormData();
        formData1.append('amdfile', file[selectedCard]);
        formData1.append('method', 'upload');
        formData1.append('user_id', previewData.userID);
        formData1.append('token', previewData.token);

        // Prepare formData for the second API call
        let formData2 = new FormData();
        formData2.append('file', file[selectedCard]);
        formData2.append('method', 'upload');
        formData2.append('user_id', previewData.userID);
        formData2.append('token', previewData.token);

        try {
            // Call both APIs in parallel using Promise.all
            const [response1, response2] = await Promise.all([
                fetch(`${HOST_URL}/get_facebook_handleid.php`, { method: "POST", body: formData1 }),
                fetch(`${HOST_URL}/get_facebook_mediaid.php`, { method: "POST", body: formData2 })
            ]);

            // Handle the first response
            const result1 = await response1.json();
            if (result1.success) {
                // setHeaderHandler(result1.header_handle);
                setHeaderHandler(prevContent => ({
                    ...prevContent,
                    [selectedCard]: result1.header_handle
                }));
                // this.setState({
                //   header_handle: result1.header_handle,
                //   isLoading: false
                // });
            }

            // Handle the second response
            const result2 = await response2.json();
            if (result2.success) {
                setMediaID(result2.media_id); // Assuming you want to save the media_id as well
                if (result2.media_id !== null) {
                    setArrayId((prevArray) => [...prevArray, result2.media_id]);
                }
            }
        } catch (error) {
            console.log("Error during API calls:", error);
        }
    };

    const generateJSONData = () => {
        if (!cards || !Array.isArray(cards)) {
            console.error("cards is undefined or not an array");
            return;
        }

        let data = {
            type: "CAROUSEL",
            cards: cards.map((card) => {
                let buttons = [];

                if (previewData.buttonType === "quick reply" && previewData.quickButton && previewData.quickButton.length > 0) {
                    const buttonData = previewData.quickButton[0];

                    if (buttonData.button1) {
                        buttons.push({
                            type: previewData.buttonType === 'quick reply' ? "QUICK_REPLY" : previewData.buttonType,
                            text: buttonData.button1
                        });
                    }
                    if (buttonData.button2) {
                        buttons.push({
                            type: previewData.buttonType === 'quick reply' ? "QUICK_REPLY" : previewData.buttonType,
                            text: buttonData.button2
                        });
                    }
                    if (buttonData.button3) {
                        buttons.push({
                            type: previewData.buttonType === 'quick reply' ? "QUICK_REPLY" : previewData.buttonType,
                            text: buttonData.button3
                        });
                    }
                } else if (Array.isArray(previewData.Urlbutton) && previewData.Urlbutton.length > 0) {

                    if (previewData.Callbutton) {
                        buttons.push({
                            countryCode: previewData.Callbutton[0].countryCode || "",
                            text: previewData.Callbutton[0].text || "",
                            phone: previewData.Callbutton[0].phone || ""
                        });
                    }

                    if (previewData.Urlbutton) {
                        buttons.push({
                            type: previewData.Urlbutton[0].urlType || "",
                            text: previewData.Urlbutton[0].urltext || "",
                            url: previewData.Urlbutton[0].url || ""
                        });
                    }
                    if (previewData.Urlbutton2) {
                        buttons.push({
                            type: previewData.Urlbutton2[0].urlType || "",
                            text: previewData.Urlbutton2[0].urltext2 || "",
                            url: previewData.Urlbutton2[0].url || ""
                        });
                    }
                    // previewData.Urlbutton.forEach((urlButton) => {
                    //     buttons.push({
                    //         type: urlButton.urlType || "",
                    //         text: urlButton.urltext || "",
                    //         url: urlButton.url || ""
                    //     });
                    // });
                }

                return {
                    components: [
                        {
                            type: "HEADER",
                            format: "IMAGE",
                            example: {
                                // header_handle: media?.[card] || ""
                                header_handle: headerHandler?.[card] || "",
                            }
                        },
                        {
                            type: "BODY",
                            text: cardContent?.[card] || "",
                            // example: {
                            //     body_text: [["example_code", "example_discount"]]
                            // }
                        },
                        {
                            type: "BUTTONS",
                            buttons: buttons
                        }
                    ]
                };
            })
        };

        setMajorData(data);
    };


    // console.log(mediaId);
    // console.log(arrayId);
    // console.log(headerHandler);
    // console.log(majorData);
    console.log(receiveRetData)
      
     






    return (
        <>
            <div className='corousal-card'>
                <div className='details-section'>
                    <div style={{ fontWeight: "bold" }}>Carousel Card</div>
                    <p style={{ fontSize: "12px" }}>You can set priority by dragging and dropping cards from left to right</p>
                    <div className='section-body-wrap'>
                        <div className='corousal-card-pagination-bar-container'>
                            <ul className='whatsappCardList' id='item'>
                                {cards.map((card, i) => {
                                    let index = i + 1;
                                    return (
                                        <li
                                            key={card}
                                            className={index === selectedCard ? 'active-dropzone' : 'choose-list'}
                                            onClick={() => Action(card, index)}
                                        >
                                            Card {card}
                                        </li>
                                    )
                                })}
                                {cards.length < 10 && (
                                    <li className='add-list' style={{ fontSize: "12px" }} onClick={addCard}>
                                        + Add Card
                                    </li>
                                )}
                            </ul>

                        </div>
                        <div className='row'>
                            {/* <div className='col-9'>
                            <div className="d-flex flex-row mb-3 whatsappInputFile">
                                <input
                                    type="file"
                                    className="form-control whatsappFormFIle"
                                    name="imagefile"
                                    accept="image/*"
                                    onChange={changeImage}
                                />
                                <button
                                    className="btn btn-sm btn-primary"
                                    onClick={CreateSession}
                                    style={{ height: "10vh", width: "30vh" }}
                                >
                                    Upload
                                </button>
                            </div>
                        </div> */}
                            {/* <div className='col-10 mt-2'>
                                <div className="bg-dark">
                                    <div className="input-group mb-2 mr-sm-2">
                                        <div className="input-group-text">
                                            Image URL
                                        </div>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={media[selectedCard] || ''}
                                            onChange={onImageChange}
                                        />
                                    </div>
                                </div>
                            </div> */}
                            <div className='col-2'>
                                {cards.length > 1 && (
                                    <FontAwesomeIcon onClick={DeleteCards} icon={faTrashAlt} />
                                )}
                            </div>
                        </div>
                        {/* <div className='row mb-3'>
                            <div className='col-8'>
                                <span
                                    data-toggle="modal"
                                    data-target="#galleryPopup"
                                    className="badge badge-info"
                                >
                                    Choose From Media Gallery
                                </span>                               
                            </div>
                            <div className='col-4'>
                                {media ? (
                                        Object.keys(media).map((x, i) => {
                                            // console.log(media[x])
                                            let index = i + 1
                                            return (
                                                <div key={index}>
                                                    {index === selectedCard ? <a target="_blank" href={media[x]} rel="noopener noreferrer">
                                                        <span className="badge badge-success">Preview</span>
                                                    </a> : null}
                                                </div>
                                            )
                                        })
                                    ) :
                                        null
                                    }
                                </div>
                        </div> */}

                        <div className="input-group mb-2 mt-4 mr-sm-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">Image Upload</div>
                            </div>
                            <input
                                type="file"
                                name="file"
                                accept=".jpg, .jpeg, .png"
                                onChange={handleFileChange}
                                className="form-control"
                            />
                            <div className="input-group-prepend">
                                {/* <div className="input-group-text">Image Upload</div> */}
                                <button type="button" onClick={SubmitFile} className="input-group-text btn btn-info">Submit</button>
                            </div>
                        </div>

                        <div className="whatsapp-box">
                            <div className="mb-3">
                                <label>
                                    <h4>
                                        Card Body
                                        <span className="text-danger">*</span>
                                    </h4>
                                </label>
                                <textarea
                                    id="message-body"
                                    onChange={onMessageChange}
                                    rows={5}
                                    value={cardContent[selectedCard] || ''}
                                    placeholder='Enter Text Here'
                                    className="form-control mb-1"
                                ></textarea>
                                {/* <div className="d-flex justify-content-between">
                                    <div className="d-flex">
                                        <div className="mx-1 px-1 cursor-pointer">
                                            <FontAwesomeIcon icon={faSmile} />
                                        </div>
                                        <div className="mx-1 px-1 cursor-pointer">
                                            <FontAwesomeIcon icon={faBold} />
                                        </div>
                                        <div className="mx-1 px-1 cursor-pointer">
                                            <FontAwesomeIcon icon={faItalic} />
                                        </div>
                                        <div className="mx-1 px-1 cursor-pointer">
                                            <FontAwesomeIcon icon={faStrikethrough} />
                                        </div>
                                        <div className="mx-1 px-1 cursor-pointer">
                                            <FontAwesomeIcon icon={faCode} />
                                        </div>
                                    </div>
                                    <div>
                                        <span><FontAwesomeIcon icon={faInfoCircle} /></span>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mt-3">
                            <button
                                className="btn btn-sm btn-secondary"
                                onClick={moveCardBackward}
                            // disabled={cards.indexOf(selectedCard) === 0}
                            >
                                Move Backward
                            </button>
                            <button
                                className="btn btn-sm btn-secondary"
                                onClick={generateJSONData}
                            // disabled={cards.indexOf(selectedCard) === cards.length - 1}
                            >
                                Move Forward
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal fade"
                id="galleryPopup"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="galleryPopupLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="galleryPopupLabel">
                                Choose Media
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {medialist && (
                                <div className="input-group drop-down-icons">
                                    <select
                                        onChange={onUrlChange}
                                        className="form-control"
                                        value={imageURL}
                                    >
                                        <option value="">Select Media</option>
                                        {medialist.map((item) => (
                                            <option value={item.doc_url}>{item.doc_name}</option>
                                        ))}
                                    </select>
                                </div>
                            )}

                            <a className="mt-3 d-block" target="_blank" href={imageURL}>
                                <span className="badge badge-success">Preview</span>
                            </a>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                data-dismiss="modal"
                                className="btn btn-warning"
                                onClick={Redirect}
                            >
                                Upload
                            </button>
                            <button
                                type="button"
                                data-dismiss="modal"
                                className="btn btn-primary"
                                onClick={setImageinText}
                            >
                                Select
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Carousel;
