import React, { Component } from "react";
import { Link } from "react-router-dom";
import AdminHeader from "../Header/AdminHeader";
import AdminFooter from "../Footer/AdminFooter";
import AdminLeftMenu from "../Navbar/AdminLeftMenu";
import { userloginDetails } from "../Utils/AdminAuth";
import Error from "../Dashboard/Error";
import { HOST_URL, NODE_HOST_URL } from "../Dashboard/api/Api";
import Axios from "../../axios/Axios";

class UserAddPrice extends Component {
  constructor() {
    const lStorage = userloginDetails();
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const empType = lStorage.user.isAdmin;

    super();

    this.state = {
      user_id: userId,
      token: userToken,
      emp_type: empType,
      successMessage: false,
      creditAmount: "",
      creditType: "",
      reMark: "",
      seletedUserId: "",
      selectedUserType: "",
      errorMessage: "",
      bgColor: "alert alert-success alert-dismissible",
      currentBalance: 0,
      currency: "",
      disableButton: false,
    };
  }

  componentDidMount() {
    this.getUserDetails();
    this.setState({
      seletedUserId: this.props.match.params.id,
      selectedUserType: this.props.match.params.userType,
    });
  }

  getUserDetails() {
    this.setState({ seletedUserId: this.props.match.params.id });
    fetch(
      `${HOST_URL}/admin_user_list.php?user_id=${this.state.user_id}&method=retrieve&type=userdetails&ret_user_id=${this.props.match.params.id}&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        // console.log('user',result);
        if (result.success === true) {
          this.setState({
            email: result.data[0].email,
            currentBalance: result.data[0].currency,
            currency: result.data[0].country_currency,
          });
        } else {
          this.setState({
            successMessage: true,
            successBg: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  }

  // addCredit = async() => {
  //   document.querySelector("body").scrollTo(0, 0);
  //   this.setState({disableButton: true})

  //   fetch(
  //     `${HOST_URL}/add_balance.php?user_id=${this.state.user_id}&method=add&amount=${this.state.creditAmount}&description=${this.state.reMark}&ret_user_id=${this.props.match.params.id}&credit_type=${this.state.creditType}&token=${this.state.token}`
  //   ).then((response) => {
  //     response.json().then((result) => {
  //       //  console.log(result);
  //       if (result.success === true) {
  //         this.setState({
  //           successMessage: true,
  //           bgColor: "alert alert-success alert-dismissible",
  //           errorMessage: result.message,
  //         });
  //         this.props.history.push(
  //           "/admin/user-transaction-details/" + this.props.match.params.id+"/client"
  //         );
  //         this.setState({disableButton: false})
  //       } else {
  //         this.setState({
  //           successMessage: true,
  //           bgColor: "alert alert-danger alert-dismissible",
  //           errorMessage: result.message,
  //         });
  //       }
  //     });
  //   });
  // }

  addCredit = async () => {
    this.setState({ disableButton: true });
    const paramData = {
      user_id: this.state.user_id,
      token: this.state.token,
      method: "recharge",
      recever_id: this.props.match.params.id,
      amount: this.state.creditAmount,
      des: this.state.reMark,
    };
    try {
      let { data } = await Axios.post(`${NODE_HOST_URL}/user`, paramData);
      if (data.success) {
        this.setState({
          successMessage: true,
          bgColor: "alert alert-success alert-dismissible",
          errorMessage: data.message,
        });
        this.props.history.push(
          "/admin/user-transaction-details/" +
            this.props.match.params.id +
            "/client"
        );
        this.setState({ disableButton: false });
      } else {
        this.setState({
          successMessage: true,
          bgColor: "alert alert-danger alert-dismissible",
          errorMessage: data.message,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <>
        {this.state.emp_type === "reseller" ? (
          <Error />
        ) : (
          <div className="wrapper">
            <AdminLeftMenu />
            <div className="main">
              <AdminHeader />
              <main className="content">
                <div className="container-fluid p-0">
                  <div className="row mb-2 mb-xl-3">
                    <div className="col-auto d-none d-sm-block">
                      <h3>
                        User Details{" "}
                        <strong className="text-info">
                          {" "}
                          {this.state.email}{" "}
                        </strong>{" "}
                      </h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title mb-0">
                          <div className="col-auto ml-auto text-right mt-n1">
                            <nav
                              className="text-center"
                              aria-label="breadcrumb"
                            >
                              <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
                                <Link
                                  to={
                                    "/admin/user-details/" +
                                    this.state.seletedUserId +
                                    `/` +
                                    this.state.selectedUserType
                                  }
                                >
                                  <li className="breadcrumb-item">
                                    User Details{" "}
                                  </li>{" "}
                                </Link>

                                <Link
                                  to={
                                    "/admin/user-transaction-details/" +
                                    this.state.seletedUserId +
                                    `/` +
                                    this.state.selectedUserType
                                  }
                                >
                                  <li className="breadcrumb-item">
                                    {" "}
                                    Transaction Details{" "}
                                  </li>
                                </Link>

                                <Link
                                  to={
                                    "/admin/user-price-details/" +
                                    this.state.seletedUserId +
                                    `/` +
                                    this.state.selectedUserType
                                  }
                                >
                                  <li className="breadcrumb-item">
                                    {" "}
                                    Add Price{" "}
                                  </li>
                                </Link>
                                {this.state.emp_type === "admin" && (
                                  <Link
                                    to={
                                      "/admin/user-billing-mode/" +
                                      this.state.seletedUserId +
                                      `/` +
                                      this.state.selectedUserType
                                    }
                                  >
                                    {" "}
                                    <li className="breadcrumb-item">
                                      {" "}
                                      Billing Mode{" "}
                                    </li>
                                  </Link>
                                )}
                                <Link
                                  to={
                                    "/admin/user-add-price/" +
                                    this.state.seletedUserId +
                                    `/` +
                                    this.state.selectedUserType
                                  }
                                >
                                  {" "}
                                  <li className="breadcrumb-item active">
                                    {" "}
                                    Add Credit{" "}
                                  </li>
                                </Link>
                               

                                <Link
                                  to={
                                    "/admin/user-entity-pass/" +
                                    this.state.seletedUserId +
                                    `/` +
                                    this.state.selectedUserType
                                  }
                                >
                                  {" "}
                                  <li className="breadcrumb-item">
                                    {" "}
                                    Entity By Pass{" "}
                                  </li>
                                </Link>

                                <Link
                                  to={
                                    "/admin/change-currency/" +
                                    this.state.seletedUserId +
                                    `/` +
                                    this.state.selectedUserType
                                  }
                                >
                                  {" "}
                                  <li className="breadcrumb-item ">
                                    {" "}
                                    Change Currency{" "}
                                  </li>
                                </Link>

                                <Link
                                  to={
                                    "/admin/bulk-sms-route/" +
                                    this.state.seletedUserId +
                                    `/` +
                                    this.state.selectedUserType
                                  }
                                >
                                  {" "}
                                  <li className="breadcrumb-item ">
                                    {" "}
                                    Bulk Sms Route Setting{" "}
                                  </li>
                                </Link>
                                <Link
                                  to={`/admin/agent-setting/${this.state.seletedUserId}/${this.state.selectedUserType}`}
                                >
                                  <li className="breadcrumb-item">
                                    Agent Setting
                                  </li>
                                </Link>
                              </ol>
                            </nav>
                          </div>
                        </h5>
                      </div>

                      <div className="card-body">
                        {this.state.successMessage ? (
                          <div className={this.state.bgColor} role="alert">
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                            <div className="">{this.state.errorMessage}</div>
                          </div>
                        ) : null}

                        <div className="filter-option">
                          <div className="row">
                            <div className="col-2">
                              <h6 className="card-subtitle text-muted">
                                Add More Credit{" "}
                              </h6>
                            </div>
                            <div className="col-6">
                              <span className="float-left">
                                <b>Current Balance:</b> {this.state.currency}{" "}
                                {this.state.currentBalance}
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-8 text-right">
                              <div className="row">
                                <div className="input-group  drop-down-icons mb-2  col-md-6">
                                  <select
                                    onChange={(event) =>
                                      this.setState({
                                        creditType: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                  >
                                    <option value="">Select Credit type</option>
                                    <option value="Demo Credit">
                                      Demo Credit
                                    </option>
                                    <option value="paid">Paid Credit</option>
                                  </select>
                                </div>

                                <div className="input-group mb-2  col-md-6">
                                  <input
                                    onChange={(event) =>
                                      this.setState({
                                        creditAmount: event.target.value,
                                      })
                                    }
                                    type="text"
                                    placeholder="Enter Amount"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <textarea
                                placeholder="Description"
                                onChange={(event) =>
                                  this.setState({ reMark: event.target.value })
                                }
                                className="form-control mb-2"
                              ></textarea>
                            </div>
                            <div className="col-md-4  text-right">
                              <div className="input-group mb-2 mr-sm-2">
                                <input
                                  type="button"
                                  onClick={() => this.addCredit()}
                                  disabled={
                                    this.state.disableButton ? true : false
                                  }
                                  value="Add Credit"
                                  className="btn btn-block btn-primary"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
              <AdminFooter />
            </div>
          </div>
        )}
      </>
    );
  }
}

export default UserAddPrice;
