import React, { Component } from "react";
import WhatsAppPreview from "./components/WhatsAppPreview";
import Collapsible from "react-collapsible";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faSmile,
  faItalic,
  faBold,
  faStrikethrough,
  faCode,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { WHATSAPP_TEMPLATE_LIST } from "../../../constants/ServiceConstants";
import api from "../../../axios/Axios";
import Alert from "../../UI/Alert/Alert";
import Spining from "../../UI/Spining/Spining";
import Header from "./whatsappForm/Header";
import Button from "./whatsappForm/Button";
import Title from "../../UI/Text/Title";
import DashboardLayout from "../../UI/Layout/DashboardLayout";
import FallBack from "./components/FallBack";
// import Picker from '@emoji-mart/react';
// import data from '@emoji-mart/data';
import EmojiPicker from "emoji-picker-react";
import axios from "../../../axios/Axios";
import { HOST_URL, NODE_HOST_URL } from "../api/Api";
import { userId } from "../../Utils/userAuth";

class RcsTemplateNew extends Component {
  constructor(props) {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    super(props);

    this.inputRef = React.createRef();
    this.buttonRef = React.createRef();
    this.ftinputRef = React.createRef();
    this.state = {
      userID: userId,
      token: userToken,
      paramID: this.props.match.params.id,
      footerOptions: false,
      buttonOptions: false,
      MapingOptions: false,
      buttonAction: false,
      callActionType: "",
      varibaleInTemplate: [],
      collaps1: true,
      collaps2: false,
      tampleName: "",
      isFallback: 0,
      smsTemplateSID: "",
      language: "",
      accountType: "",
      templateType: " ",
      headerText: "",
      headerTextPrev: "",
      bodyMessage: "",
      preBodyMessage: "",
      footerText: "",
      Callbutton: "",
      Urlbutton: "",
      Urlbutton2: "",
      quickButton: "",
      temp_status: "",
      addVariableCount: 1,
      button: [],
      SampleParameter: [],
      isLoading: false,
      successMessage: false,
      errorMessage: "",
      bgColor: "",
      isPickerVisible: false,
      currentEmoji: "",
      searchQuery: "",
      replacementText: "",
      inputFields: [""],
      newBodymsg: "",
      charCount: 0,
      maxChars: 1024,
      headerUpload: "",
      tempImage: null,
      specialUser: null,
      uploadStatus: null,
      ftcharCount: 0,
      ftmaxChars: 60,
      allButton: [],
      senderId: '',
      botMessageType: '',
      buttons: ''
    };

    this.getHeaderData = this.getHeaderData.bind(this);
    this.getButtonData = this.getButtonData.bind(this);
    this.getURLBuutton = this.getURLBuutton.bind(this);
    this.getURLBuutton2 = this.getURLBuutton2.bind(this);
    this.getQuickButton = this.getQuickButton.bind(this);
    this.headerOptions = this.headerOptions.bind(this);
    this.bodyMessageHandler = this.bodyMessageHandler.bind(this);
    this.getTemplateData = this.getTemplateData.bind(this);
    this.insertVariable = this.insertVariable.bind(this);
    this.setSampleData = this.setSampleData.bind(this);
    this.footerHandler = this.footerHandler.bind(this);

    // this.handleOriginalTextChange = this.handleOriginalTextChange.bind(this);
    // this.handleSearchQueryChange = this.handleSearchQueryChange.bind(this);
    // this.handleReplacementTextChange = this.handleReplacementTextChange.bind(this);
    // this.performSearchAndReplace = this.performSearchAndReplace.bind(this);
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevState.allButton !== this.state.allButton) {
      this.state.allButton.map((item, index) => {
       
        let key = Object.keys(item);
        if (key.includes("urltext2")) {
          this.setState({
            Urlbutton2: item,
          })
        }
        if (key.includes("urltext")) {
          this.setState({
            Urlbutton: item,
          })
        }
        if (key.includes("phone")) {
          this.setState({
            Callbutton: item,
          })
        }
      })
    }
  }




  componentDidMount() {
    const lcStorage = JSON.parse(localStorage.getItem("login"));
    const tempuserId = lcStorage.user.userId;
    const tempuser_id = tempuserId.toString();
    const tempuserToken = lcStorage.token;

    axios
      .get(
        `${HOST_URL}/get_user_route.php?user_id=${tempuser_id}&method=retrieve&token=${tempuserToken}`
      )
      .then((res) => {
        if (res.data.success === true) {
          //  setSpecialUser(res.data.data);
          this.setState({
            specialUser: res.data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    this.state.paramID && this.getTmplateDetails();
  }

  getHeaderData(headerData) {


    if (headerData.errMssg) {
      let err_msg = headerData.errMssg.toString();
      this.setState({
        errorMessage: err_msg,
        successMessage: true,
        bgColor: "alert-danger",
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    } else {
      if (
        (headerData.image ||
          headerData.video ||
          headerData.document ||
          headerData.text) &&
        this.state.specialUser === 34
      ) {
        this.setState({
          uploadStatus: true,
        });
      }
      if (this.state.templateType === "Text") {
        this.setState({
          uploadStatus: true,
        });
      }
      this.setState({
        headerUpload: headerData.headerUploadMedia,
        tempImage: headerData.tempImage,
      });
      const obj = headerData;
      if (obj.hasOwnProperty("headerUploadMedia")) {
        delete obj.headerUploadMedia;
      }

      this.setState({
        headerText: obj,
      });
    }
  }

  getButtonData(callButtonData) {
    this.setState({ Callbutton: callButtonData });
  }

  getURLBuutton(urlButtnData) {
    this.setState({ Urlbutton: urlButtnData });
  }
  getURLBuutton2(urlButtnData) {
    this.setState({ Urlbutton2: urlButtnData });
  }

  getQuickButton(quickButton) {
    this.setState({ quickButton: quickButton });
  }

  handleButtonData = (buttonData) => {
    // console.log("Button Data:", buttonData);
    this.setState({ button1: buttonData.button1, button2: buttonData.button2, button3: buttonData.button3, checkedButton: buttonData.isButtonChecked, mainurl: buttonData.mainUrl })
  };

  generateButtonJson() {
    let button = [];
    if (this.state.Callbutton[0]) {
      button.push(this.state.Callbutton[0]);
    }
    if (this.state.Urlbutton[0]) {
      button.push(this.state.Urlbutton[0]);
    }
    if (this.state.Urlbutton2[0]) {
      button.push(this.state.Urlbutton2[0]);
    }
    if (this.state.quickButton[0]) {
      button.push(this.state.quickButton[0]);
    }
    return button;
  }

  crateTemplate = async (status) => {
    this.setState({
      errorMessage: "",
      successMessage: false,
      bgColor: "",
      isLoading: false,
    });

    if (!(this.state.templateType === "Text" || this.state.templateType === "Media")) {
      this.setState({
        errorMessage: "Please select template type",
        successMessage: true,
        bgColor: "alert-danger",
        isLoading: false,
      });
      return
    }
    this.setState({ isLoading: true });
    let formValidation = this.formValidation();
    if (formValidation === true) {
      let buttom = this.generateButtonJson();
      try {
        const { data } = await api.post(WHATSAPP_TEMPLATE_LIST, {
          token: this.state.token,
          user_id: this.state.userID,
          temp_name: this.state.tampleName,
          temp_category: this.state.accountType,
          temp_type: this.state.templateType,
          temp_header: this.state.headerText,
          temp_footer: this.state.footerText,
          temp_language: this.state.language,
          temp_button: buttom,
          temp_body: this.state.bodyMessage,
          full_content: this.state.bodyMessage,
          temp_attribute: this.state.SampleParameter,
          fall_back: this.state.isFallback,
          fall_sid: this.state.smsTemplateSID,
          image_upload_id: this.state.headerUpload,
          temp_status: status,
          method: "create",
        });
        document.querySelector("body").scrollTo(0, 0);
        if (data.success === true) {
          document.querySelector("body").scrollTo(0, 0);
          this.setState({
            errorMessage: data.message,
            successMessage: true,
            bgColor: "alert-success",
            isLoading: false,
          });
          setTimeout(() => {
            this.props.history.push("whatsapp-template-list");
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
          }, 1000);
        } else {
          this.setState({
            errorMessage: data.message,
            successMessage: true,
            bgColor: "alert-danger",
            isLoading: false,
          });
        }
      } catch (err) {
        document.querySelector("body").scrollTo(0, 0);
        console.log(err);
      }
    }
  };

  updateTemplate = async (status) => {
    this.setState({ isLoading: true });
    let formValidation = this.formValidation();
    if (formValidation === true) {
      let buttom = this.generateButtonJson();
      try {
        const { data } = await api.post(WHATSAPP_TEMPLATE_LIST, {
          token: this.state.token,
          user_id: this.state.userID,
          temp_name: this.state.tampleName,
          temp_category: this.state.accountType,
          temp_type: this.state.templateType,
          temp_header: this.state.headerText,
          temp_footer: this.state.footerText,
          temp_language: this.state.language,
          temp_button: buttom,
          temp_body: this.state.bodyMessage,
          full_content: this.state.bodyMessage,
          temp_attribute: this.state.varibaleInTemplate,
          fall_back: this.state.isFallback,
          fall_sid: this.state.smsTemplateSID,
          temp_status: status,
          image_upload_id: this.state.headerUpload,
          id: this.state.paramID,
          method: "update",
        });
        // console.log("data.success==>", data);
        if (data.success === true) {
          document.querySelector("body").scrollTo(0, 0);
          this.setState({
            errorMessage: data.message,
            successMessage: true,
            bgColor: "alert-success",
            isLoading: false,
          });
        } else {
          this.setState({
            errorMessage: data.message,
            successMessage: true,
            bgColor: "alert-danger",
            isLoading: false,
          });
        }
      } catch (err) {
        document.querySelector("body").scrollTo(0, 0);
        console.log(err);
      }
    }
  };

  getTmplateDetails = async () => {
    // this.setState({ isLoading: true });
    try {
      const { data } = await api.post(`${NODE_HOST_URL}/rcs`, {
        token: this.state.token,
        user_id: this.state.userID,
        temp_id: this.state.paramID,
        method: "retrive_temp_dtl",
      });

      if (data.success === true) {
        console.log(data.data)
        await this.setState({
          tampleName: data.data.temp_name,
          botMessageType : data.data.temp_category,
          templateType: data.data.temp_type,
          language: data.data.temp_language,
          bodyMessage: data.data.temp_body,
          // buttons: this.state.temp_suggestion

          

          // Urlbutton:
          //   data.data[0].temp_button.length > 0 &&
          //   data.data[0].temp_button[0].actionType === "calltoaction"
          //     ? [data.data[0].temp_button[1]]
          //     : "",
          // Callbutton:
          //   data.data[0].temp_button.length > 0 &&
          //   data.data[0].temp_button[0].actionType === "calltoaction"
          //     ? [data.data[0].temp_button[0]]
          //     : "",
          // quickButton:
          //   data.data[0].temp_button.length > 0 &&
          //     data.data[0].temp_button[0].actionType === "quickReply"
          //     ? [data.data[0].temp_button[0]]
          //     : "",
          // temp_attribute: [],
        });
        // if (this.state.button.length > 0) {
        //   this.setState({
        //     buttonOptions: true,
        //   });
        // }
      }
    } catch (err) {
      console.log(err);
    }
  };

  headerOptions() {
    this.setState({ headerOptions: !this.state.headerOptions });
  }

  next() {
    this.setState({ isopenFirst: false, isopenSeconds: true });
  }

  getVaribale() {
    var str = this.state.bodyMessage;
    var regex = /{#([^#]+)#}/g;
    let m;
    var tempArr = [];
    while ((m = regex.exec(str)) !== null) {
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      tempArr.push(m[1]);
    }
    this.setState({ varibaleInTemplate: tempArr });
  }

  bodyMessageHandler(e) {
    const newText = e.target.value;
    const currentCharCount = newText.length;

    if (currentCharCount <= this.state.maxChars) {
      this.setState({ bodyMessage: e.target.value });
      this.setState({ preBodyMessage: e.target.value });
      this.getVaribale();
      this.setState({
        text: newText,
        charCount: currentCharCount,
      });
    }
  }

  footerHandler(e) {
    const newftText = e.target.value;
    const ftcurrentCharCount = newftText.length;

    if (ftcurrentCharCount <= this.state.ftmaxChars) {
      this.setState({ footerText: e.target.value });


      this.setState({
        textt: newftText,
        ftcharCount: ftcurrentCharCount,
      });
    }
  }



  formValidation() {
    const regex = /^[a-z_0-9]*$/;
    let text = this.state.tampleName.trim();
    let uploadStatus = this.state.uploadStatus;

    if (uploadStatus === null && this.state.specialUser === 34) {
      this.setState({
        errorMessage: "please upload the file",
        successMessage: true,
        bgColor: "alert-danger",
        isLoading: false,

      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      return false;
    }

    if (regex.test(text) === true) {
      this.setState({
        errorMessage: "",
        successMessage: false,
      });
      return true;
    } else {
      this.setState({
        errorMessage:
          "Template name don`t allow space, special characters and capital letter only underscores or small letter allowed",
        successMessage: true,
        bgColor: "alert-danger",
        isLoading: false,
      });
      return false;
    }
  }

  getTemplateData(smsTemplateId) {
    this.setState({
      smsTemplateSID: smsTemplateId,
      isFallback: 1,
    });
  }

  // insertVariable() {
  //   var textarea = document.getElementById("message-body");
  //   var cursorPosition = textarea.selectionStart;
  //   var text = textarea.value;
  //   var variable = "{{" + this.state.addVariableCount + "}}";
  //   this.setState({
  //     addVariableCount: this.state.addVariableCount + 1,
  //   });
  //   var updatedText =
  //     text.slice(0, cursorPosition) + variable + text.slice(cursorPosition);
  //   textarea.value = updatedText;

  //   // Move the cursor position after the inserted variable
  //   textarea.selectionStart = cursorPosition + variable.length;
  //   textarea.selectionEnd = cursorPosition + variable.length;
  //   textarea.focus();
  // }

  insertVariable = async (e) => {
    e.preventDefault(e);
    const { current } = this.inputRef;
    const { selectionStart, selectionEnd } = current;
    const newValue =
      this.state.bodyMessage.substring(0, selectionStart) +
      " {{" + this.state.addVariableCount + "}} " +
      this.state.bodyMessage.substring(selectionEnd);

    if (this.state.inputFields.length < 11) {
      // await this.setState((prevState) => ({
      //   bodyMessage:
      //     prevState.bodyMessage + " {{" + this.state.addVariableCount + "}}",
      // }));
      await this.setState({
        bodyMessage: newValue,
      });

      await this.setState({
        preBodyMessage: this.state.bodyMessage,
      });
      await this.setState({
        addVariableCount: this.state.addVariableCount + 1,
      });
    }
    if (this.state.inputFields.length < 11) {
      this.setState((prevState) => ({
        inputFields: [...prevState.inputFields, ""], // Adding an empty string as a new input field
      }));
    }
    this.inputRef.current.focus();
  };

  getVariable = () => {
    let tempArr = [];
    var regex = /{{([^}]+)}}/g;
    let m;
    let SampleParameter = {};
    while ((m = regex.exec(this.state.bodyMessage)) !== null) {
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      SampleParameter[`${m[1]}`] = "";
      tempArr.push(m[1]);
    }
    this.setState({
      varibaleInTemplate: tempArr,
      SampleParameter: SampleParameter,
    });
  };

  // setSampleData = (e) => {
  //   let key = e.target.name;
  //   let value = e.target.value;
  //   let object = this.state.SampleParameter;
  //   console.log(key,value,object)
  //   if (object.hasOwnProperty(key)) {
  //     object[key] = value;
  //   } else {
  //     object[key] = value;
  //   }
  //   this.setState({
  //     SampleParameter: object,
  //   });

  // };

  performSearchAndReplace() {
    const { preBodyMessage, replacementText } = this.state;
    // const regex = new RegExp(this.state.searchQuery, 'g');
    return preBodyMessage.replace(this.state.searchQuery, replacementText);
  }
  setSampleData = async (e) => {
    e.preventDefault();

    let key = e.target.name;
    let value = e.target.value;
    let object = this.state.SampleParameter;
    console.log(key, value, object);
    if (object.hasOwnProperty(key)) {
      object[key] = value;
    } else {
      object[key] = value;
    }
    this.setState({
      SampleParameter: object,
    });

    let searchName = "{{" + e.target.name + "}}";
    let newValue = e.target.value;
    await this.setState({
      preBodyMessage: this.state.bodyMessage,
    });

    await this.setState({ searchQuery: searchName });

    await this.setState({ replacementText: newValue });
    let newmsg = this.performSearchAndReplace();
    this.setState({ preBodyMessage: newmsg });
  };

  backCursor() {
    if (this.inputRef.current) {
      const currentPosition = this.inputRef.current.selectionStart;
      const newPosition = Math.max(0, currentPosition - 1);

      this.inputRef.current.focus();
      this.inputRef.current.setSelectionRange(newPosition, newPosition);
    }
  }

  handleChange = (event) => {
    event.preventDefault();
    this.setState({
      bodyMessage: event.target.value,
    });
  };

  // addBold = async (e) => {
  //   e.preventDefault();
  //   await this.setState(prevState => ({
  //     bodyMessage: prevState.bodyMessage + "**"
  //   }));
  //   this.backCursor();
  // };

  // addBold (){
  //   var textarea = document.getElementById("message-body");
  //   var cursorPosition = textarea.selectionStart;
  //   var text = textarea.value;
  //   var variable = "**";
  //   var updatedText =
  //     text.slice(0, cursorPosition) + variable + text.slice(cursorPosition);
  //   textarea.value = updatedText;
  //   this.backCursor();
  // }

  addEmoji = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      isPickerVisible: !prevState.isPickerVisible, // Toggles the state from true to false or vice versa
    }));
  };

  // handleClickOutside = (event) => {

  //   if (this.buttonRef.current && !this.buttonRef.current.contains(event.target)) {
  //     this.setState({
  //       isPickerVisible: false
  //     });
  //   }
  // };

  emoji = async (e) => {
    const nemoji = e.emoji;
    const { current } = this.inputRef;
    const { selectionStart, selectionEnd } = current;
    const newValue =
      this.state.bodyMessage.substring(0, selectionStart) +
      nemoji +
      this.state.bodyMessage.substring(selectionEnd);
    // await this.setState({
    //   currentEmoji: nemoji,
    // });

    // await this.setState((prevState) => ({
    //   bodyMessage: prevState.bodyMessage + this.state.currentEmoji,
    // }));
    await this.setState({
      bodyMessage: newValue,
    });
    await this.setState({
      preBodyMessage: this.state.bodyMessage,
    });
    this.setState((prevState) => ({
      isPickerVisible: !prevState.isPickerVisible, // Toggles the state from true to false or vice versa
    }));
  };

  addBold = async (e) => {
    e.preventDefault();
    const input = document.getElementById("message-body");
    const startPos = input.selectionStart;
    const endPos = input.selectionEnd;
    const { bodyMessage } = this.state;
    const beforeSelection = bodyMessage.substring(0, startPos);
    const selectedText = bodyMessage.substring(startPos, endPos);
    const afterSelection = bodyMessage.substring(endPos);

    if (selectedText === "") {
      await this.setState((prevState) => ({
        bodyMessage: prevState.bodyMessage + "**",
      }));
      await this.setState({
        preBodyMessage: this.state.bodyMessage,
      });
      this.backCursor();
    } else {
      const newText =
        beforeSelection + "*" + selectedText + "*" + afterSelection;
      this.setState({ bodyMessage: newText });
      this.inputRef.current.focus();
    }
  };
  strikThrough = async (e) => {
    e.preventDefault();
    const input = document.getElementById("message-body");
    const startPos = input.selectionStart;
    const endPos = input.selectionEnd;
    const { bodyMessage } = this.state;
    const beforeSelection = bodyMessage.substring(0, startPos);
    const selectedText = bodyMessage.substring(startPos, endPos);
    const afterSelection = bodyMessage.substring(endPos);

    if (selectedText === "") {
      await this.setState((prevState) => ({
        bodyMessage: prevState.bodyMessage + "~~",
      }));
      await this.setState({
        preBodyMessage: this.state.bodyMessage,
      });
      this.backCursor();
    } else {
      const newText =
        beforeSelection + "~" + selectedText + "~" + afterSelection;
      this.setState({ bodyMessage: newText });
      this.inputRef.current.focus();
    }
  };

  addItalic = async (e) => {
    e.preventDefault();
    const input = document.getElementById("message-body");
    const startPos = input.selectionStart;
    const endPos = input.selectionEnd;
    const { bodyMessage } = this.state;
    const beforeSelection = bodyMessage.substring(0, startPos);
    const selectedText = bodyMessage.substring(startPos, endPos);
    const afterSelection = bodyMessage.substring(endPos);

    if (selectedText === "") {
      await this.setState((prevState) => ({
        bodyMessage: prevState.bodyMessage + "__",
      }));

      await this.setState({
        preBodyMessage: this.state.bodyMessage,
      });
      this.backCursor();
    } else {
      const newText =
        beforeSelection + "_" + selectedText + "_" + afterSelection;
      this.setState({ bodyMessage: newText });
      this.inputRef.current.focus();
    }
  };

  monoSpace = async (e) => {
    e.preventDefault();
    const input = document.getElementById("message-body");
    const startPos = input.selectionStart;
    const endPos = input.selectionEnd;
    const { bodyMessage } = this.state;
    const beforeSelection = bodyMessage.substring(0, startPos);
    const selectedText = bodyMessage.substring(startPos, endPos);
    const afterSelection = bodyMessage.substring(endPos);

    if (selectedText === "") {
      await this.setState((prevState) => ({
        bodyMessage: prevState.bodyMessage + "``````",
      }));
      await this.setState({
        preBodyMessage: this.state.bodyMessage,
      });
      if (this.inputRef.current) {
        const currentPosition = this.inputRef.current.selectionStart;
        const newPosition = Math.max(0, currentPosition - 3);

        this.inputRef.current.focus();
        this.inputRef.current.setSelectionRange(newPosition, newPosition);
      }
    } else {
      const newText =
        beforeSelection + "```" + selectedText + "```" + afterSelection;
      this.setState({ bodyMessage: newText });
      this.inputRef.current.focus();
    }
  };

  addButton = () => {
    if (this.state.buttons.length < 10) {
      const newButtonId = this.state.buttons.length + 1;
      this.setState(prevState => ({
        buttons: [...prevState.buttons, { id: newButtonId }]
      }));
    }
  };


  deleteButton = (id) => {
    const updatedButtons = this.state.buttons.filter(button => button.id !== id);
    this.setState({ buttons: updatedButtons });
  };

  handleInputChange = (buttonId, field, value) => {
    this.setState(prevState => ({
      buttons: prevState.buttons.map(button =>
        button.id === buttonId
          ? { ...button, [field]: value }
          : button
      )
    }));
  };

  createRcsTemplate = async () => {
    let data = {
      user_id: this.state.userID,
      token: this.state.token,
      method: "create_temp",
      sender_id: "123456",
      temp_name: this.state.tampleName,
      temp_language: this.state.language,
      temp_category: this.state.botMessageType,
      temp_type: this.state.templateType,
      temp_body: this.state.bodyMessage,
      temp_suggestion: this.state.buttons
    }
    try {
      let response = await fetch(`${NODE_HOST_URL}/rcs`, {
        method: "Post",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(data)
      });
      let result = await response.json();
      if (result.success) {
        this.setState({
          errorMessage: result.message,
          successMessage: true,
          bgColor: "alert-success",
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  render() {

    return (
      <DashboardLayout>
        {this.state.isLoading && <Spining />}
        <main className="content">
          <div className="container-fluid p-0">
            <Title
              isButton={true}
              btnName={`Back To Template List`}
              actionURL={`/dashboard/whatsapp-template-list`}
            >
              Rcs Template
            </Title>

            <div className="row justify-contect-center">
              <div className="col-md-8">
                {this.state.successMessage && (
                  <Alert type={this.state.bgColor}>
                    {this.state.errorMessage}
                  </Alert>
                )}

                <div className="card whatapp-app">
                  <div className="card-header">
                    <h5 className="card-title mb-0"> Create Template </h5>
                  </div>

                  <hr className="m-0" />

                  <div className="card-body">
                    <div className="p-3">

                      <div className="row">
                        <div className='col-6'>
                          <div className="input-group mb-3 drop-down-icons">
                            <select
                              value={this.state.senderId}
                              onChange={(e) => this.setState({ senderId: e.target.value })}
                              name="agentsenderid"
                              className="form-control"
                            >
                              <option value={""}>Select Agent Sender Id</option>
                              <option value={"1"}>1</option>
                              <option value={"2"}>2</option>
                              <option value={"3"}>3</option>
                            </select>
                          </div>

                        </div>
                        <div className='col-6'>
                          <div className="input-group mb-3 drop-down-icons">
                            <select
                              value={this.state.botMessageType}
                              onChange={(e) => this.setState({ botMessageType: e.target.value })}
                              name="botmessagetype"
                              className="form-control"
                            >
                              <option value={""}>Select Bot Message Type</option>
                              <option value={"promotional"}>Promotional</option>
                              <option value={"transactional"}>Transactional</option>
                              <option value={"otp"}>OTP</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={this.state.tampleName}
                          onChange={(e) =>
                            this.setState({ tampleName: e.target.value })
                          }
                          name="tampleName"
                          placeholder="Enter Template Name (Ex : template_name)"
                        />
                      </div>
                      <div className="input-group mb-3 drop-down-icons">
                        <select
                          value={this.state.language}
                          onChange={(e) =>
                            this.setState({ language: e.target.value })
                          }
                          name="language"
                          className="form-control"
                        >
                          <option value={""}>Select Language</option>
                          <option value={"hi"}>Hindi</option>
                          <option value={"en"}>English</option>
                          <option value={"en_US"}>English(US)</option>
                        </select>
                      </div>
                      <div className="input-group mb-3 drop-down-icons">
                        <select
                          disabled={this.state.paramID}
                          value={this.state.templateType}
                          onChange={(e) =>
                            this.setState({
                              templateType: e.target.value,
                            })
                          }
                          name="templateType"
                          className="form-control"
                        >
                          <option value={" "}>Select Template Type</option>
                          <option value={"text"}>text</option>
                          {/* <option value={"richcardcarousel"}>Rich Card Carousel</option>
                          <option value={"richcardstandalone"}>Rich Card Stand Alone</option> */}
                        </select>
                      </div>
                    </div>

                    {this.state.templateType === "text" &&
                      <div className="p-3">
                        {/* {this.state.templateType === " " ? null : (
                          <Header
                            sendHeadeData={this.getHeaderData}
                            type={this.state.templateType}
                            receiveHeadeData={this.state.headerText}
                            receiveHeadePrev={this.state.headerTextPrev}
                            paramid={this.state.paramID}
                            userId={this.state.userID}
                            token={this.state.token}
                          />
                        )} */}

                        <div className="whatsapp-box">
                          <div className="mb-3">
                            <label>
                              <h4>
                                Body Message
                                <span className="text-danger">*</span>
                              </h4>
                            </label>
                            <textarea
                              id="message-body"
                              ref={this.inputRef}
                              defaultValue={this.state.bodyMessage}
                              onChange={this.bodyMessageHandler}
                              onMouseLeave={this.getVariable}
                              rows={5}
                              value={this.state.bodyMessage}
                              // maxLength={60}
                              className="form-control mb-1"
                            ></textarea>
                            <div className="d-flex justify-content-between">
                              <div className="d-flex">
                                <div
                                  className="mx-1 px-1 cursor-pointer"
                                  onClick={(e) => this.addEmoji(e)}
                                >
                                  <FontAwesomeIcon icon={faSmile} />
                                </div>
                                <div
                                  className="mx-1 px-1 cursor-pointer"
                                  onClick={this.addBold}
                                >
                                  <FontAwesomeIcon icon={faBold} />
                                </div>
                                <div
                                  className="mx-1 px-1 cursor-pointer"
                                  onClick={this.addItalic}
                                >
                                  <FontAwesomeIcon icon={faItalic} />
                                </div>
                                <div
                                  className="mx-1 px-1 cursor-pointer"
                                  onClick={this.strikThrough}
                                >
                                  <FontAwesomeIcon icon={faStrikethrough} />
                                </div>
                                <div
                                  className="mx-1 px-1 cursor-pointer"
                                  onClick={this.monoSpace}
                                >
                                  <FontAwesomeIcon icon={faCode} />
                                </div>
                              </div>
                              <div>
                                <button
                                  onClick={this.insertVariable}
                                  className="btn btn-sm btn-primary mb-2"
                                >
                                  Add Varibale
                                </button>
                              </div>
                            </div>
                            <div
                              className="float-right"
                              style={{ fontSize: "13px" }}
                            >
                              Character : {this.state.charCount}/
                              {this.state.maxChars}
                            </div>
                            <div
                              className={`${this.state.isPickerVisible ? " " : "d-none"
                                }`}
                            >
                              <EmojiPicker
                                onEmojiClick={(e) => this.emoji(e)}
                              />
                            </div>

                            {this.state.inputFields.length > 1 && (
                              <>
                                <p className="m-0">
                                  Please Add Sample Data of variables{" "}
                                </p>
                                {this.state.inputFields.map((item, index) => {
                                  if (index === 0) {
                                    return null; // Skip rendering for index 0
                                  }
                                  return (
                                    <div
                                      key={index}
                                      className="input-group mb-2 mr-sm-2"
                                    >
                                      <div className="input-group-text">{`{{${index}}}`}</div>
                                      <input
                                        type="text"
                                        required
                                        name={index}
                                        onChange={this.setSampleData}
                                        placeholder={`Sample Data of {{${index}}}`}
                                        className="form-control"
                                      />
                                    </div>
                                  );
                                })}
                              </>
                            )}
                          </div>
                        </div>

                        {/* <div className="whatsapp-box">
                          <div className="whatsapp-options">
                            <label>
                              <h4>Footer<span className="text-muted">(optional)</span></h4>
                              <p>
                                Add a short line of text to the bottom of your
                                message template
                              </p>
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                defaultChecked={
                                  this.state.footerOptions === true
                                    ? "checked"
                                    : null
                                }
                                onChange={() =>
                                  this.setState({
                                    footerOptions: !this.state.footerOptions,
                                  })
                                }
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                          {this.state.footerOptions ? (
                            <div className="">
                              <input
                                type="text"
                                ref={this.ftinputRef}
                                defaultValue={this.state.footerText}

                                onChange={this.footerHandler
                                }
                                value={this.state.footerText}
                                className="form-control"
                                id="inputFirstName"
                                placeholder="Enter footer mesage"
                              />
                              <div
                                className="float-right"
                                style={{ fontSize: "13px" }}
                              >
                                Character : {this.state.ftcharCount}/
                                {this.state.ftmaxChars}
                              </div>
                            </div>

                          ) : null}
                        </div> */}

                        {/* <div className="whatsapp-box">
                          <Button
                            sendButtonData={this.getButtonData}
                            sendQuickButton={this.getQuickButton}
                            sendURLButton={this.getURLBuutton}
                            sendURLButton2={this.getURLBuutton2}
                            paramid={this.state.paramID}
                            defaultButtnData={this.state.button}
                            sendQuickReplyButtonData={this.handleButtonData}
                          />
                        </div> */}
                      </div>}

                    <div className="p-3">
                      <div className="whatsapp-box">
                        <div className='row'>
                          <div className='col-6'>
                            <h5 className="card-title mb-0"> <b>Suggested Action/Reply Button</b> </h5>
                          </div>
                          <div className='col-6'>
                            <button
                              onClick={this.addButton}
                              className="btn float-right"
                              style={{ backgroundColor: "#563d7c", color: "white" }}
                            >
                              <FontAwesomeIcon icon={faPlus} /> Add Suggestions
                            </button>
                          </div>
                        </div>

                        {this.state.buttons.length > 0 && (
                          <div className="call-action-area">
                            {this.state.buttons.map((button) => (
                              <div className="row" key={button.id}>
                                <div className={button.type_of_action === "reply" ? "col-md-3" : "col-md-2"}>
                                  <div className="input-group mb-3 drop-down-icons">
                                    <select
                                      value={button.type_of_action}
                                      onChange={(e) => this.handleInputChange(button.id, "type_of_action", e.target.value)}
                                      className="form-control"
                                    >
                                      <option value=''>Select</option>
                                      <option value="reply">Reply</option>
                                      <option value="url_action">Url Action</option>
                                      <option value="dialer_action">Dialer Action</option>
                                    </select>
                                  </div>
                                </div>
                                <div className={button.type_of_action === "reply" ? "col-md-3" : "col-md-3"}>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={button.suggestion_text}
                                    onChange={(e) => this.handleInputChange(button.id, "suggestion_text", e.target.value)}
                                    placeholder="Suggestion Text"
                                  />
                                </div>
                                <div className={button.type_of_action === "reply" ? "col-md-3" : "col-md-3"}>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={button.suggestion_postback}
                                    onChange={(e) => this.handleInputChange(button.id, "suggestion_postback", e.target.value)}
                                    placeholder="Suggestion Postbox"
                                    maxLength={15}
                                  />
                                </div>
                                {button.type_of_action === "dialer_action" && (
                                  <div className="col-md-3">
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={button.phone_number}
                                      onChange={(e) => this.handleInputChange(button.id, "phone_number", e.target.value)}
                                      placeholder="Phone Number to Dial"
                                      maxLength={20}
                                    />
                                  </div>
                                )}
                                {button.type_of_action === "url_action" && (
                                  <div className="col-md-3">
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={button.url}
                                      onChange={(e) => this.handleInputChange(button.id, "url", e.target.value)}
                                      placeholder="URL/URI to Open"
                                      maxLength={20}
                                    />
                                  </div>
                                )}
                                <div className={button.type_of_action === "reply" ? "col-md-3" : "col-md-1"}>
                                  <span onClick={() => this.deleteButton(button.id)} style={{ cursor: "pointer" }}>
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>


                    {/* <FallBack sendSMStemplateId={this.getTemplateData} /> */}

                    <div className="text-right">
                      {this.state.paramID ? (
                        <>
                          {this.state.specialUser === 34 ? null : (
                            <button
                              onClick={() => this.updateTemplate()}
                              className="btn btn-sm btn-info"
                            >
                              {" "}
                              <FontAwesomeIcon icon={faSave} /> Update Template
                            </button>
                          )}
                        </>
                      ) : (
                        <div className="text-right">
                          {/* <button
                            onClick={() => this.crateTemplate(2)}
                            className="btn btn-sm btn-info"
                          >
                            <FontAwesomeIcon icon={faSave} /> Save Draft
                          </button> */}

                          <button
                            type="submit"
                            onClick={() => this.createRcsTemplate()}
                            className="btn btn-sm btn-primary"
                          >
                            {" "}
                            Save & Send For Approvel{" "}
                            <FontAwesomeIcon icon={faSave} />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 ">
                <div className="card ">
                  <div className="card-header">
                    <h5 className="card-title mb-0"> Template Preview </h5>
                  </div>
                  <hr className="m-0" />
                  <div className="card-body">
                    <WhatsAppPreview previewData={this.state} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </DashboardLayout>
    );
  }
}

export default RcsTemplateNew;