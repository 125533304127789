import React, { Component } from "react";
import AdvanceDataTable from "../../UI/Table/AdvanceDataTable";
import { WHATSAPP_TEMPLATE_LIST } from "../../../constants/ServiceConstants";
import api from "../../../axios/Axios";
import Title from "../../UI/Text/Title";
import DashboardLayout from "../../UI/Layout/DashboardLayout";
import { RCS_TEMPLATE_LIST_CONST, WHATSAPP_TEMPLATE_LIST_CONST } from "../../UI/Table/ColumnConst";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch
} from "@fortawesome/free-solid-svg-icons";
import { HOST_URL, NODE_HOST_URL } from "../api/Api";

class RcsTemplateList extends Component {
  constructor() {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const user_id = lStorage.user.userId;
    const token = lStorage.token;
    super();

    this.state = {
      userId: user_id,
      token: token,
      setIsLoading: true,
      rcsTemplateList: null,
      search:"",
      filterTemplateList:null,
      authenticationButton: '',
    };
    this.searchHandler = this.searchHandler.bind(this);
    this.reset = this.reset.bind(this);

  }

  componentDidMount() {
    this.getWhatsaAppTemplateList();
    this.showAuthenticationButton();
  }

  getWhatsaAppTemplateList = async () => {
    this.setState({ setIsLoading: true });
    try {
      const { data } = await api.post(`${NODE_HOST_URL}/rcs`, {
        token: this.state.token,
        user_id: this.state.userId,
        method: "retrieve_temp",
      });
      if (data.success === true) {
        console.log(data.data)
        this.setState({ rcsTemplateList: data.data, setIsLoading: false });
      } else {
        this.setState({ setIsLoading: false });
      }
    } catch (err) {
      console.log(err);
    }
  };

  searchHandler(e){
    let searchValue=e.target.value.toString();
    this.setState({
        search:searchValue
    })

  }

  reset(e){
    this.setState({
        search:"",
    })
  }

  showAuthenticationButton = async() =>{
    let response = await fetch (`${HOST_URL}/get_user_route.php?user_id=${this.state.userId}&method=retrieve&token=${this.state.token}`)
    let result = await response.json();
    if(result.success){
      // console.log(result)
      this.setState({authenticationButton: result.data})
    }
  }

  handleRedirectWhatsappTemplate = () => {
    // Access the history object via this.props
    this.props.history.push('/dashboard/create-rcs-template');
  };

  render() {
    // document.querySelector("body").scrollTo(0, 0);
    //   if(this.state.WhatsappTemplateList){
    // var filteredList = this.state.WhatsappTemplateList.filter(
    //   (item) =>
    //     // console.log(item.temp_name)
    //     item.temp_name.toLocaleLowerCase().includes(this.state.search.toLocaleLowerCase())||
    //     item.id.toString().includes(this.state.search)
    // );}
  console.log(this.state.rcsTemplateList)
    return (
      <DashboardLayout>
        <main className="content">
          <div className="container-fluid p-0">
            

            <div className="row">
              <div className="col-3">
                <h6>Rcs Template List</h6>
              </div>
              <div className="col-9 mb-2">
                <button type="button" style={{float:"right", fontSize:"12px", padding:"10px 10px 10px 10px"}} onClick={this. handleRedirectWhatsappTemplate} className="btn btn-success">Create New Template</button>
              </div>
            </div>
            
            {/* <div className="input-group pr-3 w-75">
              <input
                type="text"
                
                className="form-control"
                placeholder="Enter template name or WID"
                value={this.state.search}
                onChange={this.searchHandler}
              />
              <div className="input-group-append">
                <button disable style={{ border:"none", paddingLeft:"15px", paddingRight:"15px", cursor:"context-menu"}}><FontAwesomeIcon icon={faSearch} /></button>
              
              </div>
            </div> */}
            <AdvanceDataTable
              // tableName={"Template List"}
              tableCell={RCS_TEMPLATE_LIST_CONST}
              tableData={this.state.rcsTemplateList}
              isLoading={this.state.setIsLoading}
            />
          </div>
        </main>
      </DashboardLayout>
    );
  }
}

export default RcsTemplateList;
