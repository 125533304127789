import React, { Component } from 'react';
import AdminHeader from '../Header/AdminHeader';
import AdminFooter from '../Footer/AdminFooter';
import AdminLeftMenu from '../Navbar/AdminLeftMenu';
import EmptyImg from "../Dashboard/assets/img/create-project.png";
import { userloginDetails } from '../Utils/AdminAuth';
import { HOST_URL } from '../Dashboard/api/Api';

class AddSpamWord extends Component {

    constructor() {

        const lStorage = userloginDetails();
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;
        const isAdmin = lStorage.user.isAdmin;

        super();

        this.state = {
            user_id: userId,
            token: userToken,
            userType: isAdmin,
            SpanWord: '',
            SpanWordList: '',
            successMessage: false,
            errorMessage: '',
            bgColor: '',
        }
    }

    componentDidMount() {

        fetch(`${HOST_URL}/spam_word.php?user_id=${this.state.user_id}&method=retrieve&type=${this.state.userType}&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                // console.log(result);
                if (result.success === true) {
                    this.setState({ SpanWordList: result.data });
                } else {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }


    addSpamWord() {
        document.querySelector('body').scrollTo(0, 0);
        fetch(`${HOST_URL}/spam_word.php?user_id=${this.state.user_id}&method=create&type=${this.state.userType}&message=${this.state.SpanWord}&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                //console.log(result);
                if (result.success === true) {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-success alert-dismissible",
                        errorMessage: result.message
                    })
                } else {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }


    render() {
        return (
            <div className="wrapper">
                <AdminLeftMenu />
                <div className="main">
                    <AdminHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong> Spam  </strong> Word </h3>
                                </div>
                            </div>


                            <div className="row">
                                <div className="card">

                                    <div className="card-body">

                                        {
                                            this.state.successMessage ?
                                                <div className={this.state.bgColor} role="alert">
                                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                        <span aria-hidden="true">×</span>
                                                    </button>
                                                    <div className="">
                                                        {this.state.errorMessage}
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }

                                        <div className="filter-option">
                                            <h6 className="card-subtitle text-muted">Add New Spam Word </h6>
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-8">
                                                            <input type='text' onChange={(event) => this.setState({ SpanWord: event.target.value })} placeholder="Enter Spam Word " className="form-control" />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="input-group">
                                                                <input type="button" onClick={() => this.addSpamWord()} value="Add Spam Word" className="btn  btn-primary btn-block text-center " />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="row">
                                            <div className="col-12 col-lg-12 col-xxl-12 d-flex ">
                                                <div className="card flex-fill">

                                                    {this.state.SpanWordList ?
                                                        <div className="table-responsive tableAutoScroll">
                                                            <table  className="table table-hover table-striped table-bordered">
                                                                <thead className="sticky-top" >
                                                                    <tr>
                                                                        <th style={{ width: '60%' }}>Spam Word </th>
                                                                        <th style={{ width: '10%' }}className="">Status</th>
                                                                        <th style={{ width: '30%' }} className=""> Date / Time</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {
                                                                        this.state.SpanWordList.map((item, i) =>
                                                                            <tr key={i}>

                                                                                <td>{item.message}</td>
                                                                                <td>{item.status}</td>
                                                                                <td className="text-wrap">{item.created_date}</td>
                                                                            </tr>

                                                                        )
                                                                    }



                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        :
                                                        <div className="text-center">
                                                            <div className="card-body">
                                                                <img alt="Empty Data" className="project-empty-img" src={EmptyImg} />
                                                                <p>No Any Spam Word Found </p>

                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <AdminFooter />
                </div>
            </div>
        );
    }
}

export default AddSpamWord;