import React, { Component } from "react";
import { Link } from "react-router-dom";
import LatestRequestComponent from "./LatestRequestComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaWhatsapp } from "react-icons/fa";
import {
  faCompressAlt,
  faBriefcase,
  faBullhorn,
  faSms,
  faEnvelope,
  faWhatsapp,
  faSquareWhatsapp,
  faMicrophone,
  faBolt,
  faVoicemail,
} from "@fortawesome/free-solid-svg-icons";
import whatapp from "../Dashboard/assets/img/whatsapp.png";
import { HOST_URL, WhstappAccountList, userAPIReQuest, userProfile, whatsappSummery } from "./api/Api";
import ScoreCard from "../UI/Card/ScoreCard";

class Home extends Component {
  constructor() {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const sms_menu = lStorage.user.sms_menu === 0 ? false : true;
    const voice_menu = lStorage.user.voice_menu === 0 ? false : true;
    const email_menu = lStorage.user.email_menu === 0 ? false : true;
    const whatsapp_menu = lStorage.user.whatsapp_menu === 0 ? false : true;
    var today = new Date(),
      date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        ("0" + today.getDate()).slice(-2);
    super();
    this.state = {
      user_id: userId,
      token: userToken,
      liveProfileToken: "",
      perMintCount: 0,
      ProfileName: "",
      currentDateTime: date,
      totalSMSSubmitted: 0,
      totalEmailSubmitted: 0,
      totalVoiceSubmitted: 0,
      totalWhatsappSubmitted: 0,
      expiryDate: "",
      expiryDaysLeft: "",
      userExpiredMesage: false,
      colorUpdate: "red",
      errorMessage: "",
      successMessage: false,
      bgColor: "alert alert-success alert-dismissible",
      smsMenu: sms_menu,
      voiceMenu: voice_menu,
      emailMenu: email_menu,
      whatsappMenu: whatsapp_menu,
      whatsappNumber:null,
    };
  }
   

  
  componentDidMount() {
    this.SmsSummery();
    this.voiceSummery();
    this.emialSummery();
    this.perMintHitCount();
    this.GetingProfile();
    this.getWhatsaAppNumberList();

    whatsappSummery(this.state.currentDateTime , this.state.currentDateTime).then((res) => {
      this.setState({ totalWhatsappSubmitted: res.data.message.total });
    });
  }

  perMintHitCount() {
    userAPIReQuest().then((result) => {
      if (result.data.success === true) {
        this.setState({ perMintCount: result.data.message[0].cnt });
      } else {
        this.setState({ perMintCount: result.data.message });
      }
    });
  }

  SmsSummery() {
    fetch(
      `${HOST_URL}/sms_report.php?user_id=${this.state.user_id}&method=summary&token=${this.state.token}&date_from=${this.state.currentDateTime}&date_to=${this.state.currentDateTime}`
    ).then((response) => {
      response.json().then((result) => {
        // console.log('SmsSummery', result)
        if (result.success === true) {
          this.setState({
            totalSMSSubmitted: result.message.total,
            colorUpdate: this.state.colorUpdate === "red" ? "#3F3966" : "red",
          });
        } else {
          this.setState({ totalSMSSubmitted: result.message });
        }
      });
    });
  }

  voiceSummery() {
    fetch(
      `${HOST_URL}/voice_report.php?user_id=${this.state.user_id}&method=summary&token=${this.state.token}&date_from=${this.state.currentDateTime}&date_to=${this.state.currentDateTime}`
    ).then((response) => {
      response.json().then((result) => {
        //console.log('SmsSummery', result)
        if (result.success === true) {
          this.setState({
            totalVoiceSubmitted: result.message.total,
            colorUpdate: this.state.colorUpdate === "red" ? "#3F3966" : "red",
          });
        } else {
          this.setState({ totalVoiceSubmitted: result.message });
        }
      });
    });
  }

  emialSummery() {
    fetch(
      `${HOST_URL}/email_report.php?user_id=${this.state.user_id}&method=summary&token=${this.state.token}&date_from=${this.state.currentDateTime}&date_to=${this.state.currentDateTime}`
    ).then((response) => {
      response.json().then((result) => {
        //console.log('SmsSummery', result)
        if (result.success === true) {
          this.setState({
            totalEmailSubmitted: result.message.total,
            colorUpdate: this.state.colorUpdate === "red" ? "#3F3966" : "red",
          });
        } else {
          this.setState({ totalEmailSubmitted: result.message });
        }
      });
    });
  }

  GetingProfile() {
    userProfile().then((result) => {
      if (result.data.success === true) {
        this.setState({
          ProfileName: result.data.data[0].username,
          liveProfileToken: result.data.data[0].token,
        });
        this.forceUpdate();
        this.getuserExpiry(result.data.data[0].expiry);
      }
      if (result.message === "Invalid Token") {
        this.universalLogout();
      }
    });
  }

  universalLogout() {
    window.location = "/logout";
  }

  getuserExpiry(e) {
    const userDate = new Date(e).toISOString().slice(0, 10);
    const TodateDate = new Date().toISOString().slice(0, 10);
    if (userDate < TodateDate) {
      this.setState({ userExpiredMesage: true, expiryDaysLeft: 0 });
      setTimeout(function () {
        localStorage.clear();
        window.location = "/";
      }, 2000);
    } else {
      const userDate = new Date(e);
      const TodateDate = new Date();
      const diffTime = Math.abs(TodateDate - userDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      //console.log('expiryDate',diffDays);
      if (diffDays <= 10) {
        this.setState({ userExpiredMesage: true, expiryDaysLeft: diffDays });
      } else {
        this.setState({ userExpiredMesage: false, expiryDaysLeft: userDate });
      }
    }
  }
  handlewhatsapp = () => {
    window.location = "/dashboard/whatsapp-onboarding-new";
  };

  getWhatsaAppNumberList = async () => {

    WhstappAccountList().then((res)=> {
        if (res.data.success === true) {
          this.setState({ whatsappNumber : res.data.data[0] ,setIsLoading: false  });
        } else {
            this.setState({ ErrorResponse: res.data, setIsLoading: false });
        }
      })
};


  render() {
    
    
    return (
      <main className="content">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 mb-4">
              <div className="d-flex  flex-column flex-md-row justify-content-between">
              <div>
              <span style={{ color: "#3F3966", fontSize: 20, fontWeight: 700 }}>
                {" "}
                Welcome : {this.state.ProfileName}{" "}
              </span>
              
              
              {this.state.userExpiredMesage ? (
                <span className="badge badge-danger">
                  {" "}
                  Your Account Will expire within{" "}
                  <span>{this.state.expiryDaysLeft}</span> Days{" "}
                </span>
              ) : null}
              </div>
              {(this.state.whatsappMenu===true&&(this.state.whatsappNumber===null||this.state.whatsappNumber?.status===0))&&
              <div><button type="button"  className="btn btn-success rounded-lg" onClick={this.handlewhatsapp}>
              <FaWhatsapp size={30} className="mr-2 "/>WhatsApp Onboarding</button></div>
  }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="card">
              <div className="col-xl-12 col-xxl-5 d-flex">
                <div className="w-100">
                  <div className="row">
                    <div className="col-auto mt-3 d-none d-sm-block">
                      <h3>
                        <strong>Today's</strong> Report{" "}
                      </h3>
                    </div>
                  </div>
                  <div className="score-card">
                    {this.state.smsMenu && (
                      <div className="box">
                        <ScoreCard
                          title={` SMS`}
                          icon={faSms}
                          type="icon"
                          value={this.state.totalSMSSubmitted}
                        />
                      </div>
                    )}
                    {this.state.emailMenu && (
                      <div className="box">
                        <ScoreCard
                          title={` Email`}
                          icon={faEnvelope}
                          type="icon"
                          value={this.state.totalEmailSubmitted}
                        />
                      </div>
                    )}
                    {this.state.voiceMenu && (
                      <div className="box">
                        <ScoreCard
                          title={` Voice`}
                          icon={faMicrophone}
                          type="icon"
                          value={this.state.totalVoiceSubmitted}
                        />
                      </div>
                    )}
                    {this.state.whatsappMenu && (
                      <div className="box">
                        <ScoreCard
                          title={` WhatsApp`}
                          icon={whatapp}
                          type="img"
                          value={
                            this.state.totalWhatsappSubmitted
                              ? this.state.totalWhatsappSubmitted
                              : 0
                          }
                        />
                      </div>
                    )}
                    {(
                    <div className="box">
                      <ScoreCard
                        title={`Request / Min.`}
                        type="icon"
                        icon={faBolt}
                        value={this.state.perMintCount}
                      />
                    </div>
                    )}
                  </div>

                  {(this.state.smsMenu ||
                    this.state.voiceMenu ||
                    this.state.emailMenu) && (
                    <div className="row">
                      <div className="col-auto d-none d-sm-block">
                        <h3>
                          <strong>Quick</strong> links
                        </h3>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    {this.state.smsMenu && (
                      <div className="col-sm-3">
                        <div className="card">
                          <div className="card-body ">
                            <div className="">
                              <span className="text-danger">
                                {" "}
                                <FontAwesomeIcon icon={faSms} />{" "}
                              </span>
                              <Link to="/dashboard/sms-api-docs">
                                {" "}
                                <span className="text-muted font-weight-bold text-right">
                                  Send SMS API
                                </span>{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.emailMenu && (
                      <div className="col-sm-3">
                        <div className="card">
                          <div className="card-body">
                            <div className="">
                              <span className="text-danger">
                                {" "}
                                <FontAwesomeIcon icon={faBullhorn} />{" "}
                              </span>
                              <Link to="/dashboard/email-api-docs">
                                {" "}
                                <span className="text-muted font-weight-bold text-right">
                                  Send Email
                                </span>{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.voiceMenu && (
                      <div className="col-sm-3">
                        <div className="card">
                          <div className="card-body">
                            <div className="">
                              <span className="text-danger">
                                {" "}
                                <FontAwesomeIcon icon={faBriefcase} />{" "}
                              </span>
                              <Link to="/dashboard/voice-api-docs">
                                {" "}
                                <span className="text-muted font-weight-bold text-right">
                                  Send Voice
                                </span>{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.smsMenu && (
                      <div className="col-sm-3">
                        <div className="card">
                          <div className="card-body">
                            <div className="">
                              <span className="text-danger">
                                <FontAwesomeIcon icon={faCompressAlt} />{" "}
                              </span>
                              <Link to="/dashboard/create-sender-id">
                                {" "}
                                <span className="text-muted font-weight-bold text-right">
                                  DLT Setup
                                </span>{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <LatestRequestComponent />
        </div>
      </main>
    );
  }
}
export default Home;
