import React, { Component } from "react";

import { Helmet } from "react-helmet";
import loginimg from "../Dashboard/assets/img/login.png";
import { checkUserUrl } from "../Utils/Utils";
import { HOST_URL, NODE_HOST_URL } from "../Dashboard/api/Api";
import axios from 'axios';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { BsWindowSidebar } from "react-icons/bs";
class MyAccount extends Component {

  constructor() {
    const url2 = document.location.href;
    const location = window.location.host;
    super();
    this.state = {
      username: "",
      password: "",
      isfacebooklogin: false,
      show: false,
      loginMessage: "",
      bgColor: "",
      otherurl: url2,
      settings: null,
      loading: true,
      error: null,
      location: location,
      successMessage: false,
      errorMessage: "",
      bgColor: "",

    };
    this.keyPressed = this.keyPressed.bind(this);
    this.login = this.login.bind(this);
  }

  keyPressed(event) {
    if (event.key === "Enter") {
      this.login();
    }
  }
  componentDidMount() {
    localStorage.clear();
    this.fetchSettings();

  }
  fetchSettings = async () => {
    try {
      const requestData = {
        domain: this.state.location,
        method: "site_setting"
      };
      const { data } = await axios.post(`${NODE_HOST_URL}/site_setting`, requestData);
      console.log(data);

      if (data.success === true) {

        localStorage.setItem('siteSettings', JSON.stringify(data.data));
        this.setState({
          settings: data.data,
          loading: false,
        });
      }

      this.setState({

        loading: false,
      });
    }
    catch (error) {
      this.setState({

        loading: false,
      });
    }
  }


  login() {
    this.setState({ loginMessage: false });
    var url = document.location.host;
    fetch(`${HOST_URL}/login.php`, {
      method: "post",
      headers: {
        "content-Type": "application/json",
      },
      body: JSON.stringify(this.state),
    }).then((result) => {
      result.json().then((resp) => {
        if (resp.success === true) {
          this.setState({
            show: true,
            loginMessage: "Success",
            bgColor: "alert alert-success",
          });
          if (
            resp.user.isAdmin === "client"
          ) {
            resp["url"] = `https://` + url;
            localStorage.setItem("login", JSON.stringify(resp));
            localStorage.setItem("admin", JSON.stringify({ success: false }));
            setTimeout(function () {
              window.location = "/dashboard";
            }, 1000);
          } else if (resp.user.isAdmin === "admin") {
            localStorage.setItem("admin", JSON.stringify(resp));
            setTimeout(function () {
              window.location = "/admin";
            }, 1000);
          } else if (resp.user.isAdmin === "emp") {
            localStorage.setItem("emp", JSON.stringify(resp));
            setTimeout(function () {
              window.location = "/admin";
            }, 1000);
          } else if (resp.user.isAdmin === "reseller") {
            localStorage.setItem("reseller", JSON.stringify(resp));
            setTimeout(function () {
              window.location = "/admin";
            }, 1000);
          }
        } else {
          this.setState({
            show: true,
            loginMessage: resp.message,
            bgColor: "alert alert-danger",
          });
        }
      });
    });
  }

  // responseFacebook = (response) => {
  //     const loginWithFacebook = ({ email: response.email, name: response.name, api_response:response});
  //     fetch('https://console.authkey.io/api/facebook_login.php', {
  //         method: "post",
  //         headers: {
  //             'content-Type': 'application/json'
  //         }, body: JSON.stringify(loginWithFacebook)
  //     }).then((result) => {
  //         result.json().then((resp) => {
  //             if (resp.success === true && resp.account==='old') {
  //                 this.setState({ show: true,bgColor: "alert alert-success alert-dismissible",loginMessage: 'success'  });
  //                 localStorage.setItem('login', JSON.stringify(resp))
  //                 localStorage.setItem('admin', JSON.stringify({ "success": false }));
  //                setTimeout(function () { window.location = "/dashboard"; });
  //             }else if (resp.success === true && resp.account==='new') {
  //                 this.setState({ show: true,bgColor: "alert alert-success alert-dismissible",loginMessage: 'success'  });
  //                 localStorage.setItem('login', JSON.stringify(resp))
  //                 localStorage.setItem('admin', JSON.stringify({ "success": false }));
  //                setTimeout(function () { window.location = "/signup-success"; });
  //             } else {
  //                 this.setState({ show: true,bgColor: "alert alert-danger alert-dismissible",loginMessage: resp.message  });
  //             }
  //         })
  //     })
  //   }

  render() {


    document.querySelector("body").scrollTo(0, 0);
    return (
      <div>
        <Helmet>
          {checkUserUrl(this.state.otherurl) === true ? <title>
            Login page for SMS Email Voice Using one API - AuthKey.io |Powerful
            API built For Sending OTPs & Trans Traffic
          </title> : <title>
            welcome
          </title>}
          <meta
            name="description"
            content="Send SMS Voice or Email parallelly or use other channels as Fallback | High Volume APIs Built for Sending huge volume with Parallel Sending and Fallback option."
          />
          <style type="text/css">{`
                        .deals-and-offers {
                            display:none !important;
                        }
                        @media only screen and (max-width: 600px) {
                            .mobile-img {
                            display: none;
                            }
                        }
                    `}</style>
        </Helmet>

        <section
          className="loginbg otherlogoimg"
          style={{
            backgroundImage: window.location.host === "whatsapp.adznetworkmedia.om" || this.state.settings
              ? ``
              : `url(${loginimg})`
          }}
        >
          <div className="container d-flex flex-column">
            <div className="row flex-column-reverse flex-lg-row min-vh-100">
              <div className="col-md-6 col-lg-6 col-xl-6 py-6 py-md-0">
                <div className="mb-0">
                  <div className="card-body px-md-5 py-5">
                    {this.state.show ? (
                      <div className={this.state.bgColor}>
                        {this.state.loginMessage}
                      </div>
                    ) : null}

                    {/* <p className='mb-2'>Login with Social Media </p> */}

                    <form className="form-row">
                      <span className="clearfix"></span>

                      {/* <FacebookLogin
                                            appId="4884679621657801"
                                            textButton='Login With Facebook'
                                            autoLoad={false}
                                            fields="name,email"
                                            callback={this.responseFacebook} />
                                                                 
                                            <div className="form-group col-md-12 mt-3">
                                                <hr className='mt-0 mb-0' />
                                            </div> */}

                      <div className="form-group col-md-12">
                        <label className="form-control-label">
                          Email address
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            required
                            name="username"
                            onChange={(event) =>
                              this.setState({ username: event.target.value })
                            }
                            className="form-control logininput"
                            placeholder="name@example.com"
                          />
                        </div>
                      </div>
                      <div className="form-group col-md-12 mb-0">
                        <label className="form-control-label">
                          Enter Password
                        </label>
                        <div className="input-group ">
                          <input
                            type="password"
                            onKeyPress={this.keyPressed}
                            autoComplete="no"
                            required
                            name="password"
                            onChange={(event) =>
                              this.setState({ password: event.target.value })
                            }
                            className="form-control logininput"
                            placeholder="Password"
                          />
                        </div>
                        {/* <div className="row">
                                                    <div className="mt-4 col-md-6 text-left">
                                                        <p className="small"><Link to="/signup">Don't have account ?</Link></p>
                                                    </div>
                                                    <div className="mt-4 col-md-6 Forgot text-right">
                                                        <p><Link to="forgot-password">Forgot Password</Link></p>
                                                    </div>
                                                </div> */}
                      </div>
                      <div className="d-flex justify-content-center align-items-center mt-4">
                        <button
                          type="button"
                          onClick={() => {
                            this.login();
                          }}
                          className="home-button  btn-signup mx-2"
                        >
                          Log in
                        </button>


                        {this.state.otherurl === "https://auth.promotionmantra.com/my-account" &&
                          <a
                            href="https://auth.promotionmantra.com/resellersignup?ref=NzI3Nw=="
                            target="_blank"
                            style={{ background: "#fff" }}
                            className=" home-button mx-2"
                          >
                            Signup
                          </a>}

                        {this.state.otherurl === "https://auth.promotionmantra.com/my-account" &&
                          <p className="ml-7"> <Link to="forgot-password">Forgot Password ?</Link></p>}
                      </div>
                      <p>
                        By clicking on 'Login', you agree to our
                        <a href={this.state.settings?.term_and_condition || "#"}
                          target={this.state.settings?.term_and_condition ? "_blank" : "_self"}
                          style={{ pointerEvents: this.state.settings?.term_and_condition ? 'auto' : 'none' }} >  Terms & Conditions </a> and
                        <a href={this.state.settings?.privacy_policy || "#"}
                          target={this.state.settings?.privacy_policy ? "_blank" : "_self"}
                          style={{ pointerEvents: this.state.settings?.privacy_policy ? 'auto' : 'none' }}>  Privacy Policy</a>
                      </p>

                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-5  col-lg-5 col-xl-5">
                <div className="mb-3 login-right">
                  <h3>Login</h3>
                  <p>
                    Login in to your account to <br /> continue.
                  </p>
                </div>
                {window.location.host === "whatsapp.adznetworkmedia.om" && <div>
                  <img alt="" src="https://wpgallery.s3.ap-south-1.amazonaws.com/gallery/202502/67b859cf1b1eb.jpg" />
                </div>}

                {this.state.settings && <div>
                  <img alt="" className="loginPageLogo" src={this.state.settings?.signup_page_logo} />
                </div>}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default MyAccount;