import React, { useEffect, useState } from "react";
import "../../../../style/components/whatsAppPreview.css";
import { bg_whatsapp, whatsapp_brand } from "../../../../images/WhatsAppImages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faGlobe, faReply, faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";

function WhatsAppCarouselPreview(props) {
  const { previewData, ButtonCard, ButtonIndex } = props
  const [selectedButtonCard, setSelectedButtonCard] = useState(1)
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(1)

  useEffect(() => {
    const SendDataToparent = () => {
      ButtonCard(selectedButtonCard);
      ButtonIndex(selectedButtonIndex)
    };
    SendDataToparent();
  }, [ButtonCard, selectedButtonCard, ButtonIndex, selectedButtonIndex])

  let previewdata = props.previewData;
  let carouselContent = previewdata.carouselContent
  let selectedCard = previewdata.selectedCard;
  let selectedIndex = previewdata.selectedIndex
  let mediaData = previewData.mediaData

  const companyname = localStorage.getItem("wp-cmpy-name");
  var truncatedcompanyname;
  if (companyname) {
    if (companyname.length <= 15) {
      truncatedcompanyname = companyname
    } else {
      truncatedcompanyname = companyname.substring(0, 15) + '...';
    }
  }
  const companyimg = localStorage.getItem("wp-cmpy-img");

  const displayWhatsAppPreview = (TemplateType, headerText) => {
    for (let key in headerText) {
      if (key === "text") {
        return <strong> {previewdata.headerText.receiveHeadePrev} </strong>;
      }
      // if (key === "image") {
      //   return <img className="img-fluid" src={previewdata.headerText.image} />;
      // }
      // if (key === "video") {
      //   return (
      //     <video width="100%" controls>
      //       <source src={previewdata.headerText.video} type="video/mp4" />
      //     </video>
      //   );
      // }
      // if (key === "document") {
      //   return (
      //     <a
      //       target="_blank"
      //       rel="noopener noreferrer"
      //       href={previewdata.headerText.document}
      //     >
      //       <div className="doc-type">Document Media</div>
      //     </a>
      //   );
      // }
    }
  };

  const quickReply = () => {
    return previewdata.quickButton.map((item) => {
      let key = Object.keys(item);
      const index = key.indexOf("actionType");
      if (index > -1) {
        let data = key.splice(index, 1);
      }

      return key.map((keyitem) => (
        <div className="button-carousel-text">
          <p className="mb-1">{item[keyitem]}</p>
        </div>
      ));
    });
  };

  const handleBackward = () => {
    selectedCard = selectedButtonCard - 1
    selectedIndex = selectedButtonIndex - 1
    setSelectedButtonCard(selectedCard)
    setSelectedButtonIndex(selectedIndex)
  }

  const handleForward = () => {
    selectedCard = selectedButtonCard + 1
    selectedIndex = selectedButtonIndex + 1
    setSelectedButtonCard(selectedButtonCard + 1)
    setSelectedButtonIndex(selectedButtonIndex + 1)
  }

  console.log(previewData)

  return (
    <div className="whatsapp">
      {previewdata && (
        <>
          <ul>
            <li>
              {" "}
              <strong> Template name: </strong> {previewdata.tampleName}{" "}
            </li>
            <li>
              <strong> Language: </strong> {previewdata.language.label}{" "}
            </li>
            <li>
              {" "}
              <strong> Category: </strong>
              {previewdata.accountType}{" "}
            </li>
            <li>
              {" "}
              <strong> Type: </strong> Carousel Type {" "}
            </li>
          </ul>
          <div
            style={{ backgroundImage: `url(${bg_whatsapp})` }}
            className="whatsapp-review whatsapp-bg"
          >
            <div>
              <div className="text-section">
                <div className="whats-app-header">
                  <div className="">
                    {" "}
                    <img src={companyimg ? companyimg : whatsapp_brand} />{" "}
                  </div>
                  <div className="">
                    {" "}
                    <p>{truncatedcompanyname} </p>{" "}
                  </div>
                </div>

                <div className="carousel-body-message">
                  {displayWhatsAppPreview(previewdata.headerText)}
                  <div className="inner-carousel-body-message">
                    {/* {previewdata.headerText && <p><strong> {previewdata.headerText.receiveHeadePrev} </strong></p>} */}
                    <p>
                      {previewdata.preBodyMessage || previewdata.bodyMessage}
                    </p>

                    <small>{previewdata.footerText}</small>
                  </div>


                  <div className="inner-carousel-body-message">
                    {/* {displayWhatsAppPreview(
                previewdata.headerText.TemplateType,
                previewdata.headerText
              )} */}
                    {/* {mediaData ? (
                    Object.keys(mediaData).map((x, i) => {
                      let index = i + 1;
                      return (
                        <div key={index}>
                          {index === previewdata.selectedCard ? (
                            <img
                              src={mediaData[x]}
                              // alt={`Image ${index}`}
                              className="img-fluid" // Makes the image responsive
                              style={{ maxWidth: '150px', height: 'auto' }} // Optional: Set max size
                            />
                          ) : null}
                        </div>
                      );
                    })
                  ) : null} */}

                    {previewData.imageSrc[previewData.selectedCard] && (
                      <div>
                        {/* <h3>Selected Image for {selectedCard}</h3> */}
                        <img src={previewData.imageSrc[previewData.selectedCard]} alt="Selected" style={{ width: '150px', height: 'auto' }} />
                      </div>
                    )}


                    {carouselContent ? (
                      Object.keys(carouselContent).map((x, i) => {
                        let index = i + 1
                        return (
                          <p key={index}>
                            {index === previewdata.selectedCard ? carouselContent[x] : null}
                          </p>
                        )
                      })
                    ) :
                      null
                    }

                    <small>{previewdata.footerText}</small>
                    {previewData.buttonType === '' ? null : (
                      <>

                        {previewdata.buttonType === "call to action" ? (
                          <>
                            <hr />
                            <div className="button-carousel-text">
                              <a>
                                <p>
                                  <FontAwesomeIcon icon={faPhone} />
                                </p>
                              </a>
                            </div>
                          </>
                        ) : null}
                        {previewdata.buttonType === "quick reply" ?
                          (<>
                            <hr />
                            <div className="button-carousel-text">
                              <a>
                                <p>
                                  <FontAwesomeIcon icon={faReply} />
                                </p>
                              </a>
                            </div>
                          </>) : null}
                      </>
                    )}
                  </div>

                  {previewdata.Urlbutton[0] && (
                    <>
                      {previewdata.Urlbutton.map((item, index) => (
                        <div className="button-carousel-text">
                          <a href={`${item.url}`}>
                            {" "}
                            <p key={index}>
                              {" "}
                              <FontAwesomeIcon icon={faGlobe} /> {item.urltext}{" "}
                            </p>{" "}
                          </a>
                        </div>
                      ))}
                    </>
                  )}

                  {previewdata.Urlbutton2[0] && (
                    <>
                      {previewdata.Urlbutton2.map((item, index) => (
                        <div className="button-carousel-text">
                          <a href={`${item.url}`}>
                            {" "}
                            <p key={index}>
                              {" "}
                              <FontAwesomeIcon icon={faGlobe} /> {item.urltext2}{" "}
                            </p>{" "}
                          </a>
                        </div>
                      ))}
                    </>
                  )}

                  {previewdata.quickButton[0] && <>{quickReply()}</>}
                </div>



                {/* {previewdata.buttonEnable && (
              <div className="carousel-controls">
                <button
                  type="button"
                  onClick={handleBackward}
                  className="carousel-button left"
                >
                  <FontAwesomeIcon icon={faAngleLeft} />
                </button>
                <button
                  type="button"
                  onClick={handleForward}
                  className="carousel-button right"
                >
                  <FontAwesomeIcon icon={faAngleRight} />
                </button>
              </div>
            )} */}

                {/* {previewdata.Callbutton[0] && (
                  <>
                    {previewdata.Callbutton.map((item, index) => (
                      <div className="button-text">
                        <a href={`tel:+${item.countryCode}${item.phone}`}>
                          {" "}
                          <p key={index}>
                            {" "}
                            <FontAwesomeIcon icon={faPhone} /> {item.text}{" "}
                          </p>{" "}
                        </a>
                      </div>
                    ))}
                  </>
                )} */}


              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default WhatsAppCarouselPreview;
