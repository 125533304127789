import React, { useState } from 'react';
import Dialog from 'react-dialog';

function GetKarixSignupData(props) {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { accountResponse, allocateResponse, regionalApiResponse, KarixApiKey } = props;

    const openDialog = () => {
        setIsDialogOpen(true);
    }

    const handleClose = () => {
        setIsDialogOpen(false);
    }

    return (
        <>
            {
                isDialogOpen &&
                <Dialog
                    title={'View Karix Signup Data'}
                    modal={true}
                    height={500}
                    isDraggable={true}
                    onClose={handleClose}
                    className="p-4"
                >
                    <div className="container-fluid">
                        {/* Api Key */}
                        <div className="row mb-3">
                            <div className='col-md-6'>
                                <label htmlFor="karixApiKey" className="form-label">API Key</label>
                                <input
                                    type="text"
                                    id="karixApiKey"
                                    value={KarixApiKey}
                                    readOnly
                                    className="form-control"
                                />
                            </div>
                            {/* Account Response */}
                            <div className='col-md-6'>
                                <label htmlFor="accountResponse" className="form-label">Account Response</label>
                                <textarea
                                    id="accountResponse"
                                    value={accountResponse}
                                    readOnly
                                    className="form-control"
                                    rows="3"
                                />
                            </div>
                        </div>

                        {/* Allocate Response */}
                        <div className="row mb-3">
                            <div className='col-md-6'>
                                <label htmlFor="allocateResponse" className="form-label">Allocate Response</label>
                                <textarea
                                    id="allocateResponse"
                                    value={allocateResponse}
                                    readOnly
                                    className="form-control"
                                    rows="3"
                                />
                            </div>
                            {/* Regional API Response */}
                            <div className='col-md-6'>
                                <label htmlFor="regionalApiResponse" className="form-label">Regional API Response</label>
                                <textarea
                                    id="regionalApiResponse"
                                    value={regionalApiResponse}
                                    readOnly
                                    className="form-control"
                                    rows="3"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-end mt-3">
                        <button onClick={handleClose} className="btn btn-secondary">Close</button>
                    </div>
                </Dialog>
            }

            <span onClick={() => openDialog()} className='badge bg-primary'>View Details</span>
        </>
    );
}

export default GetKarixSignupData;
