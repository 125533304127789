import React, { Component } from "react";
import DashboardHeader from "../../Header/DashboardHeader";
import DashboardFooter from "../../Footer/DashboardFooter";
import DashboardLeftMenu from "../DashboardLeftMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tabs, Tab } from "react-bootstrap-tabs";

import {
    faBroadcastTower,
    faArrowCircleLeft,
    faAddressBook,
    faCheckCircle,
    faFlag,
    faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import Alert from "../../UI/Alert/Alert";
import Spining from "../../UI/Spining/Spining";
import {
    CAMPAIGN_CONTACT_LIST_API,
    WHATSAPP_TEMPLATE_LIST,
} from "../../../constants/ServiceConstants";
import api from "../../../axios/Axios";
import { Link } from "react-router-dom";
import WhatsAppPreview from "../whatsapp/components/WhatsAppPreview";
import { no_data } from "../../../images/WhatsAppImages";
import {
    getWhatsaAppTemplateList,
    campaignContactList,
    WhstappAccountList,
    createWhatsAppCampaign,
    contactListAPI,
    HOST_URL,
} from "../api/Api";
import { Helmet } from "react-helmet";
import BillableValue from "./component/BillableValue";
import SetMediaDynamic from "./component/SetMediaDynamic";
import Select from "react-select";
import { withRouter } from 'react-router-dom';
import UploadExcelFile from "./component/UploadExcelFile";
import UploadCSVFile from "./component/UploadCSVFile";
import { Carousel } from 'react-responsive-carousel';
import Axios from 'axios';
import style from './Rcs/rcscampaign.module.css'
class Campaign extends Component {
    constructor(props) {

        const lStorage = JSON.parse(localStorage.getItem("login"));
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;
        var today = new Date(),
            date =
                today.getFullYear() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                ("0" + today.getDate()).slice(-2);
        super(props);
        this.state = {
            user_id: userId,
            token: userToken,
            contactList: "",
            step: 0, // TODO 0
            templatePreView: "",
            previewData: "",
            sampleData: "",
            WhatsappTemplateList: "",
            smsTemplateList: "",
            schedule_datetime: date,
            is_schedule: 0,
            media_url: "",
            brandNumberList: [],
            parameter: [],
            mobile_colum: "",
            tableColume: [],
            varibaleInTemplate: "",
            seleted_brand_number: null,
            CampaignName: "",
            channel: "whatsapp",
            templteId: "",
            templateName: "",
            listId: "",
            SMSmessage: "",
            country_code: 91,
            campaignType: "1",
            textnumbers: "",
            numbersArray: [],
            fileData: "",
            countData: "",
            uploadProgress: 0,
            smsTemplateDetails: null,
            selectedListName: "",
            totalCount: "",
            isLoading: false,
            errorMessage: "",
            isError: false,
            messageType: "",
            billable: "",
            not_billable: "",
            enableCampaign: true,
            option: [],
            contactOption: [],
            urlPath: window.location,
            route: "",
            selectedOption: null,
            dynamicSampleData: [],
            csvHeaders: [],
            filteredHeaders: [],
            csvRows: [],
            mainMsg: '',
            bodyVar: [],
            bodyValue: '',
            variableMapping: {},
            previews: [],
        };
        this.nextStep = this.nextStep.bind(this);
        this.findAndReplace = this.findAndReplace.bind(this);
        this.getCsvData = this.getCsvData.bind(this);
        this.scheduleHandler = this.scheduleHandler.bind(this);
        this.setMediaURL = this.setMediaURL.bind(this);
        this.handleChannel = this.handleChannel.bind(this);
    }

    componentDidMount() {
        this.getContactList();
        this.getBrandNumber();
        this.getWhatsaAppTemplateList();
        this.getRoute();
    }

    getRoute = async () => {
        try {
            let response = await fetch(`${HOST_URL}/get_user_route.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`)
            let result = await response.json()
            if (result.success) {
                this.setState({ route: result.data })
            }
        } catch (error) {

        }

    }

    getWhatsaAppTemplateList = () => {
        getWhatsaAppTemplateList().then((res) => {
            this.setState({ isLoading: true });
            try {
                if (res.data.success === true) {
                    let withOutDynamicTemplate = [];
                    res.data.data.find((item) => {
                        if (
                            // item.temp_button.includes(`"urlType":"Dynemic"`) != true &&
                            item.temp_status === 1
                        )
                            withOutDynamicTemplate.push(item);
                    });

                    let filterData = withOutDynamicTemplate.filter(x => x.temp_type !== "Carousel");

                    filterData.forEach((item) => {
                        let newData = { value: item.id, label: item.temp_name };
                        this.setState((prevState) => ({
                            option: [...prevState.option, newData],
                        }));
                    });
                    this.setState({
                        WhatsappTemplateList: filterData,
                        isLoading: false,
                    });
                } else {
                    this.setState({ isLoading: false });
                }
            } catch (error) {
                console.log("error", error);
            }
        });
    };

    // nextStep = async () => {
    //   if (this.state.step == 0) {
    //     if (this.state.CampaignName === "" || this.state.channel === "") {
    //       this.setState({
    //         isError: true,
    //         messageType: "alert-danger",
    //         errorMessage: "Please enter all details",
    //         isLoading: false,
    //       });
    //       return false;
    //     } else {
    //       this.setState({
    //         step: this.state.step + 1,
    //         successMessage: false,
    //         isError: false,
    //       });
    //       return true;
    //     }
    //   }

    //   if (this.state.step == 1) {
    //     if (this.state.listId === "" && this.state.textnumbers === "") {
    //       this.setState({
    //         isError: true,
    //         messageType: "alert-danger",
    //         errorMessage: "Please select at list one option",
    //         isLoading: false,
    //       });
    //     } else {
    //       this.setState({
    //         step: this.state.step + 1,
    //         successMessage: false,
    //         isError: false,
    //       });
    //     }
    //   }
    //   this.setState({
    //     step: this.state.step + 1,
    //     successMessage: false,
    //     isError: false,
    //   });
    // };

    onNameChange = (e) => {
        this.setState({ CampaignName: e.target.value });
        this.setState({ isError: false });
    };

    isValid = () => {
        if (
            this.state.CampaignName === undefined ||
            this.state.CampaignName === "" || this.state.seleted_brand_number === null
        ) {
            this.setState({
                isError: true,
                messageType: "alert-danger",
                errorMessage: "Please enter all fields",
                isLoading: false,
            });
            return false;
        }
        return true;
    };

    nextStep = async () => {
        if (this.isValid()) {
            this.setState({
                step: this.state.step + 1,
            });
        }
    };

    prevStep = () => {
        this.setState({ step: this.state.step - 1 });
    };

    getContactList = () => {
        contactListAPI("retrieve").then((res) => {
            if (res.data.success === true) {
                let ctList = res.data.data;
                ctList.forEach((item) => {
                    let newCtData = { value: item.id, label: `${item.name}(${item.number_count})` };
                    this.setState((prevState) => ({
                        contactOption: [...prevState.contactOption, newCtData],
                    }));
                });
                this.setState({
                    contactList: res.data.data,
                    isLoading: false,
                });
            } else {
                this.setState({ ErrorResponse: res.data, isLoading: false });
            }
        });
    };

    createCampaign = async () => {
        this.setState({ isLoading: true });

        const newArr = [{ id: this?.state?.selectedOption?.value }];

        // ✅ Create FormData Object
        let formData = new FormData();

        // ✅ Append all key-value pairs
        formData.append("token", this.state.token);
        formData.append("user_id", this.state.user_id);
        formData.append("channel", this.state.channel);
        formData.append("template_id", this.state.templteId);
        formData.append("list_id", this.state.listId);
        formData.append(
            "parameter",
            JSON.stringify(this.state.campaignType === "1" ? this.state.parameter : this.state.variableMapping)
        ); // Convert object to JSON string
        formData.append("mobile_colum", this.state.mobile_colum);
        formData.append("camp_name", this.state.CampaignName);
        formData.append("media_url", this.state.media_url);
        formData.append("total_count", this.state.totalCount);
        formData.append("brand_number", this.state.seleted_brand_number);
        formData.append("schedule_datetime", this.state.schedule_datetime);
        formData.append("is_schedule", this.state.is_schedule);
        formData.append("method", "create_new");
        formData.append("textnumbers", this.state.numbersArray);
        formData.append(
            "contact_list",
            JSON.stringify(this.state.selectedOption ? newArr : [])
        );
        formData.append("campaign_type", this.state.campaignType === "3" ? 1 : 0);
        formData.append("dynamic_content", this.state.bodyVar.length > 0 ? 1 : 0);

        // ✅ Append File if Exists
        if (this.state.fileData) {
            formData.append("fileData", this.state.fileData);
        }

        try {
            const res = await Axios.post(`${HOST_URL}/bulk_campaign_sms_as.php`, formData, {
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    this.setState({ uploadProgress: progress }); // Update progress in state
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (res.data.success) {
                this.setState({
                    isError: true,
                    messageType: "alert-success",
                    errorMessage: res.data.message,
                });
                setTimeout(function () {
                    window.location = "/dashboard/campaign";
                }, 1000);
            } else {
                this.setState({
                    isError: true,
                    messageType: "alert-danger",
                    errorMessage: res.data.message,

                });
            }
        } catch (error) {
            console.error("Error creating campaign:", error);
            this.setState({
                isError: true,
                messageType: "alert-danger",
                errorMessage: "Something went wrong, please try again.",

            });
        }
        finally {
            this.setState({ isLoading: false })
        }
    };


    enableCampaingButton = (value) => {
        this.setState({
            enableCampaign: value,
        });
    };

    getBrandNumber = async () => {
        WhstappAccountList().then((res) => {
            if (res.data.success === true) {
                this.setState({ brandNumberList: res.data.data });
            } else {
                this.setState({ ErrorResponse: res.data.data });
            }
        });
    };

    selectedWhatsAppTemplate = async (e) => {
        this.setState({ templteId: e.value, templateName: e.label });
        this.setState({
            bodyValue: '',
            parameter: [],
            bodyVar: [],
            mainMsg: '',
            variableMapping: {},
            previews: [],

        })
        try {
            const { data } = await api.post(WHATSAPP_TEMPLATE_LIST, {
                token: this.state.token,
                user_id: this.state.user_id,
                id: e.value,
                method: "retrieveid",
            });
            let urlbtn = [];
            let urlbtn2 = [];
            let callbtn = [];
            let button1 = [];
            let button2 = [];
            let button3 = [];
            if (data.success === true) {
                if (data.data[0].temp_button) {
                    let buttonData = data.data[0].temp_button;
                    buttonData.map((item, index) => {
                        let key = Object.keys(item);
                        if (key.includes("urltext2")) {
                            urlbtn2[0] = item;
                        }
                        if (key.includes("urltext")) {
                            urlbtn[0] = item;
                        }
                        if (key.includes("phone")) {
                            callbtn[0] = item;
                        }
                        if (key.includes("button1")) {
                            button1[0] = item;
                        }
                        if (key.includes("button2")) {
                            button2[0] = item;
                        }
                        if (key.includes("button3")) {
                            button3[0] = item;
                        }
                    });
                }

                let PreviewTemplateData = {
                    tampleName: data.data[0].temp_name,
                    language: data.data[0].temp_language,
                    accountType: data.data[0].temp_category,
                    templateType: data.data[0].temp_type,
                    headerText: data.data[0].temp_header,
                    headerOptions: data.data[0].temp_header === "" ? false : true,
                    footerText: data.data[0].temp_footer,
                    footerOptions: data.data[0].temp_header === "" ? false : true,
                    temp_button: data.data[0].temp_button,
                    bodyMessage: data.data[0].temp_body,
                    button: data.data[0].temp_button,
                    temp_status: data.data[0].temp_status,
                    Urlbutton: urlbtn,
                    Urlbutton2: urlbtn2,
                    Callbutton: callbtn,
                    button1: button1,
                    button2: button2,
                    button3: button3,
                    quickButton:
                        data.data[0].temp_button.length > 0 &&
                            data.data[0].temp_button[0].actionType === "quickReply"
                            ? [data.data[0].temp_button[0]]
                            : "",
                    temp_attribute: [],
                    isLoading: false,
                };

                this.extractData(data.data[0].temp_body)
                const bodyVariables = data.data[0].temp_body.match(/\*?\{\{([^}]+)\}\}\*?/g) || [];

                this.getVariable(data.data[0].temp_body);
                this.setState({
                    templatePreView: PreviewTemplateData,
                    mainMsg: data.data[0].temp_body,
                    bodyVar: [...bodyVariables]
                });
                this.mediaTemplate(PreviewTemplateData);
            }
        } catch (err) {
            console.log(err);
        }
    };

    getVariable = (data) => {
        var regex = /{{([^}]+)}}/g;
        let m;
        var tempArr = ["mobile"];
        var parameter = {};
        while ((m = regex.exec(data)) !== null) {
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            parameter[`${m[1]}`] = "";
            tempArr.push(m[1]);
        }
        this.setState({
            varibaleInTemplate: tempArr,
            parameter: [...this.state.parameter, parameter],
        });
    };

    setParameter = (e) => {
        this.state.parameter.map((item, i) => {
            Object.keys(item).map((key) => {
                if (key === e.target.name) {
                    let obj = (this.state.parameter[i][key] = e.target.value);
                    this.findAndReplace(e.target.name, obj);
                } else {
                    console.log("something went wrong");
                }
            });
        });
    };

    handleMapping = (variable, selectedHeader) => {
        this.setState(
            (prevState) => {
                // Update the variable mapping with the new key-value pair
                const updatedVariableMapping = {
                    ...prevState.variableMapping,
                    [variable]: selectedHeader,
                };

                // Remove the previous value for this variable from filteredHeaders
                const updatedFilteredHeaders = prevState.filteredHeaders.filter(
                    (header) => header !== prevState.variableMapping[variable]
                );

                // Check if selectedHeader is already in filteredHeaders
                if (!updatedFilteredHeaders.includes(selectedHeader)) {
                    // Add the new selectedHeader only if it's not already in the array
                    updatedFilteredHeaders.push(selectedHeader);
                }

                return {
                    variableMapping: updatedVariableMapping,
                    filteredHeaders: updatedFilteredHeaders,

                };
            },
            () => {

                // Generate previews after mapping is updated


                const previews = this.generatePreviews();
                this.setState({
                    previews: previews.map((item) => ({
                        ...this.state.templatePreView, // Spread existing object
                        bodyMessage: item, // Update the property
                    })),
                });


            }
        );
    }


    generatePreviews() {
        const { csvRows, variableMapping, mainMsg, csvHeaders } = this.state;

        return csvRows.map((row) => {
            let previewString = mainMsg; // ✅ Keep this string for replacement logic
            const spans = []; // ✅ Store JSX spans separately

            // Process each variable in the mapping
            Object.keys(variableMapping).forEach((variable) => {
                const header = variableMapping[variable];
                const columnIndex = csvHeaders.indexOf(header);

                if (columnIndex !== -1) {
                    const value = row[columnIndex];

                    // ✅ Check if `*variable*` exists, else use `variable`
                    const boldKey = `*${variable}*`;
                    const normalKey = `${variable}`;

                    let splitKey = null;
                    if (previewString.includes(boldKey)) {
                        splitKey = boldKey; // Use `*variable*`
                    } else if (previewString.includes(normalKey)) {
                        splitKey = normalKey; // Use `variable`
                    }

                    if (splitKey) {
                        // ✅ Replace with a temporary marker
                        previewString = previewString.replace(splitKey, `[SPAN-${variable}]`);

                        // ✅ Store JSX data separately
                        spans.push({
                            key: variable,
                            value,
                            isBold: splitKey === boldKey, // ✅ Bold only if it was `*variable*`
                        });
                    }
                }
            });

            // ✅ Convert placeholders into JSX
            const finalPreview = previewString.split(/(\[SPAN-.*?\])/).map((part, index) => {
                const match = part.match(/\[SPAN-(.*?)\]/);
                if (match) {
                    const variable = match[1];
                    const spanData = spans.find((s) => s.key === variable);
                    return (
                        <span key={index} style={{ color: "blue", fontWeight: spanData.isBold ? "bold" : "normal" }}>
                            {spanData.value}
                        </span>
                    );
                }
                return part; // Keep normal text
            });

            return finalPreview; // ✅ Return JSX safely
        });
    }

    getColumedata = (id) => {
        campaignContactList(id).then((res) => {
            this.setState({ isLoading: true });
            try {
                if (res.data.success === true) {
                    this.setState({
                        tableColume: Object.keys(res.data.data[0]),
                        previewData: res.data.data,
                        isLoading: false,
                    });
                } else {
                    this.setState({ isLoading: false });
                }
            } catch (error) {
                console.log("error", error);
            }
        });
    };

    clearTemplatePreview = () => {
        if (this.state.templteId) {
            this.setState({
                templteId: "",
                templateName: "",
                templatePreView: "",
                mainMsg: '',
                bodyVar: [],
                varibaleInTemplate: "",
                parameter: [],
                variableMapping: {},
                previews: [],

            })

        }
    }

    handleCampaignType = (e) => {
        this.setState({
            campaignType: e.target.value,
            textnumbers: "",
            numbersArray: []
        })
        if (this.state.selectedOption) {
            this.setState({
                selectedOption: null, listId: "",
                selectedListName: ""
            })
        }
        if (this.state.fileData) {
            this.setState({
                fileData: "",
                sampleData: "",
                countData: "",
                tableColume: [],
                dynamicSampleData: [],
                csvHeaders: [],
                filteredHeaders: [],
                csvRows: [],
            })
        }

        this.clearTemplatePreview();

    }


    onChange = (e) => {
        // if (this.state.selectedOption) {
        //     this.setState({
        //         selectedOption: null, listId: "",
        //         selectedListName: ""
        //     })
        // }
        // if (this.state.fileData) {
        //     this.setState({
        //         fileData: "",
        //         sampleData: "",
        //         countData: "",
        //         tableColume: [],
        //         dynamicSampleData: [],
        //     })
        // }
        this.clearTemplatePreview();
        const value = e.target.value;
        // Allow only numbers and commas
        const validValue = value.replace(/[^0-9,\n]/g, '');
        if (value !== validValue) {
            this.setState({
                isError: true,
                messageType: "alert-danger",
                errorMessage: "Please enter a valid input containing only numbers and commas.",
                isLoading: false,
            });
        } else {
            this.setState({ isError: false, errorMessage: "", messageType: "" });
        }
        this.setState({ textnumbers: validValue });
        this.newArray(validValue);
    };

    newArray = (data) => {
        const numbers = data.split(/[\n,]+/).map((num) => num.trim());
        this.setState({ numbersArray: numbers });
    };

    contactListhandler = (e) => {
        if (!e) {
            // When cleared, reset state
            this.setState({
                selectedOption: null,
                textnumbers: "",
                numbersArray: [],
                fileData: "",
                sampleData: "",
                countData: "",
                tableColume: [],
                dynamicSampleData: [],
                selectedListName: "",
                totalCount: 0,
                listId: "",
            });
            this.clearTemplatePreview();
            return;
        }

        // When a valid option is selected
        this.setState({ selectedOption: e,
            //  textnumbers: "", numbersArray: [] 
            });

        // if (this.state.fileData) {
        //     this.setState({
        //         fileData: "",
        //         sampleData: "",
        //         countData: "",
        //         tableColume: [],
        //         dynamicSampleData: [],
        //     });
        // }

        this.clearTemplatePreview();
        this.setState({ listId: e.value });

        var item = this.state.contactList.filter(
            (item) => item.id === parseInt(e.value)
        );

        this.setState({
            selectedListName: item[0]?.name || "", // Use optional chaining to prevent errors
            totalCount: item[0]?.number_count || 0, // Default to 0 if not found
        });

        this.getColumedata(e.value);
        this.getSampleData(e.value);
    };


    getSampleData = async (id) => {
        try {
            const { data } = await api.post(CAMPAIGN_CONTACT_LIST_API, {
                token: this.state.token,
                user_id: this.state.user_id,
                list_id: id,
                method: "retrieve_data_sample",
            });
            if (data.success === true) {
                this.setState({ sampleData: data.data, setIsLoading: false });
            } else {
                this.setState({ ErrorResponse: data, setIsLoading: false });
            }
        } catch (err) {
            console.log(err);
        }
    };

    extractData = (data) => {
        const pattern = /\*?\{\{([^}]+)\}\}\*?/g;
        const matches = [];
        let match;
        while ((match = pattern.exec(data)) !== null) {
            matches.push(match[1]);
        }

        return matches;
    };

    replaceBodyVal = (index, e) => {
        const updatedValues = { ...this.state.bodyValue };
        updatedValues[e.target.name] = e.target.value;

        this.setState({ bodyValue: updatedValues, parameter: updatedValues });


        const bodydata = this.formatMessage(
            this.state.mainMsg,
            this.state.bodyVar,
            updatedValues
        );

        this.setState({
            templatePreView: {
                ...this.state.templatePreView,
                ["bodyMessage"]: bodydata,
            },
        });


    };

    formatMessage = (msg, placeholders, inputValues) => {
        const parts = msg.split(/\*?\{\{([^}]+)\}\}\*?/g);
        return parts.map((part, index) => {
            const placeholderKey = part;
            if (placeholders.includes(`{{${placeholderKey}}}`)
            ) {
                return (
                    <span key={index} style={{
                        color: inputValues[placeholderKey] ? "blue" : "", // Blue for input values, black for placeholders
                    }}>
                        {inputValues[placeholderKey] || `{{${part}}}`}
                    </span>
                );
            }
            if (placeholders.includes(`*{{${placeholderKey}}}*`)
            ) {
                return (
                    <span key={index} style={{
                        color: inputValues[placeholderKey] ? "blue" : "", // Blue for input values, black for placeholders
                        fontWeight: inputValues[placeholderKey] ? "bold" : "",
                    }}>
                        {inputValues[placeholderKey] || `*{{${part}}}*`}
                    </span>
                );
            }
            return part;
        });
    };

    renderSampleData = () => {
        let data = this.state.sampleData;
        if (data.length > 0) {
            const column = Object.keys(data[0]);
            return (
                <>
                    <p>Your Table Data </p>
                    <table className="table table-hover  my-0">
                        <thead>
                            {column.map((data, i) => (
                                <th key={i}>{data}</th>
                            ))}
                        </thead>
                        {data.map((data, i) => (
                            <tr key={i}>
                                {column.map((v) => {
                                    return <td>{data[v]}</td>;
                                })}
                            </tr>
                        ))}
                    </table>
                </>
            );
        }
    };

    findAndReplace = (name, column) => {
        let text = this.state.templatePreView.bodyMessage;
        let replaceKeyword = `{{${name}}}`;
        let value = this.state.sampleData[0][column];
        let newtext = text.replace(replaceKeyword, value);
        let obj = (this.state.templatePreView["temp_body"] = newtext);
        this.setState({
            templatePreView: {
                ...this.state.templatePreView,
                ["bodyMessage"]: newtext,
            },
        });
    };

    getCsvData(data, countData) {
        // if (this.state.selectedOption) {
        //     this.setState({
        //         selectedOption: null, listId: "",
        //         selectedListName: ""
        //     })
        // }
        // if (this.state.textnumbers) {
        //     this.setState({ textnumbers: "", numbersArray: [] })
        // }
        this.clearTemplatePreview();
        this.setState({
            sampleData: data.rowData,
            fileData: data,
            countData: countData,
            tableColume: data.tableColume,
            dynamicSampleData: countData.splice(0, 5),
        });
        this.setCsvData();
    }

    setCsvData = () => {
        const { dynamicSampleData } = this.state;
        // Extract headers and data
        const csvHeaders = dynamicSampleData[0]
            ? Object.keys(dynamicSampleData[0]).map((header) => header.trim())
            : [];

        this.setState({ csvHeaders: csvHeaders })
        const csvRows = dynamicSampleData.map((item) => Object.values(item));
        this.setState({ csvRows: csvRows })
    }

    setLoading = (value) => {
        this.setState({ isLoading: value })
    }

    setUploadProgress = (value) => {
        this.setState({ uploadProgress: value })
    }

    scheduleHandler(e) {
        if (e.target.checked === true) {
            this.setState({ is_schedule: 1 });
        } else {
            this.setState({ is_schedule: 0 });
        }
    }

    mediaTemplate() {
        let data = this.state.templatePreView;
        if (data != "" && data != undefined) {
            const hasKey = "image" in data;
            if (hasKey) {
                console.log("The key exists.");
            } else {
                console.log("The key does not exist.");
            }
        }
    }

    setMediaURL(data) {
        let newTemplate = { ...this.state.templatePreView };
        let objectkey = Object.keys(this.state.templatePreView.headerText)[0];
        if (data) {
            newTemplate.headerText[objectkey] = data;
        }

        this.setState({
            media_url: data,
        });
    }

    handleChannel(e) {
        const { pathname } = this.state.urlPath;
        const segments = pathname.split("/");
        const endPath = segments[segments.length - 1];
        const selectedChannel = e.target.value;

        if (selectedChannel === "whatsapp") {
            if (endPath === "campaign-start") {
                this.setState({ channel: selectedChannel });
            } else {
                this.props.history.push("/dashboard/campaign-start");
            }
        }

        if (selectedChannel === "sms") {
            if (endPath === "sms-campaign") {
                this.setState({ channel: selectedChannel });
            } else {
                this.props.history.push("/dashboard/sms-campaign");
            }
        }

        if (selectedChannel === "rcs") {
            if (endPath === "rcs-campaign") {
                this.setState({ channel: selectedChannel });
            } else {
                this.props.history.push("/dashboard/rcs-campaign");
            }
        }
    }

    formRender = () => {
        switch (this.state.step) {
            case 1:
                return (
                    <>
                        <div className="filter-option">
                            <div className="row">
                                <div className="col-md-12">
                                    <p className="d-block">
                                        Choose Audiance to send the campaign on
                                        <span className="text-primary"> {this.state.channel} </span>
                                    </p>
                                    <div className="">
                                        <div>
                                            {this.state.campaignType === "1" &&
                                                <Tabs id="fall-back-tab">
                                                    <Tab label="Select From Contact List">
                                                        {this.state.contactList ? (
                                                            <div className="input-group drop-down-icons mb-2 mt-4 mr-sm-2">
                                                                <Select
                                                                    value={this.state.selectedOption}
                                                                    onChange={this.contactListhandler}
                                                                    options={this.state.contactOption}
                                                                    isSearchable={true}
                                                                    placeholder="Select Contact List"
                                                                    isClearable={true}
                                                                />

                                                            </div>
                                                        ) : (
                                                            <p className="alert alert-danger d-block">
                                                                You don't have any contacts please create{" "}
                                                                <Link to="/dashboard/contact-list">Contacts</Link>{" "}
                                                                and then come back.
                                                            </p>
                                                        )}
                                                    </Tab>
                                                    <Tab label="Copy Paste Data">
                                                        <div className="input-group flex flex-column mb-2 mt-4 mr-sm-2">
                                                            <p style={{ fontSize: '.6rem', color: 'red', wordBreak: "break-all" }}>
                                                                *Enter mobile number {`${'(with country code)'}`} in new line  or comma separated
                                                            </p>
                                                            <textarea
                                                                placeholder="Enter Mobile Number"
                                                                onChange={this.onChange}
                                                                defaultValue={this.state.textnumbers}
                                                                className="form-control w-100"
                                                                rows={5}
                                                            ></textarea>
                                                        </div>
                                                        <span style={{ float: "right" }}>
                                                            count: {this.state.numbersArray.length + this.state.countData.length}
                                                        </span>
                                                    </Tab>
                                                    <Tab label="Upload .csv File">
                                                        <UploadCSVFile sendCsvData={this.getCsvData} setLoading={this.setLoading} />
                                                    </Tab>
                                                </Tabs>}
                                            {this.state.campaignType === "3" &&
                                                <Tabs id="fall-back-tab">
                                                    <Tab label="Upload .xls/.xlsx File">
                                                        <UploadExcelFile setUploadProgress={this.setUploadProgress} sendCsvData={this.getCsvData} setLoading={this.setLoading} />
                                                    </Tab>
                                                    {/* <Tab label="Select From Contact List">
                                                    {this.state.contactList ? (
                                                        <div className="input-group drop-down-icons mb-2 mt-4 mr-sm-2">

                                                            <Select
                                                                value={this.state.selectedOption}  // Bind selected option to state
                                                                onChange={this.contactListhandler}  // Handle onChange event
                                                                options={this.state.contactOption}  // Options array
                                                                isSearchable={true}  // Allow searching in options
                                                                placeholder="Select Contact List"
                                                                isClearable={true}  // Optional: allows clearing the selection
                                                            />

                                                        </div>
                                                    ) : (
                                                        <p className="alert alert-danger d-block">
                                                            You don't have any contacts please create{" "}
                                                            <Link to="/dashboard/contact-list">Contacts</Link>{" "}
                                                            and then come back.
                                                        </p>
                                                    )}
                                                </Tab> */}
                                                </Tabs>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                );

            case 2:
                return (
                    <>
                        <div className="filter-option">
                            <div className="row">
                                <div className="col-md-7">
                                    <p className="d-block mt-4">
                                        Select{" "}
                                        <span className="text-primary">{this.state.channel}</span>{" "}
                                        template to campaign
                                    </p>

                                    {this.state.WhatsappTemplateList ? (
                                        <>
                                            <div className="input-group drop-down-icons mb-2 mr-sm-2">
                                                {/* <div className="input-group-text">
                          <FontAwesomeIcon icon={faAddressBook} />
                        </div> */}

                                                {/* <select
                          onChange={this.selectedWhatsAppTemplate}
                          className="form-control"
                        >
                          <option value={``}>Select Template</option>
                          {this.state.WhatsappTemplateList.map(
                            (item, index) => (
                              <option key={index} value={item.id}>
                                {item.temp_name}
                              </option>
                            )
                          )}
                        </select> */}
                                                <Select
                                                    value={this.state.templteId ?
                                                        {
                                                            value: this.state.templteId,
                                                            label: this.state.templateName
                                                        } : null}

                                                    onChange={this.selectedWhatsAppTemplate}
                                                    options={this.state.option}
                                                    isSearchable={true}
                                                    placeholder="Select Template"
                                                // isClearable
                                                />
                                            </div>
                                            <p></p>
                                        </>
                                    ) : (
                                        <p className="alert alert-danger d-block">
                                            You don't have any template please create{" "}
                                            <Link to="/dashboard/ whatsapp-template-list">
                                                Template
                                            </Link>{" "}
                                            and then come back.
                                        </p>
                                    )}

                                    {this.state.varibaleInTemplate && (
                                        <>
                                            {this.state.campaignType === "3" && <p>Please select column </p>}
                                            {this.state.varibaleInTemplate.map((item, index) => (
                                                <>
                                                    <div key={index} className="input-group mb-2 mr-sm-2">
                                                        {this.state.campaignType === "1" && item !== "mobile" && <div className="input-group-text">{item}</div>}
                                                        {this.state.campaignType === "3" && <div className="input-group-text">{item}</div>}

                                                        {/* Show dropdown if campaignType is "3" */}
                                                        {this.state.campaignType === "3" && (
                                                            <select name={item}
                                                                onChange={item !== "mobile"
                                                                    ? (e) => this.handleMapping(`{{${item}}}`, e.target.value)
                                                                    : (e) => this.setState({ mobile_colum: e.target.value })}
                                                                value={item !== "mobile"
                                                                    ? (this.state.variableMapping[`{{${item}}}`] || "")
                                                                    : this.state.mobile_colum}
                                                                className="form-control">
                                                                <option value="">Select Column</option>
                                                                {this.state.csvHeaders?.map((column, colIndex) => (
                                                                    <option key={colIndex} value={column}>{column}</option>
                                                                ))}
                                                            </select>
                                                        )}

                                                        {/* Skip input for "mobile", otherwise show input if campaignType is "1" */}
                                                        {item !== "mobile" && this.state.campaignType === "1" && (
                                                            <input type="text" name={item} className="form-control"
                                                                placeholder={`Enter variable value`}
                                                                value={this.state.parameter[item]}
                                                                onChange={(e) => this.replaceBodyVal(index, e)} />
                                                        )}
                                                    </div>
                                                   { item !== "mobile" && this.state.campaignType === "1" && (
                                                    <p className="text-danger" style={{fontSize:".7rem"}}>Fixed value will be sent to all numbers</p>
                                                    )}
                                                </>
                                            ))}

                                            {this.state.templatePreView && (
                                                <SetMediaDynamic
                                                    setMediaURL={this.setMediaURL}
                                                    headertext={this.state.templatePreView.headerText}
                                                />
                                            )}
                                        </>
                                    )}

                                    <hr />
                                    {/* {this.state.channel === "whatsapp" &&
                                        this.state.selectedOption
                                        && (
                                            <div className="card p-2">
                                                <div className="table-responsive">
                                                    {this.renderSampleData()}
                                                </div>
                                            </div>
                                        )} */}
                                </div>
                                <div className="col-md-5">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="card-title mb-0"> Template Preview </h5>
                                        </div>
                                        <hr className="m-0" />
                                        <div className="card-body">
                                            {this.state.templatePreView ? (
                                                <>
                                                    {this.state.campaignType === "1" && (
                                                        <WhatsAppPreview previewData={this.state.templatePreView} route={this.state.route} />
                                                    )}

                                                    {this.state.campaignType === "3" && (
                                                        this.state.previews.length > 0 ? (
                                                            <Carousel
                                                                autoPlay={false}
                                                                infiniteLoop={true}
                                                                showThumbs={false}
                                                                showArrows={true}
                                                                showStatus={false}
                                                                interval={5000}
                                                                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                                                    hasPrev && <CustomPrevArrow onClick={onClickHandler} />
                                                                }
                                                                renderArrowNext={(onClickHandler, hasNext, label) =>
                                                                    hasNext && <CustomNextArrow onClick={onClickHandler} />
                                                                }
                                                            >
                                                                {this.state.previews.map((preview, index) => (
                                                                    <WhatsAppPreview key={index} previewData={preview} route={this.state.route} />
                                                                ))}
                                                            </Carousel>
                                                        ) : (
                                                            <WhatsAppPreview previewData={this.state.templatePreView} route={this.state.route} />
                                                        )
                                                    )}

                                                </>
                                            ) : (
                                                <div className="text-center">
                                                    <img className="img-fluid" src={no_data} />
                                                    <p className="text-right">
                                                        Please select {this.state.channel} Templte to
                                                        preview
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                );

            case 3:
                return (
                    <>
                        <div className="pb-4">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="campaign-preview">
                                        <p className="m-0">
                                            <strong> Campaign Name: </strong>
                                            <span className="">{this.state.CampaignName}</span>
                                        </p>
                                    </div>
                                    <div className="Campaign-status mt-2">
                                        <p className="m-0">
                                            <strong> Channel Selected : </strong>
                                            <span className="">{this.state.channel}</span>
                                        </p>
                                    </div>
                                    <div className="campaign-preview">
                                        <p className="m-0">
                                            <strong> Campaign Brand Number: </strong>
                                            <span className="">
                                                {this.state.seleted_brand_number}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="Campaign-status mt-2">
                                        <p className="m-0">
                                            <strong> Audiance : </strong>
                                            <span className="">{this.state.selectedListName}</span>
                                        </p>
                                    </div>
                                    <div className="Campaign-status mt-2">
                                        <p className="m-0">
                                            <strong> Campaign Template : </strong>
                                            <span className="">
                                                {this.state.templatePreView.tampleName}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="Campaign-status mt-2">
                                        <p className="m-0">
                                            <strong> Campaign Date : </strong>
                                            <span className="">{this.state.schedule_datetime}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex">
                                <BillableValue
                                    templteId={this.state.templteId}
                                    listId={this.state.listId}
                                    enableCampaingButton={this.enableCampaingButton}
                                />
                            </div>
                            <hr />
                            <div className="form-group">
                                <div className="form-check ">
                                    <p className="m-0 d-flex items-align-center">
                                        <input
                                            className="form-check-input mb-1"
                                            defaultValue={this.state.is_schedule}
                                            onChange={this.scheduleHandler}
                                            type="checkbox"
                                            value=""
                                            id="flexCheckDefault"
                                        />
                                        <strong> Campaign Schedule </strong>
                                    </p>
                                </div>
                                <small>
                                    {" "}
                                    Campaign scheduling is the process of specifying the start and
                                    end dates and times for a campaign.
                                </small>
                            </div>
                            {this.state.is_schedule === 1 && (
                                <>
                                    <p className="mt-3 d-block">
                                        Select Date and Time of Campaign
                                    </p>
                                    <div className="input-group w-25 mb-2 mr-sm-2">
                                        <div className="input-group-text">
                                            <FontAwesomeIcon icon={faAddressBook} />
                                        </div>
                                        <input
                                            type="datetime-local"
                                            className="form-control"
                                            onChange={(e) =>
                                                this.setState({ schedule_datetime: e.target.value })
                                            }
                                            placeholder="Example: campaign for digital marketing"
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                );

            default:
                return (
                    <div className="filter-option">
                        <div className="row justify-content-center">
                            <div className="col-md-4">
                                <p className="mt-3 d-block">Campaign Channel </p>
                                <div className="input-group drop-down-icons mb-2 mr-sm-2">
                                    <div className="input-group-text">
                                        <FontAwesomeIcon icon={faBroadcastTower} />
                                    </div>
                                    <select
                                        onChange={this.handleChannel}
                                        className="form-control"

                                        value={this.state.channel}

                                    >
                                        <option value={``}>Select Channel</option>
                                        <option value={`whatsapp`}>WhatsApp</option>
                                        <option value={`sms`}>SMS</option>
                                        <option value={`rcs`}>RCS</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <p className="mt-3 d-block">Select Country </p>
                                <div className="input-group drop-down-icons mb-2 mr-sm-2">
                                    <div className="input-group-text">
                                        <FontAwesomeIcon icon={faFlag} />
                                    </div>
                                    <select
                                        onChange={(e) =>
                                            this.setState({ country_code: e.target.value })
                                        }
                                        className="form-control"
                                        value={this.state.country_code}
                                    >
                                        <option value="91"> India </option>
                                        <option value="1"> United State</option>
                                        <option value="1"> US / Canada</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <p className="mt-3 d-block">Select Brand Number </p>
                                <div className="input-group drop-down-icons mb-2 mr-sm-2">
                                    <div className="input-group-text">
                                        <FontAwesomeIcon icon={faBroadcastTower} />
                                    </div>
                                    <select
                                        onChange={(e) =>
                                            this.setState({ seleted_brand_number: e.target.value, isError: false })
                                        }
                                        className="form-control"
                                        value={this.state.seleted_brand_number}
                                    >
                                        <option value={``}>Select Brand Number</option>
                                        {this.state.brandNumberList.map((item, i) => (
                                            <option key={i} value={item.brand_number}>
                                                {item.brand_number}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <p className="mt-3 d-block">Select Campaign Type </p>
                                <div className="input-group drop-down-icons mb-2 mr-sm-2">
                                    <div className="input-group-text">
                                        <FontAwesomeIcon icon={faBroadcastTower} />
                                    </div>
                                    <select
                                        value={this.state.campaignType}
                                        onChange={(e) =>
                                            this.handleCampaignType(e)
                                        }
                                        className="form-control"
                                    >
                                        <option value="1"> Normal Campaign </option>
                                        <option value="3"> Custom (Dynamic) campaign</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <p className="mt-3 d-block">Enter Custom Campaign Name</p>
                                <div className="input-group mb-2 mr-sm-2">
                                    <div className="input-group-text">
                                        <FontAwesomeIcon icon={faAddressBook} />
                                    </div>
                                    <input
                                        type="text"
                                        defaultValue={this.state.CampaignName}
                                        className="form-control"
                                        onChange={this.onNameChange}
                                        placeholder="Example: campaign for digitl marketing"
                                    />
                                </div>
                                <small className="text-primary">
                                    Example : Campaign for remarketing
                                </small>
                            </div>
                        </div>
                    </div>
                );
        }
    };

    render() {



        return (
            <div className="wrapper">
                <Helmet>
                    <style type="text/css">{`
         .whatsapp ul {
            display:none!important;
          }
        `}</style>
                </Helmet>

                {this.state.isLoading && (this.state.uploadProgress === 0) && <Spining />}
                {this.state.isLoading &&
                    (this.state.uploadProgress >= 100) &&
                    <div className={`${style.spinnerContainer} ${style.animatedText}`}>
                        Processing...
                    </div>
                }
                {this.state.isLoading &&
                    this.state.uploadProgress !== 0 &&
                    this.state.uploadProgress < 100 &&
                    <div className={style.spinnerContainer}>
                        <div className={style.spinnerWrapper}>
                            <div className={style.spinner}></div>
                            <div className={style.percentage}>
                                {this.state.uploadProgress}%
                            </div>
                            <p className={style.uploadText}>Uploading file</p>
                        </div>
                    </div>

                }
                <DashboardLeftMenu />
                <div className="main">
                    <DashboardHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className=" mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3>
                                        <strong>WhatsApp Campaign </strong>
                                    </h3>
                                </div>
                            </div>
                            <div className="">
                                <div className="card">
                                    <div className="card-body">
                                        <div id="msform" style={{ zIndex: "1", position: 'relative' }}>
                                            <ul id="progressbar">
                                                {this.state.step === 0 ? (
                                                    <>
                                                        <li className="active">Campaign Details</li>
                                                        <li>Choose Audience </li>
                                                        <li>Campaign Template </li>
                                                        <li> Preview Campaign </li>
                                                    </>
                                                ) : this.state.step === 1 ? (
                                                    <>
                                                        <li className="active">Campaign Details</li>
                                                        <li className="active">Choose Audience</li>
                                                        <li>Campaign Template </li>
                                                        <li> Preview Campaign </li>
                                                    </>
                                                ) : this.state.step === 2 ? (
                                                    <>
                                                        <li className="active">Campaign Details</li>
                                                        <li className="active">Choose Audience </li>
                                                        <li className="active">Campaign Template </li>
                                                        <li> Preview Campaign </li>
                                                    </>
                                                ) : this.state.step === 3 ? (
                                                    <>
                                                        <li className="active">Campaign Details</li>
                                                        <li className="active">Choose Audience </li>
                                                        <li className="active">Campaign Template </li>
                                                        <li className="active"> Preview Campaign </li>
                                                    </>
                                                ) : null}
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {this.state.isError && (
                                    <Alert type={this.state.messageType}>
                                        {this.state.errorMessage}
                                    </Alert>
                                )}

                                <div className="card">
                                    <div className="card-body campaign-box">
                                        {this.formRender()}

                                        <hr />

                                        <div className="row justify-content-center">
                                            <div className="col-md-4 mb-3">
                                                <button
                                                    disabled={this.state.step === 0}
                                                    onClick={() => this.prevStep()}
                                                    className={
                                                        this.state.step === 0
                                                            ? "btn btn-block btn-secondary"
                                                            : "btn btn-block btn-primary"
                                                    }
                                                >
                                                    <FontAwesomeIcon icon={faArrowCircleLeft} /> Previous
                                                </button>
                                            </div>
                                            <div className="col-md-4">
                                                {this.state.step === 3 ? (
                                                    <button
                                                        onClick={this.createCampaign}
                                                        className="btn btn-block btn-info"
                                                        disabled={this.state.enableCampaign}
                                                    >
                                                        <FontAwesomeIcon icon={faCheckCircle} /> Start
                                                        Campaign
                                                    </button>
                                                ) : (
                                                    <button
                                                        disabled={
                                                            this.state.isError ||
                                                            (this.state.campaignType === "3"
                                                                ? this.state.step === 1 &&
                                                                this.state.dynamicSampleData.length === 0 && !this.state.selectedOption
                                                                : this.state.step === 1 &&
                                                                (this.state.csvHeaders.length === 0 &&
                                                                    this.state.textnumbers === "" &&
                                                                    !this.state.selectedOption
                                                                )) || (this.state.step === 2 && this.state.templteId === "")
                                                        }
                                                        onClick={() => this.nextStep()}
                                                        className="btn btn-block btn-primary"
                                                    >
                                                        Next <FontAwesomeIcon icon={faArrowCircleRight} />
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <DashboardFooter />
                </div>
            </div>

        );
    }
}

class CustomPrevArrow extends Component {
    render() {
        return (
            <button
                onClick={this.props.onClick}
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "-1%",
                    transform: "translateY(-50%)",
                    backgroundColor: "transparent",
                    border: "none",
                    color: "black",
                    fontSize: "30px",
                    zIndex: "1",
                }}
            >
                &#10094; {/* Unicode for left arrow */}
            </button>
        );
    }
}

class CustomNextArrow extends Component {
    render() {
        return (
            <button
                onClick={this.props.onClick}
                style={{
                    position: "absolute",
                    top: "50%",
                    right: "-1%",
                    transform: "translateY(-50%)",
                    backgroundColor: "transparent",
                    border: "none",
                    color: "black",
                    fontSize: "30px",
                    zIndex: "1",
                }}
            >
                &#10095; {/* Unicode for right arrow */}
            </button>
        );
    }
}

export default withRouter(Campaign);