import React, { useEffect, useState } from "react";
import "../Dashboard/assets/css/app.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff, faWallet } from "@fortawesome/free-solid-svg-icons";
import { HOST_URL, userProfile } from "../Dashboard/api/Api";

const AdminHeader = () => {
  const lStorage =
    JSON.parse(localStorage.getItem("admin")) ||
    JSON.parse(localStorage.getItem("emp")) ||
    JSON.parse(localStorage.getItem("reseller")) ||
    JSON.parse(localStorage.getItem("client"));
  const Id = lStorage.user.userId;
  const name = lStorage.user.name;
  const Token = lStorage.token;
  const type = lStorage.user.isAdmin;

  const [balance , setBalance] = useState(0)
  // const loginthrough = localStorage.getItem("loginthrough");


  useEffect(() => {
    const verifyUser = {
      user_id: Id,
      token: Token,
      method: "verify",
      type: type,
    };
    fetch(`${HOST_URL}/authentication_data.php`, {
      method: "post",
      headers: {
        "content-Type": "application/json",
      },
      body: JSON.stringify(verifyUser),
    }).then((result) => {
      result.json().then((resp) => {
        // console.log(resp)
        if (resp.success === true) {
        } else {
          window.location = "/admin/logout";
        }
      });
    });
  }, []);

    useEffect(() => {
      fetchData();
    }, []);

    const fetchData = async () => {
      console.log("hello World")
      try {
        const response = await fetch (`${HOST_URL}/profile.php?user_id=${Id}&token=${Token}&method=retrieve`); // Assuming userProfile is an async function
        let result = await response.json();
        if (result.success) {
          // console.log(result.data[0].currency);
          setBalance(result.data[0].currency)
          
          // You can now set state here if you need to update the UI
          // setBalance(result.data.data[0].currency);
          // setCountryCurrency(result.data.data[0].country_currency);
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };



  // const goBackToAdmin = () => {
  // 	localStorage.removeItem("emp");
  // 	// localStorage.removeItem("loginthrough");
  // 	// localStorage.setItem()
  // 	window.location = "/admin";
  //   };

  return (
    <div style={{ marginTop: "2em" }}>
      <nav className="navbar navbar-expand navbar-light navbar-bg fixed-top">
        <div className="navbar-collapse navbarcollapse_none collapse float-right">
          <ul className="navbar-nav navbar-align ">
            {type!=="admin"&&<li
              style={{ position: "relative" }}
              className="nav-item border-right mr-1"
            >
              <Link
                style={{ color: "#3b7ddd" }}
                className="nav-icon mr-4"
                // href={
                //   parent_type !== "reseller" &&
                //   `${
                //     checkUserUrl(url) === true
                //       ? "/dashboard/transaction-payment"
                //       : "/dashboard/transaction-history"
                //   }`
                // }
				to="/admin"
              >
                <div className="position-relative wallet">
                  <FontAwesomeIcon icon={faWallet} />{" "}
                  <span className="wallet-price">
                    {" "}
                    {/* {country_currency}  */}
                    {/* : {parseFloat(balance).toFixed(2)} */}
                    : {balance}
                  </span>
                </div>

                <span
                  style={{
                    fontSize: 11 + "px",
                    position: "absolute",
                    left: 35 + "%",
                  }}
                >
                  Recharge
                </span>
              </Link>
            </li>}

            <li className="nav-item border-right">
              <div className="position-relative">
                <p className="mr-3 mb-0 mt-2 ">
                  {" "}
                  <strong> Welcome : {name} </strong>{" "}
                </p>
              </div>
            </li>
            <li className="nav-item">
              <Link className="nav-icon" to="/admin/logout">
                <div className="position-relative">
                  <FontAwesomeIcon icon={faPowerOff} />
                </div>
              </Link>
            </li>

            {/* {loginthrough ? (
            <li className="nav-item dropdown">
              <span
                style={{ cursor: "pointer" }}
                onClick={(e) => goBackToAdmin(e)}
                className="nav-icon"
              >
                <div color="red" className="position-relative">
                  <FontAwesomeIcon color="red" icon={faTimesCircle} />
                </div>
              </span>
            </li>
          ) : null } */}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default AdminHeader;
