import React, { useEffect, useState } from "react";
import AdminHeader from "../Header/AdminHeader";
import AdminFooter from "../Footer/AdminFooter";
import AdminLeftMenu from "../Navbar/AdminLeftMenu";
// import { userloginDetails } from "../Utils/AdminAuth";
import { HOST_URL, NODE_HOST_URL } from "../Dashboard/api/Api";
import Spining from "../UI/Spining/Spining";
import Select from "react-select";
import Axios from "axios";

const ResellerSiteSettings = () => {
  const lStorage = JSON.parse(localStorage.getItem("reseller")||localStorage.getItem("emp")||localStorage.getItem("admin"));
  const userId = lStorage?.user?.userId;
  const userToken = lStorage?.token;
  const type = lStorage?.user?.isAdmin;

  // Define separate states for each form input

  const [signupFile, setSignupFile] = useState("");
  const [dashboardFile, setDashboardFile] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [privacyPolicy, setPrivacyPolicy] = useState("");
  const [tagline, setTagline] = useState("");
  const [brandName, setBrandName] = useState("");
  const [host, setHost] = useState("");
  const [port, setPort] = useState("");
  const [service, setService] = useState("");
  const [smtpMail, setSmtpMail] = useState("");
  const [smtpPassword, setSmtpPassword] = useState("");

  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [bgColor, setBgColor] = useState("");
  const [loading, setLoading] = useState(false);
  const [isValidSignup, setIsValidSignup] = useState(false);
  const [isValidDashboard, setIsValidDashboard] = useState(false);
  const [signupLogoPreview, setSignupLogoPreview] = useState(null);
  const [dashboardLogoPreview, setDashboardLogoPreview] = useState(null);

  const [initialData, setInitialData] = useState(false);

  useEffect(() => {
    if (userId) {
      siteData();
    }
  }, [userId]);

  const handleFileChange = (e) => {
    setIsValidDashboard(false);
    setSuccessMessage(false);
    setIsValidSignup(false);
    const file = e.target.files[0];

    const fileName = e.target.name;

    if (file) {
      // Set the file to state for later use
      if (fileName === "dashboardLogo") {
        setDashboardFile(file);
      }
      if (fileName === "signupLogo") {
        setSignupFile(file);
      }

      const img = new Image(); // Create an image object
      const reader = new FileReader(); // Create a FileReader to read the file

      // On file load, set the source of the image
      reader.onload = (e) => {
        img.src = e.target.result; // Set the image source to the file data URL
      };
      reader.readAsDataURL(file); // Read the file as data URL (base64 encoded)

      // When the image is loaded, you can display the preview
      img.onload = () => {
        // If it's a dashboard logo, set the preview image URL in the state
        if (fileName === "dashboardLogo") {
          setDashboardLogoPreview(img.src); // Set the dashboard logo preview
        }

        // If it's a signup logo, set the preview image URL in the state
        if (fileName === "signupLogo") {
          setSignupLogoPreview(img.src); // Set the signup logo preview
        }
      };
    }
  };
  // if (fileName === "dashboardLogo") {
  //   if (file) {
  //     setDashboardFile(file);
  //     const img = new Image();
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       img.src = e.target.result;
  //     };
  //     reader.readAsDataURL(file);

  //     img.onload = () => {
  // const expectedRatio = 1;
  // const aspectRatio = img.width / img.height;

  // if (aspectRatio !== expectedRatio) {
  //   setIsValidDashboard(true);
  //   setSuccessMessage(true);
  //   setErrorMessage(
  //     `Uploaded image dimensions are incorrect! Expected:`
  //   );
  //   setBgColor("alert alert-danger");
  // } else {
  //   setIsValidDashboard(false);
  //   setErrorMessage("No file selected.");
  // }
  //       };
  //     }
  //   }
  //   if (fileName === "signupLogo") {
  //     if (file) {
  //       setSignupFile(file);
  //       const img = new Image();
  //       const reader = new FileReader();
  //       reader.onload = (e) => {
  //         img.src = e.target.result;
  //       };
  //       reader.readAsDataURL(file);

  //       img.onload = () => {
  //         // const expectedRatio = 1;
  //         // const aspectRatio = img.width / img.height;

  //         // if (aspectRatio !== expectedRatio) {
  //         //   setIsValidSignup(true);
  //         //   setSuccessMessage(true);
  //         //   setErrorMessage(
  //         //     `Uploaded image dimensions are incorrect! Expected: ${expectedRatio}, but got: ${img.width}x${img.height}`
  //         //   );
  //         //   setBgColor("alert alert-danger");
  //         // } else {
  //         //   setIsValidSignup(false);
  //         //   setErrorMessage("No file selected.");
  //         // }
  //       };
  //     }
  //   }
  // };

  // Handle changes for each field
  const handleInputChange = (setter) => (event) => {
    setSuccessMessage(false);
    setter(event.target.value);
  };

  const siteData = async () => {
    setLoading(true);
    try {
      const { data } = await Axios.post(`${NODE_HOST_URL}/user_setting`, {
        method: "retrieve_site_setting",
        user_id: userId,
        token: userToken,
      });

      if (data.success === true) {
        setSignupLogoPreview(data.data.signup_page_logo);

        setDashboardLogoPreview(data.data.dashboard_logo);

        setTermsAndConditions(data.data.term_and_condition);
        setPrivacyPolicy(data.data.privacy_policy);
        setBrandName(data.data.brand_name);
        setTagline(data.data.tagline);

        setInitialData(true);
      }
    } catch (error) {
      setSuccessMessage(true);
      setErrorMessage(error.message);
      setBgColor("alert alert-danger");
    }
    setLoading(false);
  };

  // Handle form submission
  const updateSettings = async (e) => {
    e.preventDefault();
    setSuccessMessage(false);
    setLoading(true);
    // const fileSizeDash = dashboardFile.size / (1024);
    // const fileSizeSignup = signupFile.size / (1024);
    // if (fileSizeDash > 100 && dashboardFile) {
    //   this.setState({
    //     isError: true,
    //     errorMessage: "dashboard logo should be less than 100kb",
    //     messageType: "alert-danger",
    //   });
    //   return;
    // }
    // if (fileSizeSignup > 100 && signupFile) {
    //   this.setState({
    //     isError: true,
    //     errorMessage: "signup logo should be less than 100kb",
    //     messageType: "alert-danger",
    //   });
    //   return;
    // }

    try {
      let signupUrl, dashboardUrl;

      if (signupFile) {
        const signupdata = new FormData();
        signupdata.append("amdfile", signupFile);
        signupdata.append("doc_name", "image");
        signupdata.append("doc_type", signupFile.type);
        signupdata.append("user_id", userId);
        signupdata.append("token", userToken);
        signupdata.append("method", "create");
        const signupres = await Axios.post(
          `${HOST_URL}/uploadFileWhatsapp.php`,
          signupdata
        );

        if (signupres.data.success === true) {
          signupUrl = signupres.data.url;
        } else {
          setSuccessMessage(true);
          setErrorMessage(signupres.data.message);
          setBgColor("alert alert-success");
        }
      }
      if (dashboardFile) {
        const dashbordData = new FormData();
        dashbordData.append("amdfile", dashboardFile);
        dashbordData.append("doc_name", "image");
        dashbordData.append("doc_type", dashboardFile.type);
        dashbordData.append("user_id", userId);
        dashbordData.append("token", userToken);
        dashbordData.append("method", "create");
        const dashres = await Axios.post(
          `${HOST_URL}/uploadFileWhatsapp.php`,
          dashbordData
        );

        if (dashres.data.success === true) {
          dashboardUrl = dashres.data.url;
        } else {
          setSuccessMessage(true);
          setErrorMessage(dashres.data.message);
          setBgColor("alert alert-success");
        }
      }

      const formPayload = {
        token: userToken,
        user_id: userId,
        method: "site_setting",
        dashboard_logo: dashboardUrl,
        term_and_condition: termsAndConditions,
        privacy_policy: privacyPolicy,
        brand_name: brandName,
        tagline: tagline,
        email_host: host,
        email_port: port,
        email_service: service,
        email: smtpMail,
        password: smtpPassword,
        signup_page_logo: signupUrl,
      };
      const { data } = await Axios.post(
        `${NODE_HOST_URL}/user_setting`,
        formPayload
      );
      if (data.success) {
        setSuccessMessage(true);
        setErrorMessage(data.message);
        setBgColor("alert alert-success");
      } else {
        setSuccessMessage(true);
        setErrorMessage(data.message);
        setBgColor("alert alert-danger");
      }
    } catch (error) {
      setSuccessMessage(true);
      setErrorMessage(error.message);
      setBgColor("alert alert-danger");
    }

    setLoading(false);
  };

  return (
    <div className="wrapper">
      <AdminLeftMenu />
      <div className="main">
        <AdminHeader />
        {loading && <Spining />}
        <main className="content">
          <div className="container-fluid p-0">
            <div className="row mb-2 mb-xl-3">
              <div className="col-auto d-none d-sm-block">
                <h3>
                  <strong>Update Website Settings</strong>
                </h3>
              </div>

              {successMessage && (
                <div className={bgColor} role="alert">
                  <div>{errorMessage}</div>
                  <button
                    type="button"
                    className="close ml-auto"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
              )}
            </div>

            {/* Start of the form */}
            <div className="row">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                      <div className="flex-fill">
                        <form onSubmit={updateSettings}>
                          <div className="row">
                            {/* Signup Page Logo */}
                            <div className="mb-3 col-md-6">
                              <label
                                className="form-label"
                                htmlFor="inputSignupLogo"
                              >
                                Signup Page Logo
                              </label>
                              <input
                                type="file"
                                name="signupLogo"
                                onChange={handleFileChange}
                                className={`form-control ${
                                  isValidSignup ? "border-danger" : ""
                                }`}
                                placeholder="Enter Logo URL"
                                accept="image/*"
                              />
                              <div
                                className="form-text text-muted d-flex align-items-center"
                                style={{ fontSize: "0.7rem" }}
                              >
                                <span>
                                  {" "}
                                  <strong>Note: </strong>For optimal display,
                                  use a logo size of 400px * 250px (Width *
                                  Height).
                                </span>
                              </div>
                              {signupLogoPreview && (
                                <div>
                                  <img
                                    src={signupLogoPreview}
                                    alt="Signup Logo Preview"
                                    style={{
                                      width: "100px",
                                      height: "auto",
                                      marginTop: "10px",
                                    }}
                                  />
                                </div>
                              )}
                            </div>

                            {/* Dashboard Page Logo */}
                            <div className="mb-3 col-md-6">
                              <label
                                className="form-label"
                                htmlFor="inputDashboardLogo"
                              >
                                Dashboard Page Logo
                              </label>
                              <input
                                type="file"
                                name="dashboardLogo"
                                onChange={handleFileChange}
                                className={`form-control ${
                                  isValidDashboard ? "border-danger" : ""
                                }`}
                                placeholder="Enter Logo URL"
                                accept="image/*"
                              />
                              <div
                                className="form-text text-muted d-flex align-items-center"
                                style={{ fontSize: "0.7rem" }}
                              >
                                <span>
                                  {" "}
                                  <strong>Note: </strong>For optimal display,
                                  use a logo size of 200px * 50px (Width *
                                  Height).
                                </span>
                              </div>
                              {dashboardLogoPreview && (
                                <div>
                                  <img
                                    src={dashboardLogoPreview}
                                    alt="Dashboard Logo Preview"
                                    style={{
                                      width: "100px",
                                      height: "auto",
                                      marginTop: "10px",
                                    }}
                                  />
                                </div>
                              )}
                            </div>

                            {/* Terms & Conditions */}
                            <div className="mb-3 col-md-6">
                              <label
                                className="form-label"
                                htmlFor="inputTermsAndConditions"
                              >
                                Terms & Conditions
                              </label>
                              <input
                                type="text"
                                onChange={handleInputChange(
                                  setTermsAndConditions
                                )}
                                value={termsAndConditions}
                                className="form-control"
                                placeholder="Enter Terms & Conditions"
                              />
                            </div>

                            {/* Privacy Policy */}
                            <div className="mb-3 col-md-6">
                              <label
                                className="form-label"
                                htmlFor="inputPrivacyPolicy"
                              >
                                Privacy Policy
                              </label>
                              <input
                                type="text"
                                onChange={handleInputChange(setPrivacyPolicy)}
                                value={privacyPolicy}
                                className="form-control"
                                placeholder="Enter Privacy Policy"
                              />
                            </div>

                            {/* Brand Name */}
                            <div className="mb-3 col-md-6">
                              <label
                                className="form-label"
                                htmlFor="inputBrandName"
                              >
                                Brand Name
                              </label>
                              <input
                                type="text"
                                onChange={handleInputChange(setBrandName)}
                                value={brandName}
                                className="form-control"
                                placeholder="Brand Name"
                              />
                            </div>

                            {/* Tagline */}
                            <div className="mb-3 col-md-6">
                              <label
                                className="form-label"
                                htmlFor="inputTagline"
                              >
                                Tagline
                              </label>
                              <input
                                type="text"
                                onChange={handleInputChange(setTagline)}
                                value={tagline}
                                className="form-control"
                                placeholder="Tagline"
                              />
                            </div>
                          </div>

                          {/* <div className="row mb-2 mb-xl-3">
                            <div className="col-auto d-none d-sm-block">
                              <h3>
                                <strong>Email - section</strong>
                              </h3>
                            </div>
                          </div> */}

                          {/* <div className="row">
                            <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                              <div className="flex-fill">
                                <div className="row">
                                 
                                  <div className="mb-3 col-md-6">
                                    <label className="form-label" htmlFor="inputHost">
                                      Host
                                    </label>
                                    <input
                                      type="text"
                                      onChange={handleInputChange(setHost)}
                                      className="form-control"
                                      placeholder="Enter Host"
                                    />
                                  </div>

                                  
                                  <div className="mb-3 col-md-6">
                                    <label className="form-label" htmlFor="inputPort">
                                      Port
                                    </label>
                                    <input
                                      type="text"
                                      onChange={handleInputChange(setPort)}
                                      className="form-control"
                                      placeholder="Enter Port"
                                    />
                                  </div>

                                  
                                  <div className="mb-3 col-md-6">
                                    <label className="form-label" htmlFor="inputService">
                                      Service
                                    </label>
                                    <input
                                      type="text"
                                      onChange={handleInputChange(setService)}
                                      className="form-control"
                                      placeholder="Enter Service"
                                    />
                                  </div>

                                 
                                  <div className="mb-3 col-md-6">
                                    <label className="form-label" htmlFor="inputSmtpMail">
                                      SMTP Mail
                                    </label>
                                    <input
                                      type="text"
                                      onChange={handleInputChange(setSmtpMail)}
                                      className="form-control"
                                      placeholder="Enter SMTP Mail"
                                    />
                                  </div>

                              
                                  <div className="mb-3 col-md-6">
                                    <label className="form-label" htmlFor="inputSmtpPassword">
                                      SMTP Password
                                    </label>
                                    <input
                                      type="password"
                                      onChange={handleInputChange(setSmtpPassword)}
                                      className="form-control"
                                      placeholder="Enter SMTP Password"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}

                          <div className="float-right">
                            {initialData === false ? (
                              <input
                                type="submit"
                                value="Create"
                                className="btn btn-sm btn-primary"
                              />
                            ) : (
                              <input
                                type="submit"
                                value="Update Settings"
                                className="btn btn-sm btn-primary"
                              />
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <AdminFooter />
      </div>
    </div>
  );
};

export default ResellerSiteSettings;
